import React, { Component } from "react";
import { Field, Formik } from "formik";
import API from "../../../../API";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import RedButton from "../../../../lib/RedButton";
import { pageStore, updateCell, updateMessageColors } from "../Canvas";
import { appStore } from "../../../../App";
import snackbarStore from "../../../../lib/SnackbarStore";
import Select from "react-select";

class NewMessageDialog extends Component {
	state = { ghostMode: false, ghostMessages: [] };

	close = () => {
		pageStore.mode === "New" &&
			window.graph.removeCells([pageStore.selectedCell]);
		pageStore.MessageDialogOpen = false;
	};

	componentDidMount() {
		setTimeout(() => {
			if (this.autoFocus) {
				this.autoFocus.focus();
			}
		}, 10);
	}

	render() {
		return this.state.ghostMode ? (
			<Dialog
				onClose={this.props.close}
				open={true}
				fullWidth
				style={{ padding: 20 }}
			>
				<DialogContent>
					<h3>Select ghost message to recover:</h3>
					<Select
						options={this.state.ghostMessages.map((message) => ({
							label: message.name,
							value: message.id,
						}))}
						onMenuOpen={() => {
							if (!this.state.menuPortalTarget) {
								this.setState({
									menuPortalTarget: document.getElementsByClassName(
										"MuiDialog-container"
									)[0],
								});
							}
						}}
						menuPortalTarget={this.state.menuPortalTarget}
						style={{
							menu: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
						}}
						value={this.state.selectedGhostMessage || ""}
						onChange={(val) => {
							this.setState({ selectedGhostMessage: val });
						}}
					/>
					<Grid container>
						<Grid item xs={7} />
						<Grid item xs={2}>
							<Button onClick={this.close}>Cancel</Button>
						</Grid>
						<Grid item xs={1} />
						<Grid item xs={2}>
							<RedButton
								onClick={async () => {
									updateCell(this.state.selectedGhostMessage.label);
									pageStore.MessageDialogOpen = false;

									updateMessageColors(this.props.selectedCell, "Backlog");
									await this.props.saveCanvas();
								}}
							>
								Save
							</RedButton>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		) : (
			<Formik
				onSubmit={async (values, { setSubmitting, setErrors }) => {
					const result = await API(`/message`, "POST", {
						name: values.name,
						canvasID: this.props.currentCanvasID,
						shapeID: this.props.selectedCell.id,
						projectID: appStore.selectedProject,
					});
					if (result.error && result.code !== "23505") {
						alert("There was an error. Please contact support.");
						setSubmitting(false);
					} else {
						if (result.error) {
							// If we received an error, but its code === 23505 then
							// the name was auto-incremented ;)
							snackbarStore.setMessage(
								"Warning",
								"Message name already existed. Appended a number."
							);
							values.name = result.name;
						}
						updateCell(values.name);
						setSubmitting(false);
						pageStore.MessageDialogOpen = false;

						updateMessageColors(this.props.selectedCell, "Backlog");

						// const mondayRes = await API("/monday/addNewMessage", "POST", {
						// 	projectID: result.data.projectID,
						// 	messageName: result.data.name,
						// 	messageID: result.data.id,
						// });
						//
						// if (mondayRes.error) {
						// 	snackbarStore.setMessage(
						// 		"Error",
						// 		"Monday sync failed. Please ensure you associate this project with a Monday board."
						// 	);
						// }

						await this.props.saveCanvas();
					}
				}}
				initialValues={{ name: pageStore.selectedCell.value, notes: "" }}
				validationSchema={Yup.object().shape({
					name: Yup.string().required("Required").max(255, "Too long"),
				})}
			>
				{(props) => {
					return (
						<Dialog onClose={this.props.close} open={true} fullWidth>
							<form onSubmit={props.handleSubmit}>
								<DialogContent>
									<h3>{pageStore.mode} Message</h3>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Field
												name="name"
												label="Message Name"
												variant="filled"
												component={TextField}
												error={!!props.errors.name}
												helperText={props.errors.name || ""}
												inputProps={{
													ref: (el) => {
														this.autoFocus = el;
													},
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Field
												name="notes"
												label="Notes"
												variant="filled"
												type="text"
												component={TextField}
												disabled
											/>
											<FormHelperText id="notes">Optional</FormHelperText>
										</Grid>
									</Grid>
								</DialogContent>
								<DialogActions>
									<Grid container>
										<Grid item xs={5} />
										<Grid item xs={2}>
											<Button
												onClick={async () => {
													const { ghostMessages } = await API(
														`/recoverMessages/${this.props.currentCanvasID}`,
														"GET"
													);

													this.setState({
														ghostMode: !this.state.ghostMode,
														ghostMessages,
													});
												}}
											>
												<span role={"img"} aria-label={"Ghost message"}>
													👻✉️
												</span>
											</Button>
										</Grid>
										<Grid item xs={2}>
											<Button onClick={this.close}>Cancel</Button>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={2}>
											<RedButton autoFocus color="primary" type="submit">
												Save
											</RedButton>
										</Grid>
									</Grid>
								</DialogActions>
							</form>
						</Dialog>
					);
				}}
			</Formik>
		);
	}
}

export default NewMessageDialog;
