import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

export const LinearProgressWithLabel = ({
	value,
	includeCircular = true,
	...props
}) => (
	<Box display="flex" alignItems="center" position="relative">
		<Box width="100%" mr={1}>
			<LinearProgress variant="determinate" {...props} value={value} />
		</Box>
		<Box minWidth={35}>
			<Typography variant="body2" color="textSecondary">{`${Math.round(
				value
			)}%`}</Typography>
		</Box>
		{includeCircular && value < 100 ? (
			<Box minWidth={20}>
				<CircularProgress size={15} variant="indeterminate" {...props} />
			</Box>
		) : null}
	</Box>
);
export default LinearProgressWithLabel;
