import React, { Component, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import API from "../../API";
import RedButton from "../../lib/RedButton";
import snackbarStore from "../../lib/SnackbarStore";
import shortID from "shortid";

export default class ManageUsers extends Component {
	state = {
		users: null,
		showEditUser: false,
		selectedUser: null,
		invitedUsers: null,
		mouseY: null,
		mouseX: null,
	};

	async componentDidMount() {
		const { users } = await API(`/users`, "GET");
		this.setState({
			users,
		});

		const invitedUsers = await API(`/userInvites`, "GET");
		this.setState({
			invitedUsers: invitedUsers.users,
		});
	}

	handleClose = () => {
		this.setState({ mouseX: null, mouseY: null });
	};

	setUsers = (users) => {
		this.setState({ users });
	};

	render() {
		return (
			<div>
				<h3 style={{ textDecoration: "underline" }}>Manage Users</h3>
				<p>Please contact support to re-enable a disabled user</p>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<CurrentUsers
							users={this.state.users}
							selectedUser={this.state.selectedUser}
							setSelectedUser={(userID) =>
								this.setState({ selectedUser: userID })
							}
							setEditUser={(bool) => this.setState({ showEditUser: bool })}
							updateUser={(user) => {
								this.setState({
									users: this.state.users.map((innerUser) =>
										innerUser.id === user.id ? user : innerUser
									),
								});
							}}
						/>
						{this.state.invitedUsers && this.state.invitedUsers.length > 0 ? (
							<InvitedUsers
								invitedUsers={this.state.invitedUsers}
								selectedUser={this.state.selectedUser}
								setSelectedUser={(userID) =>
									this.setState({ selectedUser: userID })
								}
							/>
						) : null}
					</Grid>
					<Grid item xs={6}>
						{this.state.showEditUser ? (
							<EditUserForm
								selectedUser={this.state.selectedUser}
								setShowEditUser={(showEditUser) =>
									this.setState({ showEditUser })
								}
								setUsers={this.setUsers}
								users={this.state.users}
							/>
						) : (
							<>
								<AddUserForm
									setInvitedUsers={(invitedUsers) =>
										this.setState({ invitedUsers })
									}
								/>
								<AddCommentUserForm setUsers={this.setUsers} />
							</>
						)}
					</Grid>
				</Grid>
			</div>
		);
	}
}

const InvitedUsers = ({ invitedUsers, selectedUser, setSelectedUser }) => {
	const [mousePosition, setMousePosition] = useState({
		mouseX: null,
		mouseY: null,
	});
	const handleClose = () => setMousePosition({ mouseX: null, mouseY: null });
	const handleRightClick = (e) => {
		e.preventDefault();
		setMousePosition({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		});
	};
	return (
		<Paper style={{ padding: 10, marginTop: 10 }}>
			<h3>Invited Users</h3>
			{invitedUsers.map((user) => {
				return (
					<div key={user.id}>
						<Button
							onClick={async () => {
								setSelectedUser({ selectedUser: user.id });
								snackbarStore.setMessage(
									"Info",
									"Update and delete coming soon"
								);
							}}
							onContextMenu={handleRightClick}
							style={{
								width: "100%",
								justifyContent: "left",
								textTransform: "none",
								fontWeight: selectedUser === user.id ? 700 : 400,
								background: selectedUser === user.id ? "#FEECF0" : "inherit",
							}}
						>
							{user.firstName} {user.lastName} ({user.email})
						</Button>
						<Menu
							keepMounted
							open={mousePosition.mouseY !== null}
							onClose={handleClose}
							anchorReference="anchorPosition"
							anchorPosition={
								mousePosition.mouseY !== null && mousePosition.mouseX !== null
									? {
											top: mousePosition.mouseY,
											left: mousePosition.mouseX,
									  }
									: undefined
							}
						>
							<MenuItem
								onClick={async () => {
									handleClose();
									await API(`/resendInviteEmail`, "POST", {
										id: user.id,
									});
									snackbarStore.setMessage("Success", "Invite email resent");
								}}
							>
								Resend email
							</MenuItem>
						</Menu>
					</div>
				);
			})}
		</Paper>
	);
};

const CurrentUsers = ({
	users,
	selectedUser,
	setSelectedUser,
	setEditUser,
	updateUser,
}) => {
	return (
		<Paper style={{ padding: 10, marginTop: 10 }}>
			<List>
				<ListItem key={0}>
					<ListItemText id={0} primary={<h3>Current Users</h3>} />
					<ListItemSecondaryAction>
						<h4>Admin</h4>
					</ListItemSecondaryAction>
				</ListItem>
				{users ? (
					users.map((user) => (
						<ListItem key={user.id} button>
							<ListItemAvatar>
								{user.profileImage ? (
									<div
										style={{
											fontSize: 10,
											maxWidth: 35,
											maxHeight: 35,
											borderRadius: "100%",
											overflow: "hidden",
										}}
									>
										<img
											src={`https://profile.tuzagtcs.com/${window.location.hostname.replace(
												".tuzagtcs.com",
												""
											)}/${user.id}.png`}
											style={{
												width: 35,
												height: 35,
												borderRadius: "100%",
												display: "inline",
											}}
											alt={`${user.firstName} ${user.lastName} profile`}
										/>
									</div>
								) : (
									<Avatar
										style={{
											width: 35,
											height: 35,
											display: "inline-flex",
										}}
									>
										{user.firstName ? user.firstName.substring(0, 1) : ""}
										{user.lastName ? user.lastName.substring(0, 1) : ""}
									</Avatar>
								)}
							</ListItemAvatar>
							<ListItemText>
								<Button
									onClick={async () => {
										setSelectedUser(user.id);
										setEditUser(true);
									}}
									style={{
										width: "100%",
										justifyContent: "left",
										textTransform: "none",
										fontWeight: selectedUser === user.id ? 700 : 400,
										background:
											selectedUser === user.id ? "#FEECF0" : "inherit",
									}}
									key={user.id}
								>
									{user.firstName} {user.lastName} (
									{shortID.isValid(user.email) ? "Comment User" : user.email})
								</Button>
							</ListItemText>
							<ListItemSecondaryAction>
								<Checkbox
									edge="end"
									onChange={async () => {
										const updatedUser = await API(`/user/${user.id}`, "PATCH", {
											isAdmin: !user.isAdmin,
										});
										updateUser({ ...user, ...updatedUser });
									}}
									checked={user.isAdmin}
									inputProps={{ "aria-labelledby": user.id }}
									color={user.isAdmin ? "primary" : "default"}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					))
				) : (
					<div style={{ textAlign: "center", padding: 15 }}>
						<CircularProgress />
					</div>
				)}
			</List>
		</Paper>
	);
};

const EditUserForm = ({ selectedUser, setShowEditUser, setUsers, users }) => (
	<Paper style={{ padding: 10, marginTop: 10 }}>
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-end",
			}}
		>
			<h3 style={{ flexGrow: 100 }}>Edit User</h3>
			<IconButton
				aria-label="close"
				size="small"
				onClick={() => setShowEditUser(false)}
			>
				<CloseIcon />
			</IconButton>
		</div>
		<RedButton
			onClick={async () => {
				await API(`/user/${selectedUser}`, "PATCH", { disabled: true });
				setUsers(
					users.filter((user) => {
						return user.id !== selectedUser;
					})
				);
				snackbarStore.setMessage("Success", "User has been disabled");
			}}
		>
			Disable User
		</RedButton>
	</Paper>
);

const AddUserForm = ({ setInvitedUsers }) => (
	<Paper style={{ padding: 10, marginTop: 10 }}>
		<h3>Add User</h3>
		<Formik
			onSubmit={async (values, { setSubmitting, resetForm }) => {
				const result = await API(`/userInvite`, "POST", values);
				if (result.error) {
					snackbarStore.setMessage("Error", "Something went wrong.");
				} else {
					snackbarStore.setMessage("Success", "User invite sent");
					resetForm();
				}
				setSubmitting(false);
				const { users } = await API(`/userInvites`, "GET");
				setInvitedUsers(users);
			}}
			initialValues={{ firstName: "", lastName: "", email: "" }}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().required("Required").max(255, "Too long"),
				lastName: Yup.string().required("Required").max(255, "Too long"),
				email: Yup.string().required("Required").max(255, "Too long"),
			})}
		>
			{(props) => {
				return (
					<form onSubmit={props.handleSubmit}>
						<Field
							name="firstName"
							label="First Name"
							// variant="outlined"
							type="text"
							style={{ marginTop: 15 }}
							component={TextField}
						/>
						<Field
							name="lastName"
							label="Last Name"
							// variant="filled"
							type="text"
							style={{ marginTop: 15 }}
							component={TextField}
						/>
						<Field
							name="email"
							label="Email"
							// variant="filled"
							type="email"
							style={{ marginTop: 15 }}
							component={TextField}
						/>
						<RedButton
							autoFocus
							color="primary"
							type="submit"
							style={{ marginTop: 15 }}
							disabled={props.isSubmitting}
						>
							Add
						</RedButton>
					</form>
				);
			}}
		</Formik>
	</Paper>
);

const AddCommentUserForm = ({ setUsers }) => (
	<Paper style={{ padding: 10, marginTop: 10 }}>
		<h3>Add Comment User</h3>
		<Formik
			onSubmit={async (values, { setSubmitting, resetForm }) => {
				const { users } = await API(`/commentUser`, "POST", { ...values });
				snackbarStore.setMessage("Success", "Comment user added");
				setUsers(users);
				setSubmitting(false);
			}}
			initialValues={{ firstName: "", lastName: "" }}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().required("Required").max(255, "Too long"),
				lastName: Yup.string().required("Required").max(255, "Too long"),
			})}
		>
			{(props) => {
				return (
					<form onSubmit={props.handleSubmit}>
						<Field
							name="firstName"
							label="First Name"
							// variant="outlined"
							type="text"
							style={{ marginTop: 15 }}
							component={TextField}
						/>
						<Field
							name="lastName"
							label="Last Name"
							// variant="filled"
							type="text"
							style={{ marginTop: 15 }}
							component={TextField}
						/>
						<RedButton
							autoFocus
							color="primary"
							type="submit"
							style={{ marginTop: 15 }}
							disabled={props.isSubmitting}
						>
							Add
						</RedButton>
					</form>
				);
			}}
		</Formik>
	</Paper>
);
