import React, { useState, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import AttributesTree from "./AttributesTree";
import AttributesUsed from "./AttributesUsed";
import AttributesSearchBar from "./AttributesSearchBar";
import AttributesResults from "./AttributesResults";
import API from "../../../../API";
import Button from "@material-ui/core/Button";
import {
	NewTemplateDialog,
	TemplateEditDeleteDialog,
	TemplateCRUDMenu,
	TemplateMenu,
} from "../Elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerPlus } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import client from "../../../../lib/feathers";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { appStore } from "../../../../App";
import { usePrevious } from "../../../../lib/HelperFunctions";

const Attributes = (props) => {
	const [searchResults, setSearchResults] = useState(null);
	const [usedAttributes, setUsedAttributes] = useState(null);
	const [
		templateEditDeleteDialogMode,
		setTemplateEditDeleteDialogMode,
	] = useState(false);
	const [newTemplateDialogOpen, setNewTemplateDialogOpen] = useState(false);
	const [elementTemplateMenu, setElementTemplateMenu] = useState(false);
	const [
		elementTemplateRightClickMenu,
		setElementTemplateRightClickMenu,
	] = useState();
	const [messageTemplates, setMessageTemplates] = useState([]);
	const messageTemplateRef = usePrevious(messageTemplates);
	const usedAttributesRef = usePrevious(usedAttributes);

	const { messageDBID, type, entryID, currentCanvasID, cellId } = props;

	useEffect(() => {
		client
			.service("channelManager")
			.create({ name: `messageAttributeTemplate` });

		client
			.service("messageAttributeTemplate")
			.on("created", (data) => {
				setMessageTemplates([...messageTemplateRef.current, data]);
			})
			.on("removed", (deleted) => {
				const toDelete = messageTemplateRef.current.findIndex(
					(item) => item.id === deleted.id
				);
				if (toDelete !== -1) {
					const toSet = [...messageTemplateRef.current];
					toSet.splice(toDelete, 1);
					setMessageTemplates(toSet);
				}
			})
			.on("patched", (patched) => {
				const toPatch = messageTemplateRef.current.findIndex(
					(item) => item.id === patched.id
				);
				if (toPatch !== -1) {
					const toSet = [...messageTemplateRef.current];
					toSet[toPatch] = patched;
					setMessageTemplates(toSet);
				}
			});

		(async () => {
			setMessageTemplates(
				await client.service("messageAttributeTemplate").find({})
			);
		})();

		return () => {
			client
				.service("messageAttributeTemplate")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
			client
				.service("channelManager")
				.remove({ name: `messageAttributeTemplate` });
		};
	}, [messageTemplateRef]);

	const addAttribute = async (id, name) => {
		if (searchResults) {
			setSearchResults(
				searchResults.filter((result) => {
					return result.id !== id;
				})
			);
		}

		setUsedAttributes([...usedAttributesRef.current, { id, name }]);

		if (type === "glossary") {
			await API(`/db/linkedGlossaryEntry`, "POST", {
				glossaryEntryID: entryID,
				linkedGlossaryEntryID: id,
			});
		} else {
			await API(
				`/db/${type}Attribute`,
				"POST",
				type === "message"
					? {
							attributeID: id,
							messageDBID: messageDBID,
					  }
					: {
							attributeID: id,
							[`${type}ID`]: cellId,
							canvasID: currentCanvasID,
					  }
			);
		}
	};

	const makeFromTemplate = async (template) => {
		try {
			const attributeIDs = template.attributes.split("|||");
			let toCreateDB = [];
			let toCreateState = [];
			for (const attributeID of attributeIDs) {
				const alreadyOnMessage = usedAttributes.find((attribute) => {
					return `${attribute.id}` === attributeID;
				});
				if (!alreadyOnMessage) {
					const attribute = await client
						.service("attribute")
						.find({ query: { id: attributeID } });
					if (attribute.length) {
						if (type === "brancher") {
							toCreateDB.push({
								brancherID: cellId,
								canvasID: currentCanvasID,
								attributeID: attribute[0].id,
							});
						} else {
							toCreateDB.push({ messageDBID, attributeID: attribute[0].id });
						}
						toCreateState.push({
							id: attribute[0].id,
							name: attribute[0].name,
						});
					}
				}
			}
			await client
				.service(type === "brancher" ? "brancherAttribute" : "messageAttribute")
				.create(toCreateDB);
			setUsedAttributes([...usedAttributesRef.current, ...toCreateState]);
			setElementTemplateMenu(null);
		} catch (e) {
			if (e?.message === "ValidationError") {
				this.props.enqueueSnackbar(
					"Duplicate attribute already exists on this message.",
					{
						variant: "error",
					}
				);
			} else {
				console.error(e);
				this.props.enqueueSnackbar(
					"Something went wrong using template to add attributes",
					{
						variant: "error",
					}
				);
			}
		}
	};

	return (
		<Fragment>
			<h3>{type === "glossary" ? "Glossaries" : "Attributes"}</h3>
			<div style={{ overflow: "hidden" }}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<AttributesSearchBar
							setSearchResults={setSearchResults}
							usedAttributes={usedAttributes}
							type={type}
						/>
						{searchResults !== null ? (
							<AttributesResults
								searchResults={searchResults}
								addAttribute={addAttribute}
							/>
						) : (
							<Fragment>
								{usedAttributes !== null && type !== "glossary" && (
									<AttributesTree
										setUsedAttributes={setUsedAttributes}
										usedAttributes={usedAttributes}
										messageDBID={messageDBID}
										type={type}
										entryID={entryID}
										currentCanvasID={currentCanvasID}
										cellId={cellId}
									/>
								)}
							</Fragment>
						)}
					</Grid>
					<Grid item xs={6}>
						<Tooltip
							title={`Add attributes from template${
								appStore.isAdmin
									? ". Right click to create & manage templates."
									: ""
							}`}
						>
							<ButtonGroup
								color="secondary"
								aria-label="text primary button group"
								style={{ float: "right" }}
								onClick={(event) => event.stopPropagation()}
								onFocus={(event) => event.stopPropagation()}
							>
								<Button
									onClick={(event) => {
										setElementTemplateMenu(event.currentTarget);
									}}
									onContextMenu={(event) => {
										event.preventDefault();
										setElementTemplateRightClickMenu(event.currentTarget);
									}}
								>
									<FontAwesomeIcon icon={faLayerPlus} />
								</Button>
							</ButtonGroup>
						</Tooltip>
						<AttributesUsed
							setUsedAttributes={setUsedAttributes}
							usedAttributes={usedAttributes}
							messageDBID={messageDBID}
							entryID={entryID}
							type={type}
							id={cellId}
							currentCanvasID={currentCanvasID}
						/>
					</Grid>
				</Grid>
			</div>
			<TemplateMenu
				anchorEl={elementTemplateMenu}
				onClose={() => {
					setElementTemplateMenu(null);
				}}
				messageTemplates={messageTemplates}
				makeFromTemplate={makeFromTemplate}
			/>
			<TemplateCRUDMenu
				anchorEl={elementTemplateRightClickMenu}
				onClose={() => {
					setElementTemplateRightClickMenu(null);
				}}
				openCreate={(event) => {
					event.stopPropagation();
					setElementTemplateRightClickMenu(null);
					setNewTemplateDialogOpen(true);
				}}
				openUpdate={() => {
					setElementTemplateRightClickMenu(null);
					setTemplateEditDeleteDialogMode("edit");
				}}
				openDelete={() => {
					setElementTemplateRightClickMenu(null);
					setTemplateEditDeleteDialogMode("delete");
				}}
				type="attribute"
				usedAttributes={usedAttributes}
			/>
			<TemplateEditDeleteDialog
				open={templateEditDeleteDialogMode}
				closeDialog={() => {
					setTemplateEditDeleteDialogMode(null);
				}}
				mode={templateEditDeleteDialogMode}
				messageTemplates={messageTemplates}
				type="Attribute"
			/>
			<NewTemplateDialog
				close={() => {
					setNewTemplateDialogOpen(false);
				}}
				open={newTemplateDialogOpen}
				type="Attribute"
				usedAttributes={usedAttributes}
			/>
		</Fragment>
	);
};

export default Attributes;
