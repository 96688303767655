import { useEffect, useRef } from "react";

export const generateFilters = (attributesData) => {
	return [
		...attributesData.map((row) => {
			const attribute = row.attribute;

			return {
				id: `eq-${attribute.id}`,
				name: "equal",
				type: {
					[attribute.id]: {
						view: "combo",
						options: attribute.options.map((option) => {
							return { value: option.name, id: option.id };
						}),
					},
				},
			};
		}),
		...attributesData.map((row) => {
			const attribute = row.attribute;
			return {
				id: `ne-${attribute.id}`,
				name: "not equal to",
				type: {
					[attribute.id]: {
						view: "combo",
						options: attribute.options.map((option) => {
							return { value: option.name, id: option.id };
						}),
					},
				},
			};
		}),
		{
			id: "equal",
			name: "equal",
			type: { text: "text" },
		},
		{
			id: "ne",
			name: "not equal",
			type: { text: "text" },
		},
		{
			id: "less",
			name: "less",
			type: { any: "text" },
		},
		{
			id: "less_or_equal",
			name: "less or equal",
			type: { any: "text" },
		},
		{
			id: "greater",
			name: "greater",
			type: { any: "text" },
		},
		{
			id: "greater_or_equal",
			name: "greater or equal",
			type: { any: "text" },
		},
		...attributesData.map((row) => {
			const attribute = row.attribute;

			const numberOptions = row.attribute.options
				.map((option) => parseFloat(option.name))
				.filter((option) => !isNaN(option));

			const rangeMax = numberOptions.length ? Math.max(...numberOptions) : 10;

			return {
				id: `between`,
				name: "between",
				type: {
					[attribute.id]: {
						view: "rangeslider",
						min: 0,
						max: rangeMax,
						title: window.webix.template("#value#"),
					},
					any: {
						view: "rangeslider",
						min: 0,
						max: 10,
						title: window.webix.template("#value#"),
					},
				},
			};
		}),
		...attributesData.map((row) => {
			const attribute = row.attribute;

			const numberOptions = row.attribute.options
				.map((option) => parseFloat(option.name))
				.filter((option) => !isNaN(option));

			const rangeMax = numberOptions.length ? Math.max(...numberOptions) : 10;

			return {
				id: "not_between",
				name: "not between",
				type: {
					[attribute.id]: {
						view: "rangeslider",
						min: 0,
						max: rangeMax,
						title: window.webix.template("#value#"),
					},
					any: {
						view: "rangeslider",
						min: 0,
						max: 10,
						title: window.webix.template("#value#"),
					},
				},
			};
		}),
		{
			id: "begins_with",
			name: "begins with",
			type: { any: "text" },
		},
		{
			id: "not_begins_with",
			name: "not begins with",
			type: { any: "text" },
		},
		{
			id: "contains",
			name: "contains",
			type: { any: "text" },
		},
		{
			id: "not_contains",
			name: "not contains",
			type: { any: "text" },
		},
		{
			id: "ends_with",
			name: "ends with",
			type: { any: "text" },
		},
		{
			id: "not_ends_with",
			name: "not ends with",
			type: { any: "text" },
		},
		{
			id: "is_empty",
			name: "is empty",
			type: { any: "none" },
		},
		{
			id: "is_not_empty",
			name: "is not empty",
			type: { any: "none" },
		},
		// ....attributesData.map((row) => {
		// 	const attribute = row.attribute;
		// 	return {
		// 		id: `ge-${attribute.id}`,
		// 		name: "less",
		// 		type: {
		// 			[attribute.id]: {
		// 				view: "text",
		// 			},
		// 		},
		// 	};
		// }),
	];
};

export function mapToObject(aMap) {
	const obj = {};
	aMap.forEach((v, k) => {
		obj[k] = v;
	});
	return obj;
}

export const findNode = (id, currentNode) => {
	let i, currentChild, result;

	if (id === currentNode.bitID) {
		return currentNode;
	} else {
		// Use a for loop instead of forEach to avoid nested functions
		// Otherwise "return" will not work properly
		if (currentNode.children) {
			for (i = 0; i < currentNode.children.length; i += 1) {
				currentChild = currentNode.children[i];

				// Search in the current child
				result = findNode(id, currentChild);

				// Return the result if the node has been found
				if (result !== false) {
					return result;
				}
			}
		}

		// The node has not been found and we have no more options
		return false;
	}
};

//Sets caret (input cursor) to the end of div inside selected element, currently used for mxGraph's text box only
export const placeCaretAtEnd = (el) => {
	el.focus();
	if (
		typeof window.getSelection != "undefined" &&
		typeof document.createRange != "undefined"
	) {
		var range = document.createRange();
		range.selectNodeContents(el);
		range.collapse(false);
		var sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	} else if (typeof document.body.createTextRange != "undefined") {
		var textRange = document.body.createTextRange();
		textRange.moveToElementText(el);
		textRange.collapse(false);
		textRange.select();
	}
};

export const isBrancher = (obj) => {
	return obj && obj.style && obj.style.includes("shape=isoRectangle;");
};

export const isPortal = (obj) => {
	return obj && obj.style && obj.style.includes("shape=circle;");
};

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref;
};

export const ReactSelectTheme = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary: "var(--red)",
		primary25: "#F0BCBF",
	},
});

export const ReactSelectStyles = {
	menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
	option: (styles) => Object.assign(styles, { cursor: "pointer" }),
	singleValue: (styles) => Object.assign(styles, { overflow: "visible" }),
};

export const sortBy = (key) => (list) =>
	list.sort((current, next) => {
		const left = current[key]?.toLowerCase();
		const right = next[key]?.toLowerCase();
		return left < right ? -1 : left > right ? 1 : 0;
	});

export const arrayMove = (arr, fromIndex, toIndex) => {
	let arrCopy = [...arr];
	const element = arrCopy[fromIndex];
	arrCopy.splice(fromIndex, 1);
	arrCopy.splice(toIndex, 0, element);

	return arrCopy;
};
