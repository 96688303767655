import React, { useEffect, useState } from "react";
import { action, remove } from "mobx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import client from "../../../lib/feathers";
import { contentStore, errorFilters, loadContent, init, reset } from "./store";
import ReactSelect from "react-select";
import {
	ReactSelectStyles,
	ReactSelectTheme,
} from "../../../lib/HelperFunctions";
import RedButton from "../../../lib/RedButton";
import { ErrorComponents } from "./ContentOutput";

export const ContentTesting = observer(({ onClose, ...props }) => {
	/* eslint-disable */
	useEffect(() => {
		init();
		const defaultFilter = errorFilters[0];
		onFilterChange({
			...defaultFilter,
			value: defaultFilter.id,
			label: defaultFilter.name,
		});
		return () => {
			reset();
		};
	}, []);
	/* eslint-disable */

	//-- Filters
	const onFilterChange = action(
		(option) => (contentStore.selectedErrorFilter = option)
	);

	//-- Output event handlers
	const onDelete = (output) =>
		client
			.service("contentOutput")
			.remove(output.id)
			.then(
				action(() => {
					remove(contentStore.output, output.id);
				})
			);

	const onTaskChange = action((option) => {
		contentStore.selectedTask = option;
		return loadContent(option.value);
	});

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={10}>
					<h3 style={{ textDecoration: "underline" }}>Content Testing</h3>
				</Grid>
				<Grid item xs={2} style={{ textAlign: "right" }}>
					<Link to="/home/taskManager">
						<RedButton style={{ width: "auto" }}>Task Manager</RedButton>
					</Link>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{/* Filters */}
					<Card style={{ overflow: "visible" }}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={6} style={{ paddingTop: 15 }}>
									{/* Filter By Canvas*/}
									<ReactSelect
										theme={ReactSelectTheme}
										styles={ReactSelectStyles}
										options={Object.values(contentStore.tasks).map(
											({ id, canvas }) => {
												return { label: canvas.name, value: id };
											}
										)}
										value={contentStore.selectedTask}
										onChange={onTaskChange}
										placeholder="Canvas"
									/>
								</Grid>
								<Grid item xs={6} style={{ paddingTop: 15 }}>
									{/* Filter by Error type */}
									<ReactSelect
										theme={ReactSelectTheme}
										styles={ReactSelectStyles}
										options={errorFilters.map(({ id, name }) => {
											return { label: name, value: id, id, name };
										})}
										value={contentStore.selectedErrorFilter}
										onChange={onFilterChange}
										placeholder="Error Filter"
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					{contentStore.loading ? (
						<div style={{ textAlign: "center", padding: 15 }}>
							<CircularProgress color="secondary" />
						</div>
					) : (
						<OutputErrors
							onDelete={onDelete}
							selectedTask={contentStore.selectedTask}
							tasks={contentStore.tasks}
							filteredOutput={contentStore.filteredOutput}
							selectedErrorFilter={contentStore.selectedErrorFilter}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
});

export default ContentTesting;

/**
 * @component OutputErrors - Functional component that just determines which
 *   component we need to display for the output
 *
 * - If the user hasn't selected a Canvas & a filter, display a prompt so they know that they need to
 * - If there's output to display, display it
 * - Otherwise, there's nothing to show, so let them know that
 */
const OutputErrors = ({
	selectedTask,
	selectedErrorFilter,
	filteredOutput,
}) => {
	const [taskRunningMessage, setTaskRunningMessage] = useState();

	useEffect(() => {
		(async () => {
			setTaskRunningMessage(null);

			if (selectedTask) {
				const tasks = await client
					.service("contentTask")
					.find({ query: { id: selectedTask.value } });

				if (tasks[0].status === "Stopped") {
					setTaskRunningMessage("The content generation task was stopped.");
				} else if (tasks[0]?.status === "Running") {
					setTaskRunningMessage(
						`The content is still being generated for this canvas. Progress: ${Math.round(
							tasks[0].progress * 100
						)}%`
					);
				} else if (tasks[0]?.status === "In Queue") {
					setTaskRunningMessage(
						`The content is still being generated for this canvas. It is currently in the queue.`
					);
				} else if (tasks[0].status === "Error") {
					setTaskRunningMessage(
						"There was an error generating the content for this canvas."
					);
				}
			}
		})();
	}, [selectedTask]);

	const showPrompt = !selectedTask || !selectedErrorFilter;
	const showOutput = filteredOutput.length > 0;
	const OutputComponent =
		ErrorComponents[selectedErrorFilter?.id] || ErrorComponents.default;

	if (showPrompt) {
		return <SelectionPrompt selectedTask={selectedTask} />;
	}

	if (showOutput) {
		return (
			<Card>
				<CardContent>
					{taskRunningMessage ? <p>{taskRunningMessage}</p> : null}
					<OutputComponent field={selectedErrorFilter} />
				</CardContent>
			</Card>
		);
	}

	return (
		<EmptyOutput
			selectedTask={selectedTask}
			taskRunningMessage={taskRunningMessage}
		/>
	);
};

const SelectionPrompt = ({ selectedTask }) => {
	return (
		<Card>
			<CardHeader title="No content to display" />
			<CardContent>
				{!selectedTask
					? "Select a canvas to display their test results"
					: "Select a filter to see results"}
			</CardContent>
		</Card>
	);
};

const EmptyOutput = ({ selectedTask, taskRunningMessage }) => {
	return (
		<Card>
			<CardContent>
				<h3>No Errors Found{taskRunningMessage ? " (Yet)" : null}</h3>
				{taskRunningMessage ? <p>{taskRunningMessage}</p> : null}
				<p>
					No errors found{taskRunningMessage ? " yet" : null} for{" "}
					{selectedTask.label}
				</p>
			</CardContent>
		</Card>
	);
};
