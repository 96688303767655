import React, { Component, Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import "./App.scss";
import tuzagTCS from "./img/tuzagTCS.svg";
import Paper from "@material-ui/core/Paper";
import RedButton from "./lib/RedButton";
import { Formik, Field } from "formik";
import { TextField } from "formik-material-ui";
// import Parse from "parse";
import { Redirect } from "react-router-dom";
import API from "./API";
import Cookies from "js-cookie";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import "url-search-params-polyfill";

const PasswordStrengthBar = React.lazy(() =>
	import("react-password-strength-bar")
);
const urlParams = new URLSearchParams(window.location.search);

export default class Login extends Component {
	state = {
		error: null,
		redirect: null,
		loading: false,
		passwordStrengthBar: null,
		passwordScore: null,
	};

	async componentDidMount() {
		// if (Cookies.get("token")) {
		// 	if (this.props.location.state && this.props.location.state.referrer) {
		// 		this.setState({ redirect: this.props.location.state.referrer });
		// 	} else {
		// 		this.setState({ redirect: "/" });
		// 	}
		// } else if (Cookies.get("refreshToken")) {
		// 	try {
		// 		const refreshedTokenData = await API(`/refreshToken`, "POST", {
		// 			refreshToken: Cookies.get("refreshToken"),
		// 			userID: Cookies.get("userID"),
		// 		});
		// 		if (refreshedTokenData.token) {
		// 			Cookies.set("token", refreshedTokenData.token);
		// 			if (this.props.location.state && this.props.location.state.referrer) {
		// 				this.setState({ redirect: this.props.location.state.referrer });
		// 			} else {
		// 				this.setState({ redirect: "/" });
		// 			}
		// 		} else {
		// 			Cookies.delete();
		// 			this.setState({ loading: false });
		// 		}
		// 	} catch (e) {
		// 		console.error(e);
		// 		this.setState({ loading: false });
		// 	}
		// } else {
		// 	this.setState({ loading: false });
		// }
	}

	render() {
		return this.state.redirect ? (
			<Redirect to={this.state.redirect} />
		) : (
			<div style={{ minHeight: "100vh", background: "#EEEEEE" }}>
				<Grid container style={{ paddingTop: 100 }}>
					<Grid item xs={false} sm={4} />
					<Grid
						item
						xs={12}
						sm={4}
						style={{
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<img
							src={tuzagTCS}
							style={{ width: "100%", maxWidth: 335, flex: 1 }}
							alt="tuzag TCS logo"
						/>
						{this.state.error ? (
							<Paper
								style={{
									width: "100%",
									maxWidth: 335,
									padding: 10,
									marginTop: 30,
								}}
							>
								<p style={{ color: "var(--red)" }}>{this.state.error}</p>
							</Paper>
						) : null}
						<Paper
							style={{
								flex: 1,
								width: "100%",
								maxWidth: 335,
								padding: 10,
								marginTop: 30,
								minHeight: 325,
							}}
						>
							{this.state.loading ? (
								<div
									style={{
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
										height: "100%",
									}}
								>
									<CircularProgress />
								</div>
							) : (
								<>
									<h3>Sign Up</h3>
									<Formik
										onSubmit={async (values, { setSubmitting }) => {
											console.log(urlParams.get("token"));
											const result = await API(`/signup`, "POST", {
												...values,
												token: urlParams.get("token"),
											});
											setSubmitting(false);

											if (result.error) {
												this.setState({
													error: result.error,
												});
											} else {
												Cookies.set("token", result.token);
												Cookies.set("refreshToken", result.refreshToken, {
													expires: 365,
												});
												Cookies.set("userID", result.userID, { expires: 365 });
												Cookies.set("userEmail", values.email, {
													expires: 365,
												});
												this.setState({ redirect: "/home" });
											}
										}}
										initialValues={{
											email: urlParams.get("email"),
											password: "",
											confirmPassword: "",
										}}
										validationSchema={Yup.object().shape({
											email: Yup.string()
												.required("Required")
												.max(255, "Too long"),
											password: Yup.string()
												.required()
												.test(
													"strong enough",
													"Password must be stronger",
													(value) => {
														return this.state.passwordScore >= 3;
													}
												),
											confirmPassword: Yup.string()
												.required()
												.label("Confirm password")
												.test(
													"passwords-match",
													"Passwords must match",
													function (value) {
														return this.parent.password === value;
													}
												),
										})}
									>
										{(props) => {
											return (
												<form onSubmit={props.handleSubmit}>
													<Field
														name="email"
														label="Email"
														variant="filled"
														type="email"
														autoComplete={false}
														style={{ marginTop: 30 }}
														component={TextField}
													/>
													<Field
														name="password"
														label="Password"
														variant="filled"
														type="password"
														style={{ marginTop: 15 }}
														autoComplete={false}
														component={TextField}
													/>
													<Suspense fallback={null}>
														<PasswordStrengthBar
															password={props.values.password}
															onChangeScore={(score) => {
																this.setState({ passwordScore: score });
																console.log(score);
															}}
														/>
													</Suspense>
													<Field
														name="confirmPassword"
														label="Confirm Password"
														variant="filled"
														type="password"
														style={{ marginTop: 15 }}
														component={TextField}
														autoComplete={false}
													/>
													<Grid container style={{ marginTop: 50 }}>
														<Grid item xs>
															<RedButton type="submit">Sign Up</RedButton>
														</Grid>
													</Grid>
												</form>
											);
										}}
									</Formik>
								</>
							)}
						</Paper>
						<p style={{ color: "gray", marginTop: 60, fontSize: 12 }}>
							v. {process.env.REACT_APP_VERSION}
						</p>
					</Grid>
				</Grid>
			</div>
		);
	}
}
