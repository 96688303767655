import React, { Component } from "react";
import Webix from "../../../lib/Webix";
import API from "../../../API";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash.debounce";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { appStore } from "../../../App";

export default class MessageBrowser extends Component {
	state = { searchTerm: "", results: [], openMessage: null, loading: false };

	search = debounce(async () => {
		this.setState({ loading: true });
		const { results } = await API(`/messageSearch`, "POST", {
			searchTerm: this.state.searchTerm,
			includeElements: true,
			projectID: appStore.selectedProject,
		});

		this.setState({ results, loading: false });
	}, 1000);

	render() {
		return (
			<div>
				<TextField
					autoFocus
					margin="dense"
					label="Message Name"
					fullWidth
					onChange={(evt) => {
						this.setState({ searchTerm: evt.target.value, loading: true });
						this.search();
					}}
					value={this.state.searchTerm}
				/>
				{this.state.loading ? (
					<div
						style={{
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							height: 100,
						}}
					>
						<CircularProgress />
					</div>
				) : null}
				{this.state.searchTerm ? (
					<>
						<List>
							{this.state.results.map((result, index) => {
								const open = this.state.openMessage === result.id;
								return (
									<div key={result.id}>
										<ListItem
											selected={index === this.state.selectedIndex}
											onClick={() => {
												if (open) {
													this.setState({ openMessage: false });
												} else {
													this.setState({ openMessage: result.id });
												}
											}}
											style={{ cursor: "pointer" }}
										>
											{result.name}
											{open ? <ExpandLess /> : <ExpandMore />}
										</ListItem>
										<Collapse in={open} timeout="auto" unmountOnExit>
											<List component="div" disablePadding>
												{result.elements.map((element) => {
													return (
														<ListItem
															button
															style={{ paddingLeft: 25 }}
															key={element.id}
															onClick={() => {
																this.props.insertLME(
																	{ name: element.name, id: element.id },
																	{
																		name: result.name,
																		id: result.id,
																	}
																);
															}}
														>
															{element.name}
														</ListItem>
													);
												})}
											</List>
										</Collapse>
									</div>
								);
							})}
						</List>
					</>
				) : (
					<Webix
						ui={{
							id: "messageTree",
							view: "tree",
							select: true,
							multiselect: false,
							drag: false,
							editable: false,
							type: {
								folder: function (obj, common) {
									//if open folder
									if (obj.open && obj.type !== "message") {
										return `<i class="fas fa-folder-open webix_tree_folder_open"></i>`;
									}
									// if closed folder
									else if (
										(obj.$count || obj.type === "folder") &&
										obj.type !== "message"
									) {
										return `<i class="fas fa-folder webix_tree_folder"></i>`;
									} else if (obj.type === "element") {
										return `<div className="webix_tree_item" style="display: inline; padding-left: 18px;">
															<span> -</span>
													</div>`;
									} else {
										return `<div className="webix_tree_item" style="display: inline;">
														<img 
														src="/img/file.svg" 
														style="height: 22px; padding-left: 4px; padding-right: 4px; margin-bottom: -2px;"/>
														</div>`;
									}
								},
							},
							url: {
								$proxy: true,
								load: async (view, params) => {
									const data = await API(
										`/messageTree?parent=${params ? params.parent : ""}`,
										"GET"
									);
									this.setState({ loading: false });
									return data;
								},
							},
							on: {
								onSelectChange: async (evt) => {
									const item = window.$$("messageTree").getItem(evt[0]);
									const parentItem = window
										.$$("messageTree")
										.getItem(item.parent);

									if (item.type === "element") {
										this.props.insertLME(
											{ name: item.value, id: parseInt(item.id) },
											{
												name: parentItem.value,
												id: parseInt(item.parent.replace("A-", "")),
											}
										);
									}
								},
							},
						}}
					/>
				)}
			</div>
		);
	}
}
