import React, { useState, Fragment, useEffect } from "react";
import Webix from "../../lib/Webix";
import { observer } from "mobx-react";
import { observe } from "mobx";
import API from "../../API";
import snackbarStore from "../../lib/SnackbarStore";
import { appStore } from "../../App";
import { withRouter } from "react-router";

const FileManager = observer((props) => {
	const [changeUpdate, setchangeUpdate] = useState(true);

	useEffect(() => {
		let disposer;
		setchangeUpdate(false);
		disposer = observe(appStore, "selectedProject", (change) => {
			if (
				change.newValue !== change.oldValue &&
				appStore.selectedProject &&
				change.oldValue !== null
			) {
				setchangeUpdate(true);
				setchangeUpdate(false);
			}
		});

		return () => {
			if (disposer) {
				disposer();
			}
		};
	}, []);

	return (
		<Fragment>
			{changeUpdate ? (
				<div>Loading...</div>
			) : (
				<div>
					<Webix
						ui={{
							id: "folderTree",
							view: "edittree",
							select: true,
							multiselect: false,
							drag: true,
							on: {
								onItemDblClick: async (id, e, node) => {
									if (id.includes("A-")) {
										const { data } = await API(
											"/db/customQuery/shape",
											"POST",
											{
												query: "findOne",
												options: {
													where: { id: id.replace("A-", "") },
													attributes: ["canvasID", "shapeID"],
													raw: true,
												},
											}
										);

										if (data.shapeID) {
											props.history.push(
												`/filemanager/${data.canvasID}/${data.shapeID}`
											);
										} else {
											snackbarStore.setMessage(
												"Error",
												"Something went wrong opening that message"
											);
										}
									}
								},
							},
							type: {
								folder: function (obj) {
									//if open folder
									if (obj.type === "message") {
										return `<i class="fas fa-envelope"></i>`;
									} else if (obj.type === "folder" && obj.open) {
										return `<i class="fas fa-folder-open webix_tree_folder_open"></i>`;
									}
									// if closed folder
									else if (obj.$count || obj.type === "folder") {
										return `<i class="fas fa-folder webix_tree_folder"></i>`;
									} else {
										return `<i class="fas fa-sticky-note"></i>`;
									}
								},
							},
							url: {
								$proxy: true,
								load: async (view, params) => {
									const data = await API(
										`/messageTree?parent=${
											params ? params.parent : ""
										}&elementsDisabled=true`,
										"GET"
									);

									return data;
								},
							},
						}}
					/>
				</div>
			)}
		</Fragment>
	);
});

export default withRouter(FileManager);
