import React, { useEffect, useState } from "react";
import { action, toJS } from "mobx";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dropzone from "react-dropzone";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import "rc-tree/assets/index.css";
import { appStore } from "../../../App";
import snackbarStore from "../../../lib/SnackbarStore";
import { attributeImporter } from "./attributeImporter";
import {
	TreeStore,
	loadTreeData,
	createAttribute,
	createFolder,
	updateAttribute,
	updateFolder,
} from "./store";
import CircularProgressWithLabel from "../../../lib/CircularProgressWithLabel";

/**
 * @component AttributeDialog - Dialog form for denoting the name & type of Attribute
 * to add to the tree.
 * Note: When the attribute is added, its parent will be based on the
 * 'selected' key/val pair in the TreeStore
 */
export const AttributeDialog = observer(() => {
	const [attributeType, setAttributeType] = useState("raw");
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);

	const onSubmit = async () => {
		setLoading(true);
		const { parentFolderID } = await createAttribute(TreeStore, {
			name,
			type: attributeType,
			projectID: toJS(appStore.selectedProject),
		});
		await loadTreeData(parentFolderID);
		return onCancel();
	};

	const onCancel = action(() => {
		setAttributeType(null);
		setName("");
		setLoading(false);
		TreeStore.modal.attributeOpen = false;
	});

	return (
		<Dialog open={true}>
			<form onSubmit={onSubmit}>
				<DialogTitle id="form-dialog-title" style={{ minWidth: 450 }}>
					Attribute Name?
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<TextField
								autoFocus
								label="name"
								fullWidth
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Grid>
						<Grid item xs={4}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Type:</FormLabel>
								<RadioGroup
									name="attributeType"
									value={attributeType}
									onChange={(e) => setAttributeType(e.target.value)}
								>
									<FormControlLabel
										value="raw"
										control={<Radio />}
										label="Raw"
									/>
									<FormControlLabel
										value="direct"
										control={<Radio />}
										label="Direct List"
									/>
									<FormControlLabel
										value="calculated"
										control={<Radio />}
										label="Calculated List"
									/>
									<FormControlLabel
										value="retrieved"
										control={<Radio />}
										label="Retrieved"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel} color="primary">
						Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						disabled={loading || !attributeType || "" === name}
						onClick={onSubmit}
					>
						Add
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
});

/**
 * @component AttributeFolderDialog - Dialog form for adding an Attribute Folder
 */
export const AttributeFolderDialog = observer(() => {
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);

	const onSubmit = async () => {
		setLoading(true);
		const { parentFolderID } = await createFolder(TreeStore, {
			name,
			projectID: toJS(appStore.selectedProject),
		});
		await loadTreeData(parentFolderID);
		return onCancel();
	};

	const onCancel = action(() => {
		setName("");
		setLoading(false);
		TreeStore.modal.folderOpen = false;
	});

	return (
		<Dialog open={true} onClose={onCancel}>
			<form onSubmit={onSubmit}>
				<DialogTitle id="form-dialog-title" style={{ minWidth: 450 }}>
					Folder Name?
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								autoFocus
								label="name"
								fullWidth
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel} color="primary">
						Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						disabled={loading || "" === name}
						onClick={onSubmit}
					>
						Add
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
});

export const EditNameDialog = ({
	loading,
	name,
	setName,
	save,
	cancel,
	label = "Folder Name",
}) => {
	const onSubmit = (e) => {
		e.preventDefault();
		save({ name })
			.then((data) => {
				if (data) {
					snackbarStore.setMessage("Success", `${name} saved`);
				}
				return data;
			})
			.catch((error) =>
				error === null ? Promise.resolve(null) : Promise.reject(error)
			);
	};

	return (
		<Dialog open={true} onClose={cancel}>
			<form onSubmit={onSubmit}>
				<DialogTitle id="form-dialog-title" style={{ minWidth: 450 }}>
					{label}
				</DialogTitle>
				<DialogContent>
					{!loading ? (
						<TextField
							label={label}
							onChange={(e) => setName(e.target.value)}
							value={name}
							autoFocus
							fullWidth
						/>
					) : (
						<div style={{ justifyContent: "center", display: "flex" }}>
							<CircularProgress size={28} />
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => cancel()} color="primary">
						Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						disabled={loading || "" === name}
						onClick={onSubmit}
					>
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

/**
 * @component EditAttributeDialog - Dialog that allows users to edit an Attribute name
 * @param {{  data: Object }}
 */
export const EditAttributeDialog = ({ data, loading, ...props }) => {
	const [name, setName] = useState("");
	const save = async () => {
		await updateAttribute(TreeStore, { name, id: data.id });
		cancel();
	};

	const cancel = action(() => {
		TreeStore.modal.editAttributeOpen = false;
	});

	useEffect(() => {
		if (!loading && data.name) {
			setName(data.name);
		}
	}, [loading, data.name]);

	return (
		<EditNameDialog
			save={save}
			cancel={cancel}
			name={name}
			setName={setName}
			loading={loading}
			label="Attribute Name"
			{...props}
		/>
	);
};

/**
 * @component EditFolderDialog - Dialog that allows users to edit an Attribute Folder name
 * @param {{  data: Object }}
 */
export const EditFolderDialog = ({ data, loading, ...props }) => {
	const [name, setName] = useState("");
	const save = async ({ name }) => {
		await updateFolder(TreeStore, { name, id: data.id });
		cancel();
		snackbarStore.setMessage("Success", `${name} saved`);
	};

	const cancel = action(() => {
		TreeStore.modal.editFolderOpen = false;
	});

	useEffect(() => {
		if (!loading && data.name) {
			setName(data.name);
		}
	}, [loading, data.name]);

	return (
		<EditNameDialog
			save={save}
			cancel={cancel}
			name={name}
			setName={setName}
			loading={loading}
			label="Folder Name"
			{...props}
		/>
	);
};

export const UploadDialog = observer(({ onImportComplete = null }) => {
	const [loading, setLoading] = useState(null);
	const close = action(() => {
		TreeStore.modal.uploadOpen = false;
	});

	const onDrop = (files) => {
		const projectID = appStore.selectedProject;
		setLoading(0);

		return attributeImporter(projectID, files[0], setLoading)
			.then((arg) => {
				setLoading(100);
				setTimeout(() => {
					if (!document.hidden) {
						close();
					}
				}, 5000);
				return arg;
			})
			.catch((e) => {
				console.error(e);
				snackbarStore.message = "Import Failed";
				snackbarStore.severity = "Error";
				snackbarStore.open = true;
				setLoading(null);
				return Promise.reject(e);
			})
			.finally(() => (onImportComplete ? onImportComplete() : null));
	};

	let toReturn;
	if (loading === null) {
		toReturn = (
			<Dropzone onDrop={onDrop}>
				{({ getRootProps, getInputProps }) => (
					<section>
						<div
							{...getRootProps()}
							style={{
								width: "100%",
								height: 75,
								borderWidth: 2,
								borderColor: "darkGray",
								borderStyle: "dashed",
								borderRadius: 5,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<input {...getInputProps()} />
							<p>Drag 'n' drop some files here, or click to select files</p>
						</div>
					</section>
				)}
			</Dropzone>
		);
	} else if (loading === 100) {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<FontAwesomeIcon
					icon={faCheckCircle}
					style={{ fontSize: 46, color: "green" }}
				/>
			</div>
		);
	} else if (loading) {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<CircularProgressWithLabel value={loading} />
			</div>
		);
	} else {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<CircularProgress />
			</div>
		);
	}

	return (
		<Dialog onClose={close} open={true} fullWidth>
			<DialogContent>
				<h3 style={{ textDecoration: "underline" }}>Upload CSV</h3>
				{toReturn}
			</DialogContent>
			<DialogActions>
				<Grid container>
					<Grid item xs={10} />
					<Grid item xs={2}>
						<Button onClick={close}>
							{loading === 100 ? "Close" : "Cancel"}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
});
