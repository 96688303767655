import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import RedButton from "../../../lib/RedButton";
import API from "../../../API";
import { CircularProgress } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class Testing extends Component {
	state = {
		loading: true,
		elements: [],
		checkedElements: [],
		loadingContent: false,
		tailoringCriteria: {},
		content: [],
	};

	loadElements = async () => {
		let { data } = await API(`/db/customQuery/element`, "POST", {
			query: "find",
			options: {
				where: { messageDBID: this.props.messageDBID },
				raw: true,
				attributes: ["name", "id"],
				order: [["id", "ASC"]],
			},
		});

		let attributesData = await API(
			`/messageRulesData/${this.props.messageDBID}`,
			"GET"
		);

		this.setState({
			elements: data,
			loading: false,
			attributesData: attributesData.data,
			tailoringCriteria: {},
		});
	};

	componentDidMount() {
		this.loadElements();
	}

	showPreview = async () => {
		this.setState({ loadingContent: true });

		let toSend = {};
		for (const [key, value] of Object.entries(this.state.tailoringCriteria)) {
			toSend[key] = value;
		}

		if (this.state.checkedElements.length !== this.state.elements.length) {
			toSend.limitToElementIDs = this.state.checkedElements;
		}
		toSend.includeFormatting = true;

		const content = await API(
			`/message/${this.props.messageDBID}`,
			"POST",
			toSend
		);

		this.setState({
			loadingContent: false,
			content: Object.entries(content)
				.sort((a, b) => {
					if (a[0] < b[0]) return -1;
					else if (a[0] > b[0]) return 1;
					return 0;
				})
				.filter((item) => {
					return !["messageType", "linkedAttributes", "checkboxes"].includes(
						item[0]
					);
				}),
		});
	};

	isJsonString = (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};

	render() {
		if (this.state.loading) {
			return (
				<div style={{ justifyContent: "center", display: "flex" }}>
					<CircularProgress size={28} />
				</div>
			);
		}
		return (
			<div>
				<h3>Quick Preview</h3>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						Select Elements:
						<FormControlLabel
							style={{ display: "block" }}
							label="Select all"
							control={
								<Checkbox
									checked={
										this.state.checkedElements.length ===
										this.state.elements.length
									}
									onChange={(evt) => {
										if (evt.target.checked) {
											this.setState({
												checkedElements: this.state.elements.map(
													(element) => element.id
												),
											});
										} else {
											this.setState({
												checkedElements: [],
											});
										}
									}}
								/>
							}
						/>
						{this.state.elements.map((element) => {
							return (
								<FormControlLabel
									key={element.id}
									style={{ display: "block" }}
									label={element.name}
									control={
										<Checkbox
											checked={this.state.checkedElements.includes(element.id)}
											onChange={(evt) => {
												if (evt.target.checked) {
													this.setState({
														checkedElements: [
															...this.state.checkedElements,
															element.id,
														],
													});
												} else {
													this.setState({
														checkedElements: this.state.checkedElements.filter(
															(checkedElement) => {
																return checkedElement !== element.id;
															}
														),
													});
												}
											}}
										/>
									}
								/>
							);
						})}
					</Grid>
					<Grid item xs={4}>
						Tailoring Criteria:
						{this.state.attributesData.map((row) => {
							if (
								row.attribute.type === "raw" ||
								row.attribute.type === "retrieved"
							) {
								return (
									<TextField
										key={row.attribute.id}
										label={row.attribute.name}
										variant="outlined"
										style={{ marginTop: 5, marginBottom: 5 }}
										value={
											this.state.tailoringCriteria[row.attribute.name] || ""
										}
										onChange={(evt) => {
											let tailoringCriteria = this.state.tailoringCriteria;
											tailoringCriteria[row.attribute.name] = evt.target.value;
											this.setState({ tailoringCriteria });
										}}
									/>
								);
							} else {
								return (
									<Autocomplete
										key={row.attribute.id}
										options={row.attribute.options.map((option) => {
											return option.name;
										})}
										style={{ marginTop: 10, marginBottom: 10 }}
										value={
											this.state.tailoringCriteria[row.attribute.name] || null
										}
										onChange={(evt, val) => {
											let tailoringCriteria = this.state.tailoringCriteria;
											tailoringCriteria[row.attribute.name] = val;
											this.setState({ tailoringCriteria });
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label={row.attribute.name}
												variant="outlined"
											/>
										)}
									/>
								);
							}
						})}
					</Grid>
					<Grid item xs={6}>
						<Paper style={{ padding: 10 }}>
							{this.state.loadingContent ? (
								<div style={{ justifyContent: "center", display: "flex" }}>
									<CircularProgress size={28} />
								</div>
							) : (
								<>
									<RedButton onClick={this.showPreview}>Show Preview</RedButton>
									{this.state.content.map((data) => {
										const element = data[0];
										const content = data[1];
										return (
											<div
												style={{ marginTop: 10, marginBottom: 10 }}
												key={element}
											>
												<p style={{ textDecoration: "underline" }}>{element}</p>
												{this.isJsonString(content) ? (
													<code>{content}</code>
												) : (
													<div
														className="previewContent"
														dangerouslySetInnerHTML={{ __html: content }}
													/>
												)}
											</div>
										);
									})}
								</>
							)}
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}
