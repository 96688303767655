import React, { Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faPlus } from "@fortawesome/pro-solid-svg-icons";

const AttributesResults = (props) => {
	const { searchResults, addAttribute } = props;

	return (
		<Fragment>
			{searchResults !== null && (
				<Paper
					elevation={10}
					className="paperPadding"
					style={{ marginTop: 10 }}
				>
					<label style={{ fontWeight: 700, fontFamily: "Raleway, sans-serif" }}>
						Attributes Results:
					</label>
					<List>
						{Array.isArray(searchResults) ? (
							searchResults.map((result, index) => {
								return (
									<ListItem
										key={result.id}
										button
										onClick={() => {
											addAttribute(result.id, result.name);
										}}
									>
										<ListItemText
											primary={
												<span>
													{result.name}{" "}
													{result.projectID ? null : (
														<FontAwesomeIcon icon={faGlobe} />
													)}
												</span>
											}
										/>
										<IconButton edge="end">
											<FontAwesomeIcon icon={faPlus} style={{ fontSize: 12 }} />
										</IconButton>
									</ListItem>
								);
							})
						) : (
							<div>{searchResults}</div>
						)}
					</List>
				</Paper>
			)}
		</Fragment>
	);
};

export default AttributesResults;
