import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import Cookies from "js-cookie";
import { batchClient } from "feathers-batch/client";

let apiURL = process.env.REACT_APP_SOCKET_URL || `http://localhost:3010`;

if (process.env.REACT_APP_ENV === "staging") {
	apiURL = "https://apistaging.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "prod") {
	apiURL = "https://api.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "engagementrx") {
	apiURL = "https://api.engagementrx.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "sensable") {
	apiURL = "https://api.sensable.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "stagingerx") {
	apiURL = "https://api.stagingerx.tuzagtcs.com";
}

export const socket = io(apiURL);
const client = feathers();
client.configure(auth({}));
client.configure(socketio(socket));

socket.on("reconnect", () => client.reAuthenticate(true));

const logout = () => {
	Cookies.remove("token");
	Cookies.remove("userID");
	Cookies.remove("refreshToken");
	Cookies.remove("userEmail");
	client.service("channelManager").remove({ name: `authenticated` });
	window.location = "/login";
};

(async () => {
	if (Cookies.get("userEmail")) {
		try {
			await client.reAuthenticate();
			client.configure(
				batchClient({
					batchService: "batch",
				})
			);

			setTimeout(() => {
				//MZM 12-8-20
				// it bothers me that this needs to run in a timeout, but it doesn't seem
				//to want to work without it, and the server side code to add all new
				//connections to the authenticated channel doesn't appear to be working
				client.service("channelManager").create({ name: `authenticated` });
			}, 1000);
		} catch (e) {
			console.error(e);
			if (process.env.REACT_APP_ENV !== "local") {
				logout();
			}
		}
	}
})();

export default client;
