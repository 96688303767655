import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import RedButton from "../../lib/RedButton";
import API from "../../API";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withRouter } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import snackbarStore from "../../lib/SnackbarStore";
const { Text } = require("slate");

const serialize = (node) => {
	try {
		if (node.bitID) {
			return `[[BIT: ${node.label}]]`;
		}

		if (node.lme) {
			return `[[LINK: ${node.label}]]`;
		}

		if (node.passthrough) {
			return `[[PASSTHROUGH: ${node.label}]]`;
		}

		if (node.elementID && node.messageID) {
			return `[[LINK ${node.label}]]`;
		}
	} catch {
		return "Undisplayable content";
	}

	if (Text.isText(node)) {
		return node.text;
	}

	const content = node.children.map((n) => serialize(n));

	return content.join("");
};

const serializeWrapper = (nodes) => {
	let content = "";
	for (const node of nodes) {
		content += serialize(node);
	}
	return content;
};

const Search = (props) => {
	const [query, setQuery] = useState("");
	const [elements, setElements] = useState();
	const [bitCases, setBitCases] = useState();
	const [replace, setReplace] = useState("");
	const [replaceElement, setReplaceElement] = useState("");

	const search = async (evt) => {
		evt.preventDefault();

		const { elements, bitCases } = await API("/search", "POST", { query });
		setElements(elements);
		setBitCases(bitCases);
	};

	const findAndReplace = async (evt) => {
		evt.preventDefault();

		await API(`/findAndReplace`, "POST", { query, replace, replaceElement });

		snackbarStore.setMessage("Success", "Find and replace done");

		setElements(null);
		setBitCases(null);
	};

	return (
		<>
			<Grid container={true} spacing={2}>
				<Grid item xs={6}>
					<Paper style={{ padding: 10, marginTop: 10 }}>
						<h3>Search</h3>
						<p style={{ paddingBottom: 10 }}>
							Warning: search is case sensitive. <code>Search</code> will not
							match <code>search</code>.
						</p>
						<form onSubmit={search}>
							<TextField
								label={"Content"}
								variant="outlined"
								value={query}
								onChange={(evt) => {
									setQuery(evt.target.value);
								}}
							/>
							{query && query !== "" ? (
								<RedButton type="submit" style={{ marginTop: 15 }}>
									Search
								</RedButton>
							) : null}
						</form>
					</Paper>
					{elements || bitCases ? (
						<Paper style={{ padding: 10, marginTop: 10 }}>
							<h3>Find and Replace</h3>
							<form onSubmit={findAndReplace}>
								<TextField
									label={"Replace with"}
									variant="outlined"
									value={replace}
									onChange={(evt) => {
										setReplace(evt.target.value);
									}}
								/>
								<TextField
									style={{ marginTop: 10 }}
									label="Element name (leave blank for all)"
									variant="outlined"
									value={replaceElement}
									onChange={(evt) => {
										setReplaceElement(evt.target.value);
									}}
								/>
								{query && query !== "" ? (
									<RedButton type="submit" style={{ marginTop: 15 }}>
										Replace
									</RedButton>
								) : null}
							</form>
						</Paper>
					) : null}
				</Grid>
				{elements || bitCases ? (
					<Grid item xs={6}>
						{elements && elements.length ? (
							<Paper style={{ padding: 10, marginTop: 10 }}>
								<h3>Elements</h3>
								<ElementSearchResults elements={elements} />
							</Paper>
						) : null}
						{bitCases && bitCases.length ? (
							<Paper style={{ padding: 10, marginTop: 10 }}>
								<h3>BitCases</h3>
								<BitCaseSearchResults bitCases={bitCases} />
							</Paper>
						) : null}
						{elements &&
						bitCases &&
						elements.length === 0 &&
						bitCases.length === 0 ? (
							<Paper style={{ padding: 10, marginTop: 10 }}>
								<h3>Search Results</h3>
								<p style={{ marginTop: 10, marginBottom: 10 }}>
									No matching results. Go fish.
								</p>
								<img
									src="https://media.giphy.com/media/baPIkfAo0Iv5K/source.gif"
									alt="nothing"
								/>
							</Paper>
						) : null}
					</Grid>
				) : null}
			</Grid>
		</>
	);
};

export const ElementSearchResults = withRouter(
	({ elements, history, hideContent = false }) => {
		return (
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Message</TableCell>
							<TableCell>Element</TableCell>
							{!hideContent && <TableCell>Element Content</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{elements.map((element) => {
							const content = hideContent
								? ""
								: serializeWrapper(JSON.parse(element.content));
							return (
								<TableRow
									key={element.id}
									onClick={() => {
										history.push(
											`/canvas/${element.message.canvasID}/${element.message.shapeID}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<TableCell>{element.message.name}</TableCell>
									<TableCell>{element.name}</TableCell>
									{!hideContent && (
										<TableCell>
											{content.length > 175 ? (
												<Tooltip title={content}>
													<p>{content.substring(0, 200).trim()}...</p>
												</Tooltip>
											) : (
												content
											)}
										</TableCell>
									)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
);

const BitCaseSearchResults = withRouter(({ bitCases, history }) => {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Message</TableCell>
						<TableCell>Element</TableCell>
						<TableCell>Bit Case Content</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{bitCases.map((bitCase) => {
						const content = serializeWrapper(JSON.parse(bitCase.content));
						return (
							<TableRow
								key={bitCase.id}
								onClick={() => {
									history.push(
										`/canvas/${bitCase.bit.element.message.canvasID}/${bitCase.bit.element.message.shapeID}`
									);
								}}
								style={{ cursor: "pointer" }}
							>
								<TableCell>{bitCase.bit.element.message.name}</TableCell>
								<TableCell>{bitCase.bit.element.name}</TableCell>
								<TableCell>
									{content.length > 175 ? (
										<Tooltip title={content}>
											<p>{content.substring(0, 200).trim()}...</p>
										</Tooltip>
									) : (
										content
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
});

export default Search;
