import React, { useEffect, useState } from "react";
import { action } from "mobx";
import "rc-tree/assets/index.css";
import {
	TreeStore,
	createEntry,
	createFolder,
	updateEntry,
	updateFolder,
} from "./store";
import { EditNameDialog } from "../AttributeBuilder/AttributeDialogs";

/**
 * @component EntryDialog - Create a new Glossary Entry
 */
export const EntryDialog = ({ selected, ...props }) => {
	const [name, setName] = useState("");

	const save = async () => {
		await createEntry(TreeStore, { name });
		cancel();
	};

	const cancel = action(() => {
		TreeStore.modal.entryOpen = false;
	});
	return (
		<EditNameDialog
			save={save}
			cancel={cancel}
			name={name}
			setName={setName}
			loading={false}
			label="Glossary Entry Name"
			{...props}
		/>
	);
};

/**
 * @component FolderDialog - Create a new Glossary Entry Folder
 */
export const FolderDialog = (props) => {
	const [name, setName] = useState("");

	const save = async () => {
		await createFolder(TreeStore, { name });
		cancel();
	};

	const cancel = action(() => {
		TreeStore.modal.folderOpen = false;
	});
	return (
		<EditNameDialog
			save={save}
			cancel={cancel}
			name={name}
			setName={setName}
			loading={false}
			label="Glossary Folder Name"
			{...props}
		/>
	);
};

export const EditEntryDialog = ({ data, loading, ...props }) => {
	const [name, setName] = useState("");

	const save = async () => {
		await updateEntry(TreeStore, { name, id: data.id });
		cancel();
	};

	const cancel = action(() => {
		TreeStore.modal.editEntryOpen = false;
	});

	useEffect(() => {
		if (!loading && data.name) {
			setName(data.name);
		}
	}, [loading, data.name]);

	return (
		<EditNameDialog
			save={save}
			cancel={cancel}
			name={name}
			setName={setName}
			loading={loading}
			label="Glossary Entry Name"
			{...props}
		/>
	);
};

export const EditFolderDialog = ({ data, loading, ...props }) => {
	const [name, setName] = useState("");

	const save = async ({ name }) => {
		await updateFolder(TreeStore, { name, id: data.id });
		cancel();
	};

	const cancel = action(() => {
		TreeStore.modal.editFolderOpen = false;
	});

	useEffect(() => {
		if (!loading && data.name) {
			setName(data.name);
		}
	}, [loading, data.name]);

	return (
		<EditNameDialog
			save={save}
			cancel={cancel}
			name={name}
			setName={setName}
			loading={loading}
			label="Folder Name"
			{...props}
		/>
	);
};
