import React, { Component } from "react";
import API from "../API";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import snackbarStore from "./SnackbarStore";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { appStore } from "../App";
import { observe } from "mobx";

/**
 * FIXME: CR 2020-Oct-01 - Replace this with the correct endpoints once we move
 *     EngagementRx & Sensable into their own backend apps
 */
const otherResponsePathMap = {
	engagementrx: "/engagementrx/otherResponse/",
	sensable: "/sensable/otherResponse/",
	default: "/chat/otherResponse/",
};

const otherResponsePath =
	otherResponsePathMap[process.env.REACT_APP_ENV] ||
	otherResponsePathMap.default;

export default class UnmatchedUserResponses extends Component {
	state = { currentPage: 1 };

	componentWillUnmount() {
		if (this.disposer) {
			this.disposer();
		}
	}

	async componentDidMount() {
		//this is hardcoded ATM, but should be updated to pull from a dynamic URL set in project management
		this.disposer = observe(appStore, "selectedProject", async (change) => {
			if (change.newValue !== change.oldValue) {
				this.load();
			}
		});

		this.load();
	}

	load = async () => {
		if (appStore.selectedProject) {
			// let otherResponses;
			const idSuffix = this.props.messageListenerAttributeID
				? this.props.messageListenerAttributeID
				: "";
			let otherResponses = await API(
				`/chat/otherResponse/${idSuffix}?dashboard=${!!this.props
					.dashboard}&projectID=${appStore.selectedProject}`,
				"GET",
				{}
			);
			try {
				const externalOtherResponses = await API(
					`${otherResponsePath}${idSuffix}?dashboard=${!!this.props
						.dashboard}&projectID=${appStore.selectedProject}`,
					"GET"
				);
				otherResponses.otherResponses = externalOtherResponses.otherResponses;
			} catch (error) {
				// FIXME: CR 2020-Oct-01 - Review if we need to handle this exception here
				//    OR if we can be smarter than Chris by proactively avoid firing the request via some configuration flag
				console.error(error);
				otherResponses.otherResponses = otherResponses.otherResponses || [];
			}
			let matchOptions = [];

			if (!this.props.dashboard) {
				const options = otherResponses?.attribute?.options || [];
				for (const option of options) {
					if (option.glossaryID && option.glossaryEntry) {
						matchOptions.push({
							glossary: true,
							name: option.glossaryEntry.name,
							option,
						});
					} else {
						matchOptions.push({ glossary: false, name: option.name, option });
					}
				}

				matchOptions.push({ name: "Ignore", glossary: false, ignore: true });
			}

			this.setState({
				otherResponses: otherResponses.otherResponses,
				matchOptions,
			});
		}
	};

	render() {
		return (
			<Paper style={{ padding: 15, marginTop: 15 }}>
				<h3>Unmatched User Responses</h3>
				{!this.state.otherResponses ||
				this.state.otherResponses.length === 0 ? (
					<p>No other responses to match</p>
				) : (
					<>
						<TableContainer component={Paper}>
							<Table style={{ width: "100%" }}>
								<TableHead>
									<TableRow>
										{this.props.dashboard ? (
											<>
												<TableCell>Message</TableCell>
												<TableCell>Attribute</TableCell>
											</>
										) : null}
										<TableCell>User Response</TableCell>
										<TableCell>Option</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.otherResponses.map((otherResponse, index) => {
										if (
											index >= (this.state.currentPage - 1) * 10 &&
											index < this.state.currentPage * 10
										) {
											if (this.props.dashboard) {
												return (
													<MatchRowDashboard
														key={otherResponse.id}
														otherResponse={otherResponse}
													/>
												);
											} else {
												return (
													<MatchRowTechnical
														key={otherResponse.id}
														otherResponse={otherResponse}
														matchOptions={this.state.matchOptions}
														dashboard={this.props.dashboard}
													/>
												);
											}
										} else {
											return null;
										}
									})}
								</TableBody>
							</Table>
						</TableContainer>
						{this.state.otherResponses.length > 10 ? (
							<div style={{ textAlign: "center", paddingTop: 15 }}>
								<Pagination
									count={Math.ceil(this.state.otherResponses.length / 10)}
									page={this.state.currentPage}
									onChange={(evt, page) => {
										this.setState({ currentPage: page });
									}}
								/>
							</div>
						) : null}
					</>
				)}
			</Paper>
		);
	}
}

class MatchRowTechnical extends Component {
	state = { matchOption: null };

	render() {
		return (
			<TableRow>
				<TableCell>{this.props.otherResponse.content}</TableCell>
				<TableCell>
					<Autocomplete
						disableClearable
						options={this.props.matchOptions}
						getOptionLabel={(option) => {
							return `${option.glossary ? "📖 " : ""}${option.name}`;
						}}
						value={this.state.matchOption}
						onChange={async (evt, val) => {
							this.setState({
								matchOption: val,
							});

							let id;

							if (!val.ignore) {
								if (val.glossary) {
									id = val.option.glossaryID;
								} else {
									id = val.option.id;
								}
							}

							await API(`/singleSynonym`, "POST", {
								synonymToAdd: this.props.otherResponse.content.substring(
									0,
									254
								),
								type: val.glossary ? "glossary" : "option",
								id,
								otherResponseID: this.props.otherResponse.id,
								ignore: !!val.ignore,
							});

							snackbarStore.setMessage(
								"Success",
								"User response matched to option"
							);
						}}
						renderInput={(params) => (
							<TextField {...params} label="Option" variant="outlined" />
						)}
					/>
				</TableCell>
			</TableRow>
		);
	}
}

class MatchRowDashboard extends Component {
	state = { matchOption: null, matchOptions: [] };

	render() {
		return (
			<TableRow>
				<TableCell>
					<Link
						to={{
							pathname: `/canvas/${this.props.otherResponse.message.canvasID}/${this.props.otherResponse.message.shapeID}`,
							state: { selectedTab: "technical" },
						}}
					>
						{this.props.otherResponse.message.name}
					</Link>
				</TableCell>
				<TableCell>{this.props.otherResponse.attribute.name}</TableCell>
				<TableCell>{this.props.otherResponse.content}</TableCell>
				<TableCell>
					<Autocomplete
						disableClearable
						options={this.state.matchOptions}
						getOptionLabel={(option) => {
							return `${option.glossary ? "📖 " : ""}${option.name}`;
						}}
						loading={this.state.matchOptions.length === 0}
						onOpen={async () => {
							if (this.state.matchOptions.length === 0) {
								const otherResponses = await API(
									`/chat/otherResponse/${this.props.otherResponse.attributeID}?dashboard=true`,
									"GET",
									{}
								);

								let matchOptions = [];

								for (const option of otherResponses.attribute.options) {
									if (option.glossaryID) {
										matchOptions.push({
											glossary: true,
											name: option.glossaryEntry.name,
											option,
										});
									} else {
										matchOptions.push({
											glossary: false,
											name: option.name,
											option,
										});
									}
								}

								matchOptions.push({
									name: "Ignore",
									glossary: false,
									ignore: true,
								});

								this.setState({
									otherResponses: otherResponses.otherResponses,
									matchOptions,
								});
							}
						}}
						value={this.state.matchOption}
						onChange={async (evt, val) => {
							this.setState({
								matchOption: val,
							});

							let id;

							if (!val.ignore) {
								if (val.glossary) {
									id = val.option.glossaryID;
								} else {
									id = val.option.id;
								}
							}

							await API(`/singleSynonym`, "POST", {
								synonymToAdd: this.props.otherResponse.content,
								type: val.glossary ? "glossary" : "option",
								id,
								otherResponseID: this.props.otherResponse.id,
								ignore: !!val.ignore,
								project: this.props.otherResponse.message.projectID || null,
							});

							snackbarStore.setMessage(
								"Success",
								"User response matched to option"
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Option"
								variant="outlined"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{params.loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</TableCell>
			</TableRow>
		);
	}
}
