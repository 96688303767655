import { observer } from "mobx-react";
import React, { Component } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import RedButton from "../../../../lib/RedButton";
import { pageStore } from "../Canvas";
import API from "../../../../API";
import FormikReactSelectCreatable from "../../../../lib/FormikReactSelectCreatable";
import debounce from "lodash.debounce";
import { TextField } from "formik-material-ui";

const NewPortalDialog = observer(
	class extends Component {
		state = { edit: false };
		close = () => {
			pageStore.mode === "New" &&
				window.graph.removeCells([pageStore.selectedCell]);
			pageStore.PortalDialogOpen = false;
		};

		componentDidMount() {
			setTimeout(() => {
				if (this.autoFocus) {
					this.autoFocus.focus();
				}
			}, 10);
		}

		constructor(props) {
			super(props);

			//this is the only way I could get debounce working without being in async &
			//callback hell. Just don't ask me why regular debounce doesn't work.
			//https://github.com/JedWatson/react-select/issues/3075#issuecomment-506647171
			const loadOptions = (inputValue, callback) => {
				this.loadOptions(inputValue).then((results) => callback(results));
				// Explicitly not returning a Promise.
				return;
			};
			this.debouncedLoadOptions = debounce(loadOptions, 500);
		}

		loadOptions = (search) => {
			return API(`/portalOptions`, "POST", {
				search,
				canvasID: this.props.currentCanvasID,
			});
		};

		render() {
			return (
				<Formik
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(false);

						const name = values.name.value || values.name;
						const selectedCell = window.graph.getSelectionCell();

						window.graph.getModel().beginUpdate();
						window.graph.cellLabelChanged(selectedCell, name, false);

						if (pageStore.mode === "New") {
							window.graph.setCellStyles("strokeWidth", "4");
							window.graph.setCellStyles("strokeColor", "#FF331C");
						}

						//MZM 2-19-21
						//Text is off centered on portals and the fix is buried in the mxGraph
						//code, which is where this fix should *actually* take place.
						window.graph.setCellStyles("spacingLeft", "-8");
						window.graph.getModel().endUpdate();

						pageStore.PortalDialogOpen = false;
					}}
					initialValues={{
						name: {
							value: pageStore.selectedCell.value,
							label: pageStore.selectedCell.value,
						},
						notes: "",
					}}
					validationSchema={Yup.object().shape({
						name: Yup.string().required("Required").max(255, "Too long"),
					})}
					innerRef={(ref) => {
						this.form = ref;
					}}
				>
					{(props) => {
						return (
							<Dialog
								onClose={this.props.close}
								open={true}
								fullWidth
								id="portalDialog"
							>
								<form onSubmit={props.handleSubmit}>
									<DialogContent>
										<h3>{pageStore.mode} Portal</h3>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												{this.state.edit ? (
													<Field
														name="name"
														label="Portal Name: "
														variant="filled"
														type="text"
														component={TextField}
														error={!!props.errors.name}
														helperText={props.errors.name || ""}
														inputProps={{
															ref: (el) => {
																this.autoFocus = el;
															},
														}}
													/>
												) : (
													<FormikReactSelectCreatable
														isClearable
														name="name"
														loadOptions={this.debouncedLoadOptions}
														label="Portal Name: "
														dialog={
															document.getElementsByClassName(
																"MuiDialog-scrollPaper"
															)[0]
														}
													/>
												)}
											</Grid>
											<Grid item xs={6}></Grid>
										</Grid>
									</DialogContent>
									<DialogActions>
										<Grid container>
											<Grid item xs={this.state.edit ? 8 : 6} />
											<Grid item xs={2}>
												<Button onClick={this.close}>Cancel</Button>
											</Grid>
											{!this.state.edit && (
												<Grid item xs={2}>
													<Button
														onClick={() => {
															this.form.setFieldValue(
																"name",
																this.form.values.name.value || ""
															);
															this.setState({ edit: true });
														}}
													>
														Edit
													</Button>
												</Grid>
											)}
											<Grid item xs={2}>
												<RedButton autoFocus color="primary" type="submit">
													Save
												</RedButton>
											</Grid>
										</Grid>
									</DialogActions>
								</form>
							</Dialog>
						);
					}}
				</Formik>
			);
		}
	}
);

export default NewPortalDialog;
