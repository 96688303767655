import React, { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import PowerIcon from "@material-ui/icons/Power";
import CircularProgress from "@material-ui/core/CircularProgress";
import { observer } from "mobx-react";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { autorun, observable } from "mobx";
import moment from "moment-timezone";
import { Animated } from "react-animated-css";
import "animate.css";
import { usePrevious } from "./HelperFunctions";

const styles = {
	root: {
		display: "flex",
		alignItems: "center",
	},
	wrapper: {
		margin: 0,
		position: "relative",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	fabProgress: {
		color: green[500],
		position: "absolute",
		top: -6,
		left: -6,
		zIndex: 1,
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
};

export const savingQueue = observable([]);

const GlobalSaveIndicator = ({
	classes,
	style = {
		position: "fixed",
		zIndex: 1301,
		bottom: process.env.REACT_APP_ENV === "local" ? 55 : 110,
		left: 10,
	},
}) => {
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [lastSave, setLastSave] = useState(null);
	const [lastSaveFormatted, setLastSaveFormatted] = useState(
		"last save unknown"
	);
	const [display, setDisplay] = useState(false);
	const loadingRef = usePrevious(loading);

	useEffect(() => {
		autorun(() => {
			if (savingQueue.length && !loadingRef.current) {
				setSuccess(false);
				setLoading(true);
				setDisplay(true);
			} else {
				setLoading(false);
				setSuccess(true);
				setLastSave(new Date());
				setDisplay(false);
			}
		});
	}, [loadingRef]);

	const renderLastSave = () => {
		if (lastSave) {
			setLastSaveFormatted(`${moment().diff(lastSave, "seconds")} seconds ago`);
		} else {
			setLastSaveFormatted("last save unknown");
		}
	};

	return (
		<Animated isVisible={display} animationOutDelay={5000}>
			<div className={classes.root} style={style}>
				<Tooltip title={lastSaveFormatted} onOpen={renderLastSave}>
					<div className={classes.wrapper}>
						<Fab
							aria-label="save"
							color="primary"
							className={success ? classes.buttonSuccess : ""}
						>
							{success ? <CheckIcon /> : <PowerIcon />}
						</Fab>
						{loading && (
							<CircularProgress size={68} className={classes.fabProgress} />
						)}
					</div>
				</Tooltip>
			</div>
		</Animated>
	);
};

export default observer(withStyles(styles)(GlobalSaveIndicator));
