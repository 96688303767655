import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { autorun } from "mobx";
import API from "../../API";
import { appStore, usersStore } from "../../App";

export default class ManageTeam extends Component {
	state = {
		users: null,
		loading: true,
		selectedUser: null,
		projectTeam: [],
	};

	async componentDidMount() {
		this._ismounted = true;

		if (!appStore.selectedProject) {
			this.setState({ loading: false });
		}
		autorun(async () => {
			if (appStore.selectedProject && this._ismounted) {
				const { users } = await API(`/users`, "GET");
				const { data } = await API(`/db/customQuery/projectTeam`, "POST", {
					query: "find",
					options: {
						where: { projectID: appStore.selectedProject },
						attributes: ["id", "userID", "isArchitect"],
					},
				});

				this.setState({
					loading: false,
					users,
					projectTeam: data,
				});
			}
		});
	}

	componentWillUnmount() {
		this._ismounted = false;
	}

	updateUsersStore = async () => {
		//update the usersStore for use elsewhere in the app
		const { users } = await API(`/commentsUsers`, "POST", {
			env: window.location.hostname.replace(".tuzagtcs.com", ""),
			projectID: appStore.selectedProject,
		});
		usersStore.users = users;
	};

	async addTeamMember(user) {
		const { data } = await API(`/db/projectTeam`, "POST", {
			userID: user.id,
			projectID: appStore.selectedProject,
		});

		this.setState({
			projectTeam: [...this.state.projectTeam, data],
		});

		this.updateUsersStore();
	}

	async removeTeamMember(user) {
		this.setState({
			projectTeam: this.state.projectTeam.filter(
				(projectTeam) => projectTeam.userID !== user.id
			),
		});
		await API(`/db/customQuery/projectTeam`, "POST", {
			query: "delete",
			options: {
				where: {
					userID: user.id,
					projectID: appStore.selectedProject,
				},
			},
		});
		this.updateUsersStore();
	}

	async toggleArchitect(user, isArchitect) {
		const { data } = await API("/db/projectTeam", "PUT", {
			data: {
				userID: user.id,
				projectID: appStore.selectedProject,
				isArchitect: !isArchitect,
			},
		});
		const projectTeam = this.state.projectTeam
			.filter((teamMember) => teamMember.userID !== user.id)
			.concat(data);
		this.setState({
			projectTeam,
		});
	}

	render() {
		return (
			<div>
				<h3 style={{ textDecoration: "underline" }}>Manage Team</h3>
				{!appStore.selectedProject && !this.state.loading ? (
					<Paper
						style={{
							padding: 10,
						}}
					>
						<p>Please select a project in the top right first</p>
					</Paper>
				) : null}
				{this.state.loading ? (
					<div style={{ textAlign: "center", padding: 15 }}>
						<CircularProgress />
					</div>
				) : null}
				{this.state.users && !this.state.loading && (
					<Grid justify="space-between" spacing={1} container>
						<Grid xs={6} item>
							<TeamMembersTable
								users={this.state.users}
								projectTeam={this.state.projectTeam}
								addTeamMember={(...args) => this.addTeamMember(...args)}
								removeTeamMember={(...args) => this.removeTeamMember(...args)}
								toggleArchitect={(...args) => this.toggleArchitect(...args)}
							/>
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}

const TeamMembersTable = ({
	users,
	projectTeam,
	addTeamMember,
	removeTeamMember,
	toggleArchitect,
}) => {
	const projectTeamUserIds = projectTeam.map((member) => member.userID);
	return (
		<TableContainer component={Paper}>
			<Table aria-label="Team Members">
				<TableHead>
					<TableRow>
						<TableCell align="left">Name</TableCell>
						<TableCell align="center">Member</TableCell>
						<TableCell align="center">Architect</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map((user) => {
						const isOnTeam = projectTeamUserIds.includes(user.id);
						const userIsArchitect =
							isOnTeam &&
							projectTeam.reduce(
								(value, { isArchitect, userID }) =>
									userID === user.id ? isArchitect : value,
								false
							);
						return (
							<TableRow key={user.id}>
								<TableCell align="left">
									{user.firstName} {user.lastName}
								</TableCell>
								<TableCell align="center" padding="checkbox">
									<Checkbox
										edge="end"
										checked={isOnTeam}
										onChange={async () => {
											const fn = isOnTeam ? removeTeamMember : addTeamMember;
											await fn(user);
										}}
										color={isOnTeam ? "primary" : "default"}
									/>
								</TableCell>
								<TableCell align="center" padding="checkbox">
									<Checkbox
										edge="end"
										checked={userIsArchitect || false}
										disabled={!isOnTeam}
										onChange={async () =>
											isOnTeam
												? await toggleArchitect(user, userIsArchitect)
												: null
										}
										color={userIsArchitect ? "primary" : "default"}
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
