import Cookies from "js-cookie";
import snackbarStore from "./lib/SnackbarStore";
import { logError } from "./lib/ErrorLogger.js";
import client from "./lib/feathers";
let apiURL = process.env.REACT_APP_API_URL || `http://localhost:3000`;

if (process.env.REACT_APP_ENV === "staging") {
	apiURL = "https://apistaging.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "prod") {
	apiURL = "https://api.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "engagementrx") {
	apiURL = "https://api.engagementrx.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "sensable") {
	apiURL = "https://api.sensable.tuzagtcs.com";
} else if (process.env.REACT_APP_ENV === "stagingerx") {
	apiURL = "https://api.stagingerx.tuzagtcs.com";
}

const API = async (
	url,
	method,
	data = {},
	errorCatcher,
	formData,
	enqueueSnackbar,
	retryAfterTokenRefresh = false
) => {
	const timestamp = new Date();
	try {
		let response;
		if (formData) {
			response = await fetch(`${apiURL}${url}`, {
				headers: {
					token: Cookies.get("token"),
				},
				method,
				body: formData,
			});
		} else {
			response = await fetch(`${apiURL}${url}`, {
				headers: {
					"Content-Type": "application/json",
					token: Cookies.get("token"),
				},
				method,
				body: method === "GET" ? null : JSON.stringify(data),
			});
		}
		const json = await response.json();

		if (json.error) {
			if (
				json.code === 401 &&
				window.location.pathname !== "/login" &&
				url !== "/refreshToken" &&
				window.location.pathname !== "/signup"
			) {
				const refreshedTokenData = await API(`/refreshToken`, "POST", {
					refreshToken: Cookies.get("refreshToken"),
					userID: Cookies.get("userID"),
					tcsVersion: process.env.REACT_APP_VERSION,
				});
				if (refreshedTokenData.token && !retryAfterTokenRefresh) {
					Cookies.set("token", refreshedTokenData.token);
					return API(url, method, data, errorCatcher, formData, null, true);
				} else {
					Cookies.remove("token");
					Cookies.remove("refreshToken");
					Cookies.remove("userID");
					window.location = "/login";
				}
			} else if (errorCatcher) {
				errorCatcher(json);
			} else if (json.code === "23505") {
				if (enqueueSnackbar) {
					enqueueSnackbar("Duplicate name already exists.", {
						variant: "error",
					});
				} else {
					snackbarStore.setMessage("Error", "Duplicate name already exists.");
				}

				console.error(json);
			} else if (json.code === "22P02") {
				snackbarStore.setMessage(
					"Error",
					"Save error. Refresh TCS to prevent further save errors."
				);
				console.error(json);
				console.log(`URL: ${url}`);
			} else {
				console.error(json);
				console.log(`URL: ${url}`);
			}
		}
		return json;
	} catch (e) {
		if (e.message?.toLowerCase() === "failed to fetch") {
			return handleFailedToFetch(
				{ timestamp, originalError: e },
				url,
				method,
				data,
				errorCatcher,
				formData,
				enqueueSnackbar,
				retryAfterTokenRefresh
			);
		}
		logError(e, {
			method,
			apiUrl: url,
			requestPayload: { ...data },
		});
		if (errorCatcher) {
			errorCatcher(e);
		} else {
			snackbarStore.setMessage(
				"Error",
				"Please contact support. API Call failed."
			);
			console.error(e);
			console.log(`API Call Failed Debug Info:`);
			console.log(url, method, data);
			return e;
		}
	}
};

export default API;
export { apiURL };

const handleFailedToFetch = ({ timestamp, originalError }, ...args) =>
	new Promise((resolve, reject) => {
		// eslint-disable-next-line no-unused-vars
		const [url, method, data, _, __, ___, retryAfterTokenRefresh] = args;
		logError(originalError, {
			method,
			originalError,
			retryAfterTokenRefresh,
			apiUrl: url,
			requestPayload: { ...data },
			socketIsConnected: client.io.connected,
			originalTimestamp: timestamp,
		});

		setTimeout(() => {
			const retryTimestamp = new Date();
			API(...args)
				.then(resolve)
				.catch((err) => {
					logError(err, {
						method,
						originalError,
						retryTimestamp,
						retryAfterTokenRefresh,
						apiUrl: url,
						requestPayload: { ...data },
						socketIsConnected: client.io.connected,
						originalTimestamp: timestamp,
					});
					return reject(err);
				});
		}, 2000);
	});
