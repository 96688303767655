import React, { useState, useCallback } from "react";
import debounce from "lodash.debounce";
import MaterialTextField from "@material-ui/core/TextField";
import { appStore } from "../../../../App";
import API from "../../../../API";

const AttributesSearchBar = (props) => {
	const [searchTerm, setSearchTerm] = useState("");
	const { setSearchResults, usedAttributes, type } = props;

	const sendQuery = async (query, attributes) => {
		const { data } = await API(
			`/${type === "glossary" ? "glossary" : "attribute"}/search/`,
			"POST",
			{
				projectID: appStore.selectedProject,
				searchTerm: query,
				notIn: Array.isArray(attributes)
					? attributes.map((item) => {
							return item.id;
					  })
					: [],
			}
		);
		if (data.length > 0) {
			setSearchResults(data);
		} else {
			setSearchResults(
				`No ${type === "glossary" ? "glossaries" : "attributes"} found`
			);
		}
	};

	const delayedQuery = useCallback(
		debounce((q, attributes) => {
			if (q) {
				sendQuery(q, attributes);
			} else {
				setSearchResults(null);
			}
		}, 500),
		[]
	);

	const onChangeHandler = (e) => {
		const attributes = usedAttributes;
		setSearchResults(null);
		setSearchTerm(e.target.value);
		delayedQuery(e.target.value, attributes);
	};
	return (
		<div>
			<MaterialTextField
				label={`${type === "glossary" ? "Glossary" : "Attribute"} Name`}
				onChange={onChangeHandler}
				value={searchTerm}
				variant="filled"
			/>
		</div>
	);
};

export default AttributesSearchBar;
