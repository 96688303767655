import React, { useEffect, useState, useCallback } from "react";
import RedButton from "../../../lib/RedButton";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import prettier from "prettier/standalone";
import plugins from "prettier/parser-babel";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import { withSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import API from "../../../API";
import { CircularProgress } from "@material-ui/core";
import debounce from "lodash.debounce";
import mouseTrap from "react-mousetrap";
import Button from "@material-ui/core/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const getMatches = (string, pattern, index = 1) => {
	let result = [];

	const matches = string.match(pattern);
	if (matches) {
		for (let i = 0; i < matches.length; i++) {
			result.push(
				new RegExp(pattern.source, pattern.flags).exec(matches[i])[index]
			);
		}
	}
	return result;
};

const CalculatedEditor = withSnackbar(
	({ id, enqueueSnackbar, bindShortcut, unbindShortcut, type }) => {
		const [paneOpen, setPaneOpen] = useState(false);
		const [code, setCode] = useState();
		const [keys, setKeys] = useState({});
		const [result, setResult] = useState(null);
		const [attributeName, setAttributeName] = useState();

		const save = async (evt) => {
			if (evt) {
				evt.preventDefault();
			}

			try {
				const formattedCode = prettier.format(code, {
					parser: "babel",
					useTabs: true,
					plugins: [plugins],
				});

				setCode(formattedCode || "");

				await API(`/db/${type}`, "POST", {
					id,
					code: formattedCode.trim() || null,
				});
			} catch (e) {
				console.warn(e);
				enqueueSnackbar("Error formatting code", {
					variant: "warning",
				});
			}
		};

		//MZM Fuckin' functional components. Debounce doesn't work unless you wrap it
		//in useCallback
		const checkForReqData = useCallback(
			debounce(async (codeToCheck = code || "") => {
				if (codeToCheck) {
					const regex = /(?:reqData|attributes)(?:\["history"])?\[\s*["']([\S\s]+?)["']\s*]/g;
					const detectedKeys = [...new Set(getMatches(codeToCheck, regex))];
					let newKeys = {};
					for (const key of detectedKeys) {
						newKeys[key] = keys[key] || "";
					}
					setKeys(newKeys);
				}
			}, 1000),
			[]
		);

		const closePane = (event) => {
			save(event);
			// document.getElementsByClassName("webix_tree")[0].focus();
			setPaneOpen(false);
			unbindShortcut(["mod+s"]);
			unbindShortcut(["esc"]);
			unbindShortcut(["option+c"]);
		};

		bindShortcut(["mod+s"], (event) => {
			save(event);
		});

		bindShortcut(["esc"], (event) => {
			closePane(event);
		});

		bindShortcut(["option+c"], (event) => {
			if (!paneOpen) {
				setPaneOpen(true);
			} else {
				closePane(event);
			}
		});

		useEffect(() => {
			(async () => {
				const { data } = await API(`/db/${type}/${id}`, "GET");
				setAttributeName(data.name);
				setCode(data?.code || " ");
				checkForReqData(data.code);
			})();

			return () => {
				unbindShortcut(["mod+s"]);
				unbindShortcut(["esc"]);
				unbindShortcut(["option+c"]);
			};

			//eslint-disable-next-line
		}, [id]);

		const run = async () => {
			await save();
			let { result } = await API(`/message/calculatedTest`, "POST", {
				code,
				keys,
			});
			if (typeof result === "object") {
				result = JSON.stringify(result);
			}
			setResult(result || "WARNING: No Result");
		};

		return (
			<div style={{ marginBottom: 20 }}>
				{code && code.trim() ? (
					<RedButton
						onClick={() => {
							setPaneOpen(true);
						}}
					>
						Open Code Editor
					</RedButton>
				) : (
					<Button
						variant="outlined"
						onClick={() => {
							setPaneOpen(true);
						}}
						style={{ width: "100%" }}
						color="primary"
					>
						Open Code Editor
					</Button>
				)}
				<Dialog
					fullScreen
					TransitionComponent={Transition}
					open={paneOpen}
					PaperProps={{
						style: {
							backgroundColor: "#f5f5f5",
						},
					}}
				>
					<AppBar style={{ position: "relative" }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								aria-label="close"
								onClick={closePane}
							>
								<CloseIcon />
							</IconButton>
							{attributeName ? <h6>{attributeName}</h6> : null}
						</Toolbar>
					</AppBar>
					{code ? (
						<Grid container spacing={2} style={{ padding: 30, margin: -20 }}>
							<Grid item xs={6}>
								<AceEditor
									mode="javascript"
									theme="github"
									onChange={(newValue) => {
										setCode(newValue || " ");
										checkForReqData(newValue);
									}}
									value={code}
									width="auto"
									tabSize={2}
									onLoad={(editor) => {
										editor.session.$worker.send("setOptions", [
											{
												undef: true, // enable warnings on undefined variables
												globals: ["reqData", "attributes", "moment", "db"],
											},
										]);
									}}
								/>
								<RedButton onClick={save} style={{ marginTop: 20 }}>
									Save
								</RedButton>
							</Grid>
							<Grid item xs={6}>
								{Object.keys(keys).map((key) => {
									const value = keys[key];
									return (
										<TextField
											key={key}
											variant="outlined"
											label={key}
											style={{ marginBottom: 10 }}
											value={value}
											onChange={(evt) => {
												setKeys({ ...keys, [key]: evt.target.value });
											}}
										/>
									);
								})}
								<RedButton onClick={run}>Run</RedButton>
								{result ? (
									<div
										style={{
											backgroundColor: "white",
											border: "1px solid black",
											padding: 10,
											fontSize: 16,
											marginTop: 15,
											paddingRight: 15,
										}}
									>
										<p>Output:</p>
										<code>{result}</code>
									</div>
								) : null}
							</Grid>
						</Grid>
					) : (
						<div
							style={{
								justifyContent: "center",
								display: "flex",
								marginTop: 15,
							}}
						>
							<CircularProgress />
						</div>
					)}
				</Dialog>
			</div>
		);
	}
);

export default mouseTrap(CalculatedEditor);
