import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import "canvas-toBlob";
import API from "../../API";
import Cookies from "js-cookie";
import shortid from "shortid";
import snackbarStore from "../../lib/SnackbarStore";

export default class ManageProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			image: `https://profile.tuzagtcs.com/${window.location.hostname.replace(
				".tuzagtcs.com",
				""
			)}/${Cookies.get("userID")}.png`,
			scale: 1,
			change: false,
		};
	}

	handleDrop = (dropped) => {
		this.setState({ image: dropped[0] });
	};

	save = async () => {
		if (this.editor) {
			// If you want the image resized to the canvas size (also a HTMLCanvasElement)
			const canvas = this.editor.getImageScaledToCanvas();
			await canvas.toBlob(async (blob) => {
				const formData = new FormData();
				formData.append("file", blob);
				const env = window.location.hostname.replace(".tuzagtcs.com", "");
				await API(`/profileUpload?env=${env}`, "POST", null, null, formData);
				this.setState({
					change: false,
					image: `https://profile.tuzagtcs.com/${window.location.hostname.replace(
						".tuzagtcs.com",
						""
					)}/${Cookies.get("userID")}.png?refresh=${shortid.generate()}`,
				});
				snackbarStore.setMessage(
					"Success",
					"Profile image has been updated. It may take an hour for it to update across TCS"
				);
			});
		}
	};

	render() {
		return (
			<Paper style={{ padding: 15 }}>
				<h3>Manage Profile</h3>
				<p>Profile Image:</p>
				{this.state.change ? (
					<>
						<Dropzone
							onDrop={this.handleDrop}
							noClick
							noKeyboard
							style={{ width: "300px", height: "300px" }}
						>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()}>
									<p>Drag and drop a new image onto the circle below</p>
									<AvatarEditor
										width={300}
										height={300}
										image={this.state.image}
										borderRadius={99999}
										scale={this.state.scale}
										ref={(el) => {
											this.editor = el;
										}}
									/>
									<input {...getInputProps()} />
								</div>
							)}
						</Dropzone>
						<p>Zoom:</p>
						<Slider
							style={{ maxWidth: 300 }}
							min={1}
							max={5}
							step={0.01}
							value={this.state.scale}
							onChange={(evt, newValue) => {
								this.setState({ scale: newValue });
							}}
						/>
						<br />
						<Button variant="contained" onClick={this.save}>
							Save
						</Button>
					</>
				) : (
					<>
						{this.state.image ? (
							<img
								src={this.state.image}
								style={{ height: 300, width: 300, borderRadius: "100%" }}
								onError={() => {
									this.setState({ image: null });
								}}
								alt="profile"
							/>
						) : (
							"No profile image yet"
						)}
						<br />
						<Button
							variant="contained"
							onClick={() => {
								this.setState({ image: null, change: true });
							}}
						>
							Change
						</Button>
					</>
				)}
			</Paper>
		);
	}
}
