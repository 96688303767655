import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {
	usePrevious,
	ReactSelectTheme,
	ReactSelectStyles,
} from "../../../lib/HelperFunctions";
import client from "../../../lib/feathers";
import { savingQueue } from "../../../lib/GlobalSaveIndicator";
import pull from "lodash.pull";
import { nanoid } from "nanoid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash.debounce";
import without from "lodash.without";
import Slider from "@material-ui/core/Slider";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const noOptionFields = ["Is Empty", "Is Not Empty"];

const textInputFields = [
	"Less",
	"Less Or Equal",
	"Greater",
	"Greater Or Equal",
	"Begins With",
	"Not Begins With",
	"Contains",
	"Not Contains",
	"Ends With",
	"Not Ends With",
];

const sliderFields = ["Between", "Not Between"];

export const RuleContainer = ({ bitCase, attributes, messageID, ...props }) => {
	return (
		<div
			style={{
				marginLeft: 20,
				marginRight: 20,
				marginTop: 10,
				marginBottom: 10,
			}}
		>
			<RuleGroup
				bitCase={bitCase}
				attributes={attributes}
				index={0}
				messageID={messageID}
			/>
		</div>
	);
};

const RuleGroup = ({
	bitCase,
	nestedGroup,
	attributes,
	index,
	depth = 0,
	messageID,
}) => {
	const [addingRule, setAddingRule] = useState(false);
	const [deletingRule, setDeletingRule] = useState(false);
	const [checkedBoxes, setCheckedBoxes] = useState([]);
	const [chainedOperatorSaveValue, setChainedOperatorSaveValue] = useState(
		null
	);

	const [bitCaseRules, setBitCaseRules] = useState(null);
	const bitCaseRulesRef = usePrevious(bitCaseRules);

	useEffect(() => {
		(async () => {
			const bitCaseRulesDB = client.service("bitCaseRule");
			let bitCaseRulesResults = await bitCaseRulesDB.find({
				query: {
					bitCaseID: nestedGroup ? null : bitCase.id,
					parentGroupID: nestedGroup?.id,
				},
			});

			for (const bitCaseRule of bitCaseRulesResults) {
				client
					.service("channelManager")
					.create({ name: `bitCaseRule/${bitCaseRule.id}` });
				bitCaseRule.group = false;

				let matchingAttribute = attributes.find(
					(att) => att.id === bitCaseRule.attributeID
				);

				if (!matchingAttribute && bitCaseRule.attributeID) {
					const matchingAttributes = await client.service("attribute").find({
						query: { id: bitCaseRule.attributeID, include: ["Option"] },
					});
					if (matchingAttributes.length) {
						attributes.push(matchingAttributes[0]);

						await client.service("messageAttribute").create({
							attributeID: matchingAttributes[0].id,
							messageDBID: messageID,
						});
					} else {
						alert(
							"Missing attribute used in rules that does not exist anymore. Please contact support."
						);
					}
				}
			}

			const bitCaseRuleGroupsDB = client.service("bitCaseRuleGroup");
			const bitCaseRuleGroupsResults = await bitCaseRuleGroupsDB.find({
				query: {
					bitCaseID: nestedGroup ? null : bitCase.id,
					parentGroupID: nestedGroup?.id,
				},
			});

			for (const group of bitCaseRuleGroupsResults) {
				client
					.service("channelManager")
					.create({ name: `bitCaseRuleGroup/${group.id}` });
				group.group = true;
			}

			setBitCaseRules(
				[...bitCaseRuleGroupsResults, ...bitCaseRulesResults].sort((a, b) => {
					return a.order - b.order;
				})
			);

			bitCaseRulesDB
				.on("created", (newRow) => {
					setBitCaseRules([
						...bitCaseRulesRef.current,
						{ ...newRow, group: false },
					]);
				})
				.on("removed", (deleted) => {
					const toDelete = bitCaseRulesRef.current.findIndex(
						(item) => item.id === deleted.id && !item.group
					);
					if (toDelete !== -1) {
						const toSet = [...bitCaseRulesRef.current];
						toSet.splice(toDelete, 1);
						setBitCaseRules(toSet);
					}
				})
				.on("patched", async (patched) => {
					if (!bitCaseRulesRef?.current) {
						return;
					}

					const toPatch = bitCaseRulesRef.current.findIndex(
						(item) => item.id === patched.id && !item.group
					);
					let toSet = [...bitCaseRulesRef.current];

					if (toPatch !== -1) {
						if (
							patched.parentGroupID &&
							nestedGroup?.id !== patched.parentGroupID
						) {
							toSet.splice(toPatch, 1);
							setBitCaseRules(toSet);
						} else {
							toSet[toPatch] = patched;
							setBitCaseRules(toSet);
						}
					}
				});

			bitCaseRuleGroupsDB
				.on("created", (newRow) => {
					setBitCaseRules([
						...bitCaseRulesRef.current,
						{ ...newRow, group: true },
					]);
				})
				.on("removed", (deleted) => {
					const toDelete = bitCaseRulesRef.current.findIndex(
						(item) => item.id === deleted.id && item.group
					);
					if (toDelete !== -1) {
						const toSet = [...bitCaseRulesRef.current];
						toSet.splice(toDelete, 1);
						setBitCaseRules(toSet);
					}
				})
				.on("patched", async (patched) => {
					if (!bitCaseRulesRef.current) {
						return;
					}

					const toPatch = bitCaseRulesRef.current.findIndex(
						(item) => item.id === patched.id && item.group
					);
					if (toPatch !== -1) {
						let toSet = [...bitCaseRulesRef.current];
						toSet[toPatch] = { ...patched, group: true };
						setBitCaseRules(toSet);
					}
				});
		})();

		return () => {
			if (!bitCaseRules) {
				return;
			}
			for (const rule of bitCaseRules) {
				if (rule.group) {
					client
						.service("channelManager")
						.remove({ name: `bitCaseRuleGroup/${rule.id}` });
				} else {
					client
						.service("channelManager")
						.create({ name: `bitCaseRule/${rule.id}` });
				}
			}

			client
				.service("bitCaseRule")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");

			client
				.service("bitCaseRuleGroup")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		};
		// eslint-disable-next-line
	}, [bitCase.id]);

	const addRule = async () => {
		setAddingRule(true);

		let row;
		if (nestedGroup) {
			row = await client.service("bitCaseRule").create({
				parentGroupID: nestedGroup.id,
				order: bitCaseRules.length,
			});
		} else {
			row = await client.service("bitCaseRule").create({
				bitCaseID: bitCase.id,
				order: bitCaseRules.length,
			});
		}
		client.service("channelManager").create({ name: `bitCaseRule/${row.id}` });

		setBitCaseRules([...bitCaseRulesRef.current, { ...row, group: false }]);

		setAddingRule(false);
	};

	const deleteRows = async () => {
		const rulesLeftInGroup =
			bitCaseRulesRef?.current.length - checkedBoxes.length;
		setDeletingRule(true);
		for (const checkedBox of checkedBoxes) {
			await client.service("bitCaseRule").remove(checkedBox);
		}
		if (nestedGroup && rulesLeftInGroup === 0) {
			await client.service("bitCaseRuleGroup").remove(nestedGroup.id);
		}
		setCheckedBoxes([]);
		setDeletingRule(false);
	};

	const toggleChainedOperator = async () => {
		const saveID = nanoid();
		savingQueue.push(saveID);

		const newValue = chainedOperator === "and" ? "or" : "and";
		setChainedOperatorSaveValue(newValue);

		if (nestedGroup) {
			await client.service("bitCaseRuleGroup").patch(nestedGroup.id, {
				chainedOperator: newValue,
			});
		} else {
			await client.service("bitCase").patch(bitCase.id, {
				chainedOperator: newValue,
			});
		}
		pull(savingQueue, saveID);
		setChainedOperatorSaveValue(null);
	};

	const group = async () => {
		const newGroup = await client.service("bitCaseRuleGroup").create({
			bitCaseID: bitCase.id,
			order: bitCaseRules.length - checkedBoxes.length,
			chainedOperator: "and",
		});

		client
			.service("channelManager")
			.create({ name: `bitCaseRuleGroup/${newGroup.id}` });

		const toMove = bitCaseRules
			.filter((rule) => {
				return !rule.group && checkedBoxes.includes(rule.id);
			})
			.map((rule, index) => {
				return {
					id: rule.id,
					parentGroupID: newGroup.id,
					order: index,
					bitCaseID: null,
				};
			});

		const remainingRules = bitCaseRules
			.filter((rule) => !checkedBoxes.includes(rule.id))
			.map((rule, index) => {
				return { ...rule, group: rule.group, order: index };
			});
		const rulesToUpdate = [
			...toMove,
			...remainingRules.filter((row) => !row.group),
		];
		const groupsToUpdate = remainingRules.filter((row) => row.group);

		let toUpdate = [];
		for (const rule of rulesToUpdate) {
			toUpdate.push(client.service("bitCaseRule").patch(rule.id, rule));
		}
		for (const rule of groupsToUpdate) {
			toUpdate.push(client.service("bitCaseRuleGroup").patch(rule.id, rule));
		}

		await Promise.all(toUpdate);

		setBitCaseRules([
			...remainingRules,
			{ ...newGroup, group: true, bitCaseRules: [] },
		]);

		setCheckedBoxes([]);
	};

	if (!bitCaseRules) {
		return <p>Loading...</p>;
	}

	let style = { display: "flex" };
	if (nestedGroup) {
		style.border = "1px solid black";

		if (bitCaseRules.length === 1) {
			style.borderRadius = "10px 10px 10px 10px";
		} else if (index === 0) {
			style.borderRadius = "10px 10px 0 0";
		} else if (index === bitCaseRules.length - 1) {
			style.borderRadius = "0 0 10px 10px";
		}

		// style.marginLeft = -15;
		// style.marginRight = -15;
		style.paddingTop = 5;
		style.paddingRight = 5;
		style.paddingLeft = 5;
		style.paddingBottom = 10;
	}

	const chainedOperator =
		chainedOperatorSaveValue ||
		nestedGroup?.chainedOperator ||
		bitCase?.chainedOperator;

	return (
		<Grid container style={style}>
			<Grid item xs={bitCaseRules.length === 1 ? 12 : 11}>
				{bitCaseRules.map((bitCaseRule, ruleIndex) => {
					if (bitCaseRule.group) {
						return (
							<RuleGroup
								key={`g-${bitCaseRule.id}`}
								bitCase={bitCase}
								nestedGroup={bitCaseRule}
								attributes={attributes}
								index={ruleIndex}
								depth={depth + 1}
								messageID={messageID}
							/>
						);
					}
					return (
						<RuleRow
							key={bitCaseRule.id}
							bitCaseRules={bitCaseRules}
							bitCaseRule={bitCaseRule}
							ruleIndex={ruleIndex}
							attributes={attributes}
							checkedBoxes={checkedBoxes}
							setCheckedBoxes={setCheckedBoxes}
							nestedGroup={nestedGroup}
						/>
					);
				})}
			</Grid>
			{bitCaseRules.length === 1 ? null : (
				<Grid
					item
					xs={1}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flex: 1,
						cursor: "pointer",
					}}
					onClick={toggleChainedOperator}
				>
					<div
						style={{
							display: "flex",
							padding: 5,
							backgroundColor: "lightGray",
							borderRadius: 4,
							boxShadow: "rgba(0,0,0,.3) 2px 2px 2px",
						}}
					>
						<span
							style={{
								display: "flex",
								padding: 5,
								color: chainedOperator === "and" ? "white" : "black",
								background: chainedOperator === "and" ? "var(--red)" : "",
								borderRadius: 4,
								border: chainedOperator === "and" ? "1px solid white" : "",
							}}
						>
							and
						</span>
						<span
							style={{
								display: "flex",
								padding: 5,
								color: chainedOperator === "or" ? "white" : "black",
								background: chainedOperator === "or" ? "var(--red)" : "",
								borderRadius: 4,
								border: chainedOperator === "or" ? "1px solid white" : "",
							}}
						>
							or
						</span>
					</div>
				</Grid>
			)}
			<Grid
				item
				xs={bitCaseRules.length === 1 ? 12 : 11}
				style={{ textAlign: "center", marginTop: 10 }}
			>
				{checkedBoxes.length && depth < 2 ? (
					<Button
						style={{ marginLeft: 10, marginRight: 10, maxWidth: 225 }}
						variant="contained"
						color="secondary"
						fullWidth={true}
						onClick={group}
					>
						Group
					</Button>
				) : null}
				{checkedBoxes.length ? (
					<Button
						style={{ marginLeft: 10, marginRight: 10, maxWidth: 225 }}
						variant="contained"
						color="secondary"
						fullWidth={true}
						onClick={deleteRows}
					>
						Delete
						{deletingRule && (
							<>
								<span>&nbsp;&nbsp;</span>
								<CircularProgress color="primary" size={14} />
							</>
						)}
					</Button>
				) : null}
				<Button
					style={{ marginLeft: 10, marginRight: 10, maxWidth: 225 }}
					variant="contained"
					color="primary"
					fullWidth={true}
					onClick={addRule}
				>
					Add Rule{nestedGroup ? " to Group" : null}
					{addingRule && (
						<>
							<span>&nbsp;&nbsp;</span>
							<CircularProgress color="secondary" size={14} />
						</>
					)}
				</Button>
			</Grid>
		</Grid>
	);
};

const RuleRow = ({
	bitCaseRule,
	bitCaseRules,
	ruleIndex,
	nestedGroup = false,
	attributes,
	checkedBoxes,
	setCheckedBoxes,
}) => {
	let rowStyle = {};

	if (bitCaseRules.length === 1) {
		//single rule case
		rowStyle = {
			paddingTop: 10,
			paddingBottom: 10,
			background: "white",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderTop: "1px solid #343d47",
			borderBottom: "1px solid #343d47",
			borderRadius: "4px 4px 4px 4px",
		};
	} else if (ruleIndex === 0) {
		//first rule in group
		rowStyle = {
			marginTop: 5,
			paddingTop: 5,
			paddingBottom: 10,
			background: "white",
			borderTop: "1px solid #343d47",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderRadius: "4px 4px 0 0",
			borderBottom: "1px solid gray",
		};
	} else if (ruleIndex + 1 === bitCaseRules.length) {
		//last rule in group
		rowStyle = {
			paddingTop: 10,
			paddingBottom: 10,
			background: "white",
			borderBottom: "1px solid #343d47",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderRadius: "0 0 4px 4px",
		};
	} else {
		//any middle rules
		rowStyle = {
			paddingTop: 10,
			paddingBottom: 10,
			background: "white",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderBottom: "1px solid gray",
		};
	}

	return (
		<Grid container style={rowStyle}>
			<Grid
				item
				xs={1}
				style={{ marginRight: -10, display: "flex", alignItems: "center" }}
			>
				{/*<OutOfGroup*/}
				{/*	nestedGroup={nestedGroup}*/}
				{/*	bitCaseRule={bitCaseRule}*/}
				{/*	bitCaseRules={bitCaseRules}*/}
				{/*/>*/}
				<GroupCheckbox
					bitCaseRule={bitCaseRule}
					checkedBoxes={checkedBoxes}
					setCheckedBoxes={setCheckedBoxes}
				/>
			</Grid>
			<Grid item xs={4} style={{ paddingRight: 10 }}>
				<AttributeSelector bitCaseRule={bitCaseRule} attributes={attributes} />
			</Grid>
			{bitCaseRule.attributeID && (
				<Grid item xs={3} style={{ paddingRight: 10 }}>
					<EqualitySelector bitCaseRule={bitCaseRule} />
				</Grid>
			)}
			{bitCaseRule.operator && (
				<Grid item xs={4}>
					<OptionField bitCaseRule={bitCaseRule} attributes={attributes} />
				</Grid>
			)}
		</Grid>
	);
};

const GroupCheckbox = ({ checkedBoxes, setCheckedBoxes, bitCaseRule }) => {
	return (
		<Checkbox
			checked={checkedBoxes.includes(bitCaseRule.id)}
			onChange={(evt) => {
				if (evt.target.checked) {
					setCheckedBoxes([...checkedBoxes, bitCaseRule.id]);
				} else {
					setCheckedBoxes(without(checkedBoxes, bitCaseRule.id));
				}
			}}
		/>
	);
};

// const OutOfGroup = ({ nestedGroup, bitCaseRule, bitCaseRules }) => {
// 	if (!nestedGroup) {
// 		return null;
// 	}
// 	return (
// 		<ArrowBackIcon
// 			style={{ cursor: "pointer" }}
// 			onClick={async () => {
// 				const otherRules = bitCaseRules.filter(
// 					(rule) => rule.id !== bitCaseRule.id
// 				);
// 				// await client.service("bitCaseRule").patch(bitCaseRule.id, {
// 				// 	parentGroupID: null,
// 				// 	bitCaseID: nestedGroup.bitCaseID,
// 				// });
// 			}}
// 		/>
// 	);
// };

const AttributeSelector = ({ attributes, bitCaseRule }) => {
	const [saveValue, setSaveValue] = useState(null);
	let attributeValueObj = attributes.find((attribute) => {
		return attribute.id === bitCaseRule.attributeID;
	});

	return (
		<Select
			theme={ReactSelectTheme}
			styles={ReactSelectStyles}
			onChange={async (value) => {
				setSaveValue(value);
				const saveID = nanoid();
				savingQueue.push(saveID);

				let toSave = { attributeID: value.value };
				if (!bitCaseRule.operator) {
					toSave.operator = "Equal To";
				}

				await client.service("bitCaseRule").patch(bitCaseRule.id, toSave);
				pull(savingQueue, saveID);
				setSaveValue(null);
			}}
			options={attributes.map((attribute) => {
				return {
					value: attribute.id,
					label: attribute.name,
				};
			})}
			value={
				saveValue ||
				(attributeValueObj && {
					value: attributeValueObj.id,
					label: attributeValueObj.name,
				})
			}
			menuPlacement="auto"
		/>
	);
};

const EqualitySelector = ({ bitCaseRule }) => {
	const [saveValue, setSaveValue] = useState(null);

	const operators = [
		"Equal To",
		"Not Equal To",
		"Less",
		"Less Or Equal",
		"Greater",
		"Greater Or Equal",
		"Between",
		"Not Between",
		"Begins With",
		"Not Begins With",
		"Contains",
		"Not Contains",
		"Ends With",
		"Not Ends With",
		"Is Empty",
		"Is Not Empty",
	];

	return (
		<Select
			theme={ReactSelectTheme}
			options={operators.map((operator) => ({
				label: operator,
				value: operator,
			}))}
			styles={ReactSelectStyles}
			value={
				saveValue ||
				(bitCaseRule.operator && {
					value: bitCaseRule.operator,
					label: bitCaseRule.operator,
				})
			}
			onChange={async (value) => {
				setSaveValue(value);
				const saveID = nanoid();
				savingQueue.push(saveID);

				if (noOptionFields.includes(value.value)) {
					await client.service("bitCaseRule").patch(bitCaseRule.id, {
						operator: value.value,
						optionValue: null,
						optionID: null,
					});
				} else if (textInputFields.includes(value.value)) {
					await client
						.service("bitCaseRule")
						.patch(bitCaseRule.id, { operator: value.value, optionID: null });
				} else if (sliderFields.includes(value.value)) {
					await client
						.service("bitCaseRule")
						.patch(bitCaseRule.id, { operator: value.value, optionID: null });
				} else {
					await client.service("bitCaseRule").patch(bitCaseRule.id, {
						operator: value.value,
						optionValue: null,
					});
				}

				pull(savingQueue, saveID);
				setSaveValue(null);
			}}
			menuPlacement="auto"
		/>
	);
};

const optionFieldSaveDebounce = debounce(
	async (value, bitCaseRule, setSaveValue, saveValueRef) => {
		const saveID = nanoid();
		savingQueue.push(saveID);

		await client
			.service("bitCaseRule")
			.patch(bitCaseRule.id, { optionValue: value });

		if (saveValueRef.current === value) {
			setSaveValue(null);
		}

		pull(savingQueue, saveID);
	},
	500
);

const OptionField = ({ bitCaseRule, attributes }) => {
	const [saveValue, setSaveValue] = useState(null);
	const attributeValueObj = attributes.find((attribute) => {
		return attribute.id === bitCaseRule.attributeID;
	});
	const { type } = attributeValueObj;
	const saveValueRef = usePrevious(saveValue);

	if (noOptionFields.includes(bitCaseRule.operator)) {
		return null;
	} else if (
		textInputFields.includes(bitCaseRule.operator) ||
		type === "retrieved" ||
		type === "raw"
	) {
		return (
			<TextField
				variant="outlined"
				inputProps={{ style: { padding: 10 } }}
				value={saveValue || bitCaseRule.optionValue || ""}
				onChange={(evt) => {
					setSaveValue(evt.target.value);
					optionFieldSaveDebounce(
						evt.target.value,
						bitCaseRule,
						setSaveValue,
						saveValueRef
					);
				}}
			/>
		);
	} else if (sliderFields.includes(bitCaseRule.operator)) {
		const numberOptions = attributeValueObj.options
			.map((option) => parseFloat(option.name))
			.filter((option) => !isNaN(option));

		const rangeMax = numberOptions.length ? Math.max(...numberOptions) : 10;

		let value;
		if (saveValue) {
			value = saveValue;
		} else if (
			bitCaseRule?.optionValue &&
			typeof bitCaseRule.optionValue === "string" &&
			RegExp("\\d+\\-\\d+").test(bitCaseRule.optionValue)
		) {
			value = bitCaseRule.optionValue.split("-").map((num) => parseInt(num));
		} else {
			value = [0, rangeMax];
		}

		return (
			<div
				style={{
					paddingLeft: 15,
					paddingRight: 15,
					paddingTop: 25,
					marginBottom: -20,
				}}
			>
				<Slider
					value={value}
					onChange={(evt, newValue) => {
						setSaveValue(newValue);
						optionFieldSaveDebounce(
							newValue.join("-"),
							bitCaseRule,
							setSaveValue,
							saveValueRef
						);
					}}
					min={0}
					max={rangeMax}
					valueLabelDisplay="on"
				/>
			</div>
		);
	} else if (type === "direct" || type === "calculated") {
		const optionObj = attributeValueObj?.options.find((option) => {
			return option.id === bitCaseRule?.optionID;
		});

		return (
			<Select
				styles={ReactSelectStyles}
				theme={ReactSelectTheme}
				options={attributeValueObj.options
					.map((option) => ({
						label: option.name,
						value: option.id,
					}))
					.sort((a, b) => {
						return a.value - b.value;
					})}
				value={
					saveValue ||
					(optionObj && { value: optionObj.id, label: optionObj.name })
				}
				onChange={async (value) => {
					setSaveValue(value);
					const saveID = nanoid();
					savingQueue.push(saveID);

					await client
						.service("bitCaseRule")
						.patch(bitCaseRule.id, { optionID: value.value });
					pull(savingQueue, saveID);
					setSaveValue(null);
				}}
				menuPlacement="auto"
			/>
		);
	} else {
		return <p>Error - This attribute type has not been implemented yet</p>;
	}
};
