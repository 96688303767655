import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import client from "../../lib/feathers";
import { ListItemText } from "@material-ui/core";
import RedButton from "../../lib/RedButton";
import { observer } from "mobx-react";
import { appStore } from "../../App";
import Dropzone from "react-dropzone";
import { papaDecorator } from "./AttributeBuilder/attributeImporter";
import snackbarStore from "../../lib/SnackbarStore";
import { usePrevious } from "../../lib/HelperFunctions";
import API from "../../API";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withRouter } from "react-router-dom";

export const TestCase = withRouter(({ history }) => {
	const [testCases, setTestCases] = useState();
	const testCasesRef = usePrevious(testCases);
	const [selectedCase, setSelectedCase] = useState();
	const [generatingMessages, setGeneratingMessages] = useState(false);
	const [caseOutput, setCaseOutput] = useState();

	useEffect(() => {
		(async () => {
			setTestCases(
				await client
					.service("testCase")
					.find({ query: { $sort: { caseNumber: 1 } } })
			);
			client.service("channelManager").create({ name: `testCase` });

			client
				.service("testCase")
				.on("created", (newRow) => {
					setTestCases([...testCasesRef.current, newRow]);
				})
				.on("removed", (removedRow) => {
					setTestCases(
						testCasesRef.current.filter((row) => row.id !== removedRow.id)
					);
				})
				.on("patched", (patched) => {
					const toPatch = testCasesRef.current.findIndex(
						(item) => item.id === patched.id
					);
					if (toPatch !== -1) {
						const toSet = [...testCases];
						toSet[toPatch] = patched;
						setTestCases(toSet);
					}
				});
		})();
		return () => {
			client.service("channelManager").remove({ name: `testCase` });
			client
				.service("testCase")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<h3 style={{ textDecoration: "underline" }}>Test Cases</h3>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Paper style={{ padding: 10 }}>
						{!testCases ? (
							<div style={{ justifyContent: "center", display: "flex" }}>
								<CircularProgress size={28} />
							</div>
						) : testCases.length === 0 ? (
							<p>No test cases exist</p>
						) : (
							<>
								<p>Click on a test case to update or delete it.</p>
								<List>
									{testCases.map((testCase) => (
										<ListItem
											style={{ cursor: "pointer" }}
											key={testCase.id}
											onClick={() => {
												setSelectedCase(testCase.id);
											}}
											selected={selectedCase === testCase.id}
										>
											<ListItemText>
												#{testCase.caseNumber} - {testCase.name} (
												{testCase.path})
											</ListItemText>
										</ListItem>
									))}
								</List>
							</>
						)}
						{selectedCase && (
							<Grid container style={{ marginTop: 10 }} spacing={2}>
								<Grid item xs={4}>
									<RedButton
										onClick={async () => {
											if (generatingMessages) {
												window.location.reload();
											} else {
												setGeneratingMessages(true);
												setCaseOutput(null);
												const { error, messages, errorIndex } = await API(
													`/startTestCase`,
													"POST",
													{
														testCaseID: selectedCase,
													}
												);
												setGeneratingMessages(false);
												setCaseOutput({ error, messages, errorIndex });
											}
										}}
									>
										Run Case
									</RedButton>
								</Grid>
								<Grid item xs={4}>
									<RedButton
										onClick={() => {
											setSelectedCase(null);
										}}
									>
										New Case
									</RedButton>
								</Grid>
								<Grid item xs={4}>
									<RedButton
										onClick={async () => {
											await client.service("testCase").remove(selectedCase);
											setSelectedCase(null);
										}}
									>
										Delete
									</RedButton>
								</Grid>
							</Grid>
						)}
					</Paper>
					{caseOutput || generatingMessages ? (
						<Paper style={{ padding: 10, marginTop: 15 }}>
							{generatingMessages ? (
								<div style={{ justifyContent: "center", display: "flex" }}>
									<CircularProgress size={28} />
								</div>
							) : null}
							{caseOutput ? (
								<>
									{caseOutput.error ? (
										<p style={{ color: "darkRed" }}>{caseOutput.error}</p>
									) : null}
									<p style={{ textDecoration: "underline" }}>Messages:</p>
									<ul>
										{caseOutput.messages.map((message, index) => {
											let style = {};
											if (
												message.match === false &&
												message.message !== "Continue"
											) {
												style.backgroundColor = "yellow";
											}
											if (message.currentMessageID) {
												style.cursor = "pointer";
											}

											return (
												<li
													key={index}
													style={style}
													onClick={async () => {
														if (message.currentMessageID) {
															const messageDB = await client
																.service("message")
																.find({
																	query: { id: message.currentMessageID },
																});
															if (messageDB.length) {
																history.push(
																	`/canvas/${messageDB[0].canvasID}/${messageDB[0].shapeID}`
																);
															}
														}
													}}
												>
													{message.message}
												</li>
											);
										})}
									</ul>
								</>
							) : null}
						</Paper>
					) : null}
				</Grid>
				<Grid item xs={6}>
					<ViewTestCaseData
						selectedCase={selectedCase}
						errorIndex={caseOutput?.errorIndex}
					/>
					<UploadCard
						selectedCase={selectedCase}
						setSelectedCase={setSelectedCase}
					/>
				</Grid>
			</Grid>
		</div>
	);
});

export const ViewTestCaseData = withRouter(
	observer(({ selectedCase, errorIndex, ...props }) => {
		const [testCaseMessages, setTestCaseMessages] = useState();
		useEffect(() => {
			(async () => {
				setTestCaseMessages(
					await client
						.service("testCaseMessage")
						.find({ query: { testCaseID: selectedCase, $sort: { id: 1 } } })
				);
			})();
		}, [selectedCase]);
		if (!selectedCase) {
			return <></>;
		} else if (!testCaseMessages) {
			return (
				<Paper style={{ padding: 15, marginBottom: 15 }}>
					<h3 style={{ textDecoration: "underline" }}>Test Case Data</h3>
					<div style={{ justifyContent: "center", display: "flex" }}>
						<CircularProgress size={28} />
					</div>
				</Paper>
			);
		} else {
			return (
				<Paper style={{ padding: 15, marginBottom: 15 }}>
					<h3 style={{ textDecoration: "underline" }}>Test Case Data</h3>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Message Num</TableCell>
									<TableCell>Message Name</TableCell>
									<TableCell>Message Content</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{testCaseMessages.map((message, index) => {
									return (
										<TableRow
											key={message.id}
											style={{
												background:
													errorIndex === index ? "rgba(255,0,0,.5)" : "inherit",
											}}
										>
											<TableCell>{index + 1}</TableCell>
											<TableCell>
												<span
													style={{ cursor: "pointer" }}
													onClick={async () => {
														const messageDB = await client
															.service("message")
															.find({ query: { name: message.messageName } });
														if (messageDB.length) {
															props.history.push(
																`/canvas/${messageDB[0].canvasID}/${messageDB[0].shapeID}`
															);
														}
													}}
												>
													{message.messageName}
												</span>
											</TableCell>
											<TableCell>{message.messageContent}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			);
		}
	})
);

export const UploadCard = observer(({ selectedCase, setSelectedCase }) => {
	const [loading, setLoading] = useState(false);

	const onDrop = async (files) => {
		const projectID = appStore.selectedProject;
		setLoading(true);
		const rows = await papaDecorator(files[0], false);

		if (rows[0][0] !== "Test Case #:" || rows[1][0] !== "Path:") {
			snackbarStore.setMessage("Error", "Invalid file uploaded.");
			setLoading(false);
			return;
		}

		const testCase = await client.service("testCase").create({
			projectID,
			caseNumber: rows[0][1],
			path: rows[1][1],
			name: `${rows[2][1]} ${rows[3][1]}`,
		});

		let rawImported = false;
		let toCreate = [];
		for (const [index, row] of rows.entries()) {
			if (index > 1) {
				if (!rawImported) {
					if (row[0] === "") {
						rawImported = true;
					} else {
						toCreate.push(
							client.service("testCaseRawData").create({
								testCaseID: testCase.id,
								attributeName: row[0],
								attributeValue: row[1],
							})
						);
					}
				} else {
					if (row[0] !== "") {
						await client.service("testCaseMessage").create({
							testCaseID: testCase.id,
							messageName: row[0],
							messageContent: row[1],
						});
					}
				}
			}
		}
		await Promise.all(toCreate);
		if (selectedCase) {
			await client.service("testCase").remove(selectedCase);
			setSelectedCase(testCase.id);
		}
		setLoading(false);
	};

	let toReturn;
	if (!loading) {
		toReturn = (
			<Dropzone onDrop={onDrop}>
				{({ getRootProps, getInputProps }) => (
					<section>
						<div
							{...getRootProps()}
							style={{
								width: "100%",
								height: 75,
								borderWidth: 2,
								borderColor: "darkGray",
								borderStyle: "dashed",
								borderRadius: 5,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<input {...getInputProps()} />
							<p>Drag 'n' drop your CSV here, or click to select file</p>
						</div>
					</section>
				)}
			</Dropzone>
		);
	} else {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<CircularProgress variant="indeterminate" />
				<p>Case creation in progress. Please wait, this may take a minute.</p>
			</div>
		);
	}

	return (
		<Paper style={{ padding: 15 }}>
			<h3 style={{ textDecoration: "underline" }}>
				{selectedCase ? "Overwrite Profile" : "New Case"}
			</h3>
			{toReturn}
		</Paper>
	);
});

export default TestCase;
