import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import RedButton from "../../lib/RedButton";
import React, { useState } from "react";
import API from "../../API";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";

const ContentLength = () => {
	const [messageName, setMessageName] = useState("");
	const [elementName, setElementName] = useState("");
	const [maxLength, setMaxLength] = useState("");
	const [includeEmptyElements, setIncludeEmptyElements] = useState(false);
	const [hide0Averages, setHide0Averages] = useState(true);
	// const [include20Deviation, setInclude20Deviation] = useState(false);
	const [results, setResults] = useState();

	const checkLength = async (evt) => {
		evt.preventDefault();

		const results = await API(`/elementLength`, "POST", {
			elementName,
			includeEmptyElements,
			messageName,
			maxLength,
			// include20Deviation,
		});
		setResults(results);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Paper style={{ padding: 10 }}>
					<h3>Content Length</h3>
					<form onSubmit={checkLength}>
						<TextField
							label={"Element Name"}
							variant="outlined"
							value={elementName}
							onChange={(evt) => {
								setElementName(evt.target.value);
							}}
						/>
						<TextField
							label={"Message Name"}
							variant="outlined"
							value={messageName}
							onChange={(evt) => {
								setMessageName(evt.target.value);
							}}
							style={{ marginTop: 15 }}
						/>
						<TextField
							label={"Max Length"}
							variant="outlined"
							value={maxLength}
							onChange={(evt) => {
								setMaxLength(evt.target.value);
							}}
							style={{ marginTop: 15 }}
							type="number"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={includeEmptyElements}
									onChange={(event) => {
										setIncludeEmptyElements(event.target.checked);
									}}
								/>
							}
							label="Include empty elements"
						/>
						{/*<FormControlLabel*/}
						{/*	control={*/}
						{/*		<Checkbox*/}
						{/*			checked={include20Deviation}*/}
						{/*			onChange={(event) => {*/}
						{/*				setInclude20Deviation(event.target.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	}*/}
						{/*	label="Include 20% deviation"*/}
						{/*/>*/}
						<RedButton type="submit" style={{ marginTop: 15 }}>
							Check Length
						</RedButton>
					</form>
				</Paper>
			</Grid>
			<Grid item xs={6}>
				<Paper style={{ padding: 10 }}>
					<h3>Results</h3>
					<ContentLengthResults results={results} />
				</Paper>
				{results?.elementsOverMax.length ? (
					<Paper style={{ padding: 10, marginTop: 15 }}>
						<h3>Max Results</h3>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Message</TableCell>
										<TableCell>Element</TableCell>
										<TableCell>Max</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{results.elementsOverMax.map((element) => {
										return (
											<TableRow key={element.id}>
												<TableCell>
													<Link
														target="_blank"
														to={`/canvas/${element.message.canvasID}/${element.message.shapeID}`}
													>
														{element.message.name}
													</Link>
												</TableCell>
												<TableCell>{element.name}</TableCell>
												<TableCell>{element.maxLength}</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				) : null}
				{results?.elementsAtDeviation.length ? (
					<Paper style={{ padding: 10, marginTop: 15 }}>
						<div>
							<h3 style={{ float: "left" }}>20% Deviation Results</h3>
							<FormControlLabel
								style={{ float: "right" }}
								control={
									<Checkbox
										checked={hide0Averages}
										onChange={(event) => {
											setHide0Averages(event.target.checked);
										}}
									/>
								}
								label="Hide 0"
							/>
						</div>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Message</TableCell>
										<TableCell>Element</TableCell>
										<TableCell>Average</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{results.elementsAtDeviation
										.filter((element) => {
											if (hide0Averages) {
												return element.average !== 0;
											} else {
												return true;
											}
										})
										.map((element) => {
											return (
												<TableRow key={element.id}>
													<TableCell>
														<Link
															target="_blank"
															to={`/canvas/${element.message.canvasID}/${element.message.shapeID}`}
														>
															{element.message.name}
														</Link>
													</TableCell>
													<TableCell>{element.name}</TableCell>
													<TableCell>{element.average}</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				) : null}
			</Grid>
		</Grid>
	);
};

export const ContentLengthResults = ({ results }) => {
	return results ? (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Stat</TableCell>
						<TableCell>Result</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.entries(results.stats).map(([key, value]) => {
						return (
							<TableRow key={key}>
								<TableCell>{key}</TableCell>
								<TableCell>{value}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	) : null;
};

export default ContentLength;
