import React, { Component } from "react";
import UnmatchedUserResponses from "../../lib/UnmatchedUserResponses";
import NotificationInbox from "./NotificationInbox";
import Kanban from "../message/left/Kanban";
import Paper from "@material-ui/core/Paper";

export default class Home extends Component {
	render() {
		return (
			<div>
				<Paper
					style={{
						height: 400,
						marginBottom: 15,
					}}
					className="dashboardKanban"
				>
					<h3 style={{ paddingLeft: 15, paddingTop: 15, marginBottom: 0 }}>
						Kanban
					</h3>
					<Kanban {...this.props} dashboard={true} />
				</Paper>
				<NotificationInbox />
				<UnmatchedUserResponses dashboard={true} />
			</div>
		);
	}
}
