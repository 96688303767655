import React, { Component } from "react";
import RedButton from "../lib/RedButton";
import Paper from "@material-ui/core/Paper";
import "./SidebarModule.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

export default class SidebarModule extends Component {
	state = { open: false };

	render() {
		return (
			<div
				style={{ marginTop: 20, marginBottom: 20 }}
				className="sidebarModule"
			>
				<RedButton
					style={
						this.state.open
							? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
							: {}
					}
					onClick={() => {
						this.setState({ open: !this.state.open });
					}}
				>
					<span style={{ marginLeft: -10 }}>{this.props.title}</span>
					{this.state.open ? (
						<FontAwesomeIcon icon={faChevronUp} />
					) : (
						<FontAwesomeIcon icon={faChevronDown} />
					)}
				</RedButton>
				{this.state.open ? (
					<Paper
						className="links"
						style={{
							padding: 10,
							borderTopLeftRadius: 0,
							borderTopRightRadius: 0,
							marginTop: -5,
						}}
					>
						{this.props.children}
					</Paper>
				) : null}
			</div>
		);
	}
}
