import React, { useRef, Fragment } from "react";
import Webix from "../../../../lib/Webix";
import API from "../../../../API";
import { withRouter } from "react-router";

let addInProgress = false;
const AttributesTree = (props) => {
	const {
		usedAttributes,
		messageDBID,
		setUsedAttributes,
		type,
		entryID,
		currentCanvasID,
		cellId,
	} = props;
	const prevUsedAtrributes = useRef();

	prevUsedAtrributes.current = usedAttributes;

	const addNewAttribute = (data, treeItem) => {
		setUsedAttributes([
			...prevUsedAtrributes.current,
			{
				id: parseInt(treeItem.id.replace("A-", "")),
				name: treeItem.value,
			},
		]);
	};

	return (
		<Fragment>
			<Webix
				ui={{
					id: `${type}attributeTree`,
					view: "edittree",
					select: true,
					multiselect: false,
					drag: true,
					on: {
						onItemClick: async (id) => {
							if (type !== "glossary") {
								const tree = window.$$(`${type}attributeTree`);
								if (id.includes("A-") && !addInProgress) {
									addInProgress = true;

									const data = await API(
										`/db/${type}Attribute`,
										"POST",
										type === "message"
											? {
													attributeID: id.replace("A-", ""),
													messageDBID: messageDBID,
											  }
											: {
													attributeID: id.replace("A-", ""),
													[`${type}ID`]: cellId,
													canvasID: currentCanvasID,
											  }
									);
									addNewAttribute(data, tree.getItem(id));
									tree.remove(id);
									addInProgress = false;
								}
							}
						},
						onItemDblClick: async (id, e, node) => {
							const tree = window.$$(`${type}attributeTree`);
							let treeItem = tree.getSelectedItem();
							if (type === "glossary") {
								const data = await API(`/db/linkedGlossaryEntry`, "POST", {
									glossaryEntryID: entryID,
									linkedGlossaryEntryID: id.replace("A-", ""),
								});
								addNewAttribute(data, treeItem);
								tree.remove(tree.getSelectedId());
							}
						},
					},
					type: {
						folder: function (obj) {
							//if open folder
							if (obj.type === "message") {
								return `<i class="fas fa-envelope"></i>`;
							} else if (obj.type === "folder" && obj.open) {
								return `<i class="fas fa-folder-open webix_tree_folder_open"></i>`;
							}
							// if closed folder
							else if (obj.$count || obj.type === "folder") {
								return `<i class="fas fa-folder webix_tree_folder"></i>`;
							} else {
								return `<i class="fas fa-sticky-note"></i>`;
							}
						},
					},
					url: {
						$proxy: true,
						load: async (view, params) => {
							const data = await API(
								`/attributesTree?parent=${params ? params.parent : ""}`,
								"POST",
								{
									notIn: Array.isArray(usedAttributes)
										? usedAttributes.map((item) => {
												return item.id;
										  })
										: [],
								}
							);
							return data;
						},
					},
				}}
			/>
		</Fragment>
	);
};

export default withRouter(AttributesTree);
