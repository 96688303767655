import debounce from "lodash.debounce";
import { action, observable, runInAction } from "mobx";
import { appStore } from "../../../App";
import API from "../../../API";
import { logError } from "../../../lib/ErrorLogger";
import snackbarStore from "../../../lib/SnackbarStore";
import { uniqById, sortBy } from "../AttributeBuilder/store";

const initialState = {
	tree: {
		init: false,
		loading: false,
		// string[] - 'key' values used for RC-Tree
		selected: [],
		loadedKeys: {},
		// data: [],
		folders: [],
		entries: [],
		treeData: [],
	},
	// Use to handle state when editing a single Entry/Folder
	detail: {
		open: false,
		loading: false,
		id: null,
		isEntry: false,
		data: {},
	},
	search: {
		loading: false,
		data: [],
		query: "",
		selectedId: null,
	},
	modal: {
		// Creation
		entryOpen: false,
		folderOpen: false,
		// Editing
		editFolderOpen: false,
		editEntryOpen: false,
	},
};

export const TreeStore = observable(initialState);

//-- Actions
export const reset = action((state) => {
	Object.entries(initialState).forEach(([key, value]) => {
		state[key] = value;
	});
});

export const resetDetail = action((state) => {
	Object.entries(initialState.detail).forEach(([key, value]) => {
		state.detail[key] = value;
	});
});

/**
 * @func updateTree - Make the `treeData` key reflect the Entries & Folders
 * @param {Object} state
 * @returns {Object}
 */
export const updateTree = (state) => {
	const { folders, entries } = state.tree;
	const roots = folders.filter((folder) => null === folder.parentFolderID);
	const treeData = sortBy("name")(roots).map((folder) =>
		Folder(folder, { entries, folders })
	);
	state.tree.treeData = treeData;
	return treeData;
};

/**
 * @typedef {Object} GlossaryResponse
 * @property {Number|null} parentFolderID
 * @property {Object[]} entries
 * @property {Object[]} folders
 */

/**
 * @func loadData - Load tree data for the given node(s)
 * @param {TreeStore} state
 * @param {Number|null} parentFolderID
 * @returns {Promise<GlossaryResponse>}
 */
export const loadData = action(async (state, parentFolderID) => {
	state.tree.init = true;
	state.tree.loading = true;
	const params = parentFolderID ? `?parentFolderID=${parentFolderID}` : "";
	const response = await API(`/glossaryTreeV2${params}`, "GET");
	state.tree.folders = uniqById(state.tree.folders.concat(response.folders));
	state.tree.entries = uniqById(state.tree.entries.concat(response.entries));
	state.tree.loading = false;
	if (parentFolderID) {
		// Key === folderID, value === RC Tree formatted parent folder ID
		state.tree.loadedKeys[parentFolderID] = `folder-${parentFolderID}`;
	}
	return response;
});

export const loadTreeData = action(async (parentFolderID) => {
	const response = await loadData(TreeStore, parentFolderID);
	runInAction(() => updateTree(TreeStore));
	return response;
});

//-- Fire a POST request & update entries in state
export const createEntry = action(async (state, data) => {
	const selectedKey = state.tree.selected[0];
	const selectedId = keyToId(selectedKey);
	const folderIsSelected = selectedKey.includes("folder");
	const parentFolderID = folderIsSelected
		? selectedId
		: state.tree.entries.find((entry) => selectedId === entry.id)
				?.parentFolderID;
	const response = await API(`/db/glossaryEntry`, "POST", {
		...data,
		parentFolderID,
		projectID: appStore.selectedProject,
	});
	const entry = response.data;
	state.tree.entries = state.tree.entries.concat(entry);
	return loadTreeData(parentFolderID);
});

//-- Fire a POST request & update folders in state
export const createFolder = action(async (state, data) => {
	const selectedKey = state.tree.selected[0];
	const selectedId = keyToId(selectedKey);
	const folderIsSelected = selectedKey.includes("folder");
	const parentFolderID = folderIsSelected
		? selectedId
		: state.tree.entries.find((entry) => selectedId === entry.id)
				?.parentFolderID;
	const response = await API(`/db/glossaryEntryFolder`, "POST", {
		...data,
		parentFolderID,
		projectID: appStore.selectedProject,
	});
	const folder = response.data;
	state.tree.folders = state.tree.folders.concat(folder);
	return loadTreeData(parentFolderID);
});

//-- Make the DELETE request, remove the entry from state
export const deleteEntry = action(async (state, id) => {
	const entry = state.tree.entries.find((a) => id === a.id);
	const { parentFolderID } = entry;
	await API(`/db/glossaryEntry/${id}`, "DELETE");
	state.tree.entries = state.tree.entries.filter((a) => id !== a.id);
	return loadTreeData(parentFolderID).then(() => ({ id, parentFolderID }));
});

//-- Make the DELETE request, remove the folder & child entries from state
export const deleteFolder = action(async (state, id) => {
	const folder = state.tree.folders.find((f) => id === f.id);
	const { parentFolderID } = folder;
	await API(`/db/glossaryEntryFolder/${id}`, "DELETE");
	state.tree.folders = state.tree.folders.filter((a) => id !== a.id);
	return loadTreeData(parentFolderID).then(() => ({ id, parentFolderID }));
});

//-- Move Entries in the tree
export const moveEntry = action(async (state, id, parentFolderID) => {
	const staleEntry = state.tree.entries.find((entry) => id === entry.id);
	// Make sure the parent changed before we fire
	const folderChanged =
		staleEntry && staleEntry.parentFolderID !== parentFolderID;
	if (!folderChanged) {
		return Promise.resolve(staleEntry);
	}
	await API(`/db/glossaryEntry`, "POST", {
		id,
		parentFolderID,
	});

	const newParent = state.tree.folders.find(
		(folder) => parentFolderID === folder.id
	);
	snackbarStore.setMessage(
		"Info",
		`Moved ${staleEntry.name} ${newParent ? `to ${newParent.name}` : ""}`
	);
	// Reload both the previous parent & the new parent
	return Promise.all([
		loadTreeData(parentFolderID),
		loadTreeData(staleEntry.parentFolderID),
	]);
});

//--  Move Glossary Entry Folders in the tree
export const moveFolder = action(async (state, id, parentFolderID) => {
	const staleFolder = state.tree.folders.find((folder) => id === folder.id);
	// Make sure the parent changed before we fire
	const folderChanged =
		staleFolder && staleFolder.parentFolderID !== parentFolderID;

	if (!folderChanged) {
		return Promise.resolve(staleFolder);
	}

	await API(`/db/glossaryEntryFolder`, "POST", {
		id,
		parentFolderID,
	});

	const newParent = state.tree.folders.find(
		(folder) => parentFolderID === folder.id
	);
	snackbarStore.setMessage(
		"Info",
		`Moved ${staleFolder.name} ${newParent ? `to ${newParent.name}` : ""}`
	);
	// Reload both the previous folder & the new folder
	return Promise.all([
		loadTreeData(parentFolderID),
		loadTreeData(staleFolder.parentFolderID),
	]);
});

export const moveMany = action(
	async (
		state,
		{ glossaryEntryIDs, glossaryEntryFolderIDs, parentFolderID }
	) => {
		try {
			state.tree.loading = true;
			const newParent = state.tree.folders.find(
				(folder) => parentFolderID === folder.id
			);
			snackbarStore.setMessage("Info", `Moving to ${newParent?.name}`);
			// Let the API handle the moving logic, we can just update the entries & folders on this end
			const { folders, entries } = await API(
				`/glossaryTreeV2/parentFolderID`,
				"POST",
				{
					glossaryEntryIDs,
					glossaryEntryFolderIDs,
					parentFolderID,
				}
			);
			runInAction(() => {
				state.tree.folders = uniqById(state.tree.folders.concat(folders));
				state.tree.entries = uniqById(state.tree.entries.concat(entries));
				state.tree.loading = false;
				runInAction(() => updateTree(state));
				snackbarStore.setMessage("Success", `Moved to ${newParent?.name}`);
			});
			return { folders, entries };
		} catch (error) {
			logError(error, {
				glossaryEntryIDs,
				glossaryEntryFolderIDs,
				parentFolderID,
				component: "GlossaryTree/store.js & MoveTo.jsx",
			});
			return Promise.reject(error);
		}
	}
);

//-- Trigger the "Edit Name" dialog(s)
export const editFolder = action(async (state, id) => {
	state.detail = {
		...initialState.detail,
		id,
		isEntry: false,
		loading: true,
		open: true,
		data: {},
	};
	const { data } = await API(`/db/glossaryEntryFolder/${id}`, "GET");
	const detail = {
		id,
		loading: false,
		data: { ...data },
		open: true,
		isEntry: false,
	};
	state.detail = {
		...state.detail,
		...detail,
	};
	return data;
});

export const editEntry = action(async (state, id) => {
	state.detail = {
		...initialState.detail,
		id,
		isEntry: true,
		loading: true,
		open: true,
		data: {},
	};
	const entryResponse = await API(`/db/glossaryEntry/${id}`, "GET");
	const entry = entryResponse.data;
	const detail = {
		id,
		loading: false,
		data: { ...entry },
		open: true,
		isEntry: true,
	};
	state.detail = { ...state.detail, ...detail };
	return entry;
});

const errorHandler = (error) => {
	const isValidationError =
		error?.message?.toLowerCase() === "validation error" ||
		(error?.error && error?.code === "23505");
	if (!isValidationError) {
		snackbarStore.setMessage(
			"Error",
			"Something went wrong setting the edited name."
		);
		logError(error, {
			component: "GlossaryTree",
			location: "store.js",
			method: "updateEntry",
		});
		return Promise.reject(error);
	}
	snackbarStore.setMessage(
		"Error",
		"Name already exists, please pick another."
	);
	return Promise.reject(null);
};

// PUT an Entry
export const updateEntry = action(async (state, { id, ...fields }) => {
	try {
		state.detail.loading = true;
		const { data } = await API(
			`/db/glossaryEntry`,
			"PUT",
			{
				data: {
					id,
					...fields,
				},
			},
			(error) => {
				throw error;
			}
		);
		state.tree.entries = state.tree.entries.map((item) =>
			id !== item.id ? item : { ...item, ...data }
		);
		state.detail.loading = false;
		state.detail.data = { ...state.detail.data, ...data };
		updateTree(state);
		return data;
	} catch (e) {
		runInAction(() => {
			state.detail.loading = false;
		});
		return errorHandler(e);
	}
});

// PUT a Folder
export const updateFolder = action(async (state, { id, ...fields }) => {
	try {
		state.detail.loading = true;
		const { data } = await API(
			"/db/glossaryEntryFolder",
			"PUT",
			{
				data: {
					id,
					...fields,
				},
			},
			(error) => {
				throw error;
			}
		);
		state.tree.folders = state.tree.folders.map((item) =>
			id !== item.id ? item : { ...item, ...data }
		);
		state.detail.loading = false;
		state.detail.data = { ...state.detail.data, ...data };
		updateTree(state);
		return data;
	} catch (e) {
		runInAction(() => {
			state.detail.loading = false;
		});
		return errorHandler(e);
	}
});

//-- Search
const _searchEntries = action(async (state, query) => {
	state.search.loading = true;
	try {
		const { glossaries } = await API("/glossaryEntry/search", "POST", {
			searchTerm: query,
			projectID: appStore.selectedProject,
		});
		// Don't update the state if the search query changed while
		// we were loading the results
		if (state.search.query === query) {
			state.search.data = glossaries;
			state.search.loading = false;
		}
		return glossaries;
	} catch (error) {
		state.search.loading = false;
		throw error;
	}
});

export const searchEntries = debounce(_searchEntries, 500);

// Helper functions
export const keyToId = (key) =>
	Number(key.replace("entry-", "").replace("folder-", ""));

export const Key = {
	toId: keyToId,
	isEntry: (key) => key.includes("entry-"),
	isFolder: (key) => key.includes("folder-"),
	getEntry: (key) => {
		const id = keyToId(key);
		return TreeStore.tree.entries.find((entry) => id === entry.id);
	},
	getFolder: (key) => {
		const id = keyToId(key);
		return TreeStore.tree.folders.find((entry) => id === entry.id);
	},
};

const Folder = (folder, { entries, folders }) => {
	const { id, name } = folder;
	const childFolders = folders
		.filter((f) => id === f.parentFolderID)
		.map((f) => Folder(f, { entries, folders }));
	const childEntries = entries
		.filter((entry) => id === entry.parentFolderID)
		.map(Entry);
	return {
		...folder,
		key: `folder-${id}`,
		title: name,
		isLeaf: false,
		children: [...sortChildren(childFolders), ...sortChildren(childEntries)],
	};
};

const sortChildren = sortBy("name");

const Entry = (entry) => ({
	...entry,
	key: `entry-${entry.id}`,
	title: entry.name,
	isLeaf: true,
});
