import React, { useState, useEffect } from "react";
import Board from "react-trello";
import camelcase from "camelcase";
import { CircularProgress } from "@material-ui/core";
import API from "../../../API";

let allMessages = {};

export default ({ history, dashboard }) => {
	const [board, setBoard] = useState();
	useEffect(() => {
		(async () => {
			let boardData = { lanes: [] };
			let statuses = [
				"Backlog",
				"To Do",
				"Doing",
				"In Process",
				"Roadblocked",
				"Done",
				"In Testing",
				"In Review",
				"Approved",
			];
			for (const status of statuses) {
				const { messages } = await API(`/kanbanDataV2`, "POST", { status });
				if (messages.length) {
					boardData.lanes.push({
						id: camelcase(status),
						title: status,
						label: `${messages.length}`,
						cards: messages.map((message) => ({
							id: `${message.id}`,
							title: message.name,
						})),
					});

					for (const message of messages) {
						allMessages[message.id] = message;
					}
				}
			}
			setBoard(boardData);
		})();

		return () => {
			allMessages = {};
		};
	}, []);

	if (!board) {
		return (
			<div style={{ textAlign: "center", paddingTop: 15 }}>
				<CircularProgress />
			</div>
		);
	}

	//MZM 3-3-21: This is read only at the moment, but it should support moving
	//cards to other statuses and other filtering we supported in KanbanLegacy.jsx

	return (
		<div style={{ height: "100%" }} className={dashboard ? "dashboardKanban" : "kanbanPage"}>
			<Board
				data={board}
				collapsibleLanes={true}
				editable={false}
				hideCardDeleteIcon={true}
				draggable={false}
				laneDraggable={false}
				cardDraggable={false}
				style={{ backgroundColor: "transparent" }}
				onCardClick={(cardID, metadata) => {
					history.push(
						`/kanban/${allMessages[cardID].canvasID}/${allMessages[cardID].shapeID}`
					);
				}}
			/>
		</div>
	);
};
