import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import tuzagLogoWhite from "../img/tuzagLogoWhite.svg";
import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";
import client from "../lib/feathers";
import { observer } from "mobx-react";
import { appStore } from "../App";

class Footer extends Component {
	state = { redirect: null };

	logout = async () => {
		try {
			Cookies.remove("token");
			Cookies.remove("userID");
			Cookies.remove("refreshToken");
			Cookies.remove("userEmail");
			client.logout();
			this.setState({ redirect: "/login" });
		} catch (e) {
			console.error(e);
		}
	};

	render() {
		let backgroundColor = "var(--red)";
		if (
			process.env.REACT_APP_ENV === "staging" ||
			process.env.REACT_APP_ENV === "stagingerx"
		) {
			backgroundColor = "#F2A524";
		} else if (process.env.REACT_APP_ENV === "local") {
			backgroundColor = "#16B1F0";
		}

		return this.state.redirect ? (
			<Redirect to={this.state.redirect} />
		) : (
			<footer
				style={{
					color: "white",
					backgroundColor,
					fontSize: 12,
					padding: appStore.smallFooter ? 5 : 20,
					position: "fixed",
					bottom: 0,
					left: 0,
					width: `calc(100% - ${appStore.smallFooter ? "10" : "40"}px)`,
				}}
				onDoubleClick={() => {
					if (appStore.smallFooter) {
						localStorage.removeItem("smallFooter");
					} else {
						localStorage.smallFooter = "true";
					}
					appStore.smallFooter = !appStore.smallFooter;
				}}
			>
				<Grid container>
					<Grid item xs style={{ alignSelf: "center" }}>
						<span>customer service</span> |{" "}
						<span>
							<a href="https://policies.wearetuzag.com">privacy policy</a>
						</span>{" "}
						|{" "}
						<span className="link clickable" onClick={this.logout}>
							sign out
						</span>
					</Grid>
					<Grid item xs style={{ textAlign: "center", alignSelf: "center" }}>
						<p style={{ display: appStore.smallFooter ? "inline" : "block" }}>
							Copyright {moment().format("YYYY")} tuzag, inc. All rights
							reserved.
						</p>
						<p style={{ display: appStore.smallFooter ? "inline" : "block" }}>
							{appStore.smallFooter ? "  " : ""}v.{" "}
							{process.env.REACT_APP_VERSION}
						</p>
					</Grid>
					<Grid item xs style={{ textAlign: "right" }}>
						<img
							src={tuzagLogoWhite}
							alt="tuzag message bubble logo"
							style={{
								height: appStore.smallFooter ? 20 : 60,
							}}
						/>
					</Grid>
				</Grid>
			</footer>
		);
	}
}

export default observer(Footer);
