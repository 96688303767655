import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../../API";
import RedButton from "../../lib/RedButton";
import { appStore, usersStore } from "../../App";
import { observer } from "mobx-react";
import downloadjs from "downloadjs";
import Dropzone from "react-dropzone";
import snackbarStore from "../../lib/SnackbarStore";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Cookies from "js-cookie";
import client from "../../lib/feathers";
import ExportContentWord from "./ExportContentWord";

const ExportImportContent = observer(() => {
	const [canvases, setCanvases] = useState(null);
	const [selectedCanvas, setSelectedCanvas] = useState(null);
	const [exporting, setExporting] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [importAsUserID, setImportAsUserID] = useState(null);
	const [exportMessageStatus, setExportMessageStatus] = useState("In Review");
	const [importMessageStatus, setImportMessageStatus] = useState("In Process");
	const [messagesList, setMessagesList] = useState(null);

	//MZM ESLint didn't like me using a mobx store as a dependency of the effect,
	//but it doesn't work without it. So you know what I have to say to esLint?
	//disable, motherfucka
	/*eslint-disable */
	useEffect(() => {
		(async () => {
			if (appStore.selectedProject) {
				const { data } = await API(`/db/customQuery/canvas`, "POST", {
					query: "find",
					options: {
						order: [["name", "ASC"]],
						attributes: ["id", "name"],
						where: { projectID: appStore.selectedProject },
					},
				});

				setCanvases(data);
			}
		})();
	}, [appStore.selectedProject]);

	useEffect(() => {
		if (!importAsUserID) {
			setImportAsUserID(Cookies.get("userID"));
		}
	}, [usersStore.users]);
	/*eslint-disable */

	const onDrop = async (files) => {
		setUploading(true);
		const formData = new FormData();
		for (const file of files) {
			formData.append("files", file);
		}
		const { error } = await API(
			`/contentImport?importAsUserID=${importAsUserID}&messageStatus=${importMessageStatus}`,
			"POST",
			null,
			null,
			formData
		);
		setUploading(false);
		if (!error) {
			snackbarStore.setMessage("Success", "Comments have been uploaded");
		} else {
			snackbarStore.setMessage(
				"Error",
				`Error on files: ${error}. The rest uploaded fine.`
			);
		}
	};

	const messageStatuses = [
		"Backlog",
		"To Do",
		"Doing",
		"In Process",
		"Roadblocked",
		"Done",
		"In Testing",
		"In Review",
		"Approved",
	];

	const listMessages = async () => {
		setMessagesList(null);

		let messages = await client
			.service("message")
			.find({ query: { canvasID: selectedCanvas.id, $sort: { name: 1 } } });

		const canvas = await client
			.service("canvas")
			.find({ query: { id: selectedCanvas.id, $select: ["name", "id"] } });

		messages = messages.map((message) => message.name);

		if (canvas.length && canvas[0]?.content) {
			//Sometimes we end up with ghost messages so this is just a lazy check
			//to make sure the message name is in the XML canvas content somewhere.
			messages = messages.filter((message) =>
				canvas[0].content.includes(message)
			);
		}

		setMessagesList(messages);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Paper style={{ padding: 15 }}>
						<h3>Export Excel/Message Names</h3>
						<p style={{ marginBottom: 10 }}>
							Clear message status to export message list.
						</p>
						{canvases ? (
							<>
								<Autocomplete
									options={canvases}
									value={selectedCanvas}
									onChange={(event, newValue) => {
										setSelectedCanvas(newValue);
									}}
									getOptionLabel={(option) => option.name || ""}
									style={{ width: "100%" }}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Select Canvas"
											variant="outlined"
										/>
									)}
								/>
								{selectedCanvas ? (
									<>
										<Autocomplete
											options={messageStatuses}
											value={exportMessageStatus}
											onChange={(event, newValue) => {
												setExportMessageStatus(newValue);
											}}
											style={{ width: "100%", marginTop: 15 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Set Exported Messages to Status"
													variant="outlined"
												/>
											)}
										/>
										<Grid container spacing={2} style={{ marginTop: 15 }}>
											{!exportMessageStatus && (
												<Grid item xs={6}>
													<RedButton onClick={listMessages}>
														List Messages
													</RedButton>
												</Grid>
											)}
											<Grid item xs={exportMessageStatus ? 12 : 6}>
												<RedButton
													onClick={async () => {
														setExporting(true);
														const { downloadPath } = await API(
															`/exportContent`,
															"POST",
															{
																exportMessageStatus,
																canvasID: selectedCanvas.id,
																canvasName: selectedCanvas.name
																	.replace(/\s/g, "-")
																	.replace(/'/g, ""),
															}
														);
														await downloadjs(`//${downloadPath}`);
														setExporting(false);
													}}
													disabled={exporting}
												>
													{exporting ? "Downloading..." : "Export"}
												</RedButton>
											</Grid>
										</Grid>
									</>
								) : null}
							</>
						) : (
							<div
								style={{
									justifyContent: "center",
									display: "flex",
									padding: 15,
								}}
							>
								<CircularProgress />
							</div>
						)}
					</Paper>
					{messagesList && (
						<Paper style={{ marginTop: 15, padding: 10 }}>
							<h3>Messages on Canvas ({messagesList.length})</h3>
							<ul>
								{messagesList.map((message, index) => {
									return <li key={index}>{message}</li>;
								})}
							</ul>
						</Paper>
					)}
					<ExportContentWord />
				</Grid>
				<Grid item xs={6}>
					<Paper style={{ padding: 15 }}>
						<h3>Import</h3>
						<Paper style={{ padding: 10, marginBottom: 15 }} elevation={3}>
							<p>
								Set dropdowns before dropping files. Message status dropdown
								will overwrite field in Excel file if set.
							</p>
						</Paper>
						{uploading ? (
							<div style={{ textAlign: "center", padding: 15 }}>
								<CircularProgress />
							</div>
						) : (
							<>
								{appStore.isAdmin && usersStore.users ? (
									<div style={{ paddingBottom: 15 }}>
										<InputLabel id="import-as-label">Import As User</InputLabel>
										<Select
											labelId="import-as-label"
											style={{ width: "100%" }}
											value={importAsUserID || ""}
											onChange={(evt) => setImportAsUserID(evt.target.value)}
										>
											{usersStore.users.map((user) => (
												<MenuItem key={user.id} value={user.id}>
													{user.value}
												</MenuItem>
											))}
										</Select>
									</div>
								) : null}
								<Autocomplete
									options={messageStatuses}
									value={importMessageStatus}
									onChange={(event, newValue) => {
										setImportMessageStatus(newValue);
									}}
									style={{ width: "100%", marginBottom: 15 }}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Set Imported Messages to Status"
										/>
									)}
								/>
								<Dropzone
									onDrop={onDrop}
									multiple
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								>
									{({ getRootProps, getInputProps }) => (
										<section>
											<div
												{...getRootProps()}
												style={{
													width: "100%",
													height: 75,
													borderWidth: 2,
													borderColor: "darkGray",
													borderStyle: "dashed",
													borderRadius: 5,
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<input {...getInputProps()} />
												<p>
													Drag 'n' drop some files here, or click to select
													files
												</p>
											</div>
										</section>
									)}
								</Dropzone>
							</>
						)}
					</Paper>
				</Grid>
			</Grid>
		</>
	);
});

export default ExportImportContent;
