import React, { useEffect, useState } from "react";
// import Backdrop from "@material-ui/core/Backdrop";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Canvas from "./Canvas";
import client, { socket } from "../../../lib/feathers";

export const CanvasWrapper = (props) => {
	const [isLoading, setLoading] = useState(true);
	// Is the socket connected?
	const [isConnected, setConnected] = useState(client.io.connected);
	// Is this tab active?
	const [isActive, setActive] = useState(tabIsVisible());
	// const hideCanvas = !isConnected || !isActive || isLoading;
	let timeout = null;

	const onConnectionChange = () => setConnected(client.io.connected);
	const onVisibilityChange = () => setActive(tabIsVisible());
	const onLoadingChange = (value) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => setLoading(value), value ? 1 : 500);
		onConnectionChange();
	};

	useEffect(() => {
		client.on("disconnect", onConnectionChange);
		client.on("connect", onConnectionChange);
		client.on("reconnect", onConnectionChange);
		return () => {
			client.off("disconnect", onConnectionChange);
			client.off("connect", onConnectionChange);
			client.off("reconnect", onConnectionChange);
		};
	}, [isConnected]);

	useEffect(() => {
		document.addEventListener("visibilitychange", onVisibilityChange);
		return () =>
			document.removeEventListener("visibilitychange", onVisibilityChange);
	}, [isActive]);

	useEffect(() => {
		//Attempt to reconnect if the computer goes to sleep. A little hacky, but
		//should get the job done.
		let lastTime = new Date().getTime();

		const interval = setInterval(async () => {
			const currentTime = new Date().getTime();
			if (currentTime > lastTime + 2000 * 2) {
				// ignore small delays
				// Probably just woke up!
				if (socket?.socket) {
					socket.socket.reconnect();
					await client.reAuthenticate();
				}
			}
			lastTime = currentTime;
		}, 2000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			{/*MZM 5/14/21 Disabling the backdrop as we aren't using sockets on the canvas*/}
			{/*<Backdrop*/}
			{/*	style={{ zIndex: 10000, color: "var(--red)", background: "#e6e6e6" }}*/}
			{/*	open={hideCanvas}*/}
			{/*	transitionDuration={200}*/}
			{/*>*/}
			{/*	{isConnected ? (*/}
			{/*		<CircularProgress color="inherit" disableShrink />*/}
			{/*	) : (*/}
			{/*		<h4>No Network Connection Detected</h4>*/}
			{/*	)}*/}
			{/*</Backdrop>*/}
			<Canvas
				{...props}
				loading={isLoading}
				setLoading={onLoadingChange}
				isActive={isActive}
			/>
		</>
	);
};

export default CanvasWrapper;

const tabIsVisible = () => document.visibilityState === "visible";
