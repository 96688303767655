import { observer } from "mobx-react";
import React, { Component } from "react";
import { usersStore } from "../../../App";
import Webix from "../../../lib/Webix";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import API from "../../../API";

export default observer(
	class Comments extends Component {
		render() {
			if (usersStore.users.length === 0) {
				return null;
			}
			return (
				<Webix
					style={{ width: "100%" }}
					className="comments"
					id="webixComponent"
					ui={{
						view: "comments",
						id: "comments",
						currentUser: parseInt(Cookies.get("userID")),
						container: "webixComponent",
						minHeight: 350,
						mentions: true,
						listItem: {
							templateDate: (obj) => {
								if (obj && obj.date) {
									const hoursAgo = moment().diff(obj.date, "hours");
									return `<span class='webix_comments_date'>${
										hoursAgo > 24
											? moment(obj.data).format("M/D/YY h:mm a")
											: moment(obj.date).fromNow()
									}</span>`;
								} else {
									return <span></span>;
								}
							},
						},
						save: async (itemID, method, itemData) => {
							const data = await API(`/comment`, "POST", {
								method,
								itemData,
								type: this.props.type,
								associatedID: this.props.associatedID,
								webixID: itemID,
							});

							return data.id;
						},
						url: {
							$proxy: true,
							load: async (view, params) => {
								const { data } = await API(`/db/customQuery/comment`, "POST", {
									query: "find",
									options: {
										where: {
											type: this.props.type,
											associatedID: this.props.associatedID,
										},
									},
								});
								let markAsRead = data
									.filter((comment) => {
										return !comment.read;
									})
									.map((comment) => {
										return comment.id;
									});
								API(`/markAsRead`, "POST", { commentIDs: markAsRead });
								return data.map((comment) => {
									return {
										id: comment.id,
										user_id: comment.userID,
										date: new Date(comment.createdAt),
										text: comment.comment,
									};
								});
							},
						},
						users: usersStore.users,
						on: {
							onUserMentioned: function (userID, id) {
								API(`/mentionCommentNotification`, "POST", {
									userID,
									webixID: id,
								});
							},
						},
					}}
				/>
			);
		}
	}
);
