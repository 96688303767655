import React, { useState } from "react";
import { action } from "mobx";
import Checkbox from "@material-ui/core/Checkbox";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import TablePaginationActions from "../../../lib/TablePaginationActions";
import { Anchor } from "../TaskManager/TaskManager";
import { contentStore, errorFilters, onFixed, onIgnore } from "./store";

const useStyles = makeStyles((theme) => ({
	outputTable: {
		tableLayout: "auto",
		minWidth: "calc(100vw - 300px)",
	},
	messageColumn: {
		// width: 300
	},
	elementColumn: {
		// width: 175
	},
	canvasColumn: {},
	errorColumn: {},
	actionColumn: {
		width: 50,
	},
}));

/**
 * @component BaseOutputTable - Pass in the ErrorFilter (see ./store.js), & optional components, this handles the rest
 * of the 'fixed', 'ignore', & other CRUD operations
 *    headerCells component - Optional Header Cell to add after the Element Name column
 *    tableCells component - Optional row cell, added after the Element Name column
 */
const BaseOutputTable = observer(
	({ field, headerCells = null, tableCells = null }) => {
		const outputList = contentStore.filteredOutput;
		const TableCells = tableCells; // Alias so React doesn't complain
		const classes = useStyles();
		const [page, setPage] = useState(0);
		const [rowsPerPage, setRowsPerPage] = useState(10);

		const onChangePage = (event, number) => setPage(number);
		const onChangeRowsPerPage = (event) => {
			setRowsPerPage(parseInt(event.target.value, 10));
			setPage(0);
		};

		const rows =
			rowsPerPage > 0
				? outputList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				: outputList;

		const emptyRows =
			rowsPerPage -
			Math.min(rowsPerPage, outputList.length - page * rowsPerPage);

		return (
			<TableContainer>
				<Table className={classes.outputTable}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.actionColumn}>Ignore</TableCell>
							<TableCell className={classes.actionColumn}>Fixed</TableCell>
							<TableCell className={classes.messageColumn}>
								Message Name
							</TableCell>
							<TableCell className={classes.elementColumn}>
								Element Name
							</TableCell>
							{headerCells}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((output) => {
							const { id, contentTaskID, message, element } = output;
							const canvas = contentStore.tasks[contentTaskID]?.canvas;
							const url = `/canvas/${canvas.id}/${message.shapeID}/element/${element.id}`;
							return (
								<TableRow key={id}>
									<TableCell align="center">
										{/* Ignore */}
										<IconButton
											onClick={() => onIgnore(field, output)}
											edge="start"
										>
											<HighlightOffIcon />
										</IconButton>
									</TableCell>
									<TableCell align="center">
										{/* Fixed */}
										<Checkbox
											edge="start"
											disableRipple
											onChange={() => onFixed(field, id)}
										/>
									</TableCell>
									<TableCell>
										{/*  Message Name*/}
										<Anchor to={url} target="_blank">
											{message.name}
										</Anchor>
									</TableCell>
									<TableCell>
										{/*  Element Name*/}
										<Anchor to={url} target="_blank">
											{element.name}
										</Anchor>
									</TableCell>
									{!TableCells ? null : (
										<TableCells output={output} canvas={canvas} />
									)}
								</TableRow>
							);
						})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
								colSpan={5}
								count={outputList.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onChangePage={onChangePage}
								onChangeRowsPerPage={onChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		);
	}
);

const AllErrorsTable = ({ ...props }) => {
	const tableCells = ({ output }) => {
		return (
			<TableCell>
				{errorFilters
					.filter(({ key }) => output[key])
					.map((errorFilter) => (
						<ErrorTypeText
							errorFilter={errorFilter}
							key={`${output.id}-${errorFilter.id}`}
						/>
					))}
			</TableCell>
		);
	};
	return (
		<BaseOutputTable
			tableCells={tableCells}
			headerCells={<TableCell>Error Type(s)</TableCell>}
			{...props}
		/>
	);
};

const ErrorTypeText = ({ errorFilter }) => (
	<Link
		onClick={action(
			() =>
				(contentStore.selectedErrorFilter = errorFilters.find(
					({ id }) => errorFilter.id === id
				))
		)}
		color="secondary"
		underline="always"
		style={{ cursor: "pointer", display: "block" }}
	>
		{errorFilter.name}
	</Link>
);

const MissingSpaceTable = ({ ...props }) => (
	<CharacterErrorTable positionKey="missingSpacePosition" {...props} />
);

const MissingQuoteTable = ({ ...props }) => (
	<CharacterErrorTable positionKey="missingQuotePosition" {...props} />
);

const TwoSpacesTable = ({ ...props }) => (
	<CharacterErrorTable
		positionKey="twoSpacesPosition"
		ErrorText={({ word }) => (
			<div
				style={{
					background: "rgba(255, 0, 0, 0.5)",
					width: "clamp(1rem, 100%, 100%)",
					height: "clamp(1rem, 100%, 100%)",
					minHeight: "1rem",
				}}
				dangerouslySetInnerHTML={{ __html: word || "" }}
			/>
		)}
		{...props}
	/>
);

const DoubleWordsTable = ({ ...props }) => (
	<WordErrorTable positionKey="doubleWordsPosition" {...props} />
);

const MisspelledWordsTable = ({ ...props }) => (
	<WordErrorTable positionKey="misspelledWordPosition" {...props} />
);

export const ErrorComponents = {
	// ErrorFilter.id: ErrorTableComponent
	1: AllErrorsTable,
	3: MissingSpaceTable,
	4: TwoSpacesTable,
	5: MissingQuoteTable,
	6: DoubleWordsTable,
	9: MisspelledWordsTable,
	default: BaseOutputTable,
};

const WordErrorTable = ({
	positionKey,
	ErrorText = RedText,
	headerCellTitle = "Content",
	...props
}) => {
	const TableCells = ({ output }) => {
		const { content } = output;
		const positionList = output[positionKey]?.length
			? output[positionKey]
			: [[0, content.length]];
		const errorTextComponents = wrapWords(content, positionList);
		return (
			<TableCell>
				<div style={{ maxWidth: "40vw" }}>
					{errorTextComponents.map((word, index) =>
						word.isError ? (
							<ErrorText
								key={index}
								dangerouslySetInnerHTML={{ __html: word.word }}
							/>
						) : (
							<div
								key={index}
								dangerouslySetInnerHTML={{ __html: word.word }}
							/>
						)
					)}
				</div>
			</TableCell>
		);
	};
	return (
		<BaseOutputTable
			tableCells={TableCells}
			headerCells={<TableCell>{headerCellTitle}</TableCell>}
			{...props}
		/>
	);
};

const CharacterErrorTable = ({
	positionKey,
	ErrorText = RedText,
	headerCellTitle = "Content",
	...props
}) => {
	const TableCells = ({ output }) => {
		const { content } = output;
		// If we don't have a list of error positions, just wrap all of the content
		// by delegating to `wrapWords`
		const errorTextComponents = output[positionKey]?.length
			? wrapCharacters(content, output[positionKey])
			: wrapWords(content, [[0, content.length]]);
		return (
			<TableCell>
				<div style={{ maxWidth: "40vw" }}>
					{errorTextComponents.map((word, index) =>
						word.isError ? (
							<ErrorText key={index} word={word.word} />
						) : (
							<div
								key={index}
								dangerouslySetInnerHTML={{ __html: word.word }}
							/>
						)
					)}
				</div>
			</TableCell>
		);
	};
	return (
		<BaseOutputTable
			tableCells={TableCells}
			headerCells={<TableCell>{headerCellTitle}</TableCell>}
			{...props}
		/>
	);
};

const RedText = ({ word, ...props }) => (
	<div
		style={{ background: "rgba(255, 0, 0, 0.5)" }}
		{...props}
		dangerouslySetInnerHTML={{ __html: word }}
	/>
);

/**
 * @func wrapWords - Transpose a `sentence` to a list with words at `positions` wrapped in the `render` function
 */
const wrapWords = (sentence, positions) =>
	positions.reduce(
		({ wordList, lastPosition }, [startPosition, endPosition]) => {
			const wordLength = endPosition - startPosition;
			const prefix = {
				isError: false,
				word: sentence.slice(lastPosition + 1, startPosition),
			};
			const wrapped = {
				isError: true,
				word: sentence.substr(startPosition, wordLength + 1),
			};
			return {
				wordList: wordList.concat([prefix, wrapped]),
				lastPosition: endPosition,
			};
		},
		{
			wordList: [],
			lastPosition: 0,
		}
	).wordList;

/**
 * @func wrapCharacters - Transpose a `sentence` to a list with characters at `positions
 */
const wrapCharacters = (sentence, positions) =>
	positions.reduce(
		({ wordList, lastPosition }, position) => {
			const prefix = {
				isError: false,
				word: position === 0 ? "" : sentence.slice(lastPosition + 1, position),
			};
			const wrapped = {
				isError: true,
				word: sentence.substr(position, 1),
			};
			return {
				lastPosition: position,
				wordList: wordList.concat([prefix, wrapped]),
			};
		},
		{
			wordList: [],
			lastPosition: -1,
		}
	).wordList;
