import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import client from "../../lib/feathers";
import { appStore, usersStore } from "../../App";
import { observer } from "mobx-react";
import Select from "react-select";
import RedButton from "../../lib/RedButton";
import snackbarStore from "../../lib/SnackbarStore";

const BulkMessageUpdate = () => {
	const [canvases, setCanvases] = useState();
	const [selectedCanvas, setSelectedCanvas] = useState();
	const [selectedMessageOwner, setSelectedMessageOwner] = useState();
	const [selectedStatus, setSelectedStatus] = useState();
	const [updateInProgress, setUpdateInProgress] = useState(false);

	useEffect(() => {
		if (appStore.selectedProject) {
			(async () => {
				const canvasesDB = await client.service("canvas").find({
					query: {
						projectID: appStore.selectedProject,
						attributes: ["id", "name"],
					},
				});
				setCanvases(canvasesDB);
			})();
		}
	}, []);

	const statusOptions = [
		"Backlog",
		"To Do",
		"Doing",
		"In Process",
		"Roadblocked",
		"Done",
		"In Testing",
		"In Review",
		"Approved",
	].map((option) => {
		return { value: option, label: option };
	});

	const updateMessages = async () => {
		setUpdateInProgress(true);
		try {
			const messages = await client
				.service("message")
				.find({ query: { canvasID: selectedCanvas.value } });

			if (selectedMessageOwner) {
				for (const message of messages) {
					try {
						await client.service("messageMember").create({
							messageID: message.id,
							userID: selectedMessageOwner.value,
						});
					} catch (e) {
						//ignore, they probably are already on the message
					}
				}

				const makeThemMessageOwner = [];
				for (const message of messages) {
					makeThemMessageOwner.push(
						client
							.service("message")
							.patch(message.id, { messageOwner: selectedMessageOwner.value })
					);
				}
				await Promise.all(makeThemMessageOwner);
			}

			if (selectedStatus) {
				let setMessageStatus = [];
				for (const message of messages) {
					setMessageStatus.push(
						client
							.service("message")
							.patch(message.id, { status: selectedStatus.value })
					);
				}
				await Promise.all(setMessageStatus);
			}
			snackbarStore.setMessage(
				`Success`,
				"Messages updated. Don't forget to tip your friendly development team on the way out."
			);
		} catch (e) {
			console.error(e);
			snackbarStore.setMessage(
				"Error",
				"Something went wrong. Contact support."
			);
		}

		setUpdateInProgress(false);
	};

	return (
		<div>
			<h3>Bulk Update Messages</h3>
			<Paper style={{ padding: 15 }}>
				{!canvases || !usersStore.users ? (
					<p>Loading...</p>
				) : (
					<>
						<p style={{ marginBottom: 20 }}>
							Leave message owner or status blank if you don't want to change
							them
						</p>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<label>Canvas:</label>
								<Select
									options={canvases.map((canvas) => ({
										label: canvas.name,
										value: canvas.id,
									}))}
									value={selectedCanvas}
									onChange={(value) => setSelectedCanvas(value)}
									isClearable={true}
								/>
							</Grid>
							<Grid item xs={4}>
								<label>Message Owner:</label>
								<Select
									options={usersStore.users.map((user) => ({
										value: user.id,
										label: user.value,
									}))}
									value={selectedMessageOwner}
									onChange={(value) => setSelectedMessageOwner(value)}
									isClearable={true}
								/>
							</Grid>
							<Grid item xs={4}>
								<label>Message Status:</label>
								<Select
									options={statusOptions}
									value={selectedStatus}
									onChange={(value) => setSelectedStatus(value)}
									isClearable={true}
								/>
							</Grid>
						</Grid>
					</>
				)}
				{selectedCanvas && (selectedMessageOwner || selectedStatus) ? (
					<RedButton
						style={{ marginTop: 20 }}
						disabled={updateInProgress}
						onClick={updateMessages}
					>
						{updateInProgress ? "Updating..." : "Update dem' Messages"}
					</RedButton>
				) : null}
			</Paper>
		</div>
	);
};

export default observer(BulkMessageUpdate);
