import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import RedButton from "../../../lib/RedButton";
import ReactSelect from "react-select";
import {
	ReactSelectStyles,
	ReactSelectTheme,
} from "../../../lib/HelperFunctions";

export const useStyles = makeStyles((theme) => ({
	textField: {
		margin: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	paper: {
		margin: theme.spacing(2),
		width: "100%",
		padding: "1rem",
		background: grey[100],
	},
	selectCheckbox: {
		padding: 0,
	},
}));

/**
 * @component CreateTask - Dialog to handle creating a new Content Task
 */
export const CreateTask = ({ loading, canvasOptions, onSave, onClose }) => {
	const [selectedIds, setSelectedIds] = useState([]);
	const allCanvasIDs = canvasOptions.map(({ id }) => id);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!selectedIds) {
			onSave(allCanvasIDs);
		} else {
			onSave(selectedIds.map((option) => option.value));
		}
		onClose();
	};

	const onChange = (options) => {
		setSelectedIds(Array.isArray(options) ? options : []);
	};

	useEffect(() => {
		setSelectedIds([]);
	}, []);

	return (
		<Dialog
			onClose={onClose}
			open={true}
			fullWidth
			maxWidth="lg"
			className="reactSelectScroll"
		>
			<DialogContent>
				<form onSubmit={onSubmit} noValidate autoComplete="off">
					<h3>Create Test Task for Canvas(es)</h3>
					<p>
						Select (multiple) canvases to run testing tasks against. Or start
						tests for all canvases by not selecting anything.
					</p>
					{loading ? (
						<div style={{ textAlign: "center", padding: 15 }}>
							<CircularProgress color="secondary" />
						</div>
					) : (
						<div style={{ width: "100%", marginTop: 10 }}>
							<ReactSelect
								options={canvasOptions.map((canvas) => {
									return { value: canvas.id, label: canvas.name };
								})}
								isMulti
								theme={ReactSelectTheme}
								styles={ReactSelectStyles}
								onChange={onChange}
								value={selectedIds}
								closeMenuOnSelect={false}
							/>
						</div>
					)}
				</form>
			</DialogContent>
			<DialogActions>
				<Grid container>
					<Grid item xs={8} />
					<Grid item xs={2}>
						<Button onClick={onClose}>Cancel</Button>
					</Grid>
					<Grid item xs={2}>
						<RedButton
							onClick={onSubmit}
							color="primary"
							type="submit"
							autoFocus
						>
							Start {selectedIds.length === 0 ? "All Tests" : "Test(s)"}
						</RedButton>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default CreateTask;
