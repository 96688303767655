/**
 * @module lib/ErrorLogger - Adds an 'onError' even listener on the window,
 * and POSTS the respective errors to the backend so they can go into Cloud Logging
 */
import throttle from "lodash.throttle";
import { apiURL } from "../API.jsx";
import Cookies from "js-cookie";

let errors = [];

const errorHandler = () => {
	fetch(`${apiURL}/logging`, {
		method: "POST",
		body: JSON.stringify({
			errors,
		}),
		headers: {
			"Content-Type": "application/json",
			token: Cookies.get("token"),
		},
	})
		.then(() => (errors = []))
		.catch((error) => {
			// Proactively avoid infinite loops in case the API is down
			console.error(error);
			return Promise.resolve(true);
		});
};

const handleError = throttle(errorHandler, 300);

window.addEventListener("error", (errorEvent) => {
	const { message, filename, lineno, colno, error = null } = errorEvent;
	// ErrorEvents are weird and don't always include all of the Error Object
	// properties.  This ensures we introspect the Error & Error message
	if (!isResizeError(error) && !isResizeError({ message })) {
		logError(error, {
			colno,
			message,
			filename,
			lineno,
			error,
			browserUrl: window.location.href,
		});
	}
});

/**
 * @func logError - Push an error into the logging queue
 * @param {Error} error
 * @param {Object} [extraFields={}] - Logging meta-data
 */
export const logError = (error, extraFields = {}) => {
	if (isResizeError(error) || !error) {
		return;
	}
	const errorPayload = errProps.reduce(
		(accum, key) => {
			const value = error[key];
			if (value) {
				accum[key] = value;
			}
			return accum;
		},
		{
			userId: Cookies.get("userID"),
			browserUrl: window.location.href,
			hasNetworkConnection: navigator.onLine,
			...extraFields,
		}
	);
	errors.push(errorPayload);
	handleError();
	return errorPayload;
};

const errProps = [
	"message",
	"fileName",
	"columnNumber",
	"lineNumber",
	"name",
	"stack",
];

// Source: https://github.com/quasarframework/quasar/issues/2233#issuecomment-678115434
const isResizeError = (error) => resizeObserverRe.test(error?.message, /gi/);

const resizeObserverRe = /^ResizeObserver loop limit exceeded/;
