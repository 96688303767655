import React, { useEffect, useState, useRef } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react";
import Paper from "@material-ui/core/Paper";
import client from "../../lib/feathers";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactSelect from "react-select";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import HeightIcon from "@material-ui/icons/Height";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { ReactSortable } from "react-sortablejs";
import snackbarStore from "../../lib/SnackbarStore";
import RedButton from "../../lib/RedButton";
import API from "../../API";
import downloadjs from "downloadjs";
import { arrayMove, ReactSelectStyles } from "../../lib/HelperFunctions";
import AddIcon from "@material-ui/icons/Add";

const Store = makeAutoObservable({
	exportTemplates: null,
	canvases: null,
	checkedCanvases: {},
	canvasFilterResults: null,
	reorderMode: false,
	checkedMessages: {},
	messages: {},
	selectedCanvas: null,
	messageFilterResults: null,
});

const ExportContentWord = () => {
	const [templateMenu, setTemplateMenu] = useState();
	const [selectedExportTemplate, setSelectedExportTemplate] = useState();
	const canvasesLabel = useRef();
	const [exporting, setExporting] = useState(false);
	const [bulkAddValue, setBulkAddValue] = useState();

	useEffect(() => {
		(async () => {
			const canvasesDB = await client
				.service("canvas")
				.find({ query: { $sort: { name: 1 }, $select: ["name", "id"] } });

			client.service("channelManager").create({ name: `exportTemplate` });

			const exportTemplatesDB = await client.service("exportTemplate").find();

			client
				.service("exportTemplate")
				.on("created", (newRow) => {
					Store.exportTemplates.push(newRow);
				})
				.on("removed", (removedRow) => {
					Store.exportTemplates = Store.exportTemplates.filter(
						(row) => row.id !== removedRow.id
					);
				})
				.on("patched", (patched) => {
					const toPatch = Store.exportTemplates.findIndex(
						(item) => item.id === patched.id
					);
					if (toPatch !== -1) {
						Store.exportTemplates[toPatch] = patched;
					}
				});

			runInAction(() => {
				Store.canvases = canvasesDB;
				Store.exportTemplates = exportTemplatesDB;
			});
		})();

		return () => {
			client
				.service("exportTemplate")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
			client.service("channelManager").remove({ name: `exportTemplate` });
		};
	}, []);

	const saveTemplate = async (exportTemplateID) => {
		let canvasesToSave = Store.canvases.filter(
			(canvas) => Store.checkedCanvases[canvas.id]
		);

		for (const canvas of canvasesToSave) {
			let messageIDs = [];

			if (Store.messages[canvas.id]) {
				let messages = Store.messages[canvas.id].filter((message) => {
					return Store.checkedMessages[canvas.id][message.id];
				});

				messageIDs = messages.map((message) => message.id).join(",");
			} else {
				const messagesDB = await client.service("message").find({
					query: {
						canvasID: canvas.id,
						$select: ["id", "shapeID"],
						$sort: { id: 1 },
					},
				});
				const shapes = await client
					.service("shape")
					.find({ query: { canvasID: canvas.id, $select: ["shapeID"] } });
				const shapeIDs = shapes.map((shape) => shape.shapeID);

				messageIDs = messagesDB
					.filter((message) => shapeIDs.includes(message.shapeID))
					.map((message) => message.id)
					.join(",");
			}

			client.service("exportTemplateCanvas").create({
				exportTemplateID,
				canvasID: canvas.id,
				messageIDs,
			});
		}
	};

	const saveAsNewTemplate = async () => {
		setTemplateMenu(null);

		const name = window.prompt("New template name?");

		const newTemplate = await client.service("exportTemplate").create({ name });

		await saveTemplate(newTemplate.id);
	};

	const updateExistingTemplate = async () => {
		setTemplateMenu(null);

		const selectedExportName = selectedExportTemplate.label;
		await client.service("exportTemplate").remove(selectedExportTemplate.value);
		const newTemplate = await client
			.service("exportTemplate")
			.create({ name: selectedExportName });
		saveTemplate(newTemplate.id);

		setSelectedExportTemplate({
			label: selectedExportName,
			value: newTemplate.id,
		});

		snackbarStore.setMessage("Success", "Export template has been updated");
	};

	const deleteTemplate = () => {
		setTemplateMenu(null);

		if (window.confirm("Are you sure you want to delete this template?")) {
			client.service("exportTemplate").remove(selectedExportTemplate.value);
			setSelectedExportTemplate(null);
		}
	};

	const setCheckedBoxes = async (val) => {
		const templateCanvases = await client
			.service("exportTemplateCanvas")
			.find({ query: { exportTemplateID: val.value } });

		let checkedCanvasesToSet = {};
		let checkedMessagesToSet = {};
		let messagesToUpdate = { ...Store.messages };
		let reorderedCanvases = [];

		for (const templateCanvas of templateCanvases) {
			const canvas = Store.canvases.find(
				(canvas) => canvas.id === templateCanvas.canvasID
			);
			reorderedCanvases.push(canvas);

			let reorderedMessages = [];

			if (!messagesToUpdate[templateCanvas.canvasID]) {
				const shapes = await client
					.service("shape")
					.find({ query: { canvasID: canvas.id, $select: ["shapeID"] } });
				const shapeIDs = shapes.map((shape) => shape.shapeID);

				const messagesDB = await client.service("message").find({
					query: {
						canvasID: templateCanvas.canvasID,
						$sort: { id: 1 },
						$select: ["name", "id", "canvasID", "shapeID"],
					},
				});

				messagesToUpdate[
					templateCanvas.canvasID
				] = messagesDB.filter((message) => shapeIDs.includes(message.shapeID));
			}
			checkedCanvasesToSet[templateCanvas.canvasID] = true;

			const templateMIDs = templateCanvas.messageIDs
				.split(",")
				.map((num) => parseInt(num));

			for (const messageID of templateMIDs) {
				if (!(templateCanvas.canvasID in checkedMessagesToSet)) {
					checkedMessagesToSet[templateCanvas.canvasID] = {};
				}
				checkedMessagesToSet[templateCanvas.canvasID][messageID] = true;
				reorderedMessages.push(
					messagesToUpdate[templateCanvas.canvasID].find(
						(message) => message.id === messageID
					)
				);
			}

			for (const message of messagesToUpdate[templateCanvas.canvasID]) {
				if (!templateMIDs.includes(message.id)) {
					reorderedMessages.push(message);
				}
			}

			messagesToUpdate[templateCanvas.canvasID] = reorderedMessages;
		}

		const canvasIDsInTemplate = templateCanvases.map(
			(canvas) => canvas.canvasID
		);
		for (const canvas of Store.canvases) {
			if (!canvasIDsInTemplate.includes(canvas.id)) {
				reorderedCanvases.push(canvas);
			}
		}

		runInAction(() => {
			Store.canvasFilterResults = null;
			Store.messageFilterResults = null;
			Store.checkedMessages = checkedMessagesToSet;
			Store.checkedCanvases = checkedCanvasesToSet;
			Store.messages = messagesToUpdate;
			Store.canvases = reorderedCanvases;
		});
	};

	if (!Store.canvases) {
		return (
			<Paper style={{ marginTop: 15, padding: 10 }}>
				<div style={{ textAlign: "center", padding: 15 }}>
					<CircularProgress />
				</div>
			</Paper>
		);
	}

	const bulkAddMessage = () => {
		if (bulkAddValue) {
			const matchingMessage = Store.messages[Store.selectedCanvas].find(
				(message) => message.id === bulkAddValue.value
			);

			if (matchingMessage) {
				if (Store.reorderMode) {
					Store.messageFilterResults.push({
						...matchingMessage,
						sortNumber: Store.messageFilterResults.length + 1,
					});
					Store.checkedMessages[Store.selectedCanvas][
						bulkAddValue.value
					] = true;
				} else {
					Store.checkedMessages[Store.selectedCanvas][
						bulkAddValue.value
					] = true;
					const itemToMoveIndex = Store.messages[
						Store.selectedCanvas
					].findIndex((message) => `${message.id}` === `${bulkAddValue.value}`);

					let numCheckedItems = 0;
					for (const value of Object.values(
						Store.checkedMessages[Store.selectedCanvas]
					)) {
						if (value) {
							numCheckedItems++;
						}
					}

					Store.messages[Store.selectedCanvas] = arrayMove(
						Store.messages[Store.selectedCanvas],
						itemToMoveIndex,
						numCheckedItems - 1
					).map((item, index) => ({ ...item, sortNumber: index + 1 }));
				}
			}
		}
		setBulkAddValue({});
	};

	const canvasesList = Store.canvasFilterResults || Store.canvases;
	const messagesList =
		Store.messageFilterResults || Store.messages[Store.selectedCanvas];

	const oneCanvasChecked = Object.values(Store.checkedCanvases).includes(true);

	return (
		<Paper style={{ marginTop: 15, padding: 15 }}>
			<h3>Export Word</h3>
			<div style={{ marginBottom: 15, display: "flex" }}>
				{!Store.exportTemplates ? null : (
					<>
						<ReactSelect
							options={Store.exportTemplates
								.filter((template) => template.name && template.id)
								.map((exportTemplate) => ({
									label: exportTemplate.name,
									value: exportTemplate.id,
								}))}
							styles={{
								...ReactSelectStyles,
								container: (provided) => ({ ...provided, flex: 1 }),
							}}
							value={selectedExportTemplate}
							onChange={(val) => {
								setSelectedExportTemplate(val);

								setCheckedBoxes(val);
							}}
							placeholder="Export Template"
						/>

						{(oneCanvasChecked || selectedExportTemplate) && (
							<MenuIcon
								onClick={(event) => {
									setTemplateMenu(event.currentTarget);
								}}
								style={{
									alignSelf: "center",
									marginLeft: 10,
									cursor: "pointer",
								}}
							/>
						)}
						<Menu
							anchorEl={templateMenu}
							onClose={() => {
								setTemplateMenu(null);
							}}
							open={!!templateMenu}
						>
							{oneCanvasChecked ? (
								<MenuItem onClick={saveAsNewTemplate}>
									Save as new template
								</MenuItem>
							) : null}
							{selectedExportTemplate && (
								<MenuItem onClick={updateExistingTemplate}>
									Update existing template
								</MenuItem>
							)}
							{selectedExportTemplate && (
								<MenuItem onClick={deleteTemplate}>Delete template</MenuItem>
							)}
						</Menu>
					</>
				)}
			</div>
			<ButtonGroup style={{ marginBottom: 20 }}>
				<Button
					onClick={() => {
						if (!Store.reorderMode) {
							runInAction(() => {
								Store.canvasFilterResults = Store.canvases.filter(
									(canvas) => Store.checkedCanvases[canvas.id]
								);

								if (Store.selectedCanvas) {
									Store.messageFilterResults = Store.messages[
										Store.selectedCanvas
									]
										.filter(
											(message) =>
												Store.checkedMessages[Store.selectedCanvas][message.id]
										)
										.map((item, index) => ({
											...item,
											sortNumber: index + 1,
										}));
								}

								Store.reorderMode = !Store.reorderMode;
							});
						} else {
							//move the sorted & checked canvases to the top, then dump in the
							// rest of the non-checked ones
							const otherCanvases = Store.canvases.filter(
								(canvas) => !Store.checkedCanvases[canvas.id]
							);
							let otherMessages;
							if (Store.selectedCanvas) {
								otherMessages = Store.messages[Store.selectedCanvas].filter(
									(message) =>
										!Store.checkedMessages[Store.selectedCanvas][message.id]
								);
							}

							runInAction(() => {
								Store.canvases = [
									...Store.canvasFilterResults,
									...otherCanvases,
								];
								if (otherMessages) {
									Store.messages[Store.selectedCanvas] = [
										...Store.messageFilterResults,
										...otherMessages,
									].map((item, index) => ({
										...item,
										sortNumber: index + 1,
									}));
								}
								Store.messageFilterResults = null;
								Store.canvasFilterResults = null;
								Store.reorderMode = !Store.reorderMode;
							});
						}
					}}
					disabled={Store.checkedCanvases.length === 0}
				>
					{Store.reorderMode ? "Checkboxes" : "Reorder"}
				</Button>
				{!Store.reorderMode && (
					<Button
						onClick={() => {
							if (Object.keys(Store.checkedCanvases).length) {
								Store.checkedCanvases = {};
							} else {
								const canvasIDs = Store.canvases.map((canvas) => canvas.id);
								let toSet = {};
								for (const canvasID of canvasIDs) {
									toSet[canvasID] = true;
								}
								Store.checkedCanvases = toSet;
							}
						}}
					>
						{Object.keys(Store.checkedCanvases).length
							? "Uncheck All Canvases"
							: "Check All Canvases"}
					</Button>
				)}
				{!Store.reorderMode &&
					Store.selectedCanvas &&
					Array.isArray(Store.messages[Store.selectedCanvas]) &&
					Store.checkedMessages[Store.selectedCanvas] && (
						<Button
							onClick={() => {
								if (
									Store.checkedMessages[Store.selectedCanvas] &&
									Object.keys(Store.checkedMessages[Store.selectedCanvas])
										.length
								) {
									Store.checkedMessages[Store.selectedCanvas] = {};
								} else {
									const messageIDs = Store.messages[Store.selectedCanvas].map(
										(message) => message.id
									);
									let toSet = {};
									for (const messageID of messageIDs) {
										toSet[messageID] = true;
									}
									Store.checkedMessages[Store.selectedCanvas] = toSet;
								}
							}}
						>
							{Object.keys(Store.checkedMessages[Store.selectedCanvas]).length
								? "Uncheck All Messages"
								: "Check All Messages"}
						</Button>
					)}
			</ButtonGroup>
			{Store.reorderMode ? (
				<p>
					Click on multiple names to reorder several items at once. Remember to
					deselect after moving.
				</p>
			) : null}
			<Grid container spacing={2}>
				<Grid item xs={6}>
					{!Store.reorderMode && (
						<TextField
							label="Filter Canvases"
							variant="outlined"
							onChange={(evt) => {
								if (evt.target.value) {
									Store.canvasFilterResults = Store.canvases.filter((canvas) =>
										canvas.name
											.toLowerCase()
											.includes(evt.target.value.toLowerCase())
									);
								} else {
									Store.canvasFilterResults = null;
								}
							}}
							style={{ marginBottom: 10 }}
						/>
					)}
					<p style={{ textDecoration: "underline" }} ref={canvasesLabel}>
						Canvases:
					</p>
					<List dense>
						{Store.reorderMode ? (
							<ReactSortable
								list={Store.canvasFilterResults || Store.canvases}
								setList={(list) => {
									if (document.activeElement.tagName !== "INPUT") {
										Store.canvasFilterResults = list.map((item, index) => ({
											...item,
											sortNumber: index + 1,
										}));
									}
								}}
								multiDrag
								selectedClass="dragSelected"
								handle={".dragHandle"}
							>
								{canvasesList.map((canvas, index) => (
									<CustomListItem
										item={canvas}
										key={canvas.id}
										canvasesLabel={canvasesLabel}
										index={index}
										type="canvas"
									/>
								))}
							</ReactSortable>
						) : (
							canvasesList.map((canvas) => (
								<CustomListItem
									item={canvas}
									key={canvas.id}
									canvasesLabel={canvasesLabel}
								/>
							))
						)}
					</List>
				</Grid>
				{Array.isArray(messagesList) ? (
					<Grid item xs={6}>
						<p style={{ textDecoration: "underline" }}>Messages:</p>
						{Store.selectedCanvas && (
							<>
								<Grid container>
									<Grid item xs={10}>
										{Store.reorderMode ? (
											<ReactSelect
												options={Store.messages[Store.selectedCanvas]
													.filter((message) => {
														const checkedIDs = Store.messageFilterResults.map(
															(message) => message.id
														);
														return !checkedIDs.includes(message.id);
													})
													.map((message) => {
														return {
															value: message.id,
															label: message.name,
														};
													})}
												onChange={(value) => {
													setBulkAddValue(value);
												}}
												value={bulkAddValue}
												onKeyDown={(evt) => {
													if (evt.key === "Enter") {
														bulkAddMessage();
													}
												}}
											/>
										) : (
											<ReactSelect
												options={Store.messages[Store.selectedCanvas]
													.filter((message) => {
														if (!Store.checkedMessages[Store.selectedCanvas]) {
															return true;
														}
														const checkedIDs = [];
														for (const [key, value] of Object.entries(
															Store.checkedMessages[Store.selectedCanvas]
														)) {
															if (value) {
																checkedIDs.push(key);
															}
														}

														return !checkedIDs.includes(`${message.id}`);
													})
													.map((message) => ({
														value: message.id,
														label: message.name,
													}))}
												onChange={(value) => {
													setBulkAddValue(value);
												}}
												value={bulkAddValue}
												onKeyDown={(evt) => {
													if (evt.key === "Enter") {
														bulkAddMessage();
													}
												}}
											/>
										)}
									</Grid>
									<Grid item xs={2}>
										<Button>
											<AddIcon onClick={bulkAddMessage} />
										</Button>
									</Grid>
								</Grid>
							</>
						)}
						{messagesList.length === 0 && "No messages"}
						<List dense>
							{Store.reorderMode ? (
								<ReactSortable
									list={messagesList}
									setList={(list) => {
										if (document.activeElement.tagName !== "INPUT") {
											const updatedList = list.map((item, index) => ({
												...item,
												sortNumber: index + 1,
											}));

											runInAction(() => {
												Store.messageFilterResults = updatedList;
												//TODO
												// Store.messages[Store.selectedCanvas] = updatedList;
											});
										}
									}}
									multiDrag
									selectedClass="dragSelected"
									handle={".dragHandle"}
									dragClass="dragSelected"
								>
									{messagesList.map((canvas, index) => (
										<CustomListItem
											item={canvas}
											key={canvas.id}
											canvasesLabel={canvasesLabel}
											index={index}
											type="message"
										/>
									))}
								</ReactSortable>
							) : (
								messagesList.map((canvas) => (
									<CustomListItem item={canvas} key={canvas.id} />
								))
							)}
						</List>
					</Grid>
				) : null}
			</Grid>
			{oneCanvasChecked && (
				<RedButton
					onClick={async () => {
						setExporting(true);
						const { downloadPath, error } = await API(
							`/exportContentWord`,
							"POST",
							{
								canvases: Store.canvases
									.filter((canvas) => Store.checkedCanvases[canvas.id])
									.map((canvas) => {
										if (!(canvas.id in Store.messages)) {
											return canvas;
										}
										return {
											...canvas,
											messages: Store.messages[canvas.id].filter((message) => {
												if (!message) {
													return false;
												}
												return Store.checkedMessages[canvas.id][message.id];
											}),
										};
									}),
							}
						);

						if (error === "timeout") {
							alert(
								"Download timed out. Please select less canvases or messages and try again."
							);
						} else if (error) {
							alert("Download error. Please contact support.");
						} else {
							await downloadjs(`//${downloadPath}`);
						}

						setExporting(false);
					}}
					disabled={exporting}
				>
					{exporting ? "Downloading..." : "Export"}
				</RedButton>
			)}
		</Paper>
	);
};
let sortNum;
const CustomListItem = observer(({ item, canvasesLabel, index, type }) => {
	const isCanvas = !("canvasID" in item);
	let isChecked;

	try {
		isChecked = !!(isCanvas
			? Store.checkedCanvases[item.id]
			: Store.checkedMessages[item.canvasID][item.id]);
	} catch {
		isChecked = false;
	}

	return (
		<ListItem
			style={{
				cursor: isCanvas ? "pointer" : "inherit",
				background:
					isCanvas && item.id === Store.selectedCanvas ? "#d7d7d7" : "inherit",
			}}
			onClick={async (event) => {
				if (!Object.keys(event.target).includes("checked") && isCanvas) {
					canvasesLabel.current.scrollIntoView({ behavior: "smooth" });
					Store.selectedCanvas = item.id;

					if (!Store.messages[item.id]) {
						const messagesDB = await client.service("message").find({
							query: {
								canvasID: item.id,
								$sort: { id: 1 },
								$select: ["name", "id", "canvasID", "shapeID"],
							},
						});

						const shapes = await client
							.service("shape")
							.find({ query: { canvasID: item.id, $select: ["shapeID"] } });
						const shapeIDs = shapes.map((shape) => shape.shapeID);

						runInAction(() => {
							Store.messages[item.id] = messagesDB
								.filter((message) => shapeIDs.includes(message.shapeID))
								.map((item, index) => ({
									...item,
									sortNumber: index + 1,
								}));

							if (!(item.id in Store.checkedMessages)) {
								const messageIDs = messagesDB.map((message) => message.id);
								let toSet = {};
								for (const messageID of messageIDs) {
									toSet[messageID] = true;
								}

								Store.checkedMessages[item.id] = toSet;
							}
						});
					}
				}
			}}
		>
			{Store.reorderMode ? (
				<>
					<TextField
						value={item.sortNumber || ""}
						onChange={(evt) => {
							item.sortNumber = evt.target.value;
						}}
						style={{ width: 50 }}
						onFocus={() => {
							sortNum = item.sortNumber;
							item.sortNumber = "";
						}}
						onBlur={(evt) => {
							if (evt.target.value === "") {
								item.sortNumber = sortNum;
								evt.target.value = sortNum;
							}
							if (!isNaN(evt.target.value)) {
								if (type === "canvas") {
									Store.canvasFilterResults = arrayMove(
										Store.canvasFilterResults,
										index,
										parseInt(evt.target.value) - 1
									).map((item, index) => ({ ...item, sortNumber: index + 1 }));
								} else if (type === "message" && Store.messageFilterResults) {
									Store.messageFilterResults = arrayMove(
										Store.messageFilterResults,
										index,
										parseInt(evt.target.value) - 1
									).map((item, index) => ({ ...item, sortNumber: index + 1 }));
								} else {
									Store.messages[Store.selectedCanvas] = arrayMove(
										Store.messages[Store.selectedCanvas],
										index,
										parseInt(evt.target.value) - 1
									).map((item, index) => ({ ...item, sortNumber: index + 1 }));
								}
							}
						}}
						onKeyPress={(evt) => {
							if (evt.key === "Enter") {
								evt.target.blur();
							}
						}}
					/>
					<div className="dragHandle">
						<HeightIcon style={{ cursor: "grab" }} />
					</div>
				</>
			) : (
				<input
					type="checkbox"
					checked={isChecked}
					onChange={(event) => {
						if (isCanvas) {
							Store.checkedCanvases[item.id] = event.target.checked;
						} else {
							Store.checkedMessages[item.canvasID][item.id] =
								event.target.checked;
						}
					}}
				/>
			)}
			<ListItemText
				onClick={() => {
					if (!Store.reorderMode) {
						if (!isCanvas) {
							Store.checkedMessages[item.canvasID][item.id] = !Store
								.checkedMessages[item.canvasID][item.id];
						}
					}
				}}
			>
				{item.name}
			</ListItemText>
		</ListItem>
	);
});

export default observer(ExportContentWord);
