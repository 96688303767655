import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import client from "../../lib/feathers";
import { appStore } from "../../App";
import { observe } from "mobx";
import Grid from "@material-ui/core/Grid";
import RedButton from "../../lib/RedButton";
import Paper from "@material-ui/core/Paper";
import CalculatedEditor from "./AttributeBuilder/CalculatedEditor";

const Retrievers = () => {
	const [retrievers, setRetrievers] = useState();
	const [selectedRetriever, setSelectedRetriever] = useState();

	const loadRetrievers = async () => {
		const retrieversDB = await client
			.service("retriever")
			.find({ where: { projectID: appStore.selectedProject } });

		setRetrievers(retrieversDB);
	};

	useEffect(() => {
		loadRetrievers();

		const disposer = observe(appStore, "selectedProject", () => {
			loadRetrievers();
		});

		return () => {
			disposer();
		};
	}, []);

	return (
		<div>
			<h3 style={{ textDecoration: "underline" }}>Retrievers</h3>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Paper style={{ padding: 10 }}>
						{!retrievers ? (
							<div style={{ justifyContent: "center", display: "flex" }}>
								<CircularProgress size={28} />
							</div>
						) : retrievers.length === 0 ? (
							<p>No retrievers exist</p>
						) : (
							<>
								<p>Click on a retriever to update or delete it.</p>
								<List>
									{retrievers.map((retriever) => (
										<ListItem
											style={{ cursor: "pointer" }}
											key={retriever.id}
											onClick={() => {
												setSelectedRetriever(retriever.id);
											}}
											selected={selectedRetriever === retriever.id}
										>
											<ListItemText>{retriever.name}</ListItemText>
										</ListItem>
									))}
								</List>
							</>
						)}
						<Grid container style={{ marginTop: 10 }} spacing={2}>
							<Grid item xs={selectedRetriever ? 4 : 12}>
								<RedButton
									onClick={async () => {
										setSelectedRetriever(null);
										const newName = window.prompt(
											"What would you like to name the retriever?"
										);

										if (newName) {
											const newRetriever = await client
												.service("retriever")
												.create({ name: newName });
											setRetrievers([...retrievers, newRetriever]);
										}
									}}
								>
									New
								</RedButton>
							</Grid>
							{selectedRetriever && (
								<Grid item xs={4}>
									<RedButton
										onClick={async () => {
											const newName = window.prompt(
												"What would you like to rename the retriever?"
											);

											if (newName) {
												await client
													.service("retriever")
													.patch(selectedRetriever, { name: newName });
												setSelectedRetriever(null);
												const retrieversUpdate = [...retrievers];
												const index = retrieversUpdate.findIndex(
													(retriever) => retriever.id === selectedRetriever
												);
												retrieversUpdate[index].name = newName;
												setRetrievers(retrieversUpdate);
											}
										}}
									>
										Edit
									</RedButton>
								</Grid>
							)}
							{selectedRetriever && (
								<Grid item xs={4}>
									<RedButton
										onClick={async () => {
											if (
												window.confirm("Are you sure you want to delete this?")
											) {
												await client
													.service("retriever")
													.remove(selectedRetriever);
												setSelectedRetriever(null);
												setRetrievers(
													retrievers.filter(
														(retriever) => retriever.id !== selectedRetriever
													)
												);
											}
										}}
									>
										Delete
									</RedButton>
								</Grid>
							)}
						</Grid>
					</Paper>
				</Grid>
				{selectedRetriever && (
					<Grid item xs={6}>
						<Paper style={{ padding: 10 }}>
							<CalculatedEditor id={selectedRetriever} type="retriever" />
						</Paper>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default Retrievers;
