import React, { Component, useState, useEffect, useRef } from "react";
import API from "../../../API";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import UnmatchedUserResponses from "../../../lib/UnmatchedUserResponses";
import QandALabel from "./QandALabel";
import Checkboxes from "./Checkboxes";
import { observable } from "mobx";
import { observer } from "mobx-react";
import client from "../../../lib/feathers";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import debounce from "lodash.debounce";
import TextField from "@material-ui/core/TextField";
import { generateFilters, usePrevious } from "../../../lib/HelperFunctions";
import Autocomplete from "react-select";
import AsyncAutocomplete from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faLayerPlus } from "@fortawesome/pro-solid-svg-icons";
import RedButton from "../../../lib/RedButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Options from "../../home/AttributeBuilder/OptionList";
import Webix from "../../../lib/Webix";
import { DialogActions, DialogTitle } from "@material-ui/core";
// import RedButton from "../../../lib/RedButton";

export let pageStore = new observable({
	useCheckboxes: false,
});

export default observer(
	class Technical extends Component {
		state = {
			questionMessage: false,
			loading: true,
			messageElements: null,
		};
		componentDidMount() {
			this.loadData();
		}

		componentDidUpdate(prevProps, prevState, snapshot) {
			if (prevProps.messageDBID !== this.props.messageDBID) {
				this.loadData();
			}
		}

		loadData = async () => {
			const elementsData = await API(`/db/customQuery/element`, "POST", {
				query: "find",
				options: {
					where: { messageDBID: this.props.messageDBID },
					attributes: ["name"],
				},
			});

			const questionMessage = elementsData.data.find((element) => {
				return element.name.toLowerCase().trim() === "question";
			});

			const { data } = await API(`/db/customQuery/messageAttribute`, "POST", {
				query: "find",
				options: {
					where: { messageDBID: this.props.messageDBID },
					include: [{ model: "Attribute", attributes: ["id", "name"] }],
				},
			});

			pageStore.useCheckboxes = this.props.messageHasCheckboxes;

			this.setState({
				loading: false,
				options: data,
				questionMessage: Boolean(questionMessage),
				messageElements: elementsData.data,
			});
		};

		componentWillUnmount() {
			client
				.service("channelManager")
				.remove({ name: `message/${this.props.messageDBID}` });
		}

		render() {
			if (
				this.state.messageElements &&
				this.state.messageElements.length === 0
			) {
				return (
					<Paper style={{ padding: 15, marginTop: 15 }}>
						<p>Please create elements on this message before using this tab</p>
					</Paper>
				);
			}
			return (
				<div>
					{this.state.loading ? (
						<div style={{ textAlign: "center", padding: 15 }}>
							<CircularProgress />
						</div>
					) : (
						<>
							<Grid container spacing={2}>
								{this.state.questionMessage
									? listenerAttribute(this.props, this.state)
									: nonQuestionMessage(this.props, this.state)}
							</Grid>
						</>
					)}
				</div>
			);
		}
	}
);

const ConditionalLinkedAttribute = ({ messageDBID, state }) => {
	const [messageLinkedAttributes, setMessageLinkedAttributes] = useState();
	const messageLinkedAttributesRef = usePrevious(messageLinkedAttributes);
	const [ruleDialogOpen, setRuleDialogOpen] = useState(false);
	const [rulesData, setRulesData] = useState(null);
	const [openAttributeRules, setOpenAttributeRules] = useState(null);
	const [rules, setRules] = useState(null);

	useEffect(() => {
		(async () => {
			setMessageLinkedAttributes(
				await client.service("messageConditionalLinkedAttribute").find({
					query: {
						messageID: messageDBID,
						include: ["Attribute"],
					},
				})
			);
		})();
	}, [messageDBID]);

	useEffect(() => {
		client
			.service("messageConditionalLinkedAttribute")
			.on("created", async (newLinkedAttribute) => {
				if (!newLinkedAttribute.attribute) {
					const attributes = await client
						.service("attribute")
						.find({ query: { id: newLinkedAttribute.attributeID } });
					newLinkedAttribute.attribute = attributes[0];
				}
				if (!messageLinkedAttributesRef.current) {
					setMessageLinkedAttributes([newLinkedAttribute]);
				} else {
					setMessageLinkedAttributes([
						...messageLinkedAttributesRef.current,
						newLinkedAttribute,
					]);
				}
			})
			.on("removed", (deleted) => {
				const toDelete = messageLinkedAttributesRef.current.findIndex(
					(item) => item.id === deleted.id
				);
				if (toDelete !== -1) {
					const toSet = [...messageLinkedAttributesRef.current];
					toSet.splice(toDelete, 1);
					setMessageLinkedAttributes(toSet);
				}
			})
			.on("patched", async (patched) => {
				const toPatch = messageLinkedAttributesRef.current.findIndex(
					(item) => item.id === patched.id
				);
				if (toPatch !== -1) {
					const attribute = await client
						.service("attribute")
						.get(patched.attributeID);
					const toSet = [...messageLinkedAttributesRef.current];
					toSet[toPatch] = { ...patched, attribute };
					setMessageLinkedAttributes(toSet);
				}
			});
		return () => {
			client
				.service("messageConditionalLinkedAttribute")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		};
	}, [messageLinkedAttributesRef]);

	if (!messageLinkedAttributes) {
		return (
			<Paper style={{ marginTop: 15, padding: 15 }}>
				<div style={{ justifyContent: "center", display: "flex" }}>
					<CircularProgress size={28} />
				</div>
			</Paper>
		);
	}

	const openRules = async (attribute) => {
		const { data } = await API(`/messageRulesData/${messageDBID}`, "GET");

		if (attribute.rules) {
			setRules(JSON.parse(attribute.rules));
		} else {
			setRules({
				glue: "and",
				rules: [{}],
			});
		}
		setOpenAttributeRules(attribute);
		setRulesData(data);
		setRuleDialogOpen(true);
	};

	const closeDialog = () => {
		setRuleDialogOpen(false);
		setRulesData(null);
		setOpenAttributeRules(null);
	};

	return (
		<Paper style={{ padding: 15, marginTop: 15 }}>
			<h3>Conditional Linked Attributes</h3>
			{messageLinkedAttributes.length === 0 ? (
				<p>No conditional linked attributes on this message yet.</p>
			) : (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Attribute</TableCell>
								<TableCell style={{ textAlign: "center" }}>Option</TableCell>
								<TableCell style={{ textAlign: "center" }}>Rules</TableCell>
								<TableCell style={{ textAlign: "center" }}>Actions</TableCell>
							</TableRow>
							{messageLinkedAttributes.map((attribute, index) => {
								return (
									<TableRow key={index}>
										<TableCell>
											<Autocomplete
												menuPosition={"fixed"}
												label="Attribute"
												value={
													attribute.attribute
														? {
																value: attribute.attributeID,
																label: attribute.attribute.name,
														  }
														: ""
												}
												onChange={async (selected) => {
													await client
														.service("messageConditionalLinkedAttribute")
														.patch(attribute.id, {
															attributeID: selected?.value,
															optionID: null,
														});
												}}
												options={state.options.map((option) => {
													return {
														value: option.attributeID,
														label: option.attribute.name,
													};
												})}
											/>
										</TableCell>
										<TableCell>
											{attribute?.attributeID ? (
												<LinkedAttributeOptions
													linkedAttribute={attribute}
													attributeID={attribute.attributeID}
													optionID={attribute.optionID}
													updateState={(value, property) => {
														const toSet = [...messageLinkedAttributes];
														toSet[index][property] = value;
														setMessageLinkedAttributes(toSet);
													}}
													service="messageConditionalLinkedAttribute"
												/>
											) : null}
										</TableCell>
										<TableCell style={{ textAlign: "center" }}>
											{attribute?.attributeID ? (
												<Button
													onClick={() => {
														openRules(attribute);
													}}
												>
													Open Rules
												</Button>
											) : null}
										</TableCell>
										<TableCell style={{ textAlign: "center" }}>
											<Button
												onClick={() => {
													let dataToDupe = { ...attribute };
													delete dataToDupe.id;
													client
														.service("messageConditionalLinkedAttribute")
														.create(dataToDupe);
												}}
											>
												<FontAwesomeIcon
													icon={faLayerPlus}
													style={{ fontSize: 18 }}
												/>
											</Button>
											<Button
												onClick={async () => {
													await client
														.service("messageConditionalLinkedAttribute")
														.remove(attribute.id);
												}}
											>
												<DeleteIcon />
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableHead>
					</Table>
				</TableContainer>
			)}
			<Grid container spacing={2} style={{ marginTop: 10 }}>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="secondary"
						startIcon={<AddIcon />}
						style={{ width: "100%" }}
						onClick={async () => {
							await client
								.service("messageConditionalLinkedAttribute")
								.create({ messageID: messageDBID });
						}}
					>
						Add Row
					</Button>
				</Grid>
				{/*<Grid item xs={6}>*/}
				{/*	<RedButton>Save</RedButton>*/}
				{/*</Grid>*/}
			</Grid>
			<Dialog
				onClose={closeDialog}
				open={ruleDialogOpen}
				fullWidth
				maxWidth="lg"
			>
				<DialogTitle>Rules</DialogTitle>
				<DialogContent>
					<Webix
						data={rules}
						ui={{
							id: `querybuilder`,
							view: "querybuilder",
							maxLevel: 3,
							fields: ruleDialogOpen
								? rulesData.map((row) => {
										const attribute = row.attribute;
										return {
											id: attribute.id,
											value: attribute.name,
											type:
												attribute.type === "direct" ||
												attribute.type === "calculated"
													? attribute.id
													: "text",
										};
								  })
								: [],
							filters: ruleDialogOpen ? generateFilters(rulesData) : [],
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={2} />
						<Grid item xs={3}>
							<Button
								variant="contained"
								color="secondary"
								style={{ width: "100%" }}
								onClick={closeDialog}
							>
								Close
							</Button>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={3}>
							<RedButton
								onClick={async () => {
									const querybuilder = window.$$(`querybuilder`);
									let updatedRules = JSON.stringify(
										querybuilder ? querybuilder.getValue() : null
									);
									await client
										.service("messageConditionalLinkedAttribute")
										.patch(openAttributeRules.id, {
											rules: updatedRules,
										});
									closeDialog();
								}}
							>
								Save & Close
							</RedButton>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

const LinkedAttribute = ({ messageDBID, state }) => {
	const [messageLinkedAttributes, setMessageLinkedAttributes] = useState();
	const messageLinkedAttributesRef = usePrevious(messageLinkedAttributes);

	useEffect(() => {
		(async () => {
			setMessageLinkedAttributes(
				await client.service("messageLinkedAttribute").find({
					query: {
						messageID: messageDBID,
						include: ["Attribute"],
					},
				})
			);
		})();
	}, [messageDBID]);

	useEffect(() => {
		client
			.service("messageLinkedAttribute")
			.on("created", (newLinkedAttribute) => {
				if (!messageLinkedAttributesRef.current) {
					setMessageLinkedAttributes([newLinkedAttribute]);
				} else {
					setMessageLinkedAttributes([
						...messageLinkedAttributesRef.current,
						newLinkedAttribute,
					]);
				}
			})
			.on("removed", (deleted) => {
				const toDelete = messageLinkedAttributesRef.current.findIndex(
					(item) => item.id === deleted.id
				);
				if (toDelete !== -1) {
					const toSet = [...messageLinkedAttributesRef.current];
					toSet.splice(toDelete, 1);
					setMessageLinkedAttributes(toSet);
				}
			})
			.on("patched", async (patched) => {
				const toPatch = messageLinkedAttributesRef.current.findIndex(
					(item) => item.id === patched.id
				);
				if (toPatch !== -1) {
					const attribute = await client
						.service("attribute")
						.get(patched.attributeID);
					const toSet = [...messageLinkedAttributesRef.current];
					toSet[toPatch] = { ...patched, attribute };
					setMessageLinkedAttributes(toSet);
				}
			});
		return () => {
			client
				.service("messageLinkedAttribute")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		};
	}, [messageLinkedAttributesRef]);

	if (!messageLinkedAttributes) {
		return (
			<Paper style={{ marginTop: 15, padding: 15 }}>
				<div style={{ justifyContent: "center", display: "flex" }}>
					<CircularProgress size={28} />
				</div>
			</Paper>
		);
	}

	return (
		<Paper style={{ padding: 15, marginTop: 15 }}>
			<h3>Linked Attributes</h3>
			{messageLinkedAttributes.length === 0 ? (
				<p>No linked attributes on this message yet.</p>
			) : (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Attribute</TableCell>
								<TableCell style={{ textAlign: "center" }}>Option</TableCell>
								<TableCell style={{ textAlign: "center" }}>Actions</TableCell>
							</TableRow>
							{messageLinkedAttributes.map((attribute, index) => {
								return (
									<TableRow key={index}>
										<TableCell>
											<Autocomplete
												menuPosition={"fixed"}
												label="Attribute"
												value={
													attribute.attribute
														? {
																value: attribute.attributeID,
																label: attribute.attribute.name,
														  }
														: ""
												}
												onChange={async (selected) => {
													await client
														.service("messageLinkedAttribute")
														.patch(attribute.id, {
															attributeID: selected?.value,
															optionID: null,
														});
												}}
												options={state.options.map((option) => {
													return {
														value: option.attributeID,
														label: option.attribute.name,
													};
												})}
											/>
										</TableCell>
										<TableCell>
											{attribute?.attributeID ? (
												<LinkedAttributeOptions
													linkedAttribute={attribute}
													attributeID={attribute.attributeID}
													optionID={attribute.optionID}
													updateState={(value, property) => {
														const toSet = [...messageLinkedAttributes];
														toSet[index][property] = value;
														setMessageLinkedAttributes(toSet);
													}}
													service="messageLinkedAttribute"
												/>
											) : null}
										</TableCell>
										<TableCell style={{ textAlign: "center" }}>
											<DeleteIcon
												style={{ cursor: "pointer" }}
												onClick={async () => {
													await client
														.service("messageLinkedAttribute")
														.remove(attribute.id);
												}}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						</TableHead>
					</Table>
				</TableContainer>
			)}
			<Grid container spacing={2} style={{ marginTop: 10 }}>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="secondary"
						startIcon={<AddIcon />}
						style={{ width: "100%" }}
						onClick={async () => {
							await client
								.service("messageLinkedAttribute")
								.create({ messageID: messageDBID });
						}}
					>
						Add Row
					</Button>
				</Grid>
				{/*<Grid item xs={6}>*/}
				{/*	<RedButton>Save</RedButton>*/}
				{/*</Grid>*/}
			</Grid>
		</Paper>
	);
};

const LinkedMessage = ({ messageID }) => {
	const [messageLinks, setMessageLinks] = useState();
	const messageLinksRef = usePrevious(messageLinks);

	useEffect(() => {
		(async () => {
			setMessageLinks(
				await client.service("messageLink").find({
					query: {
						linkedFromMessageID: messageID,
						include: ["Message"],
					},
				})
			);
		})();
	}, [messageID]);

	useEffect(() => {
		client
			.service("channelManager")
			.create({ name: `messageLink/${messageID}` });
		client
			.service("messageLink")
			.on("created", (newItem) => {
				if (!messageLinksRef.current) {
					setMessageLinks([newItem]);
				} else {
					setMessageLinks([...messageLinksRef.current, newItem]);
				}
			})
			.on("removed", (deleted) => {
				const toDelete = messageLinksRef.current.findIndex(
					(item) => item.id === deleted.id
				);
				if (toDelete !== -1) {
					const toSet = [...messageLinksRef.current];
					toSet.splice(toDelete, 1);
					setMessageLinks(toSet);
				}
			})
			.on("patched", async (patched) => {
				const toPatch = messageLinksRef.current.findIndex(
					(item) => item.id === patched.id
				);
				if (toPatch !== -1) {
					const message = await client
						.service("message")
						.get(patched.linkedToMessageID);
					const toSet = [...messageLinksRef.current];
					toSet[toPatch] = { ...patched, message };
					setMessageLinks(toSet);
				}
			});
		return () => {
			client
				.service("channelManager")
				.remove({ name: `messageLink/${messageID}` });

			client
				.service("messageLink")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		};
	}, [messageLinksRef, messageID]);

	if (!messageLinks) {
		return (
			<Paper style={{ marginTop: 15, padding: 15 }}>
				<div style={{ justifyContent: "center", display: "flex" }}>
					<CircularProgress size={28} />
				</div>
			</Paper>
		);
	}

	const loadOptions = async (inputValue) => {
		const messages = await client.service("message").find({
			query: {
				name: { $iLike: `%${inputValue}%` },
				$sort: { name: 1 },
				$limit: 50,
			},
		});

		return messages.map((message) => {
			return { value: message.id, label: message.name };
		});
	};

	return (
		<Paper style={{ padding: 15, marginTop: 15 }}>
			<h3>Linked Message</h3>
			{messageLinks.length === 0 ? (
				<p>No linked messages to this message yet.</p>
			) : (
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Message</TableCell>
								<TableCell style={{ textAlign: "center", width: 40 }}>
									Actions
								</TableCell>
							</TableRow>
							{messageLinks.map((messageLink, index) => {
								return (
									<TableRow key={index}>
										<TableCell>
											<AsyncAutocomplete
												menuPosition={"fixed"}
												label="Linked Message"
												value={
													messageLink.message
														? {
																value: messageLink.linkedToMessageID,
																label: messageLink.message.name,
														  }
														: ""
												}
												onChange={async (selected) => {
													await client
														.service("messageLink")
														.patch(messageLink.id, {
															linkedToMessageID: selected?.value,
														});
												}}
												cacheOptions
												messageLink
												loadOptions={loadOptions}
												noOptionsMessage={({ inputValue }) =>
													inputValue
														? "No matching messages"
														: "Start typing a message name..."
												}
											/>
										</TableCell>
										<TableCell style={{ textAlign: "center", width: 40 }}>
											<DeleteIcon
												style={{ cursor: "pointer" }}
												onClick={async () => {
													await client
														.service("messageLink")
														.remove(messageLink.id);
												}}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						</TableHead>
					</Table>
				</TableContainer>
			)}
			<Grid container spacing={2} style={{ marginTop: 10 }}>
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="secondary"
						startIcon={<AddIcon />}
						style={{ width: "100%" }}
						onClick={async () => {
							await client
								.service("messageLink")
								.create({ linkedFromMessageID: messageID });
						}}
					>
						Add Row
					</Button>
				</Grid>
				{/*<Grid item xs={6}>*/}
				{/*	<RedButton>Save</RedButton>*/}
				{/*</Grid>*/}
			</Grid>
		</Paper>
	);
};

const LinkedAttributeOptions = ({
	attributeID,
	optionID,
	updateState,
	linkedAttribute,
	service,
}) => {
	const [options, setOptions] = useState(null);

	const debounced = useRef(
		debounce(async (value) => {
			await client.service(service).patch(linkedAttribute.id, {
				value,
			});
		}, 300)
	).current;

	useEffect(() => {
		(async () => {
			const data = await client.service("option").find({
				query: { attributeID, $sort: { name: 1 } },
			});

			let allNumbers = true;
			for (const option of data) {
				if (isNaN(option.name)) {
					allNumbers = false;
					break;
				}
			}
			if (allNumbers) {
				data.push({ id: "increment", name: "Increment" });
			}
			data.push({ id: "clear", name: "Clear" });
			setOptions(data);
		})();
	}, [attributeID]);

	const onChange = (evt) => {
		const value = evt.target.value;

		updateState(value, "value");
		debounced(value);
	};

	if (!options) {
		return null;
	} else if (options.length === 0) {
		return (
			<FormControl variant="filled">
				<>
					<TextField
						label="Value"
						value={linkedAttribute.value || ""}
						onChange={onChange}
					/>
				</>
			</FormControl>
		);
	}

	let matchingOption = "";
	if (linkedAttribute.increment || optionID === "increment") {
		matchingOption = { label: "Increment", value: "increment" };
	} else if (linkedAttribute.clear || optionID === "clear") {
		matchingOption = { label: "Clear", value: "clear" };
	} else if (optionID) {
		const match = options.find((option) => option.id === optionID);
		matchingOption = { label: match.name, value: match.id };
	}

	return (
		<Autocomplete
			menuPosition={"fixed"}
			label="Option"
			value={matchingOption}
			options={options.map((option) => {
				return {
					value: option.id,
					label: option.name,
				};
			})}
			onChange={async (selected) => {
				if (selected.value === "increment") {
					await client.service(service).patch(linkedAttribute.id, {
						increment: true,
						clear: false,
					});
				} else if (selected.value === "clear") {
					await client.service(service).patch(linkedAttribute.id, {
						clear: true,
						increment: false,
					});
				} else {
					await client.service(service).patch(linkedAttribute.id, {
						optionID: selected?.value,
						increment: false,
						clear: false,
					});
				}

				updateState(selected?.value, "optionID");
			}}
		/>
	);
};

const nonQuestionMessage = (props, state) => {
	return (
		<>
			<Grid item xs={6}>
				<LinkedAttribute {...props} state={state} />
			</Grid>
			<Grid item xs={6}>
				<ConditionalLinkedAttribute {...props} state={state} />
			</Grid>
			<Grid item xs={6}>
				<LinkedMessage messageID={props.messageDBID} />
			</Grid>
			<Grid item xs={6}>
				<MessageInfo messageDBID={props.messageDBID} />
			</Grid>
		</>
	);
};

const listenerAttribute = (props, state) => {
	const setListenerAttribute = async (evt) => {
		if (props.messageListenerAttributeID !== evt.target.value) {
			if (props.messageListenerAttributeID) {
				if (
					!window.confirm(
						"Are you sure you want to change the listener attribute? It will wipe any Q&A Option Labels you may have set already?"
					)
				) {
					return;
				}
			}
			await API(`/db/message`, "POST", {
				id: props.messageDBID,
				listenerAttributeID: evt.target.value,
			});
			await API(`/db/customQuery/messageQAndALabel`, "POST", {
				query: "delete",
				options: { where: { messageID: props.messageDBID } },
			});
			props.setMessageListenerAttributeID(evt.target.value);
		}
	};

	return (
		<>
			<Grid item xs={6}>
				<AttributeCard
					title="Listener Attribute"
					value={props.messageListenerAttributeID}
					onChange={setListenerAttribute}
					options={state.options}
					props={props}
				/>
				{props.messageListenerAttributeID ? (
					<UnmatchedUserResponses
						otherResponses={state.otherResponses}
						messageListenerAttributeID={props.messageListenerAttributeID}
					/>
				) : null}
				<LinkedAttribute {...props} state={state} />
				<ConditionalLinkedAttribute {...props} state={state} />
				<LinkedMessage messageID={props.messageDBID} />
				<MessageInfo messageDBID={props.messageDBID} />
			</Grid>
			<Grid item xs={6}>
				{props.messageListenerAttributeID ? (
					<QandALabel
						messageID={props.messageDBID}
						messageListenerAttributeID={props.messageListenerAttributeID}
						enqueueSnackbar={props.enqueueSnackbar}
					/>
				) : null}
				{pageStore.useCheckboxes && (
					<Checkboxes
						messageID={props.messageDBID}
						useCheckboxes={pageStore.useCheckboxes}
						enqueueSnackbar={props.enqueueSnackbar}
					/>
				)}
			</Grid>
		</>
	);
};

const AttributeCard = ({ title, value, onChange, options, props }) => {
	const [glossaryModalOpen, setGlossaryModalOpen] = useState(false);
	const [optionsForGlossaryModal, setOptionsForGlossaryModal] = useState();
	const [hideGlossaryIcon, setHideGlossaryIcon] = useState(false);
	const selectedAttribute = options?.find(
		(option) => option.attribute.id === value
	);

	const handleCheckbox = async () => {
		if (pageStore.useCheckboxes) {
			if (
				!window.confirm(
					"Are you sure you want to turn off checkboxes? It will delete all checkboxes you have set."
				)
			) {
				return;
			}
		}

		pageStore.useCheckboxes = !pageStore.useCheckboxes;
		props.setMessageHasCheckboxes(pageStore.useCheckboxes);
		props.setMessageListenerAttributeID(
			pageStore.useCheckboxes ? null : props.messageListenerAttributeID
		);

		if (pageStore.useCheckboxes) {
			await API(`/db/message`, "POST", {
				id: props.messageDBID,
				listenerAttributeID: null,
				checkboxes: true,
			});
		} else {
			await API(`/db/message`, "POST", {
				id: props.messageDBID,
				checkboxes: false,
			});
			await API(`/db/customQuery/messageCheckbox`, "POST", {
				query: "delete",
				options: { where: { messageID: props.messageDBID } },
			});
		}
	};

	const handleSurveyCheckbox = async (evt) => {
		props.setSurveyMode(evt.target.checked);

		await API(`/db/message`, "POST", {
			id: props.messageDBID,
			surveyMode: evt.target.checked,
		});
	};

	return (
		<>
			<Paper style={{ padding: 15, marginTop: 10 }}>
				<h3>{title}</h3>
				<div style={{ display: "flex" }}>
					<FormControl
						variant="filled"
						disabled={pageStore.useCheckboxes}
						style={{ flexGrow: 1 }}
					>
						<InputLabel>Attribute</InputLabel>
						<Select
							value={value || ""}
							onChange={(val) => {
								setHideGlossaryIcon(false);
								setOptionsForGlossaryModal(null);
								onChange(val);
							}}
						>
							{value ? (
								<MenuItem value={null} key={0}>
									[clear]
								</MenuItem>
							) : null}
							{options.map((option) => {
								if (
									option.attribute.name.toLowerCase().endsWith(" list") &&
									option.id !== selectedAttribute?.id
								) {
									//filter any attributes that have list in the name
									//unless they're already selected in the message
									return null;
								}
								return (
									<MenuItem value={option.attributeID} key={option.attributeID}>
										{option.attribute.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					{selectedAttribute &&
						selectedAttribute?.attribute?.name.endsWith("Raw") &&
						!hideGlossaryIcon && (
							<RedButton
								onClick={async () => {
									setGlossaryModalOpen(true);
									const listAttribute = await client.service("attribute").find({
										query: {
											name: selectedAttribute.attribute.name.replace(
												"Raw",
												"List"
											),
											$limit: 1,
										},
									});

									if (listAttribute.length !== 1) {
										setHideGlossaryIcon(true);
									} else {
										const optionResponse = await API(
											`/db/customQuery/option`,
											"POST",
											{
												query: "find",
												options: {
													where: {
														attributeID: listAttribute[0].id,
													},
													order: [["name", "ASC"]],
												},
											}
										);
										setOptionsForGlossaryModal(optionResponse.data);
									}
								}}
								style={{ flex: 1, marginLeft: 15 }}
							>
								<FontAwesomeIcon icon={faBook} />
							</RedButton>
						)}
				</div>
				{title === "Listener Attribute" && (
					<>
						<FormControlLabel
							control={
								<Checkbox
									checked={props.messageHasCheckboxes}
									onChange={handleCheckbox}
									name="messageHasCheckboxes"
								/>
							}
							label="Use checkboxes on message"
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={props.message.surveyMode || false}
									onChange={handleSurveyCheckbox}
									name="messageHasCheckboxes"
								/>
							}
							label="Force survey mode"
						/>
					</>
				)}
			</Paper>
			<Dialog
				onClose={() => {
					setGlossaryModalOpen(false);
				}}
				open={glossaryModalOpen}
				fullWidth
				maxWidth="md"
			>
				<DialogContent>
					{optionsForGlossaryModal ? (
						<Options options={optionsForGlossaryModal} technicalTab={true} />
					) : (
						<div style={{ textAlign: "center", padding: 15 }}>
							<CircularProgress />
						</div>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};

const MessageInfo = ({ messageDBID }) => {
	return (
		<Paper style={{ padding: 15, marginTop: 15 }}>
			<h3>Message Info</h3>

			<p>
				<strong>Message ID: </strong>
				{messageDBID}
			</p>
		</Paper>
	);
};
