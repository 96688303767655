import React, { useState, useEffect } from "react";
import { action, observe } from "mobx";
import downloadjs from "downloadjs";
import { observer } from "mobx-react";
import Papa from "papaparse";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { faInboxOut, faInboxIn } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import API from "../../../API";
import RedButton from "../../../lib/RedButton";
import {
	deleteAttribute,
	deleteFolder,
	editAttribute,
	editFolder,
	Key,
	loadTreeData,
	moveAttribute,
	moveFolder,
	reset,
	resetDetail,
	searchAttributes,
	TreeStore,
} from "./store";
import { AttributeIcon } from "./AttributeIcons";
import {
	AttributeDialog,
	AttributeFolderDialog,
	EditAttributeDialog,
	EditFolderDialog,
	UploadDialog,
} from "./AttributeDialogs";
import { EditAttribute } from "./EditAttribute";
import { appStore } from "../../../App";

export const AttributeBuilderTree = observer(() => {
	const { init, loading, selected } = TreeStore.tree;
	const searchMode = Boolean(TreeStore.search.query?.length);

	useEffect(() => {
		if (!init) {
			loadTreeData(null)
				.then(() => true)
				.catch((error) => {
					throw error;
				});
		}
	}, [init]);

	useEffect(() => {
		const disposer = observe(appStore, "selectedProject", async (change) => {
			reset(TreeStore);
			return loadTreeData(null);
		});
		return () => {
			return disposer();
		};
	}, []);

	const onSearch = action((value) => {
		TreeStore.search.query = value;
		if (value.length) {
			TreeStore.search.loading = true;
			searchAttributes(TreeStore, value);
		} else {
			TreeStore.search.loading = false;
			TreeStore.search.query = "";
			TreeStore.search.selectedId = null;
		}
	});

	const onImportComplete = () => {
		reset(TreeStore);
		return loadTreeData(null);
	};

	return (
		<div>
			<Grid container>
				<Grid item xs={10}>
					<h3 style={{ textDecoration: "underline" }}>Attribute Builder</h3>
				</Grid>
				<Grid item xs={2} style={{ textAlign: "right" }}>
					<FontAwesomeIcon
						onClick={action(() => {
							TreeStore.modal.uploadOpen = true;
						})}
						icon={faInboxOut}
						style={{ fontSize: 20, marginRight: 15 }}
						className="clickable"
					/>
					<FontAwesomeIcon
						onClick={exportData}
						icon={faInboxIn}
						className="clickable"
						style={{ fontSize: 20 }}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Card style={{ padding: 15 }}>
						<TextField
							onChange={(e) => onSearch(e.target.value)}
							value={TreeStore.search.query}
							label="Search"
							autoFocus
							fullWidth
						/>
					</Card>
					<Card style={{ marginTop: 15, marginBottom: 15 }}>
						<CardContent
							style={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
						>
							{searchMode ? (
								<AttributeSearchResults
									loading={TreeStore.search.loading}
									attributes={TreeStore.search.data}
									selectedId={TreeStore.search.selectedId}
									results={TreeStore.search.data}
								/>
							) : (
								<AttributeTree
									treeData={TreeStore.tree.treeData}
									selectedKeys={TreeStore.tree.selected}
									loadedKeys={Object.values(TreeStore.tree.loadedKeys)}
								/>
							)}
						</CardContent>
						<CardActions>
							{!loading && init ? (
								<AttributeActions
									selected={selected}
									searchMode={searchMode}
									searchSelectedId={TreeStore.search.selectedId}
								/>
							) : null}
						</CardActions>
					</Card>
				</Grid>
				<Grid item xs={6}>
					{TreeStore.detail.open && TreeStore.detail.isAttribute ? (
						<EditAttribute
							data={TreeStore.detail.data}
							loading={TreeStore.detail.loading}
						/>
					) : null}
				</Grid>
			</Grid>
			{TreeStore.modal.attributeOpen ? (
				<AttributeDialog />
			) : TreeStore.modal.folderOpen ? (
				<AttributeFolderDialog />
			) : TreeStore.modal.editFolderOpen ? (
				<EditFolderDialog
					data={TreeStore.detail.data}
					loading={TreeStore.detail.loading}
				/>
			) : TreeStore.modal.editAttributeOpen ? (
				<EditAttributeDialog
					data={TreeStore.detail.data}
					loading={TreeStore.detail.loading}
				/>
			) : TreeStore.modal.uploadOpen ? (
				<UploadDialog onImportComplete={onImportComplete} />
			) : null}
		</div>
	);
});
export default AttributeBuilderTree;

const exportData = async () => {
	const { data } = await API(`/attributeExport`, "GET");
	const csv = Papa.unparse(data);
	return downloadjs(csv, "export.csv", "text/csv");
};

/**
 * @component AttributeTree - Mediator for rendering tree data & dispatching events
 */
const AttributeTree = ({ treeData, ...props }) => {
	const onSelect = action((_, { node }) => {
		if (!node) {
			TreeStore.tree.selected = [];
			return;
		}
		// Handle deselect & select (respectively)
		const selected = TreeStore.tree.selected.includes(node.key)
			? TreeStore.tree.selected.filter((key) => key !== node.key)
			: [node.key];
		TreeStore.tree.selected = selected;
		if (Key.isAttribute(node.key) && selected.length === 1) {
			return editAttribute(TreeStore, Key.toId(node.key));
		} else if (TreeStore.detail.open) {
			return resetDetail(TreeStore);
		}
	});

	const onDoubleClick = action((event, node) => {
		const isAttribute = Key.isAttribute(node.key);
		const modalProp = isAttribute ? "editAttributeOpen" : "editFolderOpen";
		TreeStore.modal[modalProp] = true;
		const callback = isAttribute ? editAttribute : editFolder;
		return callback(TreeStore, Key.toId(node.key));
	});

	const onDrop = ({ event, node, dragNode, dragNodesKeys, ...args }) => {
		const dragKey = dragNode.key; // Node that was moved
		// Drop key will be the closest relative in the tree
		// Ie. it will either be the sibling or the parent
		// Either way, we need the parent so we can move the Glossary
		// Entry under the right parentFolder
		const dropKey = node.key; // Key for the new parent
		const parentFolderID = !Key.isAttribute(dropKey)
			? Key.toId(dropKey)
			: Number(node.parentFolderID);
		const callback = Key.isFolder(dragKey) ? moveFolder : moveAttribute;
		return callback(TreeStore, Key.toId(dragKey), parentFolderID);
	};

	const loadBranch = (treeNode) => loadTreeData(Key.toId(treeNode.key));

	return (
		<div>
			<style dangerouslySetInnerHTML={{ __html: STYLE }} />
			{!treeData.length ? (
				<CircularProgress />
			) : (
				<Tree
					loadData={loadBranch}
					treeData={treeData}
					onSelect={onSelect}
					onDoubleClick={onDoubleClick}
					allowDrop={allowDrop}
					onDrop={onDrop}
					motion={motion}
					icon={AttributeIcon}
					draggable
					showLine
					{...props}
				/>
			)}
		</div>
	);
};

//-- Tree Helpers
const STYLE = `
    .rc-tree-child-tree {
      display: block;
    }
    
    .node-motion {
      transition: all .3s;
      overflow-y: hidden;
    }
`;

const motion = {
	motionName: "node-motion",
	motionAppear: false,
	onAppearStart: (node) => {
		return { height: 0 };
	},
	onAppearActive: (node) => ({ height: node.scrollHeight }),
	onLeaveStart: (node) => ({ height: node.offsetHeight }),
	onLeaveActive: () => ({ height: 0 }),
};

const allowDrop = ({ dropNode, dropPosition }) =>
	!(!dropNode.children && dropPosition === 0);

const AttributeSearchResults = ({ loading, attributes, selectedId }) => {
	if (loading) {
		return <CircularProgress />;
	}

	const onClick = action(({ id }) => {
		TreeStore.search.selectedId = id;
		return editAttribute(TreeStore, id);
	});

	return (
		<List dense>
			{loading ? (
				<ListItem>
					{" "}
					<CircularProgress />{" "}
				</ListItem>
			) : !attributes.length ? (
				<ListItem>
					<ListItemText>No matching Attributes found</ListItemText>
				</ListItem>
			) : (
				attributes.map((attribute) => (
					<ListItem
						key={attribute.id}
						onClick={() => onClick(attribute)}
						selected={selectedId === attribute.id}
						style={{ cursor: "pointer" }}
					>
						<ListItemIcon>
							<AttributeIcon {...attribute} isLeaf={true} />
						</ListItemIcon>
						<ListItemText>{attribute.name}</ListItemText>
					</ListItem>
				))
			)}
		</List>
	);
};

/**
 * @component AttributeActions - Card Actions/footer for the Attribute Tree
 * Handles adding & deleting Attributes & Attribute Folders from the tree
 */
const AttributeActions = observer(
	({ selected = [], searchMode = false, searchSelectedId = null }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const [confirmName, setConfirmName] = useState(null);
		const [deleteButton, setDeleteButton] = useState(null);
		const [deleteConfirm, setDeleteConfirm] = useState(false);
		const canEdit = !searchMode && selected?.length === 1;

		const close = () => setAnchorEl(null);

		const onAddClick = (e) => setAnchorEl(e.target);

		const onConfirmDelete = async () => {
			const callback = Key.isAttribute(selected[0])
				? deleteAttribute
				: deleteFolder;
			const { parentFolderID } = await callback(
				TreeStore,
				Key.toId(selected[0])
			);
			await loadTreeData(parentFolderID);
		};

		const onEditClick = action(() => {
			if (!canEdit) {
				return false;
			}
			const isAttribute = searchMode || Key.isAttribute(selected[0]);
			const modalProp = isAttribute ? "editAttributeOpen" : "editFolderOpen";
			TreeStore.modal[modalProp] = true;
			const id = searchMode ? searchSelectedId : Key.toId(selected[0]);
			const callback = isAttribute ? editAttribute : editFolder;
			return callback(TreeStore, id);
		});

		return (
			<>
				<Grid container spacing={2}>
					{/* Add an Attribute or Folder (not in search mode) */}
					<Grid item xs={4}>
						<RedButton onClick={onAddClick} disabled={!canEdit}>
							Add
						</RedButton>
						<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
							<MenuItem
								onClick={action(() => {
									TreeStore.modal.attributeOpen = true;
									close();
								})}
							>
								Attribute
							</MenuItem>
							<MenuItem
								onClick={action(() => {
									TreeStore.modal.folderOpen = true;
									close();
								})}
							>
								Folder
							</MenuItem>
							<MenuItem onClick={close}>Cancel</MenuItem>
						</Menu>
					</Grid>
					{/* Edit an Attribute or Folder */}
					<Grid item xs={4}>
						<RedButton onClick={onEditClick} disabled={!canEdit}>
							Edit Name
						</RedButton>
					</Grid>
					{/* Delete an Attribute or Folder (not in search mode) */}
					<Grid item xs={4}>
						<RedButton
							disabled={!canEdit}
							onClick={(e) => {
								setDeleteConfirm(true);
								setDeleteButton(e.target);
								const selectedId = Key.toId(selected[0]);
								const source = Key.isAttribute(selected[0])
									? TreeStore.tree.attributes
									: TreeStore.tree.folders;
								setConfirmName(
									source.find((item) => item.id === selectedId)?.name
								);
							}}
						>
							Delete
						</RedButton>
					</Grid>
				</Grid>
				<Menu
					anchorEl={deleteButton}
					keepMounted
					open={deleteConfirm}
					onClose={() => setDeleteConfirm(false)}
				>
					<MenuItem disabled>
						<Typography>
							You sure you want to delete{" "}
							<span style={{ fontWeight: "800" }}>{confirmName}</span>?
						</Typography>
					</MenuItem>
					<MenuItem onClick={onConfirmDelete} style={{ color: "red" }}>
						Yes
					</MenuItem>
					<MenuItem onClick={() => setDeleteConfirm(false)}>No</MenuItem>
				</Menu>
			</>
		);
	}
);
