import React, { Component } from "react";
import logo from "../img/tuzagTCS.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHome,
	faMapMarked,
	faQuestion,
	faSearch,
	faSitemap,
	faListAlt,
	faChevronDown,
	faFolderOpen,
	faSearchPlus,
	faMapSigns,
	faEmptySet,
} from "@fortawesome/pro-solid-svg-icons";
import "./Header.scss";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import Bowser from "bowser";
import Mousetrap from "mousetrap";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { observable } from "mobx";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { CircularProgress } from "@material-ui/core";
import API from "../API";
import { appStore } from "../App";
import { withRouter } from "react-router";

const browserInfo = Bowser.parse(window.navigator.userAgent);
const pageStore = observable({ dialogOpen: false });

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
	<TooltipTrigger
		{...props}
		tooltip={({
			arrowRef,
			tooltipRef,
			getArrowProps,
			getTooltipProps,
			placement,
		}) => (
			<div
				{...getTooltipProps({
					ref: tooltipRef,
					className: "tooltip-container",
				})}
			>
				{!hideArrow && (
					<div
						{...getArrowProps({
							ref: arrowRef,
							className: "tooltip-arrow",
							"data-placement": placement,
						})}
					/>
				)}
				{tooltip}
			</div>
		)}
	>
		{({ getTriggerProps, triggerRef }) => (
			<span
				{...getTriggerProps({
					ref: triggerRef,
					className: "trigger",
				})}
			>
				{children}
			</span>
		)}
	</TooltipTrigger>
);

export default withRouter(
	observer(
		class Header extends Component {
			state = { mapPopover: false, redirect: null };

			async componentDidMount() {
				const { user } = await API(`/userActiveProject`, "GET");
				appStore.isAdmin = user.isAdmin;
				if (!user.activeProject) {
					appStore.selectedProjectName = "(no project selected)";
				} else {
					appStore.selectedProject = user.project.id;
					appStore.selectedProjectName = user.project.name;
					appStore.selectedProjectAccessMap = {
						...appStore.selectedProjectAccessMap,
						...user.project.accessMap,
					};
				}
			}

			render() {
				let backgroundColor = "var(--red)";
				if (
					process.env.REACT_APP_ENV === "staging" ||
					process.env.REACT_APP_ENV === "stagingerx"
				) {
					backgroundColor = "#F2A524";
				} else if (process.env.REACT_APP_ENV === "local") {
					backgroundColor = "#16B1F0";
				}

				return this.state.redirect ? (
					<Redirect to={this.state.redirect} />
				) : (
					<>
						<Paper
							elevation={3}
							style={{
								zIndex: 2,
								height: 53,
								position: "fixed",
								top: 0,
								width: "100%",
							}}
							id="header"
						>
							<div
								style={{
									width: "100%",
									backgroundColor,
								}}
							>
								<img
									src={logo}
									style={{
										width: 100,
										background: "white",
										padding: 3,
										marginBottom: -3,
										borderRadius: 0,
									}}
									alt="tuzagTCS logo"
								/>
							</div>

							<div
								style={{
									backgroundColor: "var(--dark)",
									color: "white",
									fontSize: 14,
									padding: 5,
									justifyContent: "center",
									display: "flex",
								}}
							>
								<div
									style={{
										flex: 1,
										maxWidth: 525,
										display: "flex",
										justifyContent: "space-between",
									}}
									id="darkHeaderBar"
								>
									<Link to="/home">
										<FontAwesomeIcon icon={faHome} />
									</Link>
									{browserInfo.platform.model === "iPad" ? (
										appStore.selectedProject ? (
											<>
												<Link to="/canvas">
													<FontAwesomeIcon
														icon={faSitemap}
														style={{ paddingLeft: 10, paddingRight: 10 }}
														className="popoverIcon"
													/>
												</Link>
												<Link to="/filemanager">
													<FontAwesomeIcon
														icon={faFolderOpen}
														style={{ paddingLeft: 10, paddingRight: 10 }}
														className="popoverIcon"
													/>
												</Link>
												<Link to="/kanban">
													<FontAwesomeIcon
														icon={faListAlt}
														style={{ paddingLeft: 10, paddingRight: 10 }}
														className="popoverIcon"
													/>
												</Link>
											</>
										) : null
									) : (
										<Tooltip
											placement="top"
											trigger="hover"
											tooltip={
												// <Paper style={{ padding: 5, zIndex: 3, marginTop: 5 }}>
												appStore.selectedProject ? (
													<div>
														<Link to="/canvas">
															<FontAwesomeIcon
																icon={faSitemap}
																style={{ paddingLeft: 10, paddingRight: 10 }}
																className="popoverIcon"
															/>
														</Link>
														<Link to="/filemanager">
															<FontAwesomeIcon
																icon={faFolderOpen}
																style={{ paddingLeft: 10, paddingRight: 10 }}
																className="popoverIcon"
															/>
														</Link>
														<Link to="/kanban">
															<FontAwesomeIcon
																icon={faListAlt}
																style={{ paddingLeft: 10, paddingRight: 10 }}
																className="popoverIcon"
															/>
														</Link>
													</div>
												) : (
													<p>Select a project first</p>
												)
												// </Paper>
											}
										>
											<FontAwesomeIcon
												icon={faMapMarked}
												onClick={() => {
													if (
														!window.location.href.includes("/canvas") &&
														browserInfo.platform.type === "desktop" &&
														appStore.selectedProject
													) {
														this.props.history.push("/canvas");
													}
												}}
											/>
										</Tooltip>
									)}
									<FontAwesomeIcon
										icon={faQuestion}
										onClick={() => {
											window.open(
												"https://tuzag.atlassian.net/servicedesk/customer/portal/2"
											);
										}}
									/>
									{appStore.selectedProject ? (
										<Tooltip
											placement="top"
											trigger="hover"
											tooltip={
												<div>
													<FontAwesomeIcon
														icon={faSearch}
														onClick={() => {
															Mousetrap.trigger("mod+k");
														}}
														style={{
															paddingLeft: 10,
															paddingRight: 10,
															cursor: "pointer",
														}}
													/>
													<Link to="/home/search">
														<FontAwesomeIcon
															icon={faSearchPlus}
															style={{ paddingLeft: 10, paddingRight: 10 }}
														/>
													</Link>
													<FontAwesomeIcon
														icon={faMapSigns}
														onClick={() => {
															Mousetrap.trigger("mod+shift+k");
														}}
														style={{
															paddingLeft: 10,
															paddingRight: 10,
															cursor: "pointer",
														}}
													/>
													<Link to="/home/searchEmpty">
														<FontAwesomeIcon
															icon={faEmptySet}
															style={{
																paddingLeft: 10,
																paddingRight: 10,
															}}
														/>
													</Link>
												</div>
											}
										>
											<FontAwesomeIcon
												icon={faSearch}
												onClick={() => {
													Mousetrap.trigger("mod+k");
												}}
											/>
										</Tooltip>
									) : null}
								</div>
								<div style={{ position: "fixed", right: 0, top: 0 }}>
									<p
										style={{
											color: "white",
											marginTop: 5,
											marginRight: 10,
											cursor: "pointer",
										}}
										onClick={async (evt) => {
											this.setState({ anchorEl: evt.currentTarget });

											const { projects } = await API(`/userProjects`, "GET");
											this.setState({ projects });
										}}
									>
										{appStore.selectedProjectName ? (
											<>
												{appStore.selectedProjectName}{" "}
												<FontAwesomeIcon icon={faChevronDown} />
											</>
										) : null}
									</p>
									<Menu
										anchorEl={this.state.anchorEl}
										open={!!this.state.anchorEl}
										onClose={() => {
											this.setState({ anchorEl: null });
										}}
									>
										{this.state.projects ? (
											this.state.projects.length ? (
												this.state.projects.map((project) => {
													return (
														<MenuItem
															key={project.id}
															onClick={async () => {
																this.setState({ anchorEl: null });

																const activeProject = await API(
																	`/userActiveProject`,
																	"POST",
																	{
																		activeProject: project.id,
																	}
																);

																appStore.selectedProject = project.id;
																appStore.selectedProjectName = project.name;
																appStore.selectedProjectAccessMap = {
																	...appStore.selectedProjectAccessMap,
																	...activeProject.accessMap,
																};
															}}
														>
															{project.name}
														</MenuItem>
													);
												})
											) : (
												<MenuItem disabled>
													You aren't assigned to any projects
												</MenuItem>
											)
										) : (
											<div
												style={{
													justifyContent: "center",
													display: "flex",
													padding: 15,
												}}
											>
												<CircularProgress />
											</div>
										)}
									</Menu>
								</div>
							</div>
						</Paper>
						{pageStore.dialogOpen ? <WaitDialog /> : null}
					</>
				);
			}
		}
	)
);

const WaitDialog = observer(
	class extends Component {
		close = () => {
			pageStore.dialogOpen = false;
		};

		componentDidMount() {
			setTimeout(() => {
				pageStore.dialogOpen = false;
			}, 3500);
		}

		render() {
			return (
				<Dialog onClose={this.props.close} open={true} fullWidth>
					<DialogContent>
						{/*<h3>{pageStore.mode} Extractor</h3>*/}
						<img
							src="https://media.giphy.com/media/l1J9NkmXUQGtYq68E/source.gif"
							alt="not helpful gif"
							style={{ width: "100%" }}
						/>
						<Grid container>
							<Grid item xs={10}></Grid>
							<Grid item xs={2}>
								<Button onClick={this.close}>Cancel</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			);
		}
	}
);
