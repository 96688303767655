import React, { Component, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { action, observable, toJS, runInAction } from "mobx";
import API from "../../../API";
import ElementInList from "./ElementInList";
import shortid from "shortid";
import Paper from "@material-ui/core/Paper";
import SlateComp from "./Slate";
import SaveButton from "../../../lib/SaveButton";
import BitCases from "./BitCases";
import hash from "object-hash";
import { Node } from "slate";
import Pagination from "@material-ui/lab/Pagination";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { faPlus, faPaste, faLayerPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import throttle from "lodash.throttle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Mousetrap from "mousetrap";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import RedButton from "../../../lib/RedButton";
import client from "../../../lib/feathers";
import { appStore } from "../../../App";
import { withSnackbar } from "notistack";
import camelcase from "camelcase";
import GlobalSaveIndicator from "../../../lib/GlobalSaveIndicator";
import ImageIcon from "@material-ui/icons/Image";
import ImageElements, { ImageElementContent } from "./ImageElement";
import { LowPriority } from "@material-ui/icons";
import { ReactSortable } from "react-sortablejs";
import HeightIcon from "@material-ui/icons/Height";
import { arrayMove } from "../../../lib/HelperFunctions";

const initialStore = {
	notYetOpen: false,
	elements: [],
	imageElements: [],
	selectedElement: null,
	selectedElementIndex: null,
	openBitID: null,
	bitCases: [],
	attributes: null,
	notifications: [],
	saveInProgress: false,
	elementPage: 1,
	selectedImageElement: null,
	selectedImageElementIndex: null,
};

export let pageStore = new observable({ ...initialStore });

export const TemplateCRUDMenu = ({
	openCreate,
	openUpdate,
	openDelete,
	anchorEl,
	type,
	usedAttributes,
	...props
}) => {
	let createButton;
	if (usedAttributes && usedAttributes.length) {
		createButton = <MenuItem onClick={openCreate}>Create</MenuItem>;
	} else if (type === "element" && pageStore.elements.length) {
		createButton = <MenuItem onClick={openCreate}>Create</MenuItem>;
	}

	return (
		<>
			{appStore.isAdmin ? (
				<Menu
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={props.onClose}
				>
					<MenuItem disabled>Templates:</MenuItem>
					{createButton}
					<MenuItem onClick={openUpdate}>Update</MenuItem>
					<MenuItem onClick={openDelete}>Delete</MenuItem>
				</Menu>
			) : null}
		</>
	);
};

export const plainTextSerialize = (nodes) => {
	return nodes.map((n) => Node.string(n)).join("");
};

export const NewTemplateDialog = ({ close, open, type, usedAttributes }) => {
	const [newTemplateName, setNewTemplateName] = useState("");
	const [saving, setSaving] = useState(false);

	return (
		<Dialog onClose={close} open={open} fullWidth>
			<form
				onSubmit={async (evt) => {
					setSaving(true);
					evt.preventDefault();
					if (type === "Element") {
						const messageElementTemplate = await client
							.service("messageElementTemplate")
							.create({
								name: newTemplateName,
							});

						for (const element of pageStore.elements) {
							await client.service("messageElementTemplateElement").create({
								templateID: messageElementTemplate.id,
								elementName: element.name,
								elementContent: JSON.stringify(element.content),
							});
						}
					} else {
						client.service("messageAttributeTemplate").create({
							name: newTemplateName,
							attributes: usedAttributes
								.map((attribute) => {
									return attribute.id;
								})
								.join("|||"),
						});
					}
					close();
					setSaving(false);
				}}
			>
				<DialogContent>
					<h3>New Template Name</h3>
					<TextField
						label="Template Name"
						variant="filled"
						type="text"
						autoFocus={true}
						value={newTemplateName}
						onChange={(evt) => {
							setNewTemplateName(evt.target.value);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={7} />
						<Grid item xs={2}>
							<Button onClick={close}>Cancel</Button>
						</Grid>
						<Grid item xs={1} />
						<Grid item xs={2}>
							<RedButton
								autoFocus
								color="primary"
								type="submit"
								disabled={saving}
							>
								{saving ? "Creating..." : "Create"}
							</RedButton>
						</Grid>
					</Grid>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export const TemplateMenu = (props) => {
	return (
		<Menu
			anchorEl={props.anchorEl}
			keepMounted
			open={Boolean(props.anchorEl)}
			onClose={props.onClose}
		>
			{props.messageTemplates.length === 0 ? (
				<MenuItem disabled>No templates exist yet</MenuItem>
			) : (
				props.messageTemplates.map((item) => {
					return (
						<MenuItem
							key={item.id}
							onClick={(event) => {
								event.stopPropagation();
								props.makeFromTemplate(item);
							}}
						>
							{item.name}
						</MenuItem>
					);
				})
			)}
		</Menu>
	);
};

export default observer(
	class Elements extends Component {
		state = {
			elementsPanelExpanded: true,
			elementTemplateMenu: null,
			elementTemplateRightClickMenu: null,
			newTemplateDialogOpen: false,
			newTemplateName: "",
			messageTemplates: [],
			templateEditDeleteDialogMode: null,
			nestedSaving: false,
			reorderMode: false,
		};
		lastSave = "";

		setActiveElementFromURL = async () => {
			if (this.props.messageDBID) {
				const elementID = [null, undefined].includes(
					this.props.match?.params?.elementID
				)
					? null
					: Number(this.props.match?.params?.elementID);
				if (this.props.location?.state?.commentBitCaseID) {
					pageStore.selectedElementIndex = pageStore.elements.findIndex(
						(element) => {
							return (
								element.id === this.props.location.state.commentBitCaseElementID
							);
						}
					);

					pageStore.selectedElement = toJS(
						pageStore.elements.find((element) => {
							return (
								element.id === this.props.location.state.commentBitCaseElementID
							);
						})
					);
					pageStore.selectedImageElement = null;
					pageStore.selectedImageElementIndex = null;

					pageStore.openBitID = this.props.location.state.commentBitCaseBitID;
				} else if (elementID) {
					// CR 2021-Apr-01 - I had to wrap this block in a `runInAction`, otherwise one of the
					// child components would change a value in the middle of this execution ಠ_ಠ
					// runInAction wraps this in a single transaction, side-stepping side effects
					runInAction(() => {
						const current = pageStore.elements.reduce(
							({ index, instance }, element, i) =>
								elementID !== element.id
									? { index, instance }
									: { index: i, instance: element },
							{
								index: 0,
								instance: null,
							}
						);

						const { index, instance } = current;
						if (instance) {
							pageStore.selectedImageElement = null;
							pageStore.selectedImageElementIndex = null;
							pageStore.selectedElement = toJS(instance);
							pageStore.selectedElementIndex = index;
							pageStore.elementPage = Math.ceil((index + 1) / 10) || 1;
							this.setActiveElement(elementID);
						}
					});
				}
			}
		};

		async componentDidMount() {
			await this.loadElements();
			await this.setActiveElementFromURL();

			client
				.service("channelManager")
				.create({ name: `messageElementTemplate` });

			client
				.service("messageElementTemplate")
				.on("created", (data) => {
					this.setState({
						messageTemplates: [...this.state.messageTemplates, data],
					});
				})
				.on("removed", (deleted) => {
					const toDelete = this.state.messageTemplates.findIndex(
						(item) => item.id === deleted.id
					);
					if (toDelete !== -1) {
						const toSet = [...this.state.messageTemplates];
						toSet.splice(toDelete, 1);
						this.setState({ messageTemplates: toSet });
					}
				})
				.on("patched", (patched) => {
					const toPatch = this.state.messageTemplates.findIndex(
						(item) => item.id === patched.id
					);
					if (toPatch !== -1) {
						const toSet = [...this.state.messageTemplates];
						toSet[toPatch] = patched;
						this.setState({ messageTemplates: toSet });
					}
				});

			pageStore.imageElements = await client.service("imageElement").find({
				query: {
					messageDBID: this.props.messageDBID,
					include: ["Image"],
					$sort: { id: 1 },
				},
			});

			client
				.service("imageElement")
				.on("created", (newRow) => {
					pageStore.imageElements.push(newRow);
				})
				.on("removed", (removedRow) => {
					pageStore.imageElements = pageStore.imageElements.filter(
						(row) => row.id !== removedRow.id
					);
				})
				.on("patched", (patched) => {
					const toPatch = pageStore.imageElements.findIndex(
						(item) => item.id === patched.id
					);
					if (toPatch !== -1) {
						pageStore.imageElements[toPatch] = patched;
					}
				});
		}

		componentDidUpdate(prevProps, prevState, snapshot) {
			if (
				this.props.match.params.elementID !== prevProps.match.params.elementID
			) {
				this.setActiveElementFromURL();
			} else if (this.props.messageDBID !== prevProps.messageDBID) {
				this.loadElements();
			}
			if (this.disposer) {
				this.disposer();
			}
		}

		componentWillUnmount() {
			Object.entries(initialStore).forEach(
				([key, value]) => (pageStore[key] = value)
			);
			client
				.service("messageElementTemplate")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
			client
				.service("channelManager")
				.remove({ name: `messageElementTemplate` });

			client
				.service("imageElement")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		}

		loadElements = async () => {
			let { data } = await API(`/db/customQuery/element`, "POST", {
				query: "find",
				options: {
					where: { messageDBID: this.props.messageDBID },
					order: [["order", "ASC"]],
				},
			});

			for (const element of data) {
				if (element.content === "null" || !element.content) {
					element.content = [
						{
							type: "paragraph",
							children: [{ text: "" }],
						},
					];
				} else {
					element.content = JSON.parse(element.content);
				}
			}

			pageStore.elements = data;
			pageStore.originalLoadElements = data;

			const attributes = await API(`/db/customQuery/messageAttribute`, "POST", {
				query: "find",
				options: {
					where: { messageDBID: this.props.messageDBID },
					include: [{ model: "Attribute", attributes: ["id", "name", "type"] }],
				},
			});

			let toSet = [];

			for (const row of attributes.data) {
				const options = await client
					.service("option")
					.find({ query: { attributeID: row.attributeID } });
				row.attribute.options = options;
				toSet.push(row.attribute);
			}

			pageStore.attributes = toSet;

			const { commentNotifications } = await API(
				`/commentNotifications/element/${this.props.messageDBID}`,
				"GET",
				{}
			);

			pageStore.notifications = commentNotifications;

			this.setState({
				messageTemplates: await client
					.service("messageElementTemplate")
					.find({}),
			});
		};

		serialize = (nodes) => {
			return nodes.map((n) => Node.string(n)).join("\n");
		};

		saveToThrottle = async (autoSave = false, loadElementID) => {
			try {
				if (!pageStore.saveInProgress) {
					pageStore.saveInProgress = true;
					if (
						!JSON.stringify(
							pageStore.elements[pageStore.selectedElementIndex].content
						) ||
						JSON.stringify(
							pageStore.elements[pageStore.selectedElementIndex].content
						) === `[{"type":"paragraph","children":[{"text":""}]}]`
					) {
						if (
							JSON.stringify(
								pageStore.elements[pageStore.selectedElementIndex].content
							) !==
							JSON.stringify(
								pageStore.originalLoadElements[pageStore.selectedElementIndex]
									?.content
							)
						) {
							const confirmEmptySave = window.confirm(
								"Are you sure you want to save an empty element?"
							);
							if (!confirmEmptySave) {
								pageStore.saveInProgress = false;
								return;
							}
						}
					}

					const elementContent =
						pageStore.elements[pageStore.selectedElementIndex].content;
					const plainTextValue = plainTextSerialize(elementContent);
					let min = plainTextValue.length;
					let max = plainTextValue.length;
					let containsBits = false;
					const bits = JSON.stringify(elementContent).matchAll(
						/"bitID":(\d+)?/g
					);
					for (const bit of bits) {
						containsBits = true;
						const bitID = bit[1];
						const bitsDB = await client
							.service("bit")
							.find({ query: { id: parseInt(bitID) } });

						for (const bitDB of bitsDB) {
							min += bitDB.minLength;
							max += bitDB.maxLength;
						}
					}

					//ghost bit killer (bits that have no text)
					const ghostBit = JSON.stringify(
						pageStore.elements[pageStore.selectedElementIndex].content
					).match(/{"bitID":\d+,"children":\[{"text":""}]},/);
					if (ghostBit) {
						pageStore.elements[
							pageStore.selectedElementIndex
						].content = JSON.parse(
							JSON.stringify(
								pageStore.elements[pageStore.selectedElementIndex].content
							).replaceAll(ghostBit[0], "")
						);
					}

					const toSaveElements = {
						id: pageStore.selectedElement.id,
						content: JSON.stringify(
							pageStore.elements[pageStore.selectedElementIndex].content
						),
						minLength: containsBits ? min : plainTextValue.length,
						maxLength: containsBits ? max : plainTextValue.length,
					};

					let toUpdateBits = [];
					let toCreateBits = [];

					if (pageStore.openBitID) {
						//eslint-disable-next-line
						this.generateCasesToSave(
							autoSave,
							toUpdateBits,
							toCreateBits,
							pageStore.bitCases
						);
					}

					const newToSaveHash = hash({
						toSaveElements,
						toUpdateBits,
						toCreateBits,
					});

					if (this.lastSave !== newToSaveHash || !autoSave) {
						this.lastSave = newToSaveHash;

						if (toSaveElements.id === this.activeElement && autoSave) {
						} else {
							await API(`/db/element`, "POST", toSaveElements);
						}

						if (toUpdateBits.length > 0 || toCreateBits.length > 0) {
							await this.saveBitCases(
								toUpdateBits,
								toCreateBits,
								pageStore.bitCases
							);
						}
					}
					pageStore.saveInProgress = false;

					if (loadElementID) {
						action(() => {
							pageStore.openBitID = null;
							pageStore.selectedElement = null;
							pageStore.selectedElementIndex = null;
						})();

						this.props.history.push(
							`/canvas/${this.props.match.params.canvasID}/${this.props.messageDBID}/element/${loadElementID}`
						);
					}
				}
			} catch (e) {
				alert(`Save failed. Please contact support.`);
				console.error(e);
				throw e;
			}
		};

		save = throttle(this.saveToThrottle, 1000, { trailing: false });

		async saveBitCases(toUpdateBits, toCreateBits, bitCases) {
			const data = await API(`/bitCase`, "POST", {
				toUpdateBits,
				toCreateBits,
			});
			const indexFinder = toJS(bitCases);
			for (const result of data.created) {
				const index = indexFinder.findIndex((item) => {
					return item.id === result.shortID;
				});
				bitCases[index].dbID = result.id;
			}
		}

		generateCasesToSave(autoSave, toUpdateBits, toCreateBits, bitCases) {
			for (let bitCase of Object.values(toJS(bitCases))) {
				if (bitCase.id === this.activeElement && autoSave) {
					//ignore
				} else {
					// if (bitCase.else) {
					// 	bitCase.rules = null;
					// } else {
					// 	const querybuilder = window.$$(`querybuilder-${bitCase.id}`);
					// 	bitCase.rules = JSON.stringify(
					// 		querybuilder ? querybuilder.getValue() : null
					// 	);
					//
					// 	//disabled because this was firing when opening a bit
					// 	// if (
					// 	// 	(!bitCase.rules ||
					// 	// 		bitCase.rules.length === 0 ||
					// 	// 		bitCase.rules === "null") &&
					// 	// 	!autoSave
					// 	// ) {
					// 	// 	snackbarStore.setMessage(
					// 	// 		"Error",
					// 	// 		`The following case (#${
					// 	// 			parseInt(key) + 1
					// 	// 		}) is missing rules: ${this.serialize(
					// 	// 			bitCase.content
					// 	// 		).substring(0, 30)}`
					// 	// 	);
					// 	// }
					// }

					delete bitCase.rules; //this should be handled with sockets so we don't overwrite
					if (typeof bitCase.content !== "string") {
						bitCase.content = JSON.stringify(bitCase.content);
					}

					if (bitCase.rules === "null") {
						delete bitCase.rules;
					}

					if (bitCase.dbID) {
						bitCase.id = bitCase.dbID;
						toUpdateBits.push(bitCase);
					} else if (shortid.isValid(bitCase.id)) {
						bitCase.shortID = bitCase.id;
						delete bitCase.id;
						toCreateBits.push(bitCase);
					} else {
						toUpdateBits.push(bitCase);
					}
				}
			}
		}

		setActiveElement = (id) => {
			this.activeElement = id;
		};

		makeElementsFromTemplate = async (template) => {
			try {
				const templateElements = await client
					.service("messageElementTemplateElement")
					.find({ query: { templateID: template.id, $sort: { id: 1 } } });
				for (const [index, templateElement] of templateElements.entries()) {
					const result = templateElement.elementContent.matchAll(
						/"passthrough":(\d+)/g
					);
					for (const match of result) {
						const passthroughAttributeID = match[1];
						const attributeOnMessage = await client
							.service("messageAttribute")
							.find({
								query: {
									messageDBID: this.props.messageDBID,
									attributeID: passthroughAttributeID,
								},
							});
						if (attributeOnMessage.length === 0) {
							await client.service("messageAttribute").create({
								messageDBID: this.props.messageDBID,
								attributeID: passthroughAttributeID,
							});
						}
					}

					await client.service("element").create({
						name: templateElement.elementName,
						messageDBID: this.props.messageDBID,
						content: templateElement.elementContent,
						order: index + 1,
					});
				}

				this.setState({ elementTemplateMenu: null });
				await this.loadElements();
			} catch (e) {
				if (e?.message === "ValidationError") {
					this.props.enqueueSnackbar("Duplicate element name already exists.", {
						variant: "error",
					});
				} else {
					console.error(e);
					this.props.enqueueSnackbar(
						"Something went wrong using template to create elements",
						{
							variant: "error",
						}
					);
				}
			}
		};

		render() {
			return (
				<div style={{ marginTop: 15 }}>
					<Grid container spacing={2}>
						<Grid item xs={pageStore.imageElements.length ? 6 : 12}>
							<Accordion defaultExpanded={this.state.elementsPanelExpanded}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<div style={{ width: "100%" }}>
										<h3
											style={{
												marginBottom: 0,
												float: "left",
											}}
										>
											Elements
										</h3>
										{this.state.elementsPanelExpanded ? (
											<>
												<ButtonGroup
													color="secondary"
													aria-label="text primary button group"
													style={{ float: "right" }}
													onClick={(event) => event.stopPropagation()}
													onFocus={(event) => event.stopPropagation()}
												>
													<Tooltip title="New Element">
														<Button
															onClick={() => {
																pageStore.elements.push({
																	id: shortid.generate(),
																	messageDBID: this.props.messageDBID,
																	order: pageStore.elements.length + 1,
																});
															}}
														>
															<FontAwesomeIcon icon={faPlus} />
														</Button>
													</Tooltip>
													{pageStore.imageElements.length === 0 ? (
														<Tooltip title="New Image Element">
															<Button
																onClick={() => {
																	pageStore.imageElements.push({
																		id: shortid.generate(),
																		messageDBID: this.props.messageDBID,
																		edit: true,
																	});
																}}
																style={{ paddingTop: 0, paddingBottom: 0 }}
															>
																<ImageIcon style={{ fontSize: 16 }} />
															</Button>
														</Tooltip>
													) : null}
													<Tooltip title="New Elements From Template">
														<Button
															onClick={(event) => {
																this.setState({
																	elementTemplateMenu: event.currentTarget,
																});
															}}
															onContextMenu={(event) => {
																event.preventDefault();
																this.setState({
																	elementTemplateRightClickMenu:
																		event.currentTarget,
																});
															}}
														>
															<FontAwesomeIcon icon={faLayerPlus} />
														</Button>
													</Tooltip>
													<Tooltip title="Paste Element">
														<Button
															onClick={async () => {
																if (!window.localStorage.copyElementID) {
																	this.props.enqueueSnackbar(
																		"No element copied",
																		{
																			variant: "error",
																		}
																	);
																} else {
																	await API(`/elementCopy`, "POST", {
																		elementID:
																			window.localStorage.copyElementID,
																		destinationMessageID: this.props
																			.messageDBID,
																	});
																	this.loadElements();
																}
															}}
														>
															<FontAwesomeIcon icon={faPaste} />
														</Button>
													</Tooltip>
													<Tooltip title="Reorder Elements">
														<Button
															onClick={() => {
																if (!this.state.reorderMode) {
																	pageStore.selectedElement = null;
																}
																this.setState({
																	reorderMode: !this.state.reorderMode,
																});
															}}
														>
															<LowPriority style={{ fontSize: "1.1rem" }} />
														</Button>
													</Tooltip>
													{/*<Button*/}
													{/*	onClick={() => {*/}
													{/*		pageStore.notYetOpen = true;*/}
													{/*	}}*/}
													{/*>*/}
													{/*	open template*/}
													{/*</Button>*/}
													{/*<Button*/}
													{/*	onClick={() => {*/}
													{/*		pageStore.notYetOpen = true;*/}
													{/*	}}*/}
													{/*>*/}
													{/*	save as template*/}
													{/*</Button>*/}
												</ButtonGroup>
												<TemplateMenu
													anchorEl={this.state.elementTemplateMenu}
													onClose={() => {
														this.setState({ elementTemplateMenu: null });
													}}
													messageTemplates={this.state.messageTemplates}
													makeFromTemplate={this.makeElementsFromTemplate}
												/>
												<TemplateCRUDMenu
													anchorEl={this.state.elementTemplateRightClickMenu}
													onClose={() => {
														this.setState({
															elementTemplateRightClickMenu: null,
														});
													}}
													openCreate={(event) => {
														event.stopPropagation();
														this.setState({
															elementTemplateRightClickMenu: null,
															newTemplateDialogOpen: true,
														});
													}}
													openUpdate={() => {
														this.setState({
															templateEditDeleteDialogMode: "edit",
															elementTemplateRightClickMenu: null,
														});
													}}
													openDelete={() => {
														this.setState({
															templateEditDeleteDialogMode: "delete",
															elementTemplateRightClickMenu: null,
														});
													}}
													type="element"
												/>
												<NewTemplateDialog
													close={() => {
														this.setState({
															newTemplateDialogOpen: false,
														});
													}}
													open={this.state.newTemplateDialogOpen}
													type="Element"
												/>
												<TemplateEditDeleteDialog
													open={!!this.state.templateEditDeleteDialogMode}
													closeDialog={() => {
														this.setState({
															templateEditDeleteDialogMode: null,
														});
													}}
													mode={this.state.templateEditDeleteDialogMode}
													messageTemplates={this.state.messageTemplates}
													type="Element"
												/>
											</>
										) : null}
										<div style={{ clear: "both" }} />
									</div>
								</AccordionSummary>
								<AccordionDetails style={{ flexDirection: "column" }}>
									<Grid container>
										<Grid item xs={12}>
											{this.state.reorderMode ? (
												<ElementOrderList
													exitReorderMode={() => {
														this.setState({ reorderMode: false });
													}}
												/>
											) : (
												<ElementList
													enqueueSnackbar={this.props.enqueueSnackbar}
													save={this.save}
													loadElements={this.loadElements}
													commentElementID={this.props.commentElementID}
													history={this.props.history}
													match={this.props.match}
												/>
											)}
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Grid>
						{pageStore.imageElements.length ? (
							<Grid item xs={6}>
								<ImageElements
									messageDBID={this.props.messageDBID}
									match={this.props.match}
								/>
							</Grid>
						) : null}
					</Grid>
					{pageStore.selectedElement ? (
						<>
							<ElementContent
								elementID={pageStore.selectedElement.id}
								save={this.save}
								key="elementContent"
								setActiveElement={this.setActiveElement}
								loadElements={this.loadElements}
								messageID={pageStore.selectedElement.messageDBID}
							/>
							<SaveButton
								save={this.save}
								autoSave={30}
								style={{
									position: "fixed",
									zIndex: 1301,
									bottom: process.env.REACT_APP_ENV === "local" ? 55 : 110,
									right: 10,
								}}
								key="save"
							/>
						</>
					) : null}
					{pageStore.selectedImageElement ? <ImageElementContent /> : null}
					{pageStore.notYetOpen ? <NotYet /> : null}
					{pageStore.selectedElement && pageStore.openBitID ? (
						<>
							<BitCases
								bitID={pageStore.openBitID}
								messageDBID={this.props.messageDBID}
								setActiveElement={this.setActiveElement}
								selectedElement={pageStore.selectedElement}
								attributes={pageStore.attributes}
								history={this.props.history}
								commentBitCaseID={this.props.location?.state?.commentBitCaseID}
								save={this.save}
								elementID={pageStore.selectedElement.id}
								loadElements={this.loadElements}
							/>
							<GlobalSaveIndicator />
						</>
					) : null}
				</div>
			);
		}
	}
);

export const TemplateEditDeleteDialog = withSnackbar(
	({ closeDialog, open, enqueueSnackbar, mode, messageTemplates, type }) => {
		if (!messageTemplates) {
			return null;
		}

		return (
			<Dialog onClose={closeDialog} open={open}>
				<DialogContent style={{ minWidth: 400 }}>
					<List>
						<h3>Select template to {mode}</h3>
						{messageTemplates.map((template) => {
							return (
								<ListItem
									button
									key={template.id}
									onClick={async () => {
										if (mode === "delete") {
											await client
												.service(`message${type}Template`)
												.remove(template.id);

											if (type === "Element") {
												const elementsInTemplate = await client
													.service("messageElementTemplateElement")
													.find({
														query: {
															templateID: template.id,
														},
													});

												for (const elementInTemplate of elementsInTemplate) {
													client
														.service("messageElementTemplateElement")
														.remove(elementInTemplate.id);
												}
											}

											closeDialog();
											enqueueSnackbar(
												`Deleted message ${camelcase(type)} template`,
												{
													variant: "success",
												}
											);
										} else {
											if (type === "Element") {
												for (const element of pageStore.elements) {
													const existingTemplate = await client
														.service("messageElementTemplateElement")
														.find({
															query: {
																templateID: template.id,
																elementName: element.name,
															},
														});
													if (existingTemplate[0]) {
														await client
															.service("messageElementTemplateElement")
															.patch(existingTemplate[0].id, {
																elementContent: JSON.stringify(element.content),
															});
													} else {
														await client
															.service("messageElementTemplateElement")
															.create({
																elementContent: JSON.stringify(element.content),
																elementName: element.name,
																templateID: template.id,
															});
													}
												}
											} else {
												await client
													.service(`message${type}Template`)
													.patch(template.id, {
														elements: pageStore.elements
															.map((element) => {
																return element.name;
															})
															.join("|||"),
													});
											}
											closeDialog();
											enqueueSnackbar(
												`Updated message ${camelcase(type)} template`,
												{
													variant: "success",
												}
											);
										}
									}}
								>
									{template.name}
								</ListItem>
							);
						})}
					</List>
				</DialogContent>
				<DialogActions>
					<Grid container>
						<Grid item xs={10} />
						<Grid item xs={2}>
							<Button onClick={closeDialog}>Cancel</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	}
);

const ElementContent = observer(
	class extends Component {
		render() {
			return (
				<div>
					<Paper
						style={{ marginTop: 20, padding: 10, marginBottom: 50 }}
						onFocus={() => {
							this.props.setActiveElement(this.props.elementID);
						}}
					>
						<h3>Content</h3>
						<SlateComp
							type="element"
							value={toJS(
								pageStore.elements[pageStore.selectedElementIndex].content
							)}
							elements={pageStore.elements}
							onChange={(val) => {
								if (pageStore.elements.length) {
									pageStore.elements[
										pageStore.selectedElementIndex
									].content = val;
								}
							}}
							pageStore={pageStore}
							elementID={this.props.elementID}
							save={this.props.save}
							attributes={pageStore.attributes}
							element={pageStore.elements[pageStore.selectedElementIndex]}
							loadElements={this.props.loadElements}
							messageID={this.props.messageID}
						/>
					</Paper>
				</div>
			);
		}
	}
);

const ElementList = observer(
	class extends Component {
		componentDidMount() {
			Mousetrap.bind("mod+e", (e) => {
				e.preventDefault();
				pageStore.elements.push({
					id: shortid.generate(),
					messageDBID: this.props.messageDBID,
				});
			});
		}

		componentWillUnmount() {
			Mousetrap.unbind("mod+e");
			runInAction(() => (pageStore.elementPage = 1));
		}

		render() {
			const currentPage = pageStore.elementPage;
			return (
				<div>
					{pageStore.elements.length ? (
						pageStore.elements.map((element, index) => {
							if (index >= (currentPage - 1) * 10 && index < currentPage * 10) {
								return (
									<ElementInList
										enqueueSnackbar={this.props.enqueueSnackbar}
										key={element.id}
										index={index}
										element={element}
										save={this.props.save}
										loadElements={this.props.loadElements}
										commentElementID={this.props.commentElementID}
										history={this.props.history}
										match={this.props.match}
										elementID={element.id}
									/>
								);
							} else {
								return null;
							}
						})
					) : (
						<p>No elements yet...</p>
					)}
					{pageStore.elements.length > 10 ? (
						<div style={{ textAlign: "center", paddingTop: 15 }}>
							<Pagination
								count={Math.ceil(pageStore.elements.length / 10)}
								page={currentPage}
								onChange={action((evt, page) => {
									pageStore.elementPage = page;
								})}
							/>
						</div>
					) : null}
				</div>
			);
		}
	}
);

const NotYet = observer(
	class extends Component {
		close = () => {
			pageStore.notYetOpen = false;
		};

		componentDidMount() {
			setTimeout(() => {
				pageStore.notYetOpen = false;
			}, 2500);
		}

		render() {
			return (
				<Dialog open={true} fullWidth>
					<DialogContent>
						<img
							src="https://media.giphy.com/media/3o6Zt3NI3hLqP6m0YE/source.gif"
							alt="computer says no"
							style={{ width: "100%" }}
						/>
						<Grid container>
							<Grid item xs={10}></Grid>
							<Grid item xs={2}>
								<Button onClick={this.close}>Close</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			);
		}
	}
);

export const saveElementOrderToDB = () => {
	const nextSave = hash(
		pageStore.elements.map((element) => [element.id, element.order])
	);
	if (nextSave !== lastSave) {
		for (const element of pageStore.elements) {
			client.service("element").patch(element.id, { order: element.order });
			lastSave = nextSave;
		}
	}
};

let sortNum;
let lastSave;
const ElementOrderList = observer(({ exitReorderMode }) => {
	return (
		<div>
			<ReactSortable
				list={pageStore.elements}
				setList={(list) => {
					if (document.activeElement.tagName !== "INPUT") {
						pageStore.elements = list.map((item, index) => ({
							...item,
							order: index + 1,
						}));

						saveElementOrderToDB();
					}
				}}
				multiDrag
				selectedClass="dragSelected"
				dragClass="dragSelected"
			>
				{pageStore.elements.map((element, index) => (
					<div
						style={{ display: "flex", alignItems: "center" }}
						key={element.id}
					>
						<TextField
							value={element.order}
							onChange={(evt) => {
								element.order = evt.target.value;
							}}
							style={{ width: 30 }}
							onFocus={() => {
								sortNum = element.order;
								element.order = "";
							}}
							onBlur={(evt) => {
								if (evt.target.value === "") {
									element.order = sortNum;
									evt.target.value = sortNum;
								}
								if (!isNaN(evt.target.value)) {
									pageStore.elements = arrayMove(
										pageStore.elements,
										index,
										parseInt(evt.target.value) - 1
									).map((item, index) => ({ ...item, order: index + 1 }));
									saveElementOrderToDB();
								}
							}}
						/>
						<HeightIcon style={{ cursor: "grab" }} />
						{element.name}
					</div>
				))}
			</ReactSortable>
			<RedButton onClick={exitReorderMode} style={{ marginTop: 10 }}>
				Save
			</RedButton>
		</div>
	);
});
