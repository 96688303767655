import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import API from "../../API";
import TableBody from "@material-ui/core/TableBody";
import ReactHtmlParser from "react-html-parser";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { appStore } from "../../App";
import { observe } from "mobx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
export default class NotificationInbox extends Component {
	state = { commentNotifications: null, all: false, currentPage: 1 };

	componentWillUnmount() {
		if (this.disposer) {
			this.disposer();
		}
	}

	async componentDidMount() {
		this.disposer = observe(appStore, "selectedProject", async (change) => {
			if (change.newValue !== change.oldValue) {
				this.load();
			}
		});

		this.load();
	}

	load = async () => {
		if (appStore.selectedProject) {
			const { commentNotifications } = await API(
				`/commentNotifications?projectID=${appStore.selectedProject}&all=${this.state.all}`,
				"GET",
				{}
			);

			this.setState({ commentNotifications });
		}
	};

	render() {
		if (!this.state.commentNotifications) {
			return null;
		}

		return (
			<Paper style={{ padding: 10 }}>
				<div>
					<div style={{ float: "left" }}>
						<h3>Comments</h3>
					</div>
					<div style={{ float: "right" }}>
						<Grid component="label" container alignItems="center" spacing={1}>
							<Grid item>Unread</Grid>
							<Grid item>
								<FormControlLabel
									style={{ marginRight: 0 }}
									control={
										<Switch
											checked={this.state.all}
											onChange={() => {
												this.setState({ all: !this.state.all }, () => {
													this.load();
												});
											}}
											name="checkedA"
										/>
									}
								/>
							</Grid>
							<Grid item>All</Grid>
						</Grid>
					</div>
				</div>
				<div style={{ clear: "both" }} />
				{this.state.commentNotifications.length === 0 ? (
					<p>You have no {this.state.all ? " " : "unread "}comments</p>
				) : (
					<>
						<TableContainer component={Paper}>
							<Table style={{ width: "100%" }}>
								<TableHead>
									<TableRow>
										<TableCell>Message</TableCell>
										<TableCell>Commenter</TableCell>
										<TableCell>Comment</TableCell>
										{this.state.all ? null : <TableCell></TableCell>}
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.commentNotifications.map(
										(commentNotification, index) => {
											if (
												index >= (this.state.currentPage - 1) * 10 &&
												index < this.state.currentPage * 10
											) {
												let comment = commentNotification.comment.comment;
												const matches = comment.matchAll(/@"(.+?)"/g);
												for (const match of matches) {
													comment = comment.replace(
														match[0],
														`<span style="
														  color: var(--red);
														  background: #eee;
														  border-radius: 5px; 
														  padding: 2px;">
														      @${match[1]}
														</span>`
													);
												}
												comment = ReactHtmlParser(comment);

												let link;
												if (commentNotification.comment.type === "message") {
													link = {
														pathname: `/canvas/${commentNotification.comment.messageCanvasID}/${commentNotification.comment.messageShapeID}`,
														state: { selectedTab: "properties" },
													};
												} else if (
													commentNotification.comment.type === "element"
												) {
													link = {
														pathname: `/canvas/${commentNotification.comment.messageCanvasID}/${commentNotification.comment.messageShapeID}`,
														state: {
															selectedTab: "elements",
															commentElementID:
																commentNotification.comment.associatedID,
														},
													};
												} else {
													link = {
														pathname: `/canvas/${commentNotification.comment.messageCanvasID}/${commentNotification.comment.messageShapeID}`,
														state: {
															selectedTab: "elements",
															commentBitCaseID:
																commentNotification.comment.associatedID,
															commentBitCaseElementID:
																commentNotification.comment.elementID,
															commentBitCaseBitID:
																commentNotification.comment.bitID,
														},
													};
												}

												return (
													<TableRow key={commentNotification.commentID}>
														<TableCell>
															<Link to={link}>
																{commentNotification.comment.messageName}
															</Link>
														</TableCell>
														<TableCell>
															{`${commentNotification.comment.user.firstName} ${commentNotification.comment.user.lastName}`}
														</TableCell>
														<TableCell>{comment}</TableCell>
														{this.state.all ? null : (
															<TableCell>
																<Button
																	className="link"
																	onClick={() => {
																		let copy = [
																			...this.state.commentNotifications,
																		];
																		copy.splice(index, 1);
																		this.setState({
																			commentNotifications: copy,
																		});

																		API(`/markAsRead`, "POST", {
																			commentIDs: [
																				commentNotification.comment.id,
																			],
																		});
																	}}
																>
																	clear
																</Button>
															</TableCell>
														)}
													</TableRow>
												);
											} else {
												return null;
											}
										}
									)}
								</TableBody>
							</Table>
						</TableContainer>
						{this.state.commentNotifications.length > 10 ? (
							<div style={{ textAlign: "center", paddingTop: 15 }}>
								<Pagination
									count={Math.ceil(this.state.commentNotifications.length / 10)}
									page={this.state.currentPage}
									onChange={(evt, page) => {
										this.setState({ currentPage: page });
									}}
								/>
							</div>
						) : null}
					</>
				)}
			</Paper>
		);
	}
}
