import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LinearProgressWithLabel from "../../../lib/LinearProgressWithLabel";
import { makeStyles } from "@material-ui/core/styles";

const childStyles = {
	display: "block",
	padding: "0.1rem",
};

const useStyles = makeStyles((theme) => ({
	statContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	fraction: {
		display: "inline-block",
		position: "relative",
		verticalAlign: "middle",
		textAlign: "center",
		paddingLeft: "0.5rem",
		paddingRight: "0.5rem",
	},
	fractionChild: {
		display: "block",
		padding: "0.1rem",
	},
	numerator: {
		...childStyles,
	},
	denominator: {
		...childStyles,
		borderTop: "thin solid black",
	},
	separator: {
		...childStyles,
		display: "none",
	},
}));

export const OverallProgress = ({
	totalMessageCount,
	completeMessageCount,
	remainingSeconds,
}) => {
	const classes = useStyles();
	const percentComplete = (completeMessageCount / totalMessageCount) * 100;
	const remainingMinutes = (remainingSeconds / 60).toFixed(1);
	let timeToDisplay;
	if (remainingMinutes > 60) {
		const hours = Math.floor(remainingMinutes / 60);
		const minutes = Math.round(remainingMinutes % 60);

		timeToDisplay = `${hours} hr ${minutes} min`;
	} else {
		timeToDisplay = `${remainingMinutes} minutes`;
	}

	return (
		<Card>
			<CardContent>
				<Grid
					container
					alignItems="stretch"
					justify="space-between"
					spacing={2}
				>
					<Grid item xs={3}>
						<h5>Total Progress</h5>
						<LinearProgressWithLabel value={percentComplete} />
					</Grid>
					<Grid item>
						<div className={classes.statContainer}>
							<h5>Messages Remaining</h5>
							<div className={classes.fraction}>
								<div className={classes.numerator}>
									{completeMessageCount} complete
								</div>
								<div className={classes.separator}>/</div>
								<div className={classes.denominator}>
									{totalMessageCount} total
								</div>
							</div>
						</div>
					</Grid>
					<Grid item>
						<h5>Time Remaining</h5>
						{timeToDisplay}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
export default OverallProgress;
