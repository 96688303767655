import React, { Component, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import API from "../../../API";
import Select from "react-select";
import Paper from "@material-ui/core/Paper";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { appStore, usersStore } from "../../../App";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import SelectMaterialUI from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react";
import Comments from "./Comments";
import { updateMessageColors } from "../left/Canvas";
import snackbarStore from "../../../lib/SnackbarStore";
import { ContentLengthResults } from "../../home/ContentLength";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default observer(
	class Properties extends Component {
		state = {
			newMessageName: this.props.messageName,
			editMessageNameDialog: false,
			selectedTeamMembers: [],
			contentLengthPanelExpanded: false,
		};

		async componentDidMount() {
			const { data } = await API(`/db/customQuery/messageMember`, "POST", {
				query: "find",
				options: { where: { messageID: this.props.messageDBID } },
				attributes: ["userID"],
			});

			this.setState({
				selectedTeamMembers: data.map((member) => {
					return member.userID;
				}),
			});
		}

		save = async () => {
			let result = await API(`/db/message`, "POST", {
				id: this.props.messageDBID,
				name: this.state.newMessageName,
			});

			if (result.code === "23505") {
				//sometimes we try to rename to a message name that is no longer on
				//the canvas but lingers in the messages table, so we check the shape
				//table to make sure it still exists on a canvas. If it doesn't, it's
				//a ghost message in the message table only, so delete it and retry the
				//rename
				const shapeResult = await API(`/db/customQuery/shape`, "POST", {
					query: "findOne",
					options: {
						where: { name: this.state.newMessageName },
					},
				});

				if (!shapeResult?.data) {
					const toDelete = await API(`/db/customQuery/message`, "POST", {
						query: "findOne",
						options: { where: { name: this.state.newMessageName } },
					});
					if (toDelete.data) {
						await API(`/db/message/${toDelete.data.id}`, "DELETE");
						result = await API(`/db/message`, "POST", {
							id: this.props.messageDBID,
							name: this.state.newMessageName,
						});
					} else {
						snackbarStore.setMessage(
							"Error",
							"Error renaming. Invisible message. Please contact support"
						);
					}
				}
			}

			if (result.code === "23505") {
				this.setState({
					error: "Message name already exists. Appended a number.",
				});
				const regexMatch = this.state.newMessageName.match(/\d+$/);
				if (regexMatch.length) {
					this.setState({
						newMessageName:
							this.state.newMessageName.substring(0, regexMatch.index) +
							(parseInt(regexMatch[0], 10) + 1),
					});
				} else {
					this.setState({ newMessageName: this.state.newMessageName + "-2" });
				}
			} else {
				this.props.setMessageName(this.state.newMessageName);
				const { error } = await API(`/updateMessageName`, "POST", {
					messageID: this.props.messageDBID,
					newName: this.state.newMessageName,
				});
				if (error) {
					snackbarStore.setMessage(
						"Error",
						"Error updating message name on canvas. Please report to support."
					);
				}
				if (window.loadCanvas) {
					window.loadCanvas();
				}
				this.setState({ editMessageNameDialog: false });
			}
		};

		setDropdown = async (value) => {
			this.props.message.status = value.value;

			if (window.graph) {
				const allCells = Object.values(window.graph.getModel().cells);

				const isMessage = (obj) => {
					try {
						return obj.style && obj.style.includes("shape=messageRectangle;");
					} catch {
						return false;
					}
				};

				const cell = allCells.find((cell) => {
					return isMessage(cell) && cell.value === this.props.message.name;
				});

				if (cell) {
					updateMessageColors(cell, value.value);
				}
			}

			await API(`/db/message`, "POST", {
				id: this.props.messageDBID,
				status: value.value,
			});

			const mondayRes = await API("/monday/editMessage", "POST", {
				messageID: this.props.messageDBID,
				newStatus: value.value,
			});
			if (mondayRes.error) {
				this.props.enqueueSnackbar(
					"Monday sync failed. Please ensure this status is available on associated Monday board.",
					{
						variant: "error",
					}
				);
			}
		};

		render() {
			const options = [
				"Backlog",
				"To Do",
				"Doing",
				"In Process",
				"Roadblocked",
				"Done",
				"In Testing",
				"In Review",
				"Approved",
			].map((option) => {
				return { value: option, label: option };
			});
			return (
				<div style={{ marginTop: 15 }}>
					<h3>Properties</h3>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Accordion
								expanded={this.state.contentLengthPanelExpanded}
								onChange={() => {
									this.setState({
										contentLengthPanelExpanded: !this.state
											.contentLengthPanelExpanded,
									});
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<h3>Content Length</h3>
								</AccordionSummary>
								<AccordionDetails>
									<ContentLength
										message={this.props.message}
										contentLengthPanelExpanded={
											this.state.contentLengthPanelExpanded
										}
									/>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={6}>
							<Paper style={{ padding: 15 }}>
								<Grid container>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={4} style={{ alignSelf: "center" }}>
												Message Name:
											</Grid>
											<Grid
												item
												xs={8}
												style={{ alignSelf: "center", cursor: "pointer" }}
												onClick={() => {
													this.setState({ editMessageNameDialog: true });
												}}
											>
												{this.props.messageName}
												{appStore.selectedProjectAccessMap.update ||
												process.env.REACT_APP_ENV === "local" ? (
													<div style={{ float: "right" }}>
														<Button
															variant="contained"
															style={{
																marginLeft: 15,
															}}
														>
															<FontAwesomeIcon icon={faPencil} />
														</Button>
													</div>
												) : null}
											</Grid>
										</Grid>
										<Grid container style={{ marginTop: 10 }}>
											<Grid item xs={4} style={{ alignSelf: "center" }}>
												Status:
											</Grid>
											<Grid item xs={8}>
												<Select
													value={{
														value: this.props.message.status,
														label: this.props.message.status,
													}}
													onChange={this.setDropdown}
													options={options}
													clearable={false}
													// disabled={
													// 	this.state.saving ||
													// 	globalStore.userType === "Read Only" ||
													// 	globalStore.userType === "Developer"
													// }
													styles={{
														control: (styles) => {
															let backgroundColor = "white";

															if (this.props.message.status === "Backlog") {
																backgroundColor = "#16B1F0";
															} else if (
																this.props.message.status === "To Do"
															) {
																backgroundColor = "#EF5F2F";
															} else if (
																this.props.message.status === "Doing"
															) {
																backgroundColor = "#390A4E";
															} else if (
																this.props.message.status === "In Process"
															) {
																backgroundColor = "#F2A524";
															} else if (
																this.props.message.status === "Roadblocked"
															) {
																backgroundColor = "#D1383F";
															} else if (this.props.message.status === "Done") {
																backgroundColor = "#7FAF41";
															} else if (
																this.props.message.status === "In Testing"
															) {
																backgroundColor = "#C6C6C6";
															} else if (
																this.props.message.status === "Approved"
															) {
																backgroundColor = "#2F3D47";
															} else if (
																this.props.message.status === "In Review"
															) {
																backgroundColor = "#764899";
															}

															return {
																...styles,
																backgroundColor,
															};
														},
														singleValue: (styles) => {
															let color = "black";

															if (this.props.message.status === "Backlog") {
																color = "white";
															} else if (
																this.props.message.status === "To Do"
															) {
																color = "white";
															} else if (
																this.props.message.status === "Doing"
															) {
																color = "white";
															} else if (
																this.props.message.status === "In Process"
															) {
																color = "white";
															} else if (
																this.props.message.status === "Roadblocked"
															) {
																color = "white";
															} else if (this.props.message.status === "Done") {
																color = "white";
															} else if (
																this.props.message.status === "In Testing"
															) {
																color = "black";
															} else if (
																this.props.message.status === "Approved"
															) {
																color = "white";
															} else if (
																this.props.message.status === "In Review"
															) {
																color = "white";
															}

															return {
																...styles,
																color,
															};
														},
													}}
												/>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={4} style={{ alignSelf: "center" }}>
												Message Owner:
											</Grid>
											<Grid item xs={8}>
												<FormControl>
													<InputLabel id="teamMember">Message Owner</InputLabel>
													<SelectMaterialUI
														labelId="messageOwner"
														value={this.props.message.messageOwner || ""}
														onChange={async (evt) => {
															const selected = evt.target.value;
															this.props.setMessageOwner(selected);

															await API(`/db/message`, "POST", {
																messageOwner: selected,
																id: this.props.messageDBID,
															});

															// const mondayRes = await API(
															// 	"/monday/editMessage",
															// 	"POST",
															// 	{
															// 		messageID: this.props.messageDBID,
															// 		messageOwner: selected,
															// 	}
															// );
															// if (mondayRes.error) {
															// 	this.props.enqueueSnackbar(
															// 		"Monday sync failed. Please ensure you associate this user with a Monday account.",
															// 		{
															// 			variant: "error",
															// 		}
															// 	);
															// }

															if (
																!this.state.selectedTeamMembers.includes(
																	selected
																)
															) {
																API(`/db/messageMember`, "POST", {
																	userID: selected,
																	messageID: this.props.messageDBID,
																});
																this.setState({
																	selectedTeamMembers: [
																		...this.state.selectedTeamMembers,
																		selected,
																	],
																});
															}
														}}
														input={<Input />}
														renderValue={(selected) => {
															const user = usersStore.users.find((user) => {
																return user.id === selected;
															});

															return user ? user.value : "";
														}}
														MenuProps={MenuProps}
													>
														{[
															...usersStore.users.map((user) => (
																<MenuItem key={user.id} value={user.id}>
																	<ListItemText primary={user.value} />
																</MenuItem>
															)),
															<MenuItem key="empty" value="">
																<ListItemText primary="(remove message owner)" />
															</MenuItem>,
														]}
													</SelectMaterialUI>
												</FormControl>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={4} style={{ alignSelf: "center" }}>
												Team Members:
											</Grid>
											<Grid item xs={8}>
												<FormControl>
													<InputLabel id="teamMember">Team Member</InputLabel>
													<SelectMaterialUI
														labelId="teamMember"
														multiple
														value={this.state.selectedTeamMembers}
														onChange={(evt) => {
															// Ensure the message owner is always on the team & dedupe
															const selectedTeamMembers = this.props.message
																.messageOwner
																? [
																		...new Set(
																			evt.target.value.concat(
																				this.props.message.messageOwner
																			)
																		),
																  ]
																: evt.target.value;
															this.setState({
																selectedTeamMembers,
															});
															API(`/messageTeam`, "POST", {
																members: selectedTeamMembers,
																messageID: this.props.messageDBID,
															});
														}}
														input={<Input />}
														renderValue={(selected) => {
															let names = [];
															for (const id of selected) {
																const user = usersStore.users.find((user) => {
																	return user.id === id;
																});

																if (user) {
																	names.push(user.value);
																}
															}
															return names.join(", ");
														}}
														MenuProps={MenuProps}
													>
														{usersStore.users.map((user) => (
															<MenuItem key={user.id} value={user.id}>
																<Checkbox
																	checked={
																		this.state.selectedTeamMembers.indexOf(
																			user.id
																		) > -1
																	}
																	disabled={
																		this.props.message.messageOwner === user.id
																	}
																/>
																<ListItemText primary={user.value} />
															</MenuItem>
														))}
													</SelectMaterialUI>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
					<Dialog
						open={this.state.editMessageNameDialog}
						onClose={() => {
							this.setState({ editMessageNameDialog: false });
						}}
					>
						<DialogContent style={{ minWidth: 400 }}>
							<TextField
								label="Message Name"
								value={this.state.newMessageName}
								onChange={(evt) => {
									this.setState({ newMessageName: evt.target.value });
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									this.setState({ editMessageNameDialog: false });
								}}
							>
								Cancel
							</Button>
							<Button color="primary" onClick={this.save}>
								Save
							</Button>
						</DialogActions>
					</Dialog>
					<Accordion style={{ marginTop: 15 }} expanded>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<h3 style={{ marginBottom: 0 }}>Comments</h3>
						</AccordionSummary>
						<AccordionDetails>
							<Comments
								type="message"
								associatedID={this.props.messageDBID}
								history={this.props.history}
							/>
						</AccordionDetails>
					</Accordion>
				</div>
			);
		}
	}
);

const ContentLength = ({ message, contentLengthPanelExpanded }) => {
	const [results, setResults] = useState();

	useEffect(() => {
		if (contentLengthPanelExpanded) {
			(async () => {
				const results = await API(`/elementLength`, "POST", {
					includeEmptyElements: false,
					messageName: message.name,
				});
				setResults(results);
			})();
		}
	}, [contentLengthPanelExpanded, message.name]);

	return <ContentLengthResults results={results} />;
};
