import React, { useEffect, useState } from "react";
import Select from "react-select";
import client from "../../../lib/feathers";
import { appStore } from "../../../App";
import { observer } from "mobx-react";
import { Paper, Grid } from "@material-ui/core";
import RedButton from "../../../lib/RedButton";
import { useHistory } from "react-router-dom";
import { observe } from "mobx";
import snackbarStore from "../../../lib/SnackbarStore";

const PrototypeStart = observer(() => {
	const [messages, setMessages] = useState();
	const [selectedMessage, setSelectedMessage] = useState();
	const history = useHistory();

	useEffect(() => {
		const disposer = observe(appStore, "selectedProject", async () => {
			if (appStore.selectedProject) {
				setMessages(
					await client
						.service("message")
						.find({ query: { projectID: appStore.selectedProject } })
				);
			}
		});

		return () => {
			if (disposer) {
				disposer();
			}
		};
	}, []);

	return (
		<div>
			<h3 style={{ textDecoration: "underline" }}>Prototype Starter</h3>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Paper style={{ padding: 15 }}>
						<p>Select message to use as a starting point: </p>
						{messages ? (
							<Select
								options={messages.map((message) => ({
									label: message.name,
									value: message.id,
								}))}
								onChange={(val) => {
									setSelectedMessage(val);
								}}
								value={selectedMessage}
								style={{ paddingBottom: 10, paddingTop: 10 }}
							/>
						) : (
							<p>Loading...</p>
						)}
						{selectedMessage ? (
							<RedButton
								style={{ marginTop: 15 }}
								onClick={async () => {
									const matchingMessage = messages.find((message) => {
										return message.id === selectedMessage.value;
									});
									history.push(
										`/canvas/${matchingMessage.canvasID}/${matchingMessage.shapeID}/prototype?messageID=${matchingMessage.id}`
									);
								}}
							>
								Start Prototype
							</RedButton>
						) : null}
					</Paper>
				</Grid>
				<Grid item xs={6}>
					<Paper style={{ padding: 15 }}>
						<RedButton
							onClick={() => {
								snackbarStore.setMessage("success", "User data cleared");
							}}
						>
							Reset User Data
						</RedButton>
					</Paper>
					<Paper style={{ padding: 15, marginTop: 15 }}>
						<h4>Existing user data:</h4>
						<p>No user data yet...</p>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
});

export default PrototypeStart;
