import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { ReactSortable } from "react-sortablejs";
import API from "../../../API";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import RedButton from "../../../lib/RedButton";
import HeightIcon from "@material-ui/icons/Height";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

export default class Checkboxes extends Component {
	state = { attributeOptions: null, checkboxOptions: null };

	async componentDidMount() {
		await this.load();
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.messageID !== this.props.messageID) {
			await this.load();
		} else if (prevProps.useCheckboxes !== this.props.useCheckboxes) {
			await API(`/db/customQuery/messageCheckbox`, "POST", {
				query: "delete",
				options: { where: { messageID: this.props.messageID } },
			});
			await this.load();
		}
	}

	load = async () => {
		let { checkboxAttributes, existingCheckboxes } = await API(
			`/message/${this.props.messageID}/getCheckboxData`,
			"GET"
		);

		if (!existingCheckboxes.length) {
			existingCheckboxes = [
				{
					label: "",
				},
			];
		}

		this.setState({
			attributeOptions: checkboxAttributes,
			checkboxOptions: existingCheckboxes,
		});
	};

	save = async () => {
		await API(`/saveCheckboxes`, "POST", {
			messageID: this.props.messageID,
			checkboxes: this.state.checkboxOptions,
		});

		await this.load();

		this.props.enqueueSnackbar("Checkbox Options Saved", {
			variant: "success",
		});
	};

	delete = async (rowID, rowIndex) => {
		const removedRow = this.state.checkboxOptions.filter(
			(option, index) => index !== rowIndex
		);
		this.setState({ checkboxOptions: removedRow });

		if (rowID) {
			await API(`/db/customQuery/messageCheckbox`, "POST", {
				query: "delete",
				options: {
					where: { id: rowID },
				},
			});
		}
	};

	render() {
		return (
			<Fragment>
				<Snackbar
					open={this.state.open}
					autoHideDuration={5000}
					onClose={() => {
						this.setState({
							open: false,
						});
					}}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
				>
					<Alert
						elevation={6}
						severity={this.state.severity?.toLowerCase()}
						style={{ marginBottom: 100 }}
						onClose={() => {
							this.setState({
								open: false,
							});
						}}
					>
						<AlertTitle>{this.state.severity}</AlertTitle>
						{this.state.message}
					</Alert>
				</Snackbar>
				<Paper style={{ padding: 10, marginTop: 10 }}>
					<h3>Checkbox Options</h3>
					{!this.state.checkboxOptions ? (
						<div style={{ justifyContent: "center", display: "flex" }}>
							<CircularProgress size={28} />
						</div>
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Label</TableCell>
											<TableCell style={{ textAlign: "center" }}>
												Attribute
											</TableCell>
											<TableCell style={{ textAlign: "center" }}>
												Actions
											</TableCell>
										</TableRow>
									</TableHead>
									<ReactSortable
										list={this.state.checkboxOptions}
										setList={(newState) => {
											const updatedOrder = newState.map((checkbox, index) => {
												return { ...checkbox, order: index };
											});
											this.setState({ checkboxOptions: updatedOrder });
										}}
										tag={TableBody}
									>
										{this.state.checkboxOptions.map((row, index) => {
											return (
												<TableRow key={index}>
													<TableCell>
														<TextField
															value={row.label || ""}
															onChange={(evt) => {
																const options = [...this.state.checkboxOptions];
																options[index].label = evt.target.value;
																this.setState({ checkboxOptions: options });
															}}
															placeholder={
																this.state.checkboxOptions[index]?.attribute
																	?.name || ""
															}
														/>
													</TableCell>
													<TableCell>
														<FormControl variant="filled">
															<InputLabel>Attribute</InputLabel>
															<Select
																value={row.attribute?.id || ""}
																onChange={(evt) => {
																	const attributeData = this.state.attributeOptions.find(
																		(attr) => attr.id === evt.target.value
																	);
																	const options = [
																		...this.state.checkboxOptions,
																	];
																	options[index].attribute = attributeData;
																	this.setState({ checkboxOptions: options });
																}}
															>
																{this.state.attributeOptions.map((option) => (
																	<MenuItem value={option.id} key={option.id}>
																		{option.name}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</TableCell>
													<TableCell style={{ textAlign: "center" }}>
														{this.state.checkboxOptions.length > 1 ? (
															<HeightIcon style={{ cursor: "grab" }} />
														) : null}
														<DeleteIcon
															style={{ cursor: "pointer" }}
															onClick={() => this.delete(row.id, index)}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</ReactSortable>
								</Table>
							</TableContainer>
							<Grid container spacing={2} style={{ marginTop: 10 }}>
								<Grid item xs={6}>
									<Button
										variant="contained"
										color="secondary"
										startIcon={<AddIcon />}
										onClick={() =>
											this.setState({
												checkboxOptions: [
													...this.state.checkboxOptions,
													{
														label: "",
													},
												],
											})
										}
										style={{ width: "100%" }}
									>
										Add Row
									</Button>
								</Grid>
								<Grid item xs={6}>
									<RedButton onClick={this.save}>Save</RedButton>
								</Grid>
							</Grid>
						</>
					)}
				</Paper>
			</Fragment>
		);
	}
}
