import React, { useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useFormikContext, useField } from "formik";

const FormikReactSelectCreatable = ({
	loadOptions,
	label,
	portalPlacement,
	...props
}) => {
	const { setFieldValue, setFieldTouched } = useFormikContext();
	const [field, meta] = useField(props);
	const [menuPortalTarget, setMenuPortalTarget] = useState();

	/**
	 * Will manually set the value belong to the name props in the Formik form using setField
	 */
	function handleOptionChange(selection) {
		setFieldValue(props.name, selection);
	}

	/**
	 * Manually updated the touched property for the field in Formik
	 */
	function updateBlur() {
		setFieldTouched(props.name, true);
	}

	return (
		<React.Fragment>
			<label htmlFor={props.id}>{label}</label>
			<AsyncCreatableSelect
				loadOptions={loadOptions}
				{...field}
				{...props}
				onBlur={updateBlur}
				onChange={handleOptionChange}
				autoFocus={true}
				onMenuOpen={() => {
					if (!menuPortalTarget) {
						setMenuPortalTarget(
							document.getElementsByClassName("MuiDialog-container")[0]
						);
					}
				}}
				menuPortalTarget={menuPortalTarget}
				style={{
					menu: (provided) => ({
						...provided,
						zIndex: 9999,
					}),
				}}
				defaultOptions
			/>
			{meta.touched && meta.error ? (
				<span className="custom-input-error">{meta.error.value}</span>
			) : null}
		</React.Fragment>
	);
};

export default FormikReactSelectCreatable;
