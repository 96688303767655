import React, { useState, useEffect } from "react";
import { action, observable, remove } from "mobx";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import debounce from "lodash.debounce";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import moment from "moment-timezone";
import { observer } from "mobx-react";
import Paper from "@material-ui/core/Paper";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ReplayIcon from "@material-ui/icons/Replay";
import StopIcon from "@material-ui/icons/Stop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import client from "../../../lib/feathers";
import CreateTask from "./CreateTask";
import TablePaginationActions from "../../../lib/TablePaginationActions";
import LinearProgressWithLabel from "../../../lib/LinearProgressWithLabel";
import OverallProgress from "./OverallProgress";
import RedButton from "../../../lib/RedButton";
import { sortBy } from "../../../lib/HelperFunctions";

const initialState = {
	loading: true,
	tasks: {},
	detail: {
		open: false,
		id: null,
		content: [],
		task: null,
		loading: false,
	},
	overallProgress: {
		loading: false,
		totalMessageCount: 0,
		completeMessageCount: 0,
		totalSeconds: 0,
		remainingSeconds: 0,
		timestamp: new Date(),
	},
	createModal: {
		loading: false,
		open: false,
		canvasOptions: [],
	},
};

const contentStore = observable(initialState);

const reset = action(() => {
	Object.entries(initialState).forEach(([key, value]) => {
		contentStore[key] = value;
	});
});

const afterUpdate = debounce(
	action(() => {
		if (contentStore.overallProgress.loading) {
			return;
		}
		contentStore.overallProgress.loading = true;
		return client
			.service("contentTest")
			.get(1)
			.then(
				action((resp) => {
					contentStore.overallProgress = {
						...contentStore.overallProgress,
						...resp,
						loading: false,
					};
					return resp;
				})
			)
			.catch(console.error);
	}),
	1000
);

export const TaskManager = observer(() => {
	const taskService = client.service("contentTask");

	/*eslint-disable */
	useEffect(() => {
		client.service("channelManager").create({ name: `contentTask` });
		// Load up the ContentTask records
		taskService
			.find({
				query: {
					include: ["Canvas"],
				},
			})
			.then(
				action((tasks) => {
					const taskMap = tasks
						.filter((task) => task.canvas?.id)
						.reduce((accum, task) => ((accum[task.id] = task), accum), {});
					contentStore.tasks = { ...contentStore.tasks, ...taskMap };
					contentStore.loading = false;
					return tasks;
				})
			)
			.catch(
				action((error) => {
					contentStore.loading = false;
					return Promise.reject(error);
				})
			);

		taskService
			.on("patched", onUpdate)
			.on("created", onUpdate)
			.on(
				"removed",
				action((task) => {
					remove(contentStore.tasks, task.id);
				})
			);

		afterUpdate();

		return () => {
			reset();
			client.service("channelManager").remove({ name: "contentTask" });
			taskService
				.removeListener("patched")
				.removeListener("created")
				.removeListener("removed");
		};
	}, []);
	/*eslint-disable */

	const onUpdate = ({ id }) =>
		taskService
			.find({
				query: {
					id,
					include: ["Canvas"],
				},
			})
			.then(
				action((tasks) => {
					tasks
						.filter((task) => task.canvas?.id)
						.forEach((item) => (contentStore.tasks[item.id] = item));
					return tasks;
				})
			)
			.finally(afterUpdate);

	const killAll = () => {
		const killable = Object.values(contentStore.tasks).filter(({ status }) =>
			stoppableStatusOptions.includes(status)
		);
		return Promise.all(
			killable.map((task) => client.service("contentTest").remove(task.id, {}))
		);
	};

	// Create modal callbacks
	const onCreateOpen = action(() => {
		contentStore.createModal.loading = true;
		contentStore.createModal.open = true;
		return client
			.service("contentTest")
			.find({
				query: {},
			})
			.then(
				action((canvasOptions) => {
					contentStore.createModal.loading = false;
					contentStore.createModal.canvasOptions = sortBy("name")(
						canvasOptions
					);
					return canvasOptions;
				})
			);
	});
	const onCreateClose = action(() => (contentStore.createModal.open = false));
	const onCreateSave = (selectedIds) =>
		Promise.all(
			selectedIds.map((canvasID) =>
				client.service("contentTest").create({ canvasID })
			)
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<h3 style={{ textDecoration: "underline" }}>Task Manager</h3>
			</Grid>
			{contentStore.overallProgress.totalMessageCount ? (
				<Grid item xs={12}>
					<OverallProgress {...contentStore.overallProgress} />
				</Grid>
			) : null}
			<Grid item xs={12}>
				<Paper style={{ padding: 15 }}>
					<Grid container>
						<Grid item xs={12}>
							<div style={{ display: "flex", wrap: "nowrap" }}>
								<Button
									onClick={onCreateOpen}
									startIcon={<AddIcon />}
									style={{ marginLeft: 15, width: "initial" }}
									variant="contained"
									color="secondary"
								>
									Add
								</Button>
								<RedButton
									onClick={() => killAll()}
									startIcon={<StopIcon />}
									style={{
										width: "initial",
										marginLeft: "1rem",
										marginRight: "1rem",
									}}
								>
									Stop All
								</RedButton>
							</div>
						</Grid>
						<Grid item xs={12}>
							{contentStore.loading ? (
								<div style={{ textAlign: "center", padding: 15 }}>
									<CircularProgress color="secondary" />
								</div>
							) : (
								<ContentTaskTable />
							)}
						</Grid>
					</Grid>
					{contentStore.createModal.open ? (
						<CreateTask
							{...contentStore.createModal}
							onSave={onCreateSave}
							onClose={onCreateClose}
						/>
					) : null}
				</Paper>
			</Grid>
		</Grid>
	);
});

export default TaskManager;

const resumableStatusOptions = ["Stopped", "Error"];
const stoppableStatusOptions = ["In Queue", "Running"];
const restartableStatusOptions = ["Complete"]
	.concat(resumableStatusOptions)
	.concat(stoppableStatusOptions);

/**
 * @component ContentTaskTable - Displays all contentTasks
 */
const ContentTaskTable = observer(({ ...props }) => {
	const { tasks } = contentStore;
	const taskList = sortTasks(Object.values(tasks));
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const onChangePage = (event, number) => setPage(number);
	const onChangeRowsPerPage = (event) => (
		setRowsPerPage(parseInt(event.target.value, 10)), setPage(0)
	);

	const rows =
		rowsPerPage > 0
			? taskList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			: taskList;

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, taskList.length - page * rowsPerPage);

	const resumeTask = (task) =>
		client.service("contentTest").patch(task.id, { type: "resume" });

	const restartTask = (task) =>
		client.service("contentTest").patch(task.id, { type: "restart" });

	const stopTask = (task) => client.service("contentTest").remove(task.id, {});

	const buttonStyles = {
		width: "initial",
		marginLeft: "1rem",
		marginRight: "1rem",
	};

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Canvas Name</TableCell>
						<TableCell>Updated At</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Progress</TableCell>
						<TableCell align="right">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((task) => (
						<TableRow key={task.id}>
							<TableCell>
								<Anchor to={`/canvas/${task.canvas.id}`}>
									{task.canvas?.name}
								</Anchor>
							</TableCell>
							<TableCell>{formatDate(task.updatedAt)}</TableCell>
							<TableCell>{task.status}</TableCell>
							<TableCell>
								<LinearProgressWithLabel
									includeCircular={stoppableStatusOptions.includes(task.status)}
									value={task.progress * 100}
								/>
							</TableCell>
							<TableCell align="right">
								<Button
									onClick={() => restartTask(task)}
									startIcon={<ReplayIcon />}
									disabled={!restartableStatusOptions.includes(task.status)}
									style={buttonStyles}
								>
									Restart
								</Button>
								<Button
									onClick={() => resumeTask(task)}
									startIcon={<PlayCircleFilledIcon />}
									disabled={!resumableStatusOptions.includes(task.status)}
									style={buttonStyles}
								>
									Resume
								</Button>
								<RedButton
									onClick={() => stopTask(task)}
									startIcon={<StopIcon />}
									disabled={!stoppableStatusOptions.includes(task.status)}
									style={buttonStyles}
								>
									Stop
								</RedButton>
							</TableCell>
						</TableRow>
					))}
					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							colSpan={5}
							count={taskList.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { "aria-label": "rows per page" },
								native: true,
							}}
							onChangePage={onChangePage}
							onChangeRowsPerPage={onChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
});

const formatDate = (date) => moment(date).format("M/D/YY h:mm a");

const sortTasks = (tasks) =>
	tasks.sort((left, right) => {
		const leftValue = Number(new Date(left.updatedAt));
		const rightValue = Number(new Date(right.updatedAt));
		return leftValue < rightValue ? 1 : leftValue > rightValue ? -1 : 0;
	});

export const Anchor = ({ to, ...props }) => (
	<Link
		to={to}
		component={RouterLink}
		{...props}
		underline="always"
		color="secondary"
	/>
);
