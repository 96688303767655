import { observer } from "mobx-react";
import React, { Component } from "react";
import SidebarModule from "./SidebarModule";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import { appStore } from "../App";

export default observer(
	class Sidebar extends Component {
		render() {
			const { isAdmin, selectedProjectAccessMap } = appStore;
			const canManageTeam = isAdmin || selectedProjectAccessMap.update;
			return (
				<div
					className="invisibleScrollbar"
					style={{
						backgroundColor: "var(--darkGray)",
						height: `calc(100vh - ${appStore.smallFooter ? "90" : "160"}px)`, //160 comes from the height of the footer & header
						width: 240,
						boxShadow: "4px 4px 4px rgba(0,0,0,.5)",
						marginTop: -5,
						paddingTop: 10,
						paddingLeft: 10,
						paddingRight: 10,
						zIndex: -1,
						position: "fixed",
						overflow: "scroll",
					}}
				>
					<SidebarModule title="Project Management">
						<NavLink to="/home/manageProjects" activeClassName="activePage">
							Manage Projects
						</NavLink>
						{canManageTeam && (
							<NavLink to="/home/manageTeam" activeClassName="activePage">
								Manage Team
							</NavLink>
						)}
						<NavLink
							to="/home/exportImportContent"
							activeClassName="activePage"
						>
							Export/Import Content
						</NavLink>
						<NavLink to="/home/contentLength" activeClassName="activePage">
							Content Length
						</NavLink>
						<NavLink to="/home/ghostKiller" activeClassName="activePage">
							<span role="img" aria-label={"Ghost Killer"}>
								⛔️👻✉️
							</span>
						</NavLink>
						<NavLink to={"/home/bulkMessageUpdate"}>
							Bulk Message Update
						</NavLink>
					</SidebarModule>
					<SidebarModule title="Account Management">
						<NavLink to="/home/manageProfile" activeClassName="activePage">
							Manage Profile
						</NavLink>
						{isAdmin && (
							<NavLink to="/home/manageUsers" activeClassName="activePage">
								Manage Users
							</NavLink>
						)}
					</SidebarModule>
					<SidebarModule title="Attributes Management">
						<NavLink to="/home/attributeBuilder" activeClassName="activePage">
							Attribute Builder
						</NavLink>
						<NavLink to="/home/glossary" activeClassName="activePage">
							Glossary
						</NavLink>
						<NavLink to="/home/retrievers" activeClassName="activePage">
							Retrievers
						</NavLink>
					</SidebarModule>
					<SidebarModule title="Testing">
						<NavLink to="/home/prototypeStart">Prototype</NavLink>
						<NavLink to="/home/transcript">Transcript Testing</NavLink>
						<NavLink to="/home/testCase">Test Cases</NavLink>
						<NavLink to="/home/contentTesting">Content Testing</NavLink>
					</SidebarModule>
				</div>
			);
		}
	}
);
