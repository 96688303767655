import React, { useEffect, Fragment } from "react";
import API from "../../../../API";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/pro-solid-svg-icons";

const AttributesUsed = (props) => {
	const {
		setUsedAttributes,
		usedAttributes,
		messageDBID,
		entryID,
		type,
		id,
		currentCanvasID,
	} = props;

	const loadAttributes = async () => {
		const { data } = await API(`/db/customQuery/${type}Attribute`, "POST", {
			query: "find",
			options: {
				where:
					type === "message"
						? { messageDBID: messageDBID }
						: {
								[`${type}ID`]: id,
								canvasID: currentCanvasID,
						  },

				include: [{ model: "Attribute", attributes: ["id", "name"] }],
			},
		});
		return data;
	};

	useEffect(() => {
		const getUsedAttributes = async () => {
			if (type === "glossary") {
				const { data } = await API(
					`/db/customQuery/linkedGlossaryEntry`,
					"POST",
					{
						query: "find",
						options: {
							where: { glossaryEntryID: entryID },
							include: [{ model: "GlossaryEntry", attributes: ["id", "name"] }],
						},
					}
				);

				const glossaryData = data.map((obj) => obj.glossaryEntry);

				setUsedAttributes(glossaryData);
			} else {
				/*MZM 8-19-21 One way or another (ie I didn't investigate why this was
				happening) duplicate attributes are showing up on the same message,
				likely through copy and paste. This automatically detects them when
				the attributes tab is first load, nukes them, then reloads the data.*/
				let data = await loadAttributes();

				let duplicateAttributeTracker = [];
				let oneDeleted = false;
				for (const usedAttribute of data) {
					if (duplicateAttributeTracker.includes(usedAttribute.attributeID)) {
						await API(`/db/${type}Attribute/${usedAttribute.id}`, "DELETE", {});
						oneDeleted = true;
					} else {
						duplicateAttributeTracker.push(usedAttribute.attributeID);
					}
				}

				if (oneDeleted) {
					data = await loadAttributes();
				}
				setUsedAttributes(data.map((obj) => obj.attribute));
			}
		};

		getUsedAttributes();

		return () => {};

		//eslint-disable-next-line
	}, []);

	const remove = async (idToDelete) => {
		if (type === "glossary") {
			await API(`/db/customQuery/linkedGlossaryEntry`, "POST", {
				query: "delete",
				options: {
					where: {
						glossaryEntryID: entryID,
						linkedGlossaryEntryID: idToDelete,
					},
				},
			});
		} else {
			if (type === "message") {
				const data = await API(`/message/safeDeleteAttribute`, "DELETE", {
					messageDBID: messageDBID,
					attributeID: idToDelete,
				});

				if (!data.safeToDelete) {
					alert(
						`${data.whereIsUsed} Please remove the attribute or change it to another one, then try the delete again.`
					);
				} else {
					await API(`/db/customQuery/messageAttribute`, "POST", {
						query: "delete",
						options: {
							where: { messageDBID: messageDBID, attributeID: idToDelete },
						},
					});

					setUsedAttributes(
						usedAttributes.filter((result) => {
							return result.id !== idToDelete;
						})
					);
				}
			} else {
				const data = await API(
					`/canvasBrancher/safeDeleteAttribute`,
					"DELETE",
					{
						brancherID: id,
						attributeID: idToDelete,
						canvasID: currentCanvasID,
					}
				);

				if (!data.safeToDelete) {
					alert(
						`${data.whereIsUsed} Please remove the attribute or change it to another one, then try the delete again.`
					);
				} else {
					await API(`/db/customQuery/brancherAttribute`, "POST", {
						query: "delete",
						options: {
							where: {
								attributeID: idToDelete,
								brancherID: id,
							},
						},
					});

					setUsedAttributes(
						usedAttributes.filter((result) => {
							return result.id !== idToDelete;
						})
					);
				}
			}
		}
	};
	return (
		<Fragment>
			{usedAttributes === null || usedAttributes.length === 0 ? (
				<p>
					No {type === "glossary" ? "linked glossaries" : "attributes"} on this{" "}
					{type} yet
				</p>
			) : (
				<div>
					<label style={{ fontWeight: 700, fontFamily: "Raleway, sans-serif" }}>
						Attributes:
					</label>
					<List>
						{usedAttributes.map((result) => {
							return (
								<ListItem key={result.id} button>
									<ListItemText primary={result.name} />
									<IconButton
										edge="end"
										onClick={() => {
											remove(result.id);
										}}
									>
										<FontAwesomeIcon icon={faMinus} style={{ fontSize: 12 }} />
									</IconButton>
								</ListItem>
							);
						})}
					</List>
				</div>
			)}
		</Fragment>
	);
};

export default AttributesUsed;
