import React, { Component } from "react";
import "./message/left/Canvas.scss";
import { observer } from "mobx-react";
import { observable, observe } from "mobx";
import Fab from "@material-ui/core/Fab";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import API from "../API";
import shortid from "shortid";
import snackbarStore from "../lib/SnackbarStore";
import { withRouter } from "react-router";
import { appStore } from "../App";
import { isPortal } from "../lib/HelperFunctions";

export const pageStore = new observable({
	selectedCell: null,
	instanceID: shortid.generate(),
});

// Extend the EditorUI init method
var editorUiInit = window.EditorUi.prototype.init;
window.EditorUi.prototype.init = function () {
	editorUiInit.apply(this, arguments);
	this.actions.get("export").setEnabled(false);
	this.actions.get("open").setEnabled(false);
	this.actions.get("import").setEnabled(false);
	this.actions.get("save").setEnabled(false);
	this.actions.get("saveAs").setEnabled(false);
	this.actions.get("export").setEnabled(false);
	this.actions.get("rotation").setEnabled(false);
};
const enc = new window.mxCodec();

const CanvasViewer = withRouter(
	observer(
		class Canvas extends Component {
			state = {
				currentCanvasID: null,
				layoutMenu: null,
				lastClickedPortal: null,
			};
			canvasHistory = [];
			messagesToDeleteFromDB = [];

			async componentWillUnmount() {
				window.loadCanvas = null;

				if (this.disposer) {
					this.disposer();
				}

				if (this.disposer2) {
					this.disposer2();
				}

				this._ismounted = false;
			}

			componentDidUpdate(prevProps, prevState, snapshot) {
				//TODO REL refactor still needs to be done
				if (
					this.props.match.params.canvasID !==
						prevProps.match.params.canvasID &&
					this.props.match.params.canvasID !== this.state.currentCanvasID
				) {
					this.loadCanvas();
				}
			}

			async componentDidMount() {
				this._ismounted = true;
				let authed = false;

				while (!authed) {
					if (window.localStorage.canvasKey) {
						this.canvasPassword = window.localStorage.canvasKey;
						authed = true;
					} else {
						this.canvasPassword = window.prompt("Password, please: ");

						if (!this.canvasPassword) {
							return;
						}
						const { success } = await API(
							`/canvasViewerPassword?canvasKey=${this.canvasPassword}`,
							"GET"
						);

						if (success) {
							window.localStorage.canvasKey = this.canvasPassword;
						}
						authed = success;
					}
				}

				this.disposer = observe(appStore, "canvasID", (change) => {
					this.loadCanvas(null, appStore.canvasID);
				});

				window.loadCanvas = this.loadCanvas;
				(() => {
					// Adds required resources (disables loading of fallback properties, this can only
					// be used if we know that all keys are defined in the language specific file)
					window.mxResources.loadDefaultBundle = true;
					var bundle =
						window.mxResources.getDefaultBundle(
							window.RESOURCE_BASE,
							window.mxLanguage
						) ||
						window.mxResources.getSpecialBundle(
							window.RESOURCE_BASE,
							window.mxLanguage
						);

					// Fixes possible asynchronous requests
					window.mxUtils.getAll(
						[
							bundle,
							"/mxgraph/grapheditor/" + window.STYLE_PATH + "/default.xml",
						],
						async (xhr) => {
							// Adds bundle text to resources
							window.mxResources.parse(xhr[0].getText());

							// Configures the default graph theme
							var themes = {};
							themes[
								window.Graph.prototype.defaultThemeName
							] = xhr[1].getDocumentElement();

							window.mxVertexHandler.prototype.rotationEnabled = false;
							window.mxEdgeHandler.prototype.cloneEnabled = false;
							window.mxGraphHandler.prototype.cloneEnabled = false;

							const editor = new window.Editor(
								window.urlParams["chrome"] === "0",
								themes
							);

							window.editor = editor;
							window.graph = editor.graph;

							//disable click on the icons that appear on hover to prevent cloning
							window.HoverIcons.prototype.click = () => {};

							// Main
							window.editorui = new window.EditorUi(
								editor,
								document.getElementById("geEditor")
							);

							window.graph.dblClick = async (evt, cell) => {
								pageStore.selectedCell = cell;
							};

							window.mxPopupMenu.prototype.enabled = false;

							const rightClickOrTapAndHold = (sender, evt) => {
								if (!evt.properties.cell) {
									//do nothing, there's no cell selected
								} else if (
									evt.properties.cell.style.includes(
										"shape=mxgraph.flowchart.manual_operation"
									) &&
									evt.properties.cell.value !== "Top Level of Project"
								) {
									this.containerRightClicked(evt.properties.cell.value);
								} else if (
									evt.properties.cell.style.includes("shape=offPageConnector")
								) {
									this.intraCanvasPortalRightClicked(evt.properties.cell);
								} else if (
									evt.properties.cell.style.includes("shape=trapezoid")
								) {
									if (this.canvasHistory.length > 1) {
										this.back();
									} else {
										alert(
											"This is the top level of the project. Please interact with other shapes on this canvas"
										);
									}
								} else if (evt.properties.cell.style.includes("shape=circle")) {
									//portal right clicked
									const allCells = Object.values(window.graph.getModel().cells);
									const rightClickCell = evt.properties.cell;

									let matchingPortal;
									if (
										this.state.lastClickedPortal &&
										this.state.lastClickedPortal.value === rightClickCell.value
									) {
										matchingPortal = this.state.lastClickedPortal;
									} else {
										matchingPortal = allCells.find((cell) => {
											if (
												!isPortal(cell) ||
												cell.value !== rightClickCell.value ||
												cell.id === rightClickCell.id
											) {
												return false;
											} else if (
												findOutgoingLines(rightClickCell)?.length > 0 &&
												findIncomingLines(cell)?.length > 0
											) {
												const secondMatch = !!allCells.find(
													(secondMatchingCell) => {
														return (
															secondMatchingCell.id !== rightClickCell.id &&
															secondMatchingCell.value ===
																rightClickCell.value &&
															secondMatchingCell.id !== cell.id &&
															findOutgoingLines(rightClickCell).length > 0 &&
															findIncomingLines(secondMatchingCell).length > 0
														);
													}
												);

												if (secondMatch) {
													snackbarStore.setMessage(
														"Info",
														"There are multiple matching incoming portals. Taking you to the first one we found."
													);
												}

												return true;
											} else if (
												findIncomingLines(rightClickCell).length > 0 &&
												findOutgoingLines(cell).length > 0
											) {
												return true;
											} else {
												return false;
											}
										});
									}

									if (matchingPortal) {
										window.graph.scrollCellToVisible(matchingPortal, true);
										if (rightClickCell.style?.includes("strokeColor")) {
											window.graph.setCellStyles("strokeWidth", "1", [
												rightClickCell,
											]);
											window.graph.setCellStyles("strokeColor", "#000", [
												rightClickCell,
											]);
										}
										this.setState({ lastClickedPortal: rightClickCell });
									} else {
										snackbarStore.setMessage(
											"Error",
											"Can't find a matching portal."
										);
										window.graph.setCellStyles("strokeWidth", "4", [
											rightClickCell,
										]);
										window.graph.setCellStyles("strokeColor", "#FF331C", [
											rightClickCell,
										]);
									}
								}
							};

							window.graph.addListener(
								window.mxEvent.CLICK,
								async (sender, evt) => {
									window.mxEvent.consume(evt.properties.event);
									rightClickOrTapAndHold(sender, evt);
								}
							);

							window.graph.addListener(
								window.mxEvent.TAP_AND_HOLD,
								async (sender, evt) => {
									rightClickOrTapAndHold(sender, evt);
								}
							);

							window.graph.addListener(
								window.mxEvent.SAVE,
								async (sender, evt) => {
									window.mxEvent.consume(evt.properties.event);
								}
							);

							const canvasIDRaw = [
								...this.props.location.pathname.matchAll(/\/(\d+)\/(\d+)/g),
							];

							if (appStore.canvasID) {
								await this.loadCanvas(null, appStore.canvasID, true);
							} else if (canvasIDRaw.length) {
								for (const match of canvasIDRaw) {
									const canvasID = match[1];
									// const messageID = match[2];
									await this.loadCanvas(null, canvasID, true);
								}
							} else if (this.props.match.params.canvasID) {
								await this.loadCanvas(null, null, true);
							} else {
								await this.loadCanvas("Engagement Rx", null, true);
							}
						},
						function () {
							document.body.innerHTML =
								'<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
						}
					);
				})();

				const findOutgoingLines = (cellToGetOutgoingLines) => {
					return (
						cellToGetOutgoingLines.edges?.filter((edge) => {
							return (
								edge.source &&
								edge.target &&
								edge.source.id === cellToGetOutgoingLines.id &&
								edge.target.id !== cellToGetOutgoingLines.id
							);
						}) || []
					);
				};

				const findIncomingLines = (cell) => {
					return (
						cell.edges?.filter((edge) => {
							return (
								edge.source &&
								edge.target &&
								edge.source.id !== cell.id &&
								edge.target.id === cell.id
							);
						}) || []
					);
				};

				// Mousetrap.bind("mod+y", async (e) => {
				// 	e.preventDefault();
				// 	const currentNode = enc.encode(window.graph.getModel());
				//
				// 	const currentCanvasXML = window.mxUtils.getXml(
				// 		currentNode,
				// 		this.linefeed
				// 	);
				//
				// 	const { data } = await API(`/db/customQuery/canvas`, "POST", {
				// 		query: "findOne",
				// 		options: {
				// 			where: { id: this.state.currentCanvasID },
				// 			attributes: ["name"],
				// 		},
				// 	});
				//
				// 	downloadjs(currentCanvasXML, `${data.name}.xml`, "application/xml");
				// });
			}

			containerRightClicked = async (name) => {
				await this.loadCanvas(name);
			};

			intraCanvasPortalRightClicked = async (portal) => {
				const portalOut =
					portal.edges.length && portal.id === portal.edges[0].source.id;

				const portalIn =
					portal.edges.length && portal.id === portal.edges[0].target.id;

				if (!portalOut && !portalIn) {
					snackbarStore.setMessage(
						"Warning",
						"A connection line must be made on the portal before navigating to another canvas"
					);
				} else {
					const { data } = await API(
						`/db/customQuery/intraCanvasPortal?canvasKey=${this.canvasPassword}`,
						"POST",
						{
							query: "find",
							options: {
								where: { name: portal.value, type: portalOut ? "IN" : "OUT" },
								include: [{ model: "Canvas", attributes: ["name", "id"] }],
							},
						}
					);

					if (data.length) {
						await this.loadCanvas(data[0].canvas.name);

						const allCells = Object.values(window.graph.getModel().cells);
						const matchingPortal = allCells.find((cell) => {
							return `${data[0].cellID}` === cell.id;
						});

						if (matchingPortal) {
							window.graph.scrollCellToVisible(matchingPortal, true);
						}
					} else {
						snackbarStore.setMessage("Warning", "No matching portal found");
					}
				}
			};

			loadCanvas = async (name, canvasID, canvasChange = false) => {
				if (!name && !canvasID && !this.props.match.params.canvasID) {
					return;
				}
				if (canvasChange) {
					this.canvasHistory = [name];
				} else if (this.canvasHistory[this.canvasHistory.length - 1] !== name) {
					this.canvasHistory.push(name);
				}

				let data;

				if (canvasID) {
					const result = await API(
						`/db/canvas/${canvasID}?canvasKey=${this.canvasPassword}`,
						"GET"
					);
					data = result.data;
				} else if (name) {
					const result = await API(
						`/db/customQuery/canvas?canvasKey=${this.canvasPassword}`,
						"POST",
						{
							query: "findOne",
							options: { where: { name, projectID: 1 } },
						}
					);
					data = result.data;
				} else if (this.props.match.params.canvasID) {
					const urlParams = new URLSearchParams(window.location.search);
					const result = await API(
						`/db/${
							urlParams.get("saveHistory") ? "canvasSaveHistory" : "canvas"
						}/${this.props.match.params.canvasID}?canvasKey=${
							this.canvasPassword
						}`,
						"GET"
					);
					data = result.data;
				} else {
					alert("Something went wrong loading the canvas");
					return;
				}

				if (!data) {
					alert("Error loading canvas");
				} else {
					this.setState({
						currentCanvasID: data.id,
					});
					const doc = window.mxUtils.parseXml(data.content);
					const node = doc.documentElement;
					this.initialXML = node;
					this.xmlToCompareAtSave = data.content;

					window.editor.setGraphXml(node);
					if (appStore) {
						appStore.loaded = data.id;
					}
				}

				const allCells = Object.values(window.graph.getModel().cells);
				const currentMessage = allCells.find((cell) => {
					return cell.id === this.props.match.params.messageID;
				});

				if (currentMessage) {
					window.graph.scrollCellToVisible(currentMessage, true);
				}

				const isMessage = (obj) => {
					try {
						return obj.style && obj.style.includes("shape=messageRectangle;");
					} catch {
						return false;
					}
				};
				const allMessageCells = [];
				for (const cell of allCells) {
					if (isMessage(cell)) {
						allMessageCells.push(cell);
					}
				}
				const { messages } = await API(
					`/messageStatus?canvasKey=${this.canvasPassword}`,
					"POST",
					{
						messageNames: allMessageCells.map((cell) => {
							return cell.value;
						}),
					}
				);
				for (const message of messages) {
					const cell = allMessageCells.find((cell) => {
						return cell.value === message.name;
					});
					if (cell) {
						updateMessageColors(cell, message.status);
					}
				}

				const currentNode = enc.encode(window.graph.getModel());

				const currentCanvasXML = window.mxUtils.getXml(
					currentNode,
					this.linefeed
				);
				this.xmlToCompareAtSave = currentCanvasXML;
			};

			back = async () => {
				this.canvasHistory.pop();
				await this.loadCanvas(
					this.canvasHistory[this.canvasHistory.length - 1]
				);
			};

			arrange = (type) => {
				new window[type](window.graph).execute(window.graph.getDefaultParent());
				this.setState({ layoutMenu: null });
			};

			toggleSidebar() {
				const sidebar = document.getElementsByClassName("geFormatContainer")[0];

				const sidebarShowing = sidebar.style.display === "block";

				sidebar.style.display = sidebarShowing ? "none" : "block";
			}

			render() {
				return (
					<>
						<div id="geEditor" style={{ height: "100vh" }} />

						{this.canvasHistory.length > 1 ? (
							<Fab
								color="primary"
								aria-label="add"
								style={{ position: "fixed", zIndex: 1, top: 60, left: 85 }}
								onClick={this.back}
							>
								<FontAwesomeIcon icon={faChevronLeft} />
							</Fab>
						) : null}
						<Fab
							color="primary"
							aria-label="Zoom In"
							style={{
								position: "absolute",
								zIndex: 1,
								bottom: 75,
								left: 85,
							}}
							onClick={() => (window.graph ? window.graph.zoomIn() : null)}
						>
							<ZoomInIcon fontSize="large" />
						</Fab>
						<Fab
							color="primary"
							aria-label="Zoom Out"
							style={{ position: "absolute", zIndex: 1, bottom: 10, left: 85 }}
							onClick={() => (window.graph ? window.graph.zoomOut() : null)}
						>
							<ZoomOutIcon fontSize="large" />
						</Fab>
					</>
				);
			}
		}
	)
);

export const updateCell = (
	name,
	selectedCell = window.graph.getSelectionCell()
) => {
	window.graph.getModel().beginUpdate();
	window.graph.cellLabelChanged(selectedCell, name, false);
	window.graph.getModel().endUpdate();
};

export const updateMessageColors = (cell, messageStatus) => {
	window.graph.getModel().beginUpdate();
	if (messageStatus === "Backlog") {
		window.graph.setCellStyles("fillColor", "#16B1F0", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "To Do") {
		window.graph.setCellStyles("fillColor", "#EF5F2F", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "Doing") {
		window.graph.setCellStyles("fillColor", "#390A4E", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "In Process") {
		window.graph.setCellStyles("fillColor", "#F2A524", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "Roadblocked") {
		window.graph.setCellStyles("fillColor", "#D1383F", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "Done") {
		window.graph.setCellStyles("fillColor", "#7FAF41", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "In Testing") {
		window.graph.setCellStyles("fillColor", "#C6C6C6", [cell]);
		window.graph.setCellStyles("fontColor", "#000", [cell]);
	} else if (messageStatus === "Approved") {
		window.graph.setCellStyles("fillColor", "#2F3D47", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "In Review") {
		window.graph.setCellStyles("fillColor", "#764899", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	}
	window.graph.getModel().endUpdate();
};

export default CanvasViewer;
