/* istanbul ignore file */
// example of custom component with Webix UI inside
// this one is a static view, not linked to the React data store

import React, { Component } from "react";
import ReactDOM from "react-dom";
import "@xbs/querybuilder";
import "@xbs/querybuilder/querybuilder.min.css";

class Webix extends Component {
	constructor() {
		super();

		window.webix.protoUI(
			{
				name: "edittree",
			},
			window.webix.EditAbility,
			window.webix.ui.tree
		);
	}
	// state = { webixID: shortid.generate() };

	render() {
		return (
			<div
				onClick={this.props.onClick}
				className={`webixComponent ${this.props.className || ""}`}
				id={this.props.id}
				style={this.props.style}
				ref="root"
			/>
		);
	}

	setWebixData(data) {
		data = JSON.parse(JSON.stringify(data));
		const ui = this.ui;
		if (ui.setValues) {
			ui.setValues(data);
		} else if (ui.parse) {
			ui.parse(data);
		} else if (ui.setValue) {
			ui.setValue(data);
		}
	}

	componentWillUnmount() {
		if (this.ui) {
			this.ui.destructor();
		}
		this.ui = null;
	}

	componentDidUpdate(prevProps = {}, prevState, snapshot) {
		if (
			this.props.data &&
			JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
		) {
			this.setWebixData(this.props.data);
		}
		if (this.props.select) {
			this.select(this.props.select);
		}
		// if (props.ui.rows) {
		// 	if (!isEqual(props.ui.rows, this.props.ui.rows)) {
		// 		console.log("not equal", props, this.props);
		// 		webix.ui(
		// 			{
		// 				rows: props.ui.rows
		// 			},
		// 			$$(this.state.webixID)
		// 		);
		// 	} else {
		// 	}
		// }
	}

	componentDidMount() {
		this.ui = window.webix.ui({
			// id: this.state.webixID,
			container: ReactDOM.findDOMNode(this.refs.root),
			...this.props.ui,
		});

		this.componentDidUpdate();

		window.webix.event(window, "resize", () => {
			if (this.ui) {
				this.ui.adjust();
			}
		});

		if (this.props.elementUI) {
			this.props.elementUI(this.ui, this.props.name);
		}
	}
}

export default Webix;
