import React, { Component } from "react";
import API from "../../../API";
import CircularProgress from "@material-ui/core/CircularProgress";
import { appStore } from "../../../App";
import snackbarStore from "../../../lib/SnackbarStore";
import Elements from "./Elements";
import Attributes from "./Attributes/Attributes";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Technical from "./Technical";
import Testing from "./Testing";
import Properties from "./Properties";
import firebase from "firebase/app";
import { withSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import client from "../../../lib/feathers";

const isNumber = (n) => {
	try {
		const asNumber = parseInt(n);
		return n !== null && !isNaN(asNumber) && isFinite(asNumber);
	} catch (error) {
		return false;
	}
};

export default withSnackbar(
	class RightPane extends Component {
		state = {
			loading: true,
			selectedTab: this.props.location.state?.selectedTab || "elements",
		};

		componentDidMount() {
			if (
				this.props.match.params.messageID &&
				isNumber(this.props.match.params.messageID)
			) {
				this.loadMessage();
			} else if (!isNumber(this.props.match.params.messageID)) {
				this.props.enqueueSnackbar("Invalid URL, redirecting to the Canvas", {
					variant: "warning",
				});
				this.props.history.replace(
					`/canvas/${this.props.match.params.canvasID}`
				);
			}
		}

		componentDidUpdate(prevProps, prevState, snapshot) {
			if (
				isNumber(this.props.match.params.messageID) &&
				this.props.match.params.messageID !== prevProps.match.params.messageID
			) {
				this.loadMessage();
			}
		}

		async loadMessage() {
			this.setState({ loading: true });
			if (!isNumber(this.props.match.params.messageID)) {
				this.props.enqueueSnackbar("Invalid URL, redirecting to the Canvas", {
					variant: "warning",
				});
				return this.props.history.replace(
					`/canvas/${this.props.match.params.canvasID}`
				);
			}
			const { data } = await API(`/db/customQuery/message`, "POST", {
				query: "findOne",
				options: {
					where: {
						canvasID: this.props.match.params.canvasID,
						shapeID: this.props.match.params.messageID,
					},
					include: [{ model: "Canvas", attributes: ["name"] }],
				},
			});

			if (data && data.id) {
				setTimeout(async () => {
					//MZM 4-27-21 This only works if it's in a set timeout
					//Spent **hours** trying to figure out why and hit a dead end.
					//If you can figure out how to get it to work, let Max know.
					await client
						.service("channelManager")
						.create({ name: `message/${data.id}` });
				}, 2000);

				this.props.setPaneTitle(
					<>
						<h6 style={{ flexGrow: 1 }}>
							<span style={{ fontWeight: 700 }}>{data.name}</span>{" "}
							<span
								style={{ color: "#d6d6d6", cursor: "pointer" }}
								onClick={() => {
									this.props.history.push(
										`/canvas/${this.props.match.params.canvasID}`
									);
								}}
							>
								({data.canvas.name})
							</span>
						</h6>
						<IconButton
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={() => {
								if (this.props.match.params.messageID) {
									this.props.history.push(
										`/${this.props.view}/${this.props.match.params.canvasID}/${this.props.match.params.messageID}/prototype?messageID=${this.state.messageDBID}`
									);
								}
							}}
						>
							<PlayCircleOutlineIcon style={{ cursor: "pointer" }} />
						</IconButton>
					</>
				);

				this.setState({
					loading: false,
					messageDBID: data.id,
					name: data.name,
					messageListenerAttributeID: data.listenerAttributeID,
					message: data,
					messageHasCheckboxes: data.checkboxes,
				});

				firebase.analytics().logEvent("message_opened", {
					messageDBID: data.id,
				});

				// Make sure the app store's project is synced with this project
				// For example, if a user loads a message pane via a link (vs. navigating through the app)
				// for a message that belongs to Foo Project, but their active Project is Bar Project,
				// then we need to set their active Project to the Foo Project
				if (
					appStore.selectedProject &&
					appStore.selectedProject !== data.projectID
				) {
					try {
						const project = await API(
							`/userActiveProject`,
							"POST",
							{ activeProject: data.projectID },
							(err) => {
								throw err;
							}
						);
						appStore.selectedProject = project.project.id;
						appStore.selectedProjectName = project.project.name;
					} catch (err) {
						// This will throw a 401 (unauthorized) error if the user is not authenticated
						// in which case, we redirect them to the root canvas view, which will load up the project
						// that they do have access to (if possible)
						console.error(err);
						const errMessage = `You do not have access to this project.${
							err?.project?.name ? ` Redirecting to ${err?.project?.name}` : ""
						}`;
						snackbarStore.setMessage("Error", errMessage);
						alert(errMessage);
						this.props.history.push("/canvas/");
					}
				}
			} else {
				const selectedCells = window.graph.getSelectionCells();
				//sometimes the graph wants to play games so we rely on local storage
				//as a backup
				const name =
					selectedCells[0]?.value ||
					window.localStorage.getItem("lastClickedMessageName");
				if (name) {
					const { data } = await API(`/db/customQuery/message`, "POST", {
						query: "findOne",
						options: {
							where: {
								canvasID: this.props.match.params.canvasID,
								name,
							},
							raw: true,
						},
					});
					if (data) {
						//the message exists in the DB, just with a different shape ID
						//update it in the DB then reload the message
						data.shapeID = this.props.match.params.messageID;
						await API(`/db/message`, "POST", data);
						this.loadMessage();
					} else {
						const { data } = await API(`/db/customQuery/message`, "POST", {
							query: "findOne",
							options: {
								where: {
									canvasID: this.props.match.params.canvasID,
									shapeID: this.props.match.params.messageID,
								},
								paranoid: false,
								raw: true,
								attributes: ["id"],
							},
						});

						if (data) {
							//the shape exists on the canvas because we just double clicked it,
							//the message got deleted in the DB, so just restore it
							await API(`/db/restore/message/${data.id}`, "POST");
							this.loadMessage();
						} else {
							const { data } = await API(`/db/customQuery/message`, "POST", {
								query: "findOne",
								options: {
									raw: true,
									where: {
										projectID: appStore.selectedProject,
										name,
									},
								},
							});

							if (data) {
								//The message already exists on this project but on a different canvas.
								//Double check the shapes table to make sure the message is actually on that canvas.
								const shapeData = await API(`/db/customQuery/shape`, "POST", {
									query: "findOne",
									options: {
										raw: true,
										attributes: ["canvasID", "id"],
										where: {
											name,
										},
									},
								});

								if (
									shapeData.data?.id &&
									shapeData.data?.canvasID !== this.props.match.params.canvasID
								) {
									//The message table is out of sync with the canvas XML & shapes
									//Auto fix it and reopen the message
									await API(`/db/message`, "POST", {
										id: data.id,
										canvasID: this.props.match.params.canvasID,
										shapeID: this.props.match.params.messageID,
									});

									this.loadMessage();
								} else {
									//Everything is in sync and we can't auto heal the messages
									//table. Throw an error and wait for someone to report an issue.
									this.props.enqueueSnackbar(
										"Message name exists on this project on another canvas. Please contact support",
										{
											variant: "error",
										}
									);
								}
							} else {
								//the message doesn't exist in the DB but the name is free, so
								//just create it
								const { error } = await API(`/message`, "POST", {
									name,
									canvasID: this.props.match.params.canvasID,
									shapeID: this.props.match.params.messageID,
									projectID: appStore.selectedProject,
								});

								if (!error) {
									this.loadMessage();
								} else {
									//our self healing failed, throw an error message
									this.props.enqueueSnackbar("Error loading message", {
										variant: "error",
									});
								}
							}
						}
					}
				} else {
					this.props.enqueueSnackbar("Error loading message", {
						variant: "error",
					});
				}
			}
		}

		renderPanel = () => {
			switch (this.state.selectedTab) {
				case "attributes":
					return (
						<Attributes
							type={"message"}
							messageDBID={this.state.messageDBID}
							enqueueSnackbar={this.props.enqueueSnackbar}
						/>
					);
				case "elements":
					return (
						<Elements
							enqueueSnackbar={this.props.enqueueSnackbar}
							closeSnackbar={this.props.closeSnackbar}
							messageDBID={this.state.messageDBID}
							commentElementID={this.props.location?.state?.commentElementID}
							history={this.props.history}
							location={this.props.location}
							match={this.props.match}
						/>
					);
				case "technical":
					return (
						<Technical
							enqueueSnackbar={this.props.enqueueSnackbar}
							messageDBID={this.state.messageDBID}
							messageListenerAttributeID={this.state.messageListenerAttributeID}
							setMessageListenerAttributeID={(id) => {
								this.setState({ messageListenerAttributeID: id });
							}}
							messageHasCheckboxes={this.state.messageHasCheckboxes}
							setMessageHasCheckboxes={(bool) =>
								this.setState({ messageHasCheckboxes: bool })
							}
							message={this.state.message}
							setSurveyMode={(val) => {
								this.setState({
									message: { ...this.state.message, surveyMode: val },
								});
							}}
						/>
					);
				case "testing":
					return <Testing messageDBID={this.state.messageDBID} />;
				case "properties":
					return (
						<Properties
							enqueueSnackbar={this.props.enqueueSnackbar}
							message={this.state.message}
							messageDBID={this.state.messageDBID}
							messageName={this.state.name}
							setMessageName={(name) => {
								this.setState({ name });
							}}
							setMessageOwner={(messageOwner) => {
								this.setState({
									message: { ...this.state.message, messageOwner },
								});
							}}
							history={this.props.history}
						/>
					);
				default:
					return <p>Select a tab above</p>;
			}
		};

		render() {
			return this.state.loading ? (
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						height: "100%",
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<div style={{ margin: "25px 50px 0 50px" }}>
					{/*

						//This now automatically happens on closing of the dialog from hell...
					<FontAwesomeIcon
						icon={faMapPin}
						style={{ cursor: "pointer", marginLeft: 5, marginRight: 5 }}
						onClick={() => {
							const allCells = Object.values(window.graph.getModel().cells);
							const matchingShape = allCells.find((cell) => {
								return (
									this.state.name === cell.value &&
									cell.style &&
									cell.style.includes("shape=messageRectangle;")
								);
							});

							if (matchingShape) {
								window.graph.scrollCellToVisible(matchingShape, true);
							} else {
								this.props.enqueueSnackbar(
									"We couldn't find the shape. Looks like you either navigated to a different canvas or we got lost on the canvas. Anyone got a GPS?",
									{
										variant: "error",
									}
								);
							}
						}}
					/>
					*/}
					<div style={{ justifyContent: "center", display: "flex" }}>
						<Tabs
							value={this.state.selectedTab}
							onChange={(evt, value) => {
								this.setState({ selectedTab: value });
							}}
							variant="scrollable"
							scrollButtons="auto"
							indicatorColor="primary"
						>
							<Tab label="Attributes" value="attributes" />
							<Tab label="Elements" value="elements" />
							<Tab label="Technical" value="technical" />
							<Tab label="Testing" value="testing" />
							<Tab label="Properties" value="properties" />
						</Tabs>
					</div>
					{this.renderPanel()}
				</div>
			);
		}
	}
);
