import { observer } from "mobx-react";
import React, { Component } from "react";
import { Field, Formik } from "formik";
import API from "../../../../API";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import Attributes from "../../right/Attributes/Attributes";
import Button from "@material-ui/core/Button";
import RedButton from "../../../../lib/RedButton";
import { checkBrancherRules, pageStore, updateCell } from "../Canvas";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { BrancherRuleContainer } from "./BrancherRuleBuilder";
import client from "../../../../lib/feathers";

class NameAndNotesSection extends Component {
	componentDidMount() {
		setTimeout(() => {
			if (this.autoFocus) {
				this.autoFocus.focus();
			}
		}, 1000);
	}

	render() {
		return (
			<form
				onSubmit={this.props.formikProps.handleSubmit}
				style={{ width: "100%" }}
			>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Field
							name="name"
							label="Brancher Name"
							variant="filled"
							type="text"
							component={TextField}
							error={!!this.props.formikProps.errors.name}
							helperText={this.props.formikProps.errors.name || ""}
							inputProps={{
								ref: (el) => {
									this.autoFocus = el;
								},
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="notes"
							label="Notes"
							variant="filled"
							type="text"
							component={TextField}
							disabled
						/>
						<FormHelperText id="notes">Optional</FormHelperText>
					</Grid>
				</Grid>
			</form>
		);
	}
}

const RulesPanel = observer(
	class RulesPanel extends Component {
		state = {
			outgoingLines: null,
			attributesData: null,
			rulesToRender: [],
			branchers: [],
		};

		async componentDidMount() {
			const brancher = this.props.selectedCell;
			let outgoingLines = [];
			if (brancher.edges) {
				outgoingLines = brancher.edges
					.filter((edge) => {
						return edge.source.id === brancher.id;
					})
					.map((edge) => {
						return { name: edge.target.value, id: edge.target.id };
					});
			}

			const { data } = await API(
				`/brancherRulesData/${this.props.currentCanvasID}/${brancher.id}`,
				"GET"
			);

			let branchers = [];
			for (const line of outgoingLines) {
				let brancherDBs = await client.service("brancher").find({
					query: {
						canvasID: this.props.currentCanvasID,
						brancherCanvasID: this.props.selectedCell.id,
						shapeTargetCanvasID: line.id,
					},
				});
				if (brancherDBs.length === 0) {
					const newBrancher = await client.service("brancher").create({
						canvasID: this.props.currentCanvasID,
						brancherCanvasID: this.props.selectedCell.id,
						shapeTargetCanvasID: line.id,
					});
					await client.service("brancherRuleV2").create({
						brancherID: newBrancher.id,
						parentGroupID: null,
						order: 0,
					});
					branchers.push(newBrancher);
				} else {
					branchers.push(brancherDBs[0]);
				}
				client
					.service("channelManager")
					.create({ name: `brancher/${branchers[branchers.length - 1].id}` });
			}

			client
				.service("brancher")
				.on("created", (newRow) => {
					this.setState({ branchers: [...this.state.branchers, newRow] });
				})
				.on("removed", (deleted) => {
					const toDelete = this.state.branchers.findIndex(
						(item) => item.id === deleted.id && !item.group
					);
					if (toDelete !== -1) {
						const toSet = [...this.state.branchers];
						toSet.splice(toDelete, 1);
						this.setState({ branchers: toSet });
					}
				})
				.on("patched", async (patched) => {
					const toPatch = this.state.branchers.findIndex(
						(item) => item.id === patched.id
					);
					if (toPatch !== -1) {
						const toSet = [...this.state.branchers];
						toSet[toPatch] = patched;
						this.setState({
							branchers: toSet,
							outgoingLines: this.state.outgoingLines,
						});
					}
				});

			this.setState({
				attributesData: data.map((attribute) => attribute.attribute),
				outgoingLines,
				branchers,
			});
		}

		componentWillUnmount() {
			for (const brancher of this.state.branchers) {
				client
					.service("channelManager")
					.remove({ name: `brancher/${brancher.id}` });
			}
			client
				.service("brancher")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		}

		buildRules = () => {
			return this.state.branchers.map((brancher) => {
				const line = this.state.outgoingLines.find((line) => {
					return `${brancher.shapeTargetCanvasID}` === line.id;
				});
				return (
					<Paper
						elevation={10}
						key={line.id}
						style={{
							marginTop: 15,
							marginBottom: 15,
							border: "1px solid black",
							padding: 10,
						}}
					>
						<h4 style={{ display: "inline" }}>To: {line.name}</h4>
						<FormControlLabel
							style={{ marginLeft: 15 }}
							control={
								<Checkbox
									checked={brancher.else}
									onChange={(event) => {
										if (event.target.checked) {
											for (const brancherLoop of this.state.branchers) {
												if (brancherLoop.id === brancher.id) {
													client
														.service("brancher")
														.patch(brancherLoop.id, { else: true });
												} else {
													client
														.service("brancher")
														.patch(brancherLoop.id, { else: false });
												}
											}
										} else {
											client
												.service("brancher")
												.patch(brancher.id, { else: false });
										}
									}}
								/>
							}
							label="Else"
						/>
						{brancher.else ? null : (
							<BrancherRuleContainer
								line={line}
								brancher={brancher}
								attributes={this.state.attributesData}
							/>
						)}
					</Paper>
				);
			});
		};

		renderRuleBuilders() {
			if (
				this.state.outgoingLines === null ||
				this.state.attributesData === null
			) {
				return (
					<div style={{ textAlign: "center" }}>
						<CircularProgress />
					</div>
				);
			} else if (this.state.outgoingLines.length === 0) {
				return <p>There are no outgoing lines connected to this brancher.</p>;
			} else {
				return this.buildRules();
			}
		}

		render() {
			return (
				<div id="querybuilder" style={{ width: "100%" }}>
					{this.renderRuleBuilders()}
				</div>
			);
		}
	}
);

const NewBrancherDialog = observer(
	class extends Component {
		state = {
			nameExpanded: true,
			rulesExpanded: false,
			attributesExpanded: false,
		};

		close = () => {
			pageStore.mode === "New" &&
				window.graph.removeCells([pageStore.selectedCell]);
			pageStore.BrancherDialogOpen = false;
		};

		redOutline = async () => {
			const cell = pageStore.selectedCell;

			//MZM 4/2/21
			//We rerun this query even though the rules data could already be in state
			//because it's possible the rules panel wasn't expanded, and therefore not
			//already loaded
			//This will likely be scraped when we move to the new canvas, so don't
			//overthink it too much.
			const branchers = await client.service("brancher").find({
				query: {
					canvasID: this.state.currentCanvasID,
					brancherCanvasID: cell.id,
					include: ["BrancherRuleV2"],
				},
			});

			const hasRules = checkBrancherRules(branchers, cell);

			if (!hasRules) {
				window.graph.setCellStyles("strokeWidth", "4", [cell]);
				window.graph.setCellStyles("strokeColor", "#FF331C", [cell]);
			} else {
				window.graph.setCellStyles("strokeWidth", "1", [cell]);
				window.graph.setCellStyles("strokeColor", "#000", [cell]);
			}
		};

		render() {
			let name = pageStore.selectedCell ? pageStore.selectedCell.value : "";

			//MZM 5-27-21 XML hates < or > signs, so we have to unescape it sometimes
			//This can be removed when we migrate off mxGraph
			try {
				if (name.includes("&lt;")) {
					name = name.replace("&lt;", "<")
				}
			} catch{
				//ignore, &lt; showing up isn't a huge deal considering we're migrating
				//off XML
			}

			return (
				<Formik
					onSubmit={async (values, { setSubmitting, setErrors }) => {
						setSubmitting(false);
						updateCell(values.name);
						this.redOutline();
						pageStore.BrancherDialogOpen = false;
					}}
					initialValues={{
						name,
						notes: "",
					}}
					validationSchema={Yup.object().shape({
						name: Yup.string().required("Required").max(255, "Too long"),
					})}
				>
					{(props) => {
						return (
							<Dialog
								onClose={this.props.close}
								open={true}
								fullWidth
								maxWidth="lg"
							>
								<DialogContent>
									<h3>{pageStore.mode} Brancher</h3>
									<div>
										<Accordion
											expanded={this.state.nameExpanded}
											style={{
												border: this.state.nameExpanded
													? "1px solid black"
													: "none",
											}}
											onChange={() => {
												this.setState({
													nameExpanded: !this.state.nameExpanded,
												});
											}}
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												Name & Notes
											</AccordionSummary>
											<AccordionDetails>
												<NameAndNotesSection formikProps={props} />
											</AccordionDetails>
										</Accordion>
										<Accordion
											style={{
												border: this.state.attributesExpanded
													? "1px solid black"
													: "none",
											}}
											expanded={this.state.attributesExpanded}
											onChange={() => {
												this.setState({
													attributesExpanded: !this.state.attributesExpanded,
												});
											}}
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												Attributes
											</AccordionSummary>
											<AccordionDetails style={{ display: "block" }}>
												<Attributes
													type="brancher"
													currentCanvasID={this.props.currentCanvasID}
													cellId={this.props.selectedCell.id}
												/>
											</AccordionDetails>
										</Accordion>
										<Accordion
											style={{
												border: this.state.rulesExpanded
													? "1px solid black"
													: "none",
											}}
											expanded={this.state.rulesExpanded}
											onChange={() => {
												this.setState({
													rulesExpanded: !this.state.rulesExpanded,
												});
											}}
											TransitionProps={{ unmountOnExit: true }}
										>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												Rules
											</AccordionSummary>
											<AccordionDetails>
												<RulesPanel
													currentCanvasID={this.props.currentCanvasID}
													selectedCell={this.props.selectedCell}
												/>
											</AccordionDetails>
										</Accordion>
									</div>
								</DialogContent>
								<DialogActions>
									<Grid container>
										<Grid item xs={7} />
										<Grid item xs={2}>
											<Button onClick={this.close}>Cancel</Button>
										</Grid>
										<Grid item xs={1} />
										<Grid item xs={2}>
											<RedButton
												autoFocus
												color="primary"
												type="submit"
												onClick={props.submitForm}
											>
												Save
											</RedButton>
										</Grid>
									</Grid>
								</DialogActions>
							</Dialog>
						);
					}}
				</Formik>
			);
		}
	}
);

export default NewBrancherDialog;
