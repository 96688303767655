import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Dropzone from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import API from "../../API";
import snackbarStore from "../../lib/SnackbarStore";
import { papaDecorator } from "./AttributeBuilder/attributeImporter";
import Papa from "papaparse";
import downloadjs from "downloadjs";
import client from "../../lib/feathers";
import { nanoid } from "nanoid";
import CircularProgressWithLabel from "../../lib/CircularProgressWithLabel";

const TranscriptTesting = () => {
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);

	const onDrop = async (files) => {
		const uploadID = nanoid();
		setUploading(true);
		const rows = await papaDecorator(files[0], false);

		client.service("channelManager").create({ name: `testing/${uploadID}` });

		let usersMessages = [];
		let caseNames = [];

		//This is if we decide to use attribute names instead of sending each message
		// for (const [rowIndex, row] of rows.entries()) {
		// 	let attributeName = "";
		// 	for (const [colIndex, col] of row.entries()) {
		// 		if (rowIndex === 0) {
		// 			if (colIndex !== 0) {
		// 				caseNames.push(col);
		// 				usersMessages.push({});
		// 			}
		// 		} else {
		// 			if (colIndex === 0) {
		// 				attributeName = col;
		// 			} else {
		// 				usersMessages[colIndex - 1][attributeName] = col;
		// 			}
		// 		}
		// 	}
		// }

		for (const [rowIndex, row] of rows.entries()) {
			for (const [colIndex, col] of row.entries()) {
				if (rowIndex === 0) {
					caseNames.push(col);
					usersMessages.push([""]);
				} else {
					usersMessages[colIndex].push(col);
				}
			}
		}

		const { error, data } = await API(`/transcriptTest`, "POST", {
			usersMessages,
			caseNames,
			uploadID,
		});

		setUploading(false);
		setProgress(0);
		client.service("channelManager").remove({ name: `testing/${uploadID}` });

		if (!error) {
			const csv = Papa.unparse(data);
			downloadjs(
				`\uFEFF${csv}`, //this character is required to force Excel into UTF8
				"export.csv",
				"text/csv;charset=utf-8"
			);
			snackbarStore.setMessage("Success", "Transcript downloaded");
		} else {
			snackbarStore.setMessage(
				"Error",
				`Error generating transcripts. Contact support.`
			);
		}
	};

	useEffect(() => {
		client.service("testing").on("created", ({ currentCase, total }) => {
			setProgress((currentCase / total) * 100);
		});

		return () => {
			client.service("channelManager").remove({ name: `testing/*` });
			client.service("channelManager").removeListener("created");
		};
	}, []);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Paper style={{ padding: 15 }}>
						{uploading ? (
							<div style={{ textAlign: "center", padding: 15 }}>
								<CircularProgressWithLabel value={progress} />
							</div>
						) : (
							<Dropzone
								onDrop={onDrop}
								accept=".csv, application/vnd.ms-excel, text/csv"
							>
								{({ getRootProps, getInputProps }) => (
									<section>
										<div
											{...getRootProps()}
											style={{
												width: "100%",
												height: 75,
												borderWidth: 2,
												borderColor: "darkGray",
												borderStyle: "dashed",
												borderRadius: 5,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<input {...getInputProps()} />
											<p>
												Drag 'n' drop some files here, or click to select files
											</p>
										</div>
									</section>
								)}
							</Dropzone>
						)}
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default TranscriptTesting;
