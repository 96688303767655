import React, { useState, useEffect } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import API from "../../../API";
import { appStore } from "../../../App";
import RedButton from "../../../lib/RedButton";
import {
	TreeStore,
	deleteEntry,
	deleteFolder,
	editEntry,
	editFolder,
	Key,
	keyToId,
	loadTreeData,
	moveEntry,
	moveFolder,
	moveMany,
	resetDetail,
	searchEntries,
} from "./store";
import {
	EntryDialog,
	EditEntryDialog,
	FolderDialog,
	EditFolderDialog,
} from "./GlossaryDialogs";
import { EditGlossary } from "./EditGlossary";
import { MoveToContextMenu, MoveToDialog } from "./MoveTo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faInboxIn,
	faInboxOut,
} from "@fortawesome/pro-solid-svg-icons";
import Papa from "papaparse";
import downloadjs from "downloadjs";
import snackbarStore from "../../../lib/SnackbarStore";
import Dropzone from "react-dropzone";
import CircularProgressWithLabel from "../../../lib/CircularProgressWithLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { reset } from "./store";
import { papaDecorator } from "../AttributeBuilder/attributeImporter";

const exportData = async () => {
	const { data } = await API(`/glossaryExport`, "GET");
	const csv = Papa.unparse(data);
	return downloadjs(csv, "export.csv", "text/csv");
};

export const Glossary = observer(() => {
	const { init, loading, selected } = TreeStore.tree;
	const searchMode = Boolean(TreeStore.search.query?.length);

	useEffect(() => {
		if (!init) {
			loadTreeData(null)
				.then(() => true)
				.catch((error) => {
					throw error;
				});
		}
	}, [init]);

	const onSearch = action((value) => {
		TreeStore.search.query = value;
		if (value.length) {
			TreeStore.search.loading = true;
			searchEntries(TreeStore, value);
		} else {
			TreeStore.search.loading = false;
			TreeStore.search.query = "";
			TreeStore.search.selectedId = null;
		}
	});

	const onImportComplete = () => {
		TreeStore.tree.init = true;
		reset(TreeStore);
		loadTreeData(null);
	};

	return (
		<div>
			<Grid container spacing={1}>
				<Grid item xs={10}>
					<h3 style={{ textDecoration: "underline" }}>Glossary</h3>
				</Grid>
				<Grid item xs={2} style={{ textAlign: "right" }}>
					<FontAwesomeIcon
						onClick={action(() => {
							TreeStore.modal.uploadOpen = true;
						})}
						icon={faInboxOut}
						style={{ fontSize: 20, marginRight: 15 }}
						className="clickable"
					/>
					<FontAwesomeIcon
						onClick={exportData}
						icon={faInboxIn}
						className="clickable"
						style={{ fontSize: 20 }}
					/>
				</Grid>
				<Grid item xs={6}>
					<Card style={{ padding: 15 }}>
						<TextField
							onChange={(e) => onSearch(e.target.value)}
							value={TreeStore.search.query}
							label="Search"
							autoFocus
							fullWidth
						/>
					</Card>
					<Card style={{ marginTop: 15, marginBottom: 15 }}>
						<CardContent
							style={{ maxHeight: "calc(100vh - 400px)", overflow: "auto" }}
						>
							{searchMode ? (
								<GlossarySearchResults
									loading={TreeStore.search.loading}
									entries={TreeStore.search.data}
									selectedId={TreeStore.search.selectedId}
								/>
							) : (
								<GlossaryTree
									treeData={TreeStore.tree.treeData}
									selectedKeys={selected}
									loadedKeys={Object.values(TreeStore.tree.loadedKeys)}
								/>
							)}
						</CardContent>
						<CardActions>
							{!loading && init ? (
								<GlossaryActions
									selected={selected}
									searchMode={searchMode}
									searchSelectedId={TreeStore.search.selectedId}
								/>
							) : null}
						</CardActions>
					</Card>
				</Grid>
				<Grid item xs={6}>
					{TreeStore.detail.open && TreeStore.detail.isEntry ? (
						<EditGlossary
							id={TreeStore.detail.id}
							data={TreeStore.detail.data}
							loading={TreeStore.detail.loading}
						/>
					) : null}
				</Grid>
			</Grid>
			{TreeStore.modal.entryOpen ? (
				<EntryDialog selected={selected} />
			) : TreeStore.modal.folderOpen ? (
				<FolderDialog selected={selected} />
			) : TreeStore.modal.editFolderOpen ? (
				<EditFolderDialog
					data={TreeStore.detail.data}
					loading={TreeStore.detail.loading}
				/>
			) : TreeStore.modal.editEntryOpen ? (
				<EditEntryDialog
					data={TreeStore.detail.data}
					loading={TreeStore.detail.loading}
				/>
			) : TreeStore.modal.uploadOpen ? (
				<UploadDialog onImportComplete={onImportComplete} />
			) : null}
		</div>
	);
});

export default Glossary;

const GlossaryTree = observer((props) => {
	const [moveToAnchor, setMoveToAnchor] = useState(null);
	const [moveDialogOpen, setMoveDialogOpen] = useState(false);
	const [moveToTitle, setMoveToTitle] = useState(null);
	const [moveExcludeIDs, setMoveExcludeIDs] = useState([]);

	const onSelect = action((_, { nativeEvent, node }) => {
		if (!node) {
			TreeStore.tree.selected = [];
			return;
		}
		// Handle deselect & select (respectively)
		const selectedKeys = TreeStore.tree.selected.includes(node.key)
			? TreeStore.tree.selected.filter((key) => key !== node.key)
			: TreeStore.tree.selected.concat(node.key);
		// Allow multiple selections when ctrl, alt, or cmd are pressed
		const selected = !(nativeEvent.ctrlKey || nativeEvent.metaKey)
			? selectedKeys.slice(-1)
			: [...new Set(selectedKeys)];
		TreeStore.tree.selected = selected;
		const isEntry = Key.isEntry(node.key);
		if (isEntry && selected.length === 1) {
			return editEntry(TreeStore, keyToId(node.key));
		} else if (TreeStore.detail.open) {
			return resetDetail(TreeStore);
		}
	});

	const onDoubleClick = action((event, node) => {
		const isEntry = Key.isEntry(node.key);
		const modalProp = isEntry ? "editEntryOpen" : "editFolderOpen";
		TreeStore.modal[modalProp] = true;
		const callback = isEntry ? editEntry : editFolder;
		return callback(TreeStore, Key.toId(node.key));
	});

	const onRightClick = action(({ event, node }) => {
		// Add the clicked node.key to the list of selected keys, grab the selected
		// entry & folder names so we can display them in the "Move To" dialog,
		// & set the anchor so the menu displays
		TreeStore.tree.selected = TreeStore.tree.selected
			.filter((key) => key !== node.key)
			.concat(node.key);
		setMoveToAnchor(event.target);
	});

	const onDrop = ({ event, node, dragNode, dragNodesKeys, ...args }) => {
		const dragKey = dragNode.key; // Node that was moved
		// Drop key will be the closest relative in the tree
		// Ie. it will either be the sibling or the parent
		// Either way, we need the parent so we can move the Glossary
		// Entry under the right parentFolder
		const dropKey = node.key; // Key for the new parent
		const parentFolderID = !dropKey.includes("entry")
			? keyToId(dropKey)
			: Number(node.parentFolderID);
		const callback = dragKey.includes("folder-") ? moveFolder : moveEntry;
		return callback(TreeStore, keyToId(dragKey), parentFolderID);
	};

	const onMoveOpen = (open) => {
		setMoveDialogOpen(open);
		if (open) {
			const movingNames = TreeStore.tree.selected
				.map((key) =>
					Key.isEntry(key) ? Key.getEntry(key)?.name : Key.getFolder(key)?.name
				)
				.join(", ");
			setMoveToTitle(`Move ${movingNames}`);
			setMoveExcludeIDs(
				TreeStore.tree.selected.filter(Key.isFolder).map(Key.toId)
			);
		}
	};

	const onMoveClose = () => {
		setMoveToAnchor(null);
		setMoveDialogOpen(false);
	};

	const onMoveSave = async (parentFolderID) => {
		if (!parentFolderID) {
			onMoveClose();
			return Promise.resolve(null);
		}
		const selected = TreeStore.tree.selected;
		const glossaryEntryFolderIDs = selected.filter(Key.isFolder).map(Key.toId);
		const glossaryEntryIDs = selected.filter(Key.isEntry).map(Key.toId);
		return moveMany(TreeStore, {
			glossaryEntryIDs,
			glossaryEntryFolderIDs,
			parentFolderID,
		}).then(onMoveClose, onMoveClose);
	};

	const loadFolders = () =>
		API(`/db/customQuery/glossaryEntryFolder`, "POST", {
			query: "find",
			options: {
				where: {
					projectID: [Number(appStore.selectedProject), null],
				},
			},
		}).then(({ data }) => data);

	const loadBranch = (treeNode) => loadTreeData(keyToId(treeNode.key));

	const { treeData } = TreeStore.tree;
	return (
		<div>
			<style dangerouslySetInnerHTML={{ __html: STYLE }} />
			{!treeData.length ? (
				<div style={{ textAlign: "center" }}>
					<CircularProgress />
				</div>
			) : (
				<Tree
					loadData={loadBranch}
					treeData={treeData}
					onSelect={onSelect}
					onDoubleClick={onDoubleClick}
					allowDrop={allowDrop}
					onDrop={onDrop}
					onRightClick={onRightClick}
					motion={motion}
					draggable={TreeStore.tree.selected.length === 1}
					multiple
					showLine
					{...props}
				/>
			)}
			{moveToAnchor && TreeStore.tree.selected.length ? (
				<MoveToContextMenu
					anchorEl={moveToAnchor}
					open={moveDialogOpen}
					setOpen={onMoveOpen}
					onClose={() => {
						setMoveToAnchor(null);
					}}
				/>
			) : null}
			{moveDialogOpen && TreeStore.tree.selected.length ? (
				<MoveToDialog
					onClose={onMoveClose}
					onSave={onMoveSave}
					loadFolders={loadFolders}
					title={moveToTitle}
					excludeIDs={moveExcludeIDs}
				/>
			) : null}
		</div>
	);
});

//-- Tree Helpers
const STYLE = `
    .rc-tree-child-tree {
      display: block;
    }

    .node-motion {
      transition: all .3s;
      overflow-y: hidden;
    }
`;

const motion = {
	motionName: "node-motion",
	motionAppear: false,
	onAppearStart: (node) => {
		return { height: 0 };
	},
	onAppearActive: (node) => ({ height: node.scrollHeight }),
	onLeaveStart: (node) => ({ height: node.offsetHeight }),
	onLeaveActive: () => ({ height: 0 }),
};

const allowDrop = ({ dropNode, dropPosition }) =>
	!(!dropNode.children && dropPosition === 0);

//-- Tree Search
const GlossarySearchResults = ({ loading, entries, selectedId }) => {
	if (loading) {
		return <CircularProgress />;
	}

	const onClick = action(({ id }) => {
		TreeStore.search.selectedId = id;
		return editEntry(TreeStore, id);
	});

	return (
		<List dense>
			{loading ? (
				<ListItem>
					{" "}
					<CircularProgress />{" "}
				</ListItem>
			) : !entries.length ? (
				<ListItem>
					<ListItemText>No matching Entries found</ListItemText>
				</ListItem>
			) : (
				entries.map((entry) => (
					<ListItem
						key={entry.id}
						onClick={() => onClick(entry)}
						selected={selectedId === entry.id}
						style={{ cursor: "pointer" }}
					>
						<ListItemText>{entry.name}</ListItemText>
					</ListItem>
				))
			)}
		</List>
	);
};

const GlossaryActions = ({ selected, searchMode, searchSelectedId }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [confirmName, setConfirmName] = useState(null);
	const [deleteButton, setDeleteButton] = useState(null);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const canEdit = !searchMode && selected?.length === 1;

	const close = () => setAnchorEl(null);

	const onAddClick = (e) => setAnchorEl(e.target);

	const onConfirmDelete = async () => {
		const isEntry = selected[0].includes("entry-");
		const callback = isEntry ? deleteEntry : deleteFolder;
		const { parentFolderID } = await callback(TreeStore, keyToId(selected[0]));
		await loadTreeData(parentFolderID);
	};

	const onEditClick = action(() => {
		if (!canEdit) {
			return false;
		}
		const isEntry = searchMode || selected[0].includes("entry-");
		const modalProp = isEntry ? "editEntryOpen" : "editFolderOpen";
		TreeStore.modal[modalProp] = true;
		const id = searchMode ? searchSelectedId : keyToId(selected[0]);
		const callback = isEntry ? editEntry : editFolder;
		return callback(TreeStore, id);
	});

	return (
		<>
			<Grid container spacing={2}>
				{/* Add an Entry or Folder (not in search mode) */}
				<Grid item xs={4}>
					<RedButton onClick={onAddClick} disabled={!canEdit}>
						Add
					</RedButton>
					<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
						<MenuItem
							onClick={action(() => {
								TreeStore.modal.entryOpen = true;
								close();
							})}
						>
							Entry
						</MenuItem>
						<MenuItem
							onClick={action(() => {
								TreeStore.modal.folderOpen = true;
								close();
							})}
						>
							Folder
						</MenuItem>
						<MenuItem onClick={close}>Cancel</MenuItem>
					</Menu>
				</Grid>
				{/* Edit an Entry or Folder */}
				<Grid item xs={4}>
					<RedButton onClick={onEditClick} disabled={!canEdit}>
						Edit Name
					</RedButton>
				</Grid>
				{/* Delete an Entry or Folder (not in search mode) */}
				<Grid item xs={4}>
					<RedButton
						disabled={!canEdit}
						onClick={(e) => {
							setDeleteConfirm(true);
							setDeleteButton(e.target);
							const isEntry = selected[0].includes("entry-");
							const selectedId = keyToId(selected[0]);
							const source = isEntry
								? TreeStore.tree.entries
								: TreeStore.tree.folders;
							setConfirmName(
								source.find((item) => item.id === selectedId)?.name
							);
						}}
					>
						Delete
					</RedButton>
				</Grid>
			</Grid>
			<Menu
				anchorEl={deleteButton}
				keepMounted
				open={deleteConfirm}
				onClose={() => setDeleteConfirm(false)}
			>
				<MenuItem disabled>
					<Typography>
						You sure you want to delete{" "}
						<span style={{ fontWeight: "800" }}>{confirmName}</span>?
					</Typography>
				</MenuItem>
				<MenuItem onClick={onConfirmDelete} style={{ color: "red" }}>
					Yes
				</MenuItem>
				<MenuItem onClick={() => setDeleteConfirm(false)}>No</MenuItem>
			</Menu>
		</>
	);
};

export const lowerCase = (s) => (s ? String(s).toLowerCase().trim() : "");

export const UploadDialog = observer(({ onImportComplete = null }) => {
	const [loading, setLoading] = useState(null);
	const close = action(() => {
		TreeStore.modal.uploadOpen = false;
	});

	const glossaryImporter = async (projectID, file, setLoading) => {
		const fileRows = await papaDecorator(file);
		const { parsedRows } = parseFile(fileRows); //optionCount
		await API(`/glossaryImport`, "POST", {
			data: parsedRows,
			projectID,
		});
	};

	const parseFile = (rows) => {
		let results = [];
		let synonymCount = 0;
		for (const row of rows) {
			const rowType = lowerCase(row["Folder/File"]);
			// Exit early if we reached the end of the file
			if (rowType === "end of file") {
				break;
			}
			const parentFolder = row["Parent Folder"];
			const folderName = row["Folder Name"];
			const entryName = row["Entry Name"];
			const synonym = row["Synonym"];

			if ("folder" === rowType) {
				results.push({
					type: rowType,
					name: folderName,
					parentFolder: parentFolder === "root" ? null : parentFolder,
				});
			} else if ("file" === rowType) {
				synonymCount++;
				results.push({
					type: rowType,
					entryName: entryName,
					fileType: lowerCase(row["File Type"]).split(" ")[0],
					parentFolder: parentFolder === "root" ? null : parentFolder,
					synonyms: [synonym],
				});
			} else if (synonym && synonym.length) {
				synonymCount++;
				// Otherwise, it's an option row.  Grab the last row & push
				// this value into its list of options
				results[results.length - 1].synonyms.push(synonym);
			}
		}
		return { parsedRows: results, synonymCount };
	};

	const onDrop = (files) => {
		const projectID = appStore.selectedProject;
		setLoading(0);

		return glossaryImporter(projectID, files[0], setLoading)
			.then((arg) => {
				setLoading(100);
				setTimeout(() => {
					if (!document.hidden) {
						close();
					}
				}, 5000);
				return arg;
			})
			.catch((e) => {
				console.error(e);
				snackbarStore.message = "Import Failed";
				snackbarStore.severity = "Error";
				snackbarStore.open = true;
				setLoading(null);
				return Promise.reject(e);
			})
			.finally(() => (onImportComplete ? onImportComplete() : null));
	};

	let toReturn;
	if (loading === null) {
		toReturn = (
			<Dropzone onDrop={onDrop}>
				{({ getRootProps, getInputProps }) => (
					<section>
						<div
							{...getRootProps()}
							style={{
								width: "100%",
								height: 75,
								borderWidth: 2,
								borderColor: "darkGray",
								borderStyle: "dashed",
								borderRadius: 5,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<input {...getInputProps()} />
							<p>Drag 'n' drop some files here, or click to select files</p>
						</div>
					</section>
				)}
			</Dropzone>
		);
	} else if (loading === 100) {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<FontAwesomeIcon
					icon={faCheckCircle}
					style={{ fontSize: 46, color: "green" }}
				/>
			</div>
		);
	} else if (loading) {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<CircularProgressWithLabel value={loading} />
			</div>
		);
	} else {
		toReturn = (
			<div style={{ textAlign: "center" }}>
				<CircularProgress />
			</div>
		);
	}

	return (
		<Dialog onClose={close} open={true} fullWidth>
			<DialogContent>
				<h3 style={{ textDecoration: "underline" }}>Upload CSV</h3>
				{toReturn}
			</DialogContent>
			<DialogActions>
				<Grid container>
					<Grid item xs={10} />
					<Grid item xs={2}>
						<Button onClick={close}>
							{loading === 100 ? "Close" : "Cancel"}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
});
