/**
 * @component TablePaginationActions
 * See source for example of how to use this in your table(s)
 * @source: https://material-ui.com/components/tables/#custom-pagination-options
 */
import React from "react";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useTheme } from "@material-ui/core/styles";

export const TablePaginationActions = ({
	count,
	page,
	rowsPerPage,
	onChangePage,
}) => {
	const theme = useTheme();
	const handleFirstPageClick = (event) => onChangePage(event, 0);
	const handleBackClick = (event) => onChangePage(event, page - 1);
	const handleNextClick = (event) => onChangePage(event, page + 1);
	const handleLastPageClick = (event) =>
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

	return (
		<div style={{ flexShrink: 0, marginLeft: theme.spacing(2.5) }}>
			<IconButton
				onClick={handleFirstPageClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
};
export default TablePaginationActions;
