import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Attributes from "../../message/right/Attributes/Attributes";
import API from "../../../API";
import { logError } from "../../../lib/ErrorLogger";
import { usePrevious } from "../../../lib/HelperFunctions";

export const EditGlossary = ({ id, loading }) => (
	<>
		{loading ? (
			<Paper style={{ padding: 15 }}>
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						minHeight: 200,
					}}
				>
					<CircularProgress />
				</div>
			</Paper>
		) : (
			<>
				<SynonymsCard id={id} />
				<Paper style={{ marginTop: 20, padding: 15 }}>
					<Attributes entryID={id} type={"glossary"} />
				</Paper>
			</>
		)}
	</>
);

const splitLines = (text) => text.split(/\r?\n/);

const clampLength = (min, max) => (text) => text.trim().substring(min, max);

const uniqText = (textList) => [...new Set(textList)];

const lineBreaks = (textList) => textList.join("\n");

export const SynonymsCard = ({ id }) => {
	const [synonymsText, setSynonymsText] = useState("");
	const [loading, setLoading] = useState(true);
	const synonymTextRef = usePrevious(synonymsText);
	const idRef = usePrevious(id);

	const save = async (
		setText = true,
		synonymsTextToSet = synonymsText,
		glossaryID = id
	) => {
		const deduped = uniqText(splitLines(synonymsTextToSet)).map(
			clampLength(0, 254)
		);
		if (setText) {
			setSynonymsText(lineBreaks(deduped.sort()));
		}

		await API(`/synonym/glossary`, "POST", {
			synonyms: deduped,
			glossaryID: glossaryID || id,
		});
	};

	const dedupe = () => {
		setSynonymsText(lineBreaks(uniqText(splitLines(synonymsText))));
	};

	const sort = () => {
		setSynonymsText(lineBreaks(splitLines(synonymsText).sort()));
	};

	useEffect(() => {
		setLoading(true);
		API(`/db/customQuery/glossaryEntrySynonym`, "POST", {
			query: "find",
			options: {
				where: {
					glossaryID: id,
				},
			},
		})
			.then(({ data }) => {
				setSynonymsText(lineBreaks(data.map(({ synonym }) => synonym)));
				setLoading(false);
				return data;
			})
			.catch((error) => {
				logError(error, {
					component: "EditGlossary.jsx",
					method: "init",
				});
				return Promise.reject(error);
			});

		//eslint-disable the fuck out of this because we want the value at cleanup
		// not the original value
		return () => {
			// eslint-disable-next-line
			if (synonymTextRef.current) {
				//save the glossary entries you're clicking away from
				// eslint-disable-next-line
				save(false, synonymTextRef.current, idRef.current)
					.then((arg) => arg)
					.catch((error) => {
						logError(error, {
							component: "EditGlossary.jsx",
							method: "useEffect -> teardown",
						});
						return Promise.reject(error);
					});
			}
		};
		// eslint-disable-next-line
	}, [id]);

	if (loading) {
		return (
			<Paper style={{ padding: 15 }}>
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						minHeight: 200,
					}}
				>
					<CircularProgress />
				</div>
			</Paper>
		);
	} else {
		return (
			<Paper style={{ padding: 15 }}>
				<h3>Edit Glossary Entry</h3>
				<Grid container>
					<Grid item xs={6}>
						<h3>Synonyms:</h3>
					</Grid>
					<Grid item xs={6} style={{ textAlign: "right" }}>
						<ButtonGroup
							color="secondary"
							variant="text"
							style={{ width: "100%", justifyContent: "flex-end" }}
						>
							<Button onClick={dedupe}>Dedupe</Button>
							<Button onClick={sort}>Sort</Button>
							<Button onClick={save}>Save</Button>
						</ButtonGroup>
					</Grid>
					<TextField
						value={synonymsText}
						onChange={(evt) => {
							setSynonymsText(evt.target.value);
						}}
						rows={10}
						label="Synonyms"
						variant="outlined"
						multiline
					/>
				</Grid>
			</Paper>
		);
	}
};

