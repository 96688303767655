import { observer } from "mobx-react";
import React, { Component } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { appStore } from "../../../../App";
import RedButton from "../../../../lib/RedButton";
import { pageStore, updateCell } from "../Canvas";
import API from "../../../../API";
import client from "../../../../lib/feathers";

const NewContainerDialog = observer(
	class extends Component {
		close = () => {
			pageStore.mode === "New" &&
				window.graph.removeCells([pageStore.selectedCell]);
			pageStore.ContainerDialogOpen = false;
		};

		componentDidMount() {
			setTimeout(() => {
				if (this.autoFocus) {
					this.autoFocus.focus();
				}
			}, 10);
		}

		render() {
			return (
				<Formik
					onSubmit={async (values, { setSubmitting, setErrors }) => {
						setSubmitting(true);
						const selectedCell = window.graph.getSelectionCell();
						const oldCellName = `${selectedCell.value}`;

						if (pageStore.mode === "Edit") {
							//update the canvas name in the DB & containers on any other canvas
							//that use that name
							await API(`/renameCanvas`, "POST", {
								name: values.name,
								previousName: selectedCell.value,
								currentCanvasID: this.props.currentCanvasID,
							});

							//update containers on this canvas with the same name
							const allCells = Object.values(window.graph.getModel().cells);
							for (const cell of allCells) {
								if (
									cell.style &&
									cell.style.includes(
										"shape=mxgraph.flowchart.manual_operation"
									) &&
									cell.value === oldCellName
								) {
									updateCell(values.name, cell);
								}
							}
						} else {
							updateCell(values.name);
							// Update the shape on the canvas, then make a POST to create a
							// record for the new Canvas that this Container shape points to
							// if the canvas doesn't already exist.
							const existingCanvas = await client.service("canvas").find({
								query: {
									projectID: appStore.selectedProject,
									name: values.name,
								},
							});
							if (existingCanvas.length === 0) {
								const content = `<mxGraphModel><root><mxCell id="0"/><mxCell id="1" parent="0"/><mxCell id="2" value="${values.name}" style="shape=trapezoid;perimeter=trapezoidPerimeter;whiteSpace=wrap;html=1;fillColor=#ffffff;" vertex="1" parent="1"><mxGeometry x="375" y="200" width="100" height="50" as="geometry"/></mxCell></root></mxGraphModel>`;
								await API("/db/canvas", "POST", {
									content,
									name: values.name,
									projectID: appStore.selectedProject,
								});
							}
						}
						setSubmitting(false);
						this.props.save();
						pageStore.ContainerDialogOpen = false;
					}}
					initialValues={{ name: pageStore.selectedCell.value, notes: "" }}
					validationSchema={Yup.object().shape({
						name: Yup.string().required("Required").max(255, "Too long"),
					})}
				>
					{(props) => {
						return (
							<Dialog onClose={this.props.close} open={true} fullWidth>
								<form onSubmit={props.handleSubmit}>
									<DialogContent>
										<h3>{pageStore.mode} Container</h3>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Field
													name="name"
													label="Container Name"
													variant="filled"
													type="text"
													component={TextField}
													error={!!props.errors.name}
													helperText={props.errors.name || ""}
													inputProps={{
														ref: (el) => {
															this.autoFocus = el;
														},
													}}
												/>
											</Grid>
											<Grid item xs={6}>
												<Field
													name="notes"
													label="Notes"
													variant="filled"
													type="text"
													component={TextField}
													disabled
												/>
												<FormHelperText id="notes">Optional</FormHelperText>
											</Grid>
										</Grid>
									</DialogContent>
									<DialogActions>
										<Grid container>
											<Grid item xs={7} />
											<Grid item xs={2}>
												<Button onClick={this.close}>Cancel</Button>
											</Grid>
											<Grid item xs={1} />
											<Grid item xs={2}>
												<RedButton autoFocus color="primary" type="submit">
													Save
												</RedButton>
											</Grid>
										</Grid>
									</DialogActions>
								</form>
							</Dialog>
						);
					}}
				</Formik>
			);
		}
	}
);

export default NewContainerDialog;
