import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import Mousetrap from "mousetrap";
import moment from "moment-timezone";
import Tooltip from "@material-ui/core/Tooltip";

const styles = {
	root: {
		display: "flex",
		alignItems: "center",
	},
	wrapper: {
		margin: 0,
		position: "relative",
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	fabProgress: {
		color: green[500],
		position: "absolute",
		top: -6,
		left: -6,
		zIndex: 1,
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
};

// export default function SaveButton(props) {
class SaveButton extends Component {
	state = {
		loading: false,
		success: false,
		lastSave: null,
		lastSaveFormatted: "",
	};

	lastSave = null;

	componentDidMount() {
		Mousetrap.bind("mod+s", (e) => {
			e.preventDefault();
			this.handleButtonClick();
		});

		if (this.props.autoSave) {
			this.autoSaveInterval = setInterval(async () => {
				if (this.props.autoSaveData === "canvas") {
					const enc = new window.mxCodec();
					const currentNode = enc.encode(window.graph.getModel());
					const currentCanvasXML = window.mxUtils.getXml(
						currentNode,
						this.linefeed
					);

					if (currentCanvasXML !== this.lastSave) {
						this.lastSave = currentCanvasXML;
						await this.handleButtonClick(true);
					}
				} else {
					await this.handleButtonClick(true);
				}
			}, this.props.autoSave * 1000);
		}
	}

	componentWillUnmount() {
		Mousetrap.unbind("mod+s");

		clearInterval(this.autoSaveInterval);
	}

	handleButtonClick = async (autoSave = false) => {
		if (!this.state.loading) {
			this.setState({ success: false, loading: true });

			try {
				await this.props.save(autoSave);

				this.setState({ success: true, loading: false });

				setTimeout(() => {
					this.setState({ success: false, lastSave: new Date() });
				}, 3000);
			} catch (e) {
				console.error(
					`Error saving at ${moment()
						.tz(moment.tz.guess())
						.format(`MMMM Do YYYY, h:mm:ss a z`)}`
				);
				console.error(e);
				alert(
					`Save failed. Please hit "OK," refresh the page, and make sure you haven't lost any content. Then report this message to the developers.`
				);
				console.log("Alert dismissed");
				this.setState({ success: false, loading: false });
			}
		}
	};

	renderLastSave = () => {
		if (this.state.lastSave) {
			this.setState({
				lastSaveFormatted: `${moment().diff(
					this.state.lastSave,
					"seconds"
				)} seconds ago`,
			});
		} else {
			this.setState({ lastSaveFormatted: "last save unknown" });
		}
	};

	render() {
		const classes = this.props.classes;

		return (
			<div
				className={classes.root}
				style={this.props.style}
				onContextMenu={this.props.onContextMenu}
			>
				<Tooltip
					title={this.state.lastSaveFormatted}
					onOpen={this.renderLastSave}
				>
					<div className={classes.wrapper}>
						<Fab
							aria-label="save"
							color="primary"
							className={this.state.success ? classes.buttonSuccess : ""}
							onClick={async () => {
								await this.handleButtonClick(false);
							}}
						>
							{this.state.success ? <CheckIcon /> : <SaveIcon />}
						</Fab>
						{this.state.loading && (
							<CircularProgress size={68} className={classes.fabProgress} />
						)}
					</div>
				</Tooltip>
			</div>
		);
	}
}

export default withStyles(styles)(SaveButton);
