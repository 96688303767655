import React, { Component } from "react";
import { GiftedChat, Bubble, Composer } from "react-native-gifted-chat";
import { View } from "react-native";
import API from "../../../API";
import shortid from "shortid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
import { observe } from "mobx";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { capitalCase } from "change-case";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import Fab from "@material-ui/core/Fab";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import RedButton from "../../../lib/RedButton";
const queryString = require("query-string");

let userData = {};

export default class Prototype extends Component {
	parsed = queryString.parse(this.props.location.search);

	state = {
		messages: [],
		messageAttributes: {},
		currentMessageID: this.parsed.messageID,
		input: "",
		loading: true,
		statement: null,
	};

	componentWillUnmount() {
		userData = {};
	}

	async componentDidMount() {
		this.setState({
			messages: [
				{
					_id: 1,
					text: "Loading...",
					createdAt: new Date(),
					user: {
						_id: 2,
						name: "TCS",
					},
				},
			],
		});

		const {
			messages,
			nextMessageID,
			messageAttributes,
			...response
		} = await API(`/chat`, "POST", {
			currentMessageID: this.state.currentMessageID,
			start: true,
			includeAttributes: true,
		});

		this.setState({
			messages: messages
				.map((message, index) => {
					return {
						_id: shortid.generate(),
						createdAt: new Date(),
						text: message,
						user: {
							_id: 2,
							name: "TCS",
						},
						messageID:
							index === 0 ? this.state.currentMessageID : nextMessageID,
					};
				})
				.reverse(),
			messageAttributes,
			statement: response.messageType === "statement",
			statementButton:
				response.messageType === "statement" ? response.statementButton : null,
			currentMessageID: nextMessageID,
			loading: false,
		});
	}

	onSend = async (messages = [], statementSend = false) => {
		if (statementSend || messages[0].text) {
			if (!statementSend) {
				this.setState((previousState) => ({
					messages: GiftedChat.append(previousState.messages, messages),
				}));
			}
			const data = await API(`/chat`, "POST", {
				currentMessageID: this.state.currentMessageID,
				message: statementSend ? "" : messages[0].text,
				userData,
				includeAttributes: true,
			});
			this.setState((previousState) => ({
				messages: GiftedChat.append(
					previousState.messages,
					data.messages
						.map((message, index) => {
							return {
								_id: shortid.generate(),
								createdAt: new Date(),
								text: message,
								user: {
									_id: 2,
									name: "TCS",
								},
								userData: data.newUserData,
								messageID: data.nextMessageID || this.state.currentMessageID,
							};
						})
						//don't show empty messages
						.filter((message) => {
							return typeof message?.text === "string"
								? message.text.trim()
								: false;
						})
						.reverse()
				),
				messageAttributes: data.messageAttributes,
				currentMessageID: data.nextMessageID,
				statement: data.messageType === "statement",
				statementButton:
					data.messageType === "statement" ? data.statementButton : null,
			}));
			if (data.newUserData) {
				userData = { ...userData, ...data.newUserData };
			}
			this.giftedChat.focusTextInput();

			//move canvas to next message
			if (window.graph && this.state.currentMessageID) {
				const canvasData = await API("/db/customQuery/message", "POST", {
					query: "findOne",
					options: {
						where: { id: data.nextMessageID },
						raw: true,
						attributes: ["shapeID", "canvasID"],
					},
				});

				const highlightCell = () => {
					const allCells = Object.values(window.graph.getModel().cells);
					const matchingShape = allCells.find((cell) => {
						return cell.id === `${canvasData.data.shapeID}`;
					});

					const existingHighlightCell = allCells.find((cell) => {
						return cell.id.includes("highlight");
					});

					window.graph.model.beginUpdate();

					if (existingHighlightCell) {
						window.graph.removeCells([existingHighlightCell]);
					}

					if (matchingShape) {
						//MZM 10-1-20 - Disable highlighting the cell in yellow because it
						//was preventing you from double clicking to open the message.
						//The proper fix for this is to z index the highlight so it's *under*
						//the actual message, but didn't have the time to research how you
						//could "move to back" in mxGraph.

						// const newCell = window.graph.insertVertex(
						// 	matchingShape.parent,
						// 	`highlight${Math.floor(Math.random() * 1000)}`,
						// 	"",
						// 	matchingShape.geometry.x - 5,
						// 	matchingShape.geometry.y - 5,
						// 	matchingShape.geometry.width + 11,
						// 	matchingShape.geometry.height + 11,
						// 	`rounded=0;shadow=1;whiteSpace=wrap;html=1;fillColor=none;strokeColor=#FFFF00;perimeterSpacing=5;strokeWidth=10;`
						// );
						window.graph.scrollCellToVisible(matchingShape, true);
					}
					window.graph.model.endUpdate();
				};
				//REL - Simulator now works in fullscren and doesn't show canvas so no need to highlight the cell.
				// if (this.props.appStore.loaded !== canvasData.data.canvasID) {
				// 	this.props.appStore.canvasID = canvasData.data.canvasID;
				// } else {
				// 	highlightCell();
				// }

				this.disposer = observe(this.props.appStore, "loaded", (change) => {
					//wait for the canvas to change, then deal with updating the shape
					highlightCell();
					this.disposer();
				});
			}
		}
	};

	renderMessage(props) {
		let tooltipContent = [];
		if (props.currentMessage.messageID && this.state.messageAttributes) {
			const messageAttributes =
				this.state.messageAttributes[props.currentMessage.messageID]
					?.attributes || [];
			tooltipContent = messageAttributes
				.filter(({ attribute, value }) => value)
				.map(({ attribute, value }) => (
					<p key={attribute.name}>{`${capitalCase(attribute.name)}: ${
						attribute.type === "list" ? capitalCase(value) : value
					}`}</p>
				));
		}

		return (
			<div
				style={{ marginTop: 2, marginBottom: 2 }}
				onClick={async () => {
					if (props.currentMessage.messageID) {
						const { data } = await API("/db/customQuery/message", "POST", {
							query: "findOne",
							options: {
								where: { id: props.currentMessage.messageID },
								raw: true,
								attributes: ["shapeID", "canvasID"],
							},
						});

						if (data) {
							this.setState({
								redirect: `/canvas/${data.canvasID}/${data.shapeID}`,
							});
						}
					}
				}}
			>
				<Bubble
					{...props}
					wrapperStyle={{
						left: {
							marginRight: 0,
						},
					}}
					containerStyle={{
						left: {
							display: "inline-block",
							maxWidth: "85%",
						},
					}}
				/>
				{tooltipContent.length ? (
					<Tooltip title={tooltipContent} style={{ margin: "auto" }}>
						<IconButton aria-label="delete">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</div>
		);
	}

	exportFile = () => {
		const text = this.state.messages.reverse().map((message) => {
			return `${message.user.name === "TCS" ? "TCS:\r\n" : "User:\r\n"}${
				message.text
			}\r\n\r\n`;
		});
		const blob = new Blob([text.join("")], {
			type: "text/plain;charset=utf-8",
		});
		saveAs(blob, `${moment().format("h:mm:ss a l")}.txt`);
	};

	render() {
		if (this.state.redirect) {
			return <Redirect push to={this.state.redirect} />;
		}
		if (this.state.loading) {
			return (
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						height: "100%",
					}}
				>
					<CircularProgress />
				</div>
			);
		}
		return (
			<div style={{ height: "98%", margin: "50px" }} className="prototype">
				<h3>Prototype</h3>
				<View style={{ width: "98%", height: "96%" }}>
					<GiftedChat
						messages={this.state.messages}
						onSend={(messages) => this.onSend(messages)}
						user={{
							_id: 1,
						}}
						multiline={false}
						text={this.state.input}
						onInputTextChanged={(value) => {
							this.setState({ input: value });
						}}
						ref={(el) => {
							this.giftedChat = el;
						}}
						textInputProps={{
							onKeyPress: (evt) => {
								if (evt.charCode === 13) {
									this.setState({ input: "" });

									this.onSend([
										{
											_id: shortid.generate(),
											createdAt: new Date(),
											text: evt.target.value,
											user: { _id: 1 },
										},
									]);
								}
							},
							tabIndex: "0",
						}}
						renderMessage={this.renderMessage.bind(this)}
						renderComposer={(props) =>
							this.state.statement ? (
								<View style={{ width: "100%" }}>
									<RedButton onClick={() => this.onSend([], true)}>
										{this.state.statementButton}
									</RedButton>
								</View>
							) : (
								<Composer {...props} />
							)
						}
					/>
				</View>
				<div
					onClick={this.exportFile}
					style={{
						cursor: "pointer",
						position: "fixed",
						top: 85,
						right: 15,
					}}
				>
					<Fab disabled aria-label="export" color="primary">
						<SaveAltIcon />
					</Fab>
				</div>
			</div>
		);
	}
}
