import React, { useEffect, useState } from "react";
import client from "../../lib/feathers";
import Select from "react-select";
import RedButton from "../../lib/RedButton";
import snackbarStore from "../../lib/SnackbarStore";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { observe } from "mobx";
import { observer } from "mobx-react";
import { appStore } from "../../App";

const GhostKiller = observer(() => {
	const [messages, setMessages] = useState();
	const [selectedMessage, setSelectedMessage] = useState();

	useEffect(() => {
		const disposer = observe(appStore, "selectedProject", async () => {
			if (appStore.selectedProject) {
				setMessages(
					await client
						.service("message")
						.find({ query: { projectID: appStore.selectedProject } })
				);
			}
		});

		return () => {
			if (disposer) {
				disposer();
			}
		};
	}, []);

	return (
		<Grid container>
			<Grid item xs={6}>
				<Paper style={{ padding: 15 }}>
					<h3>Ghost Message Killer</h3>
					<p>
						Give the dropdown a second to open, there are a lot of messages for
						it to load...
					</p>
					{messages ? (
						<Select
							options={messages.map((message) => ({
								label: message.name,
								value: message.id,
							}))}
							onChange={(val) => {
								setSelectedMessage(val);
							}}
							value={selectedMessage}
							style={{ paddingBottom: 10, paddingTop: 10 }}
						/>
					) : (
						<p>Loading...</p>
					)}
					{selectedMessage && (
						<RedButton
							style={{ marginBottom: 10 }}
							onClick={async () => {
								await client.service("message").remove(selectedMessage.value);
								setMessages(
									messages.filter(
										(message) => message.id !== selectedMessage.value
									)
								);
								setSelectedMessage(null);

								snackbarStore.setMessage(
									"success",
									"Ghost successful converted into ectoplasm"
								);
							}}
						>
							Ghost Busters!
						</RedButton>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
});

export default GhostKiller;
