import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import RedButton from "../../lib/RedButton";
import Paper from "@material-ui/core/Paper";
import API from "../../API";
import { appStore } from "../../App";
import snackbarStore from "../../lib/SnackbarStore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
import { observable, observe } from "mobx";
import { observer } from "mobx-react";
import downloadjs from "downloadjs";
import client from "../../lib/feathers";

var manageProjectStore = observable({
	projects: null,
	newProjectName: "",
	newProjectModalOpen: false,
	editedProjectName: "",
	selectedEditProject: null,
	mondayBoards: null,
	selectedCurrentSprintBoard: "",
	selectedBacklogBoard: "",
});

export default observer(
	class ManageProjects extends Component {
		componentDidMount = async () => {
			const { data } = await API(`/db/project`, "GET");
			manageProjectStore.projects = data;
		};

		createProject = async (evt) => {
			evt.preventDefault();

			if (manageProjectStore.newProjectName) {
				const { project } = await API(`/project`, "POST", {
					name: manageProjectStore.newProjectName,
				});
				if (project) {
					appStore.selectedProject = project.id;
					appStore.selectedProjectName = project.name;

					this.props.history.push(`/canvas`);
				} else {
					snackbarStore.setMessage(
						"Error",
						"Error creating new project. Name probably already exists"
					);
				}
			}
		};

		render() {
			return (
				<div>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Paper style={{ padding: 10, marginBottom: 10 }}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<div style={{ flexGrow: 100 }}>
										<h3 style={{ marginBottom: 0 }}>Create New Project</h3>
									</div>
									<div style={{ flex: 1 }}>
										<RedButton
											onClick={() =>
												(manageProjectStore.newProjectModalOpen = true)
											}
										>
											<AddIcon />
										</RedButton>
									</div>
								</div>
							</Paper>
							<Dialog
								onClose={() => (manageProjectStore.newProjectModalOpen = false)}
								aria-labelledby="simple-dialog-title"
								open={manageProjectStore.newProjectModalOpen}
							>
								<Paper style={{ padding: 10 }}>
									<DialogTitle>New Project</DialogTitle>
									<DialogContent>
										<form onSubmit={this.createProject}>
											<TextField
												autoFocus
												margin="dense"
												id="name"
												label="Project Name"
												fullWidth
												value={manageProjectStore.newProjectName}
												onChange={(evt) => {
													manageProjectStore.newProjectName = evt.target.value;
												}}
											/>
											<RedButton style={{ marginTop: 15 }} type="submit">
												Create
											</RedButton>
										</form>
									</DialogContent>
								</Paper>
							</Dialog>
							<Paper style={{ padding: 10 }}>
								<h3 style={{ textDecoration: "underline" }}>Manage Projects</h3>
								{manageProjectStore.projects ? (
									<>
										{manageProjectStore.projects.map((project) => {
											return (
												<ProjectInList
													project={project}
													key={project.id}
													reload={this.componentDidMount}
												/>
											);
										})}
									</>
								) : (
									<div style={{ textAlign: "center" }}>
										<CircularProgress />
									</div>
								)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							{manageProjectStore.selectedEditProject && (
								<ProjectEditCard reload={this.componentDidMount} />
							)}
							{appStore.selectedProjectAccessMap.update ? (
								<>
									<Paper style={{ padding: 10 }}>
										<h3 style={{ textDecoration: "underline" }}>Oh shit</h3>
										<p>Something go missing on the canvas?</p>
										<RedButton
											style={{ marginTop: 15 }}
											onClick={async () => {
												await API(`/canvas/ohShit`, "GET");
												alert("Oh shit logs saved. Now go tell Max!");
											}}
										>
											Press Me!
										</RedButton>
									</Paper>
									<Paper style={{ padding: 10, marginTop: 15 }}>
										<h3 style={{ textDecoration: "underline" }}>
											Sync Canvas w/DB
										</h3>
										<p>
											Use this sparingly. It takes about half a minute to run.
										</p>
										<RedButton
											style={{ marginTop: 15 }}
											onClick={async () => {
												snackbarStore.setMessage(
													"Info",
													"Shapes table rebuild in progress. Please wait, this might be a minute."
												);
												await API(`/compileCanvases`, "POST", {
													rebuild: true,
												});
												snackbarStore.setMessage(
													"Success",
													"Shapes table has been rebuilt"
												);
											}}
										>
											Rebuild
										</RedButton>
									</Paper>
								</>
							) : null}
						</Grid>
					</Grid>
				</div>
			);
		}
	}
);

const ProjectEditCard = observer(
	class extends Component {
		state = {
			buttonText: "Sync Existing Messages to Monday",
			exporting: false,
			imageCDNURL: null,
		};
		componentDidMount = async () => {
			const projectDB = await client
				.service("project")
				.find({ query: { id: manageProjectStore.selectedEditProject.id } });
			this.setState({ imageCDNURL: projectDB[0].imageCDNURL });

			const data = await API(`/monday/getBoards`, "POST");
			manageProjectStore.mondayBoards = data;

			const setMondaySelects = () => {
				if (manageProjectStore.selectedEditProject.mondayBacklogBoardID) {
					manageProjectStore.selectedBacklogBoard =
						manageProjectStore.selectedEditProject.mondayBacklogBoardID;
				} else {
					manageProjectStore.selectedBacklogBoard = "";
				}

				if (manageProjectStore.selectedEditProject.mondayCurrentSprintBoardID) {
					manageProjectStore.selectedCurrentSprintBoard =
						manageProjectStore.selectedEditProject.mondayCurrentSprintBoardID;
				} else {
					manageProjectStore.selectedCurrentSprintBoard = "";
				}
			};

			setMondaySelects();

			this.disposer = observe(
				manageProjectStore,
				"selectedEditProject",
				(change) => {
					setMondaySelects();
				}
			);
		};

		componentWillUnmount() {
			if (this.diosposer) {
				this.disposer();
			}
		}

		render() {
			return (
				<div>
					<Paper style={{ padding: 10, marginBottom: 20 }}>
						<h3>Editing {manageProjectStore.selectedEditProject.name}</h3>
						<TextField
							margin="dense"
							id="name"
							label="New Project Name"
							fullWidth
							value={manageProjectStore.editedProjectName}
							onChange={(evt) => {
								manageProjectStore.editedProjectName = evt.target.value;
							}}
							autoComplete="off"
						/>
						<RedButton
							style={{ marginTop: 10 }}
							onClick={async () => {
								const res = await API(`/db/project`, "POST", {
									id: manageProjectStore.selectedEditProject.id,
									name: manageProjectStore.editedProjectName,
								});
								if (res.error)
									snackbarStore.setMessage(
										"Error",
										"Error changing project name. Name probably already exists"
									);
								else {
									snackbarStore.setMessage(
										"Success",
										"Project name successfully updated"
									);
									manageProjectStore.selectedEditProject.name =
										manageProjectStore.editedProjectName;
									this.props.reload();
								}
							}}
							disabled={
								!manageProjectStore.editedProjectName ||
								manageProjectStore.editedProjectName === ""
							}
						>
							Edit Name
						</RedButton>
						<Divider style={{ marginTop: 20, marginBottom: 20 }} />
						<h3>Image Library</h3>
						<TextField
							label="Image CDN URL"
							fullWidth
							value={this.state.imageCDNURL || ""}
							onChange={(evt) => {
								this.setState({ imageCDNURL: evt.target.value });
							}}
							onBlur={(evt) => {
								client
									.service("project")
									.patch(manageProjectStore.selectedEditProject.id, {
										imageCDNURL: evt.target.value,
									});
							}}
						/>
						<RedButton
							style={{ marginTop: 10 }}
							onClick={async () => {
								this.setState({ exporting: true });
								const { downloadPath } = await API(
									`/downloadImageLibrary`,
									"POST",
									{
										env: process.env.REACT_APP_ENV,
									}
								);
								await downloadjs(`//${downloadPath}`);
								this.setState({ exporting: false });
							}}
							disabled={this.state.exporting}
						>
							{this.state.exporting ? "Generating download..." : "Download"}
						</RedButton>
						<Divider style={{ marginTop: 20, marginBottom: 20 }} />
						<h3>Sync Glossaries To Amazon</h3>
						<RedButton
							style={{ marginTop: 10 }}
							onClick={async () => {
								const result = await API(
									`/syncGlossaryToAmazon/${manageProjectStore.selectedEditProject.id}`,
									"GET",
									null
								);
								if (result.error) {
									snackbarStore.setMessage("Error", result.error);
								} else {
									snackbarStore.setMessage("Success", "Glossaries synced!");
								}
							}}
						>
							Sync to Amazon
						</RedButton>
					</Paper>
				</div>
			);
		}
	}
);

const ProjectInList = observer(
	class extends Component {
		state = {
			deleteAnchorEl: null,
		};
		delete = async () => {
			await API(`/project/${this.props.project.id}`, "DELETE");

			if (appStore.selectedProject === this.props.project.id) {
				appStore.selectedProject = null;
				appStore.selectedProjectName = "(no project selected)";
			}

			this.props.reload();
		};

		render() {
			return (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<div style={{ flexGrow: 100 }}>
						<Button
							style={{
								width: "100%",
								justifyContent: "left",
								textTransform: "none",
							}}
							onClick={async () => {
								manageProjectStore.selectedEditProject = this.props.project;
							}}
						>
							{this.props.project.name}
						</Button>
					</div>
					<div style={{ flex: 1 }}>
						<ButtonGroup
							key={1}
							color="secondary"
							aria-label="text primary button group"
							style={{ width: "100%" }}
							variant="text"
						>
							<Button
								onClick={() =>
									(manageProjectStore.selectedEditProject = this.props.project)
								}
								style={{ width: "100%" }}
							>
								<FontAwesomeIcon icon={faPencil} />
							</Button>
							<Button
								onClick={(evt) => {
									this.setState({ deleteAnchorEl: evt.currentTarget });
								}}
								style={{ width: "100%" }}
							>
								<FontAwesomeIcon icon={faTrash} />
							</Button>
						</ButtonGroup>
					</div>
					<Menu
						anchorEl={this.state.deleteAnchorEl}
						keepMounted
						open={Boolean(this.state.deleteAnchorEl)}
						onClose={() => {
							this.setState({ deleteAnchorEl: null });
						}}
					>
						<MenuItem disabled>You Sure?</MenuItem>
						<MenuItem style={{ color: "red" }} onClick={this.delete}>
							Yes
						</MenuItem>
						<MenuItem
							onClick={() => {
								this.setState({ deleteAnchorEl: null });
							}}
						>
							No
						</MenuItem>
					</Menu>
				</div>
			);
		}
	}
);
