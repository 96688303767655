import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {
	usePrevious,
	ReactSelectTheme,
	ReactSelectStyles,
} from "../../../../lib/HelperFunctions";
import client from "../../../../lib/feathers";
import { savingQueue } from "../../../../lib/GlobalSaveIndicator";
import pull from "lodash.pull";
import { nanoid } from "nanoid";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import debounce from "lodash.debounce";
import without from "lodash.without";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Slider from "@material-ui/core/Slider";

const noOptionFields = ["Is Empty", "Is Not Empty"];

const textInputFields = [
	"Less",
	"Less Or Equal",
	"Greater",
	"Greater Or Equal",
	"Begins With",
	"Not Begins With",
	"Contains",
	"Not Contains",
	"Ends With",
	"Not Ends With",
];

const sliderFields = ["Between", "Not Between"];

export const BrancherRuleContainer = ({ brancher, attributes, ...props }) => {
	return (
		<div
			style={{
				marginLeft: 20,
				marginRight: 20,
				marginTop: 10,
				marginBottom: 10,
			}}
		>
			<RuleGroup brancher={brancher} attributes={attributes} index={0} />
		</div>
	);
};

const RuleGroup = ({ brancher, nestedGroup, attributes, index, depth = 0 }) => {
	const [addingRule, setAddingRule] = useState(false);
	const [deletingRule, setDeletingRule] = useState(false);
	const [checkedBoxes, setCheckedBoxes] = useState([]);
	const [chainedOperatorSaveValue, setChainedOperatorSaveValue] = useState(
		null
	);

	const [brancherRules, setbrancherRules] = useState(null);
	const brancherRulesRef = usePrevious(brancherRules);

	useEffect(() => {
		(async () => {
			const brancherRulesDB = client.service("brancherRuleV2");
			let brancherRulesResults = await brancherRulesDB.find({
				query: {
					brancherID: nestedGroup ? null : brancher.id,
					parentGroupID: nestedGroup?.id,
				},
			});

			for (const brancherRule of brancherRulesResults) {
				client
					.service("channelManager")
					.create({ name: `brancherRuleV2/${brancherRule.id}` });
				brancherRule.group = false;

				let matchingAttribute = attributes.find(
					(att) => att.id === brancherRule.attributeID
				);

				if (!matchingAttribute && brancherRule.attributeID) {
					const matchingAttributes = await client.service("attribute").find({
						query: { id: brancherRule.attributeID, include: ["Option"] },
					});
					if (matchingAttributes.length) {
						attributes.push(matchingAttributes[0]);

						await client.service("brancherAttribute").create({
							attributeID: matchingAttributes[0].id,
							brancherID: brancher.brancherCanvasID,
							canvasID: brancher.canvasID,
						});
					} else {
						alert(
							"Missing attribute used in rules that does not exist anymore. Please contact support."
						);
					}
				}
			}

			const brancherRuleGroupsDB = client.service("brancherRuleGroup");
			const brancherRuleGroupsResults = await brancherRuleGroupsDB.find({
				query: {
					brancherID: nestedGroup ? null : brancher.id,
					parentGroupID: nestedGroup?.id,
				},
			});

			for (const group of brancherRuleGroupsResults) {
				client
					.service("channelManager")
					.create({ name: `brancherRuleGroup/${group.id}` });
				group.group = true;
			}

			setbrancherRules(
				[...brancherRuleGroupsResults, ...brancherRulesResults].sort((a, b) => {
					return a.order - b.order;
				})
			);

			brancherRulesDB
				.on("created", (newRow) => {
					setbrancherRules([
						...brancherRulesRef.current,
						{ ...newRow, group: false },
					]);
				})
				.on("removed", (deleted) => {
					const toDelete = brancherRulesRef.current.findIndex(
						(item) => item.id === deleted.id && !item.group
					);
					if (toDelete !== -1) {
						const toSet = [...brancherRulesRef.current];
						toSet.splice(toDelete, 1);
						setbrancherRules(toSet);
					}
				})
				.on("patched", async (patched) => {
					if (!brancherRulesRef?.current) {
						return;
					}

					const toPatch = brancherRulesRef.current.findIndex(
						(item) => item.id === patched.id && !item.group
					);
					let toSet = [...brancherRulesRef.current];

					if (toPatch !== -1) {
						if (
							patched.parentGroupID &&
							nestedGroup?.id !== patched.parentGroupID
						) {
							toSet.splice(toPatch, 1);
							setbrancherRules(toSet);
						} else {
							toSet[toPatch] = patched;
							setbrancherRules(toSet);
						}
					}
				});

			brancherRuleGroupsDB
				.on("created", (newRow) => {
					setbrancherRules([
						...brancherRulesRef.current,
						{ ...newRow, group: true },
					]);
				})
				.on("removed", (deleted) => {
					const toDelete = brancherRulesRef.current.findIndex(
						(item) => item.id === deleted.id && item.group
					);
					if (toDelete !== -1) {
						const toSet = [...brancherRulesRef.current];
						toSet.splice(toDelete, 1);
						setbrancherRules(toSet);
					}
				})
				.on("patched", async (patched) => {
					if (!brancherRulesRef.current) {
						return;
					}

					const toPatch = brancherRulesRef.current.findIndex(
						(item) => item.id === patched.id && item.group
					);
					if (toPatch !== -1) {
						let toSet = [...brancherRulesRef.current];
						toSet[toPatch] = { ...patched, group: true };
						setbrancherRules(toSet);
					}
				});
		})();

		return () => {
			if (!brancherRules) {
				return;
			}
			for (const rule of brancherRules) {
				if (rule.group) {
					client
						.service("channelManager")
						.remove({ name: `brancherRuleGroup/${rule.id}` });
				} else {
					client
						.service("channelManager")
						.create({ name: `brancherRuleV2/${rule.id}` });
				}
			}

			client
				.service("brancherRuleV2")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");

			client
				.service("brancherRuleGroup")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		};
		// eslint-disable-next-line
	}, [brancher.id]);

	const addRule = async () => {
		setAddingRule(true);

		let row;
		if (nestedGroup) {
			row = await client.service("brancherRuleV2").create({
				parentGroupID: nestedGroup.id,
				order: brancherRules.length,
			});
		} else {
			row = await client.service("brancherRuleV2").create({
				brancherID: brancher.id,
				order: brancherRules.length,
			});
		}
		client
			.service("channelManager")
			.create({ name: `brancherRuleV2/${row.id}` });

		setbrancherRules([...brancherRulesRef.current, { ...row, group: false }]);

		setAddingRule(false);
	};

	const deleteRows = async () => {
		const rulesLeftInGroup =
			brancherRulesRef?.current.length - checkedBoxes.length;
		setDeletingRule(true);
		for (const checkedBox of checkedBoxes) {
			await client.service("brancherRuleV2").remove(checkedBox);
		}
		if (nestedGroup && rulesLeftInGroup === 0) {
			await client.service("brancherRuleGroup").remove(nestedGroup.id);
		}
		setCheckedBoxes([]);
		setDeletingRule(false);
	};

	const toggleChainedOperator = async () => {
		const saveID = nanoid();
		savingQueue.push(saveID);

		const newValue = chainedOperator === "and" ? "or" : "and";
		setChainedOperatorSaveValue(newValue);

		if (nestedGroup) {
			await client.service("brancherRuleGroup").patch(nestedGroup.id, {
				chainedOperator: newValue,
			});
		} else {
			await client.service("brancher").patch(brancher.id, {
				chainedOperator: newValue,
			});
		}
		pull(savingQueue, saveID);
		setChainedOperatorSaveValue(null);
	};

	const group = async () => {
		const newGroup = await client.service("brancherRuleGroup").create({
			brancherID: brancher.id,
			order: brancherRules.length - checkedBoxes.length,
			chainedOperator: "and",
		});

		client
			.service("channelManager")
			.create({ name: `brancherRuleGroup/${newGroup.id}` });

		const toMove = brancherRules
			.filter((rule) => {
				return !rule.group && checkedBoxes.includes(rule.id);
			})
			.map((rule, index) => {
				return {
					id: rule.id,
					parentGroupID: newGroup.id,
					order: index,
					brancherID: null,
				};
			});

		const remainingRules = brancherRules
			.filter((rule) => !checkedBoxes.includes(rule.id))
			.map((rule, index) => {
				return { ...rule, group: rule.group, order: index };
			});
		const rulesToUpdate = [
			...toMove,
			...remainingRules.filter((row) => !row.group),
		];
		const groupsToUpdate = remainingRules.filter((row) => row.group);

		let toUpdate = [];
		for (const rule of rulesToUpdate) {
			toUpdate.push(client.service("brancherRuleV2").patch(rule.id, rule));
		}
		for (const rule of groupsToUpdate) {
			toUpdate.push(client.service("brancherRuleGroup").patch(rule.id, rule));
		}

		await Promise.all(toUpdate);

		setbrancherRules([
			...remainingRules,
			{ ...newGroup, group: true, brancherRules: [] },
		]);

		setCheckedBoxes([]);
	};

	if (!brancherRules) {
		return <p>Loading...</p>;
	}

	let style = { display: "flex" };
	if (nestedGroup) {
		style.border = "1px solid black";

		if (brancherRules.length === 1) {
			style.borderRadius = "10px 10px 10px 10px";
		} else if (index === 0) {
			style.borderRadius = "10px 10px 0 0";
		} else if (index === brancherRules.length - 1) {
			style.borderRadius = "0 0 10px 10px";
		}

		// style.marginLeft = -15;
		// style.marginRight = -15;
		style.paddingTop = 5;
		style.paddingRight = 5;
		style.paddingLeft = 5;
		style.paddingBottom = 10;
	}

	const chainedOperator =
		chainedOperatorSaveValue ||
		nestedGroup?.chainedOperator ||
		brancher?.chainedOperator;

	return (
		<Grid container style={style}>
			<Grid item xs={brancherRules.length === 1 ? 12 : 11}>
				{brancherRules.map((brancherRule, ruleIndex) => {
					if (brancherRule.group) {
						return (
							<RuleGroup
								key={`g-${brancherRule.id}`}
								brancher={brancher}
								nestedGroup={brancherRule}
								attributes={attributes}
								index={ruleIndex}
								depth={depth + 1}
							/>
						);
					}
					return (
						<RuleRow
							key={brancherRule.id}
							brancherRules={brancherRules}
							brancherRule={brancherRule}
							ruleIndex={ruleIndex}
							attributes={attributes}
							checkedBoxes={checkedBoxes}
							setCheckedBoxes={setCheckedBoxes}
							nestedGroup={nestedGroup}
						/>
					);
				})}
			</Grid>
			{brancherRules.length === 1 ? null : (
				<Grid
					item
					xs={1}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flex: 1,
						cursor: "pointer",
					}}
					onClick={toggleChainedOperator}
				>
					<div
						style={{
							display: "flex",
							padding: 5,
							backgroundColor: "lightGray",
							borderRadius: 4,
							boxShadow: "rgba(0,0,0,.3) 2px 2px 2px",
						}}
					>
						<span
							style={{
								display: "flex",
								padding: 5,
								color: chainedOperator === "and" ? "white" : "black",
								background: chainedOperator === "and" ? "var(--red)" : "",
								borderRadius: 4,
								border: chainedOperator === "and" ? "1px solid white" : "",
							}}
						>
							and
						</span>
						<span
							style={{
								display: "flex",
								padding: 5,
								color: chainedOperator === "or" ? "white" : "black",
								background: chainedOperator === "or" ? "var(--red)" : "",
								borderRadius: 4,
								border: chainedOperator === "or" ? "1px solid white" : "",
							}}
						>
							or
						</span>
					</div>
				</Grid>
			)}
			<Grid
				item
				xs={brancherRules.length === 1 ? 12 : 11}
				style={{ textAlign: "center", marginTop: 10 }}
			>
				{checkedBoxes.length && depth < 2 ? (
					<Button
						style={{ marginLeft: 10, marginRight: 10, maxWidth: 225 }}
						variant="contained"
						color="secondary"
						fullWidth={true}
						onClick={group}
					>
						Group
					</Button>
				) : null}
				{checkedBoxes.length ? (
					<Button
						style={{ marginLeft: 10, marginRight: 10, maxWidth: 225 }}
						variant="contained"
						color="secondary"
						fullWidth={true}
						onClick={deleteRows}
					>
						Delete
						{deletingRule && (
							<>
								<span>&nbsp;&nbsp;</span>
								<CircularProgress color="primary" size={14} />
							</>
						)}
					</Button>
				) : null}
				<Button
					style={{ marginLeft: 10, marginRight: 10, maxWidth: 225 }}
					variant="contained"
					color="primary"
					fullWidth={true}
					onClick={addRule}
				>
					Add Rule{nestedGroup ? " to Group" : null}
					{addingRule && (
						<>
							<span>&nbsp;&nbsp;</span>
							<CircularProgress color="secondary" size={14} />
						</>
					)}
				</Button>
			</Grid>
		</Grid>
	);
};

const RuleRow = ({
	brancherRule,
	brancherRules,
	ruleIndex,
	nestedGroup = false,
	attributes,
	checkedBoxes,
	setCheckedBoxes,
}) => {
	let rowStyle = {};

	if (brancherRules.length === 1) {
		//single rule case
		rowStyle = {
			paddingTop: 10,
			paddingBottom: 10,
			background: "white",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderTop: "1px solid #343d47",
			borderBottom: "1px solid #343d47",
			borderRadius: "4px 4px 4px 4px",
		};
	} else if (ruleIndex === 0) {
		//first rule in group
		rowStyle = {
			marginTop: 5,
			paddingTop: 5,
			paddingBottom: 10,
			background: "white",
			borderTop: "1px solid #343d47",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderRadius: "4px 4px 0 0",
			borderBottom: "1px solid gray",
		};
	} else if (ruleIndex + 1 === brancherRules.length) {
		//last rule in group
		rowStyle = {
			paddingTop: 10,
			paddingBottom: 10,
			background: "white",
			borderBottom: "1px solid #343d47",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderRadius: "0 0 4px 4px",
		};
	} else {
		//any middle rules
		rowStyle = {
			paddingTop: 10,
			paddingBottom: 10,
			background: "white",
			borderLeft: "1px solid #343d47",
			borderRight: "1px solid #343d47",
			borderBottom: "1px solid gray",
		};
	}

	return (
		<Grid container style={rowStyle}>
			<Grid
				item
				xs={1}
				style={{ marginRight: -10, display: "flex", alignItems: "center" }}
			>
				{/*<OutOfGroup*/}
				{/*	nestedGroup={nestedGroup}*/}
				{/*	brancherRule={brancherRule}*/}
				{/*	brancherRules={brancherRules}*/}
				{/*/>*/}
				<GroupCheckbox
					brancherRule={brancherRule}
					checkedBoxes={checkedBoxes}
					setCheckedBoxes={setCheckedBoxes}
				/>
			</Grid>
			<Grid item xs={4} style={{ paddingRight: 10 }}>
				<AttributeSelector
					brancherRule={brancherRule}
					attributes={attributes}
				/>
			</Grid>
			{brancherRule.attributeID && (
				<Grid item xs={3} style={{ paddingRight: 10 }}>
					<EqualitySelector brancherRule={brancherRule} />
				</Grid>
			)}
			{brancherRule.operator && (
				<Grid item xs={4}>
					<OptionField brancherRule={brancherRule} attributes={attributes} />
				</Grid>
			)}
		</Grid>
	);
};

const GroupCheckbox = ({ checkedBoxes, setCheckedBoxes, brancherRule }) => {
	return (
		<Checkbox
			checked={checkedBoxes.includes(brancherRule.id)}
			onChange={(evt) => {
				if (evt.target.checked) {
					setCheckedBoxes([...checkedBoxes, brancherRule.id]);
				} else {
					setCheckedBoxes(without(checkedBoxes, brancherRule.id));
				}
			}}
		/>
	);
};

// const OutOfGroup = ({ nestedGroup, brancherRule, brancherRules }) => {
// 	if (!nestedGroup) {
// 		return null;
// 	}
// 	return (
// 		<ArrowBackIcon
// 			style={{ cursor: "pointer" }}
// 			onClick={async () => {
// 				const otherRules = brancherRules.filter(
// 					(rule) => rule.id !== brancherRule.id
// 				);
// 				// await client.service("brancherRuleV2").patch(brancherRule.id, {
// 				// 	parentGroupID: null,
// 				// 	brancherID: nestedGroup.brancherID,
// 				// });
// 			}}
// 		/>
// 	);
// };

const AttributeSelector = ({ attributes, brancherRule }) => {
	const [saveValue, setSaveValue] = useState(null);
	let attributeValueObj = attributes.find((attribute) => {
		return attribute.id === brancherRule.attributeID;
	});

	return (
		<Select
			theme={ReactSelectTheme}
			styles={ReactSelectStyles}
			onChange={async (value) => {
				setSaveValue(value);
				const saveID = nanoid();
				savingQueue.push(saveID);

				let toSave = { attributeID: value.value };
				if (!brancherRule.operator) {
					toSave.operator = "Equal To";
				}

				await client.service("brancherRuleV2").patch(brancherRule.id, toSave);
				pull(savingQueue, saveID);
				setSaveValue(null);
			}}
			options={attributes.map((attribute) => {
				return {
					value: attribute.id,
					label: attribute.name,
				};
			})}
			value={
				saveValue ||
				(attributeValueObj && {
					value: attributeValueObj.id,
					label: attributeValueObj.name,
				})
			}
			menuPlacement="auto"
		/>
	);
};

const EqualitySelector = ({ brancherRule }) => {
	const [saveValue, setSaveValue] = useState(null);

	const operators = [
		"Equal To",
		"Not Equal To",
		"Less",
		"Less Or Equal",
		"Greater",
		"Greater Or Equal",
		"Between",
		"Not Between",
		"Begins With",
		"Not Begins With",
		"Contains",
		"Not Contains",
		"Ends With",
		"Not Ends With",
		"Is Empty",
		"Is Not Empty",
	];

	return (
		<Select
			theme={ReactSelectTheme}
			options={operators.map((operator) => ({
				label: operator,
				value: operator,
			}))}
			styles={ReactSelectStyles}
			value={
				saveValue ||
				(brancherRule.operator && {
					value: brancherRule.operator,
					label: brancherRule.operator,
				})
			}
			onChange={async (value) => {
				setSaveValue(value);
				const saveID = nanoid();
				savingQueue.push(saveID);

				if (noOptionFields.includes(value.value)) {
					await client.service("brancherRuleV2").patch(brancherRule.id, {
						operator: value.value,
						optionValue: null,
						optionID: null,
					});
				} else if (textInputFields.includes(value.value)) {
					await client
						.service("brancherRuleV2")
						.patch(brancherRule.id, { operator: value.value, optionID: null });
				} else if (sliderFields.includes(value.value)) {
					await client
						.service("brancherRuleV2")
						.patch(brancherRule.id, { operator: value.value, optionID: null });
				} else {
					await client.service("brancherRuleV2").patch(brancherRule.id, {
						operator: value.value,
						optionValue: null,
					});
				}

				pull(savingQueue, saveID);
				setSaveValue(null);
			}}
			menuPlacement="auto"
		/>
	);
};

const optionFieldSaveDebounce = debounce(
	async (value, brancherRule, setSaveValue, saveValueRef) => {
		const saveID = nanoid();
		savingQueue.push(saveID);

		await client
			.service("brancherRuleV2")
			.patch(brancherRule.id, { optionValue: value });

		if (saveValueRef.current === value) {
			setSaveValue(null);
		}

		pull(savingQueue, saveID);
	},
	500
);

const OptionField = ({ brancherRule, attributes }) => {
	const [saveValue, setSaveValue] = useState(null);
	const attributeValueObj = attributes.find((attribute) => {
		return attribute.id === brancherRule.attributeID;
	});
	const { type } = attributeValueObj;
	const saveValueRef = usePrevious(saveValue);

	if (noOptionFields.includes(brancherRule.operator)) {
		return null;
	} else if (
		textInputFields.includes(brancherRule.operator) ||
		type === "retrieved" ||
		type === "raw"
	) {
		return (
			<TextField
				variant="outlined"
				inputProps={{ style: { padding: 10 } }}
				value={saveValue || brancherRule.optionValue || ""}
				onChange={(evt) => {
					setSaveValue(evt.target.value);
					optionFieldSaveDebounce(
						evt.target.value,
						brancherRule,
						setSaveValue,
						saveValueRef
					);
				}}
			/>
		);
	} else if (sliderFields.includes(brancherRule.operator)) {
		const numberOptions = attributeValueObj.options
			.map((option) => parseFloat(option.name))
			.filter((option) => !isNaN(option));

		const rangeMax = numberOptions.length ? Math.max(...numberOptions) : 10;

		let value;
		if (saveValue) {
			value = saveValue;
		} else if (
			brancherRule?.optionValue &&
			typeof brancherRule.optionValue === "string" &&
			RegExp("\\d+\\-\\d+").test(brancherRule.optionValue)
		) {
			value = brancherRule.optionValue.split("-").map((num) => parseInt(num));
		} else {
			value = [0, rangeMax];
		}

		return (
			<div
				style={{
					paddingLeft: 15,
					paddingRight: 15,
					paddingTop: 25,
					marginBottom: -20,
				}}
			>
				<Slider
					value={value}
					onChange={(evt, newValue) => {
						setSaveValue(newValue);
						optionFieldSaveDebounce(
							newValue.join("-"),
							brancherRule,
							setSaveValue,
							saveValueRef
						);
					}}
					min={0}
					max={rangeMax}
					valueLabelDisplay="on"
				/>
			</div>
		);
	} else if (type === "direct" || type === "calculated") {
		const optionObj = attributeValueObj?.options.find((option) => {
			return option.id === brancherRule?.optionID;
		});

		return (
			<Select
				styles={ReactSelectStyles}
				theme={ReactSelectTheme}
				options={attributeValueObj.options
					.map((option) => ({
						label: option.name,
						value: option.id,
					}))
					.sort((a, b) => {
						return a.value - b.value;
					})}
				value={
					saveValue ||
					(optionObj && { value: optionObj.id, label: optionObj.name })
				}
				onChange={async (value) => {
					setSaveValue(value);
					const saveID = nanoid();
					savingQueue.push(saveID);

					await client
						.service("brancherRuleV2")
						.patch(brancherRule.id, { optionID: value.value });
					pull(savingQueue, saveID);
					setSaveValue(null);
				}}
				menuPlacement="auto"
			/>
		);
	} else {
		return <p>Error - This attribute type has not been implemented yet</p>;
	}
};
