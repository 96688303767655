import { action, makeAutoObservable, remove, runInAction } from "mobx";
import client from "../../../lib/feathers";
import { logError } from "../../../lib/ErrorLogger";

const F = () => false;

const initialState = {
	loading: true,
	tasks: {},
	selectedTask: null,
	selectedErrorFilter: { label: null, value: null, id: null },
	output: {},

	get filteredOutput() {
		const selectedErrorFilter = this.selectedErrorFilter?.id;
		const filterFunction =
			errorFilters.find((item) => selectedErrorFilter === item.id)?.filter || F;
		return Object.values(this.output).filter(filterFunction);
	},
};

// const makeStore = makeAutoObservable (initialState)

const createStore = () => makeAutoObservable(initialState);

export const contentStore = createStore();

export const reset = action(() => {
	Object.entries(initialState).forEach(([key, value]) => {
		contentStore[key] = value;
	});
});

export const init = action(() => {
	contentStore.loading = true;
	return client
		.service("contentTask")
		.find({
			query: {
				include: ["Canvas"],
			},
		})
		.then(
			action((tasks) => {
				contentStore.loading = false;
				contentStore.tasks = {
					...contentStore.tasks,
					...reduceById(tasks.filter(hasCanvas)),
				};
				return tasks;
			})
		)
		.catch(
			action((error) => {
				contentStore.loading = false;
				logError(error);
				return Promise.reject(error);
			})
		);
});

export const loadContent = (contentTaskIDs) =>
	client
		.service("contentOutput")
		.find({
			query: {
				contentTaskID: contentTaskIDs,
				include: ["Element", "Message"],
			},
		})
		.then(
			action((output) => {
				contentStore.output = { ...reduceById(output) };
				return output;
			})
		);

const filterFields = [
	{
		// 2
		name: "Empty Element/Case",
		key: "emptyElement",
	},
	{
		// 3
		name: "Missing Space",
		key: "missingSpace",
	},
	{
		// 4
		name: "Two Spaces",
		key: "twoSpaces",
	},
	{
		// 5
		name: "Missing Quote",
		key: "missingQuote",
	},
	{
		// 6
		name: "Double Words",
		key: "doubleWords",
	},
	{
		// 7
		name: "Leading or Trailing Space",
		key: "leadingOrTrailingSpace",
	},
	{
		// 8
		name: "Question Goes Here",
		key: "questionGoesHere",
	},
	{
		// 9
		name: "Misspelled Word",
		key: "misspelledWord",
	},
];

const prop = (key) => (target) => target[key];

const errorKeys = filterFields.map(({ key }) => key);

const allErrors = {
	name: "All Errors",
	// Include the given output record if any of its 'error' fields are true
	filter: (output) => errorKeys.some((key) => output[key]),
	isAll: true,
};

//-- Error filters are used to filter content output
export const errorFilters = [allErrors]
	.concat(filterFields)
	.map(({ name, key, filter = null, isAll = false }, index) => ({
		name,
		key,
		isAll,
		id: index + 1,
		filter: filter || prop(key),
		label: name,
		value: index + 1,
	}));

const reduceById = (list) =>
	list.reduce((accum, item) => {
		accum[item.id] = item;
		return accum;
	}, {});

const hasCanvas = (task) => task.canvas?.id;

/**
 * @func onFixed - Mark a ContentError as "Fixed" by setting its respective error key to "false"
 * @param {Object} field - Error keys
 * @param {Number} id
 * @returns {Promise<*>}
 */
export const onFixed = async (field, id) => {
	const errorField = errorFilters.find((f) => field.id === f.id);
	const keys = !errorField.isAll
		? [errorField.key]
		: filterFields.map(({ key }) => key);
	const payload = keys.reduce((obj, key) => ({ ...obj, [key]: false }), {});
	const data = await client.service("contentOutput").patch(id, payload);
	runInAction(() => {
		remove(contentStore.output, id);
	});
	return data;
};

/**
 * @func onIgnore - Create a `ContentIgnore` record so it's ignored next time,
 *     then call `onDone` to remove this error type
 * @param {Object} field
 * @param {Object} output
 * @param {Number} output.id
 * @param {String} output.name
 * @param {Number} output.messageID
 * @param {Number} output.elementID
 * @returns {Promise<*>}
 */
export const onIgnore = async (field, { id, name, messageID, elementID }) => {
	const errorField = errorFilters.find((f) => field.id === f.id);
	const keys = !errorField.isAll
		? [errorField.key]
		: filterFields.map(({ key }) => key);
	await Promise.all(
		keys.map((key) =>
			client.service("contentIgnore").create({
				messageID: messageID,
				elementID: elementID,
				[key]: true,
			})
		)
	);
	return onFixed(errorField, id);
};
