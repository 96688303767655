import { observable, action } from "mobx";

const snackbarStore = new observable({
	open: false,
	severity: "",
	message: "",
	setMessage: action(function (severity, message) {
		this.severity = severity;
		this.message = message;

		//no idea why this set timeout is necessary, but if you figure it out, let @max know
		setTimeout(() => {
			this.open = true;
		}, 1);
	}),
});

export default snackbarStore;
