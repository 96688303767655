import React, { useState } from "react";
import { observer } from "mobx-react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import shortid from "shortid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPencil,
	faPlus,
	faSave,
	faTimes,
	faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useHistory } from "react-router-dom";
import client from "../../../lib/feathers";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { runInAction } from "mobx";
import { pageStore } from "./Elements";
import Paper from "@material-ui/core/Paper";
import Dropzone from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import API from "../../../API";

const ImageElements = observer(({ messageDBID, match }) => {
	return (
		<Accordion defaultExpanded>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<div style={{ width: "100%" }}>
					<h3
						style={{
							marginBottom: 0,
							float: "left",
						}}
					>
						Image Elements
					</h3>
					<ButtonGroup
						color="secondary"
						aria-label="text primary button group"
						style={{ float: "right" }}
						onClick={(event) => event.stopPropagation()}
						onFocus={(event) => event.stopPropagation()}
					>
						<Tooltip title="New Image Element">
							<Button
								onClick={() => {
									pageStore.imageElements.push({
										id: shortid.generate(),
										messageDBID,
										edit: true,
									});
								}}
							>
								<FontAwesomeIcon icon={faPlus} />
							</Button>
						</Tooltip>
					</ButtonGroup>
				</div>
			</AccordionSummary>
			<AccordionDetails
				style={{ flexDirection: "column", maxHeight: 450, overflowY: "auto" }}
			>
				{pageStore.imageElements.map((imageElement, index) => (
					<ImageElementInList
						imageElement={imageElement}
						index={index}
						key={imageElement.id}
						match={match}
					/>
				))}
			</AccordionDetails>
		</Accordion>
	);
});

const ImageElementInList = observer(({ imageElement, index, match }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [editName, setEditName] = useState(imageElement.name || "");

	const isSelected = pageStore.selectedImageElement?.id === imageElement.id;

	const save = async () => {
		setLoading(true);
		if (shortid.isValid(imageElement.id)) {
			try {
				await client
					.service("imageElement")
					.create({ messageDBID: imageElement.messageDBID, name: editName });
				pageStore.imageElements.splice(index, 1);
			} catch (e) {
				if (e.code === 400) {
					alert(
						"Error creating image element. Image element name probably already exists."
					);
					setLoading(false);
				}
			}
		} else {
			pageStore.imageElements[index].edit = false;
			await client
				.service("imageElement")
				.patch(imageElement.id, { name: editName });
			setLoading(false);
		}
	};

	if (imageElement.edit) {
		return (
			<form style={{ display: "flex", alignItems: "center" }}>
				<div style={{ flexGrow: 100 }}>
					<TextField
						label="Name"
						variant="outlined"
						value={editName}
						onChange={(evt) => {
							setEditName(evt.target.value);
						}}
						size="small"
						disabled={loading}
						autoFocus
						autoComplete="off"
					/>
				</div>
				<div style={{ flex: 1 }}>
					{loading ? (
						<div style={{ justifyContent: "center", display: "flex" }}>
							<CircularProgress size={28} />
						</div>
					) : (
						<ButtonGroup
							color="secondary"
							aria-label="text primary button group"
							style={{ width: "100%", marginLeft: 15 }}
						>
							<Button
								onClick={save}
								disabled={!editName || editName === ""}
								type="submit"
							>
								<FontAwesomeIcon icon={faSave} />
							</Button>
							<Button
								onClick={async () => {
									pageStore.imageElements[index].edit = false;
								}}
							>
								<FontAwesomeIcon icon={faTimes} />
							</Button>
						</ButtonGroup>
					)}
				</div>
			</form>
		);
	} else {
		return (
			<div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<div style={{ flexGrow: 100 }}>
						<Button
							style={{
								width: "100%",
								justifyContent: "left",
								textTransform: "none",
								fontWeight: isSelected ? 700 : 400,
								background: isSelected ? "#FEECF0" : "inherit",
							}}
							onClick={() => {
								runInAction(() => {
									pageStore.selectedElement = null;
									pageStore.selectedElementIndex = null;
									pageStore.selectedImageElementIndex = index;
									pageStore.selectedImageElement = imageElement;
								});
								history.push(
									`/canvas/${match.params.canvasID}/${match.params.messageID}`
								);
							}}
						>
							{imageElement.name}
						</Button>
					</div>
					<div style={{ flex: 1 }}>
						{loading ? (
							<div style={{ justifyContent: "center", display: "flex" }}>
								<CircularProgress size={28} />
							</div>
						) : (
							<ButtonGroup>
								<Tooltip title="Edit Name">
									<Button
										onClick={() => {
											pageStore.imageElements[index].edit = true;
										}}
									>
										<FontAwesomeIcon icon={faPencil} />
									</Button>
								</Tooltip>
								<Tooltip title="Delete">
									<Button
										onClick={async () => {
											if (
												window.confirm(
													"Are you sure you want to delete this image element?"
												)
											) {
												await client
													.service("imageElement")
													.remove(imageElement.id);
											}
										}}
									>
										<FontAwesomeIcon icon={faTrash} />
									</Button>
								</Tooltip>
							</ButtonGroup>
						)}
					</div>
				</div>
			</div>
		);
	}
});

export const ImageElementContent = observer(() => {
	const onDrop = async (files) => {
		const file = files[0];

		if (!file) {
			return alert("File too large or not supported.");
		}

		const formData = new FormData();
		formData.append("file", file);

		const { error, imageDB } = await API(
			`/imageUpload?imageElementID=${pageStore.selectedImageElement.id}&env=${process.env.REACT_APP_ENV}`,
			"POST",
			null,
			null,
			formData
		);
		if (error) {
			console.error(error);
			alert("Something went wrong uploading that image.");
		} else {
			pageStore.selectedImageElement.image = imageDB;
		}
	};

	return (
		<Paper style={{ marginTop: 20, padding: 10, marginBottom: 50 }}>
			<h3>Image</h3>
			<Grid container>
				<Grid item xs={6}>
					{pageStore.selectedImageElement.image ? (
						<img
							src={`https://images.tuzagtcs.com/${process.env.REACT_APP_ENV}/${pageStore.selectedImageElement.image.id}.${pageStore.selectedImageElement.image.extension}`}
							style={{ maxWidth: "100%" }}
							alt={""}
						/>
					) : (
						<Dropzone
							multiple={false}
							onDrop={onDrop}
							accept={[
								"image/png",
								"image/jpeg",
								"image/svg+xml",
								"image/webp",
							]}
							maxSize={10485760 /*10mb*/}
						>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div
										{...getRootProps()}
										style={{
											width: "100%",
											height: 75,
											borderWidth: 2,
											borderColor: "darkGray",
											borderStyle: "dashed",
											borderRadius: 5,
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<input {...getInputProps()} />
										<p>Drag 'n' drop image here, or click to select file</p>
									</div>
								</section>
							)}
						</Dropzone>
					)}
				</Grid>
				<Grid item xs={6}>
					{pageStore.selectedImageElement.image ? (
						<>
							<Button
								onClick={async () => {
									if (
										window.confirm(
											"Are you sure you want to delete this image?"
										)
									) {
										client
											.service("imageElement")
											.patch(pageStore.selectedImageElement.id, {
												imageID: null,
											});
										client
											.service("image")
											.remove(pageStore.selectedImageElement.image.id, {
												query: { env: process.env.REACT_APP_ENV },
											});
										pageStore.selectedImageElement.image = null;
									}
								}}
							>
								Delete image
							</Button>
							<TextField
								label={"Class name"}
								value={pageStore.selectedImageElement.image.class || ""}
								onChange={(evt) => {
									pageStore.selectedImageElement.image.class = evt.target.value;
								}}
								onBlur={(evt) => {
									client
										.service("image")
										.patch(pageStore.selectedImageElement.image.id, {
											class: evt.target.value,
										});
								}}
							/>
						</>
					) : null}
				</Grid>
			</Grid>
		</Paper>
	);
});

export default ImageElements;
