import React, { Component } from "react";
import { observer } from "mobx-react";
import shortid from "shortid";
import TextField from "@material-ui/core/TextField";
import { pageStore, saveElementOrderToDB } from "./Elements";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import API from "../../../API";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPencil,
	faSave,
	faTrash,
	faTimes,
	faCopy,
	faComments,
	faCommentDots,
	faStickyNote,
} from "@fortawesome/pro-solid-svg-icons";
import duplicate from "../../../img/duplicate.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Comments from "./Comments";
import Popover from "@material-ui/core/Popover";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { action } from "mobx";

export default observer(
	class ElementInList extends Component {
		constructor(props) {
			super(props);

			this.state = {
				loading: false,
				deleteConfirm: false,
				commentsModalOpen: props.commentElementID === props.element.id,
			};
		}

		save = async () => {
			this.setState({ loading: true });

			let toSend = JSON.parse(JSON.stringify(this.props.element));
			if (shortid.isValid(toSend.id)) {
				delete toSend.id;
			}
			delete toSend.content; //don't send the content when saving the name
			try {
				const { data } = await API(
					`/db/element`,
					"POST",
					toSend,
					null,
					null,
					this.props.enqueueSnackbar
				);

				this.setState({ loading: false });

				if (data.error) {
					if (data.code === "23505") {
						this.props.enqueueSnackbar("Element names must be unique", {
							variant: "error",
						});
					} else {
						console.error(data);
						this.props.enqueueSnackbar("Error saving element", {
							variant: "error",
						});
					}
				} else {
					pageStore.elements[this.props.index].id = data.id;
					pageStore.elements[this.props.index].name = toSend.name;
					pageStore.elements[this.props.index].edit = false;
					if (!pageStore.elements[this.props.index].content) {
						pageStore.elements[this.props.index].content = [
							{
								type: "paragraph",
								children: [{ text: "" }],
							},
						];
					}
				}
			} catch (e) {
				console.log(e);
				//We handle this in API.js
				// this.props.enqueueSnackbar("Error saving element", {
				// 	variant: "error",
				// });
			}
		};

		delete = async () => {
			this.setState({ loading: true });
			await API(`/db/element/${this.props.element.id}`, "DELETE");
			pageStore.selectedElement = null;
			pageStore.selectedElementIndex = null;
			pageStore.elements.splice(this.props.index, 1);
			pageStore.elements = pageStore.elements.map((item, index) => ({
				...item,
				order: index + 1,
			}));
			saveElementOrderToDB();
		};

		render() {
			const hasComments = !!pageStore.notifications?.find((notificiation) => {
				return notificiation.comment.associatedID === this.props.element.id;
			});
			const isSelected =
				pageStore.selectedElement &&
				pageStore.selectedElement.id === this.props.element.id;

			return (
				<div className="ElementInList">
					{shortid.isValid(this.props.element.id) || this.props.element.edit ? (
						<form style={{ display: "flex", alignItems: "center" }}>
							<div style={{ flexGrow: 100 }}>
								<TextField
									label="Name"
									variant="outlined"
									value={this.props.element.name || ""}
									onChange={(evt) => {
										pageStore.elements[this.props.index].name =
											evt.target.value;
									}}
									size="small"
									disabled={this.state.loading}
									autoFocus
									autoComplete="off"
								/>
							</div>
							<div style={{ flex: 1 }}>
								{this.state.loading ? (
									<div style={{ justifyContent: "center", display: "flex" }}>
										<CircularProgress size={28} />
									</div>
								) : (
									<ButtonGroup
										color="secondary"
										aria-label="text primary button group"
										style={{ width: "100%", marginLeft: 15 }}
									>
										<Button
											onClick={this.save}
											disabled={
												!this.props.element.name ||
												this.props.element.name === ""
											}
											type="submit"
										>
											<FontAwesomeIcon icon={faSave} />
										</Button>
										<Button
											onClick={() => {
												if (this.props.element.edit) {
													pageStore.elements[this.props.index].edit = false;
												} else {
													pageStore.elements.splice(this.props.index, 1);
												}
											}}
										>
											<FontAwesomeIcon icon={faTimes} />
										</Button>
									</ButtonGroup>
								)}
							</div>
						</form>
					) : (
						<div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
								}}
							>
								<div style={{ flexGrow: 100 }}>
									<Button
										style={{
											width: "100%",
											justifyContent: "left",
											textTransform: "none",
											fontWeight: isSelected ? 700 : 400,
											background: isSelected ? "#FEECF0" : "inherit",
											opacity:
												pageStore.saveInProgress && isSelected
													? 0.618
													: "initial",
										}}
										onClick={async () => {
											if (pageStore.selectedElement) {
												const content = JSON.stringify(
													pageStore.selectedElement.content
												);
												if (
													content &&
													content !==
														`[{"type":"paragraph","children":[{"text":""}]}]`
												) {
													await this.props.save();
												}
											}
											if (!pageStore.saveInProgress) {
												/*MZM 9/24/20 & 4/12/21 Slate throws a fit when you change
												 * elements so we just close out of the element before switching.*/
												action(() => {
													pageStore.openBitID = null;
													pageStore.selectedElement = null;
													pageStore.selectedElementIndex = null;
												})();

												const { canvasID, messageID } = this.props.match.params;
												this.props.history.push(
													`/canvas/${canvasID}/${messageID}/element/${this.props.elementID}`
												);
											} else {
												this.props.enqueueSnackbar(
													"Please wait for the save to complete",
													{
														variant: "warning",
													}
												);
											}
										}}
									>
										{this.props.element.name}
									</Button>
								</div>
								<div style={{ flex: 1 }}>
									{this.state.loading ? (
										<div style={{ justifyContent: "center", display: "flex" }}>
											<CircularProgress size={28} />
										</div>
									) : (
										[
											<ButtonGroup
												key={1}
												color="secondary"
												aria-label="text primary button group"
												style={{ marginLeft: 15 }}
											>
												<Tooltip title="Notes">
													<Button
														onClick={(evt) => {
															this.setState({ noteAnchor: evt.currentTarget });
														}}
													>
														<FontAwesomeIcon
															icon={faStickyNote}
															style={
																this.props.element.notes &&
																this.props.element.notes !== ""
																	? { color: "#f2a524" }
																	: null
															}
														/>
													</Button>
												</Tooltip>
												<Tooltip title="Edit Name">
													<Button
														onClick={() => {
															pageStore.elements[this.props.index].edit = true;
														}}
													>
														<FontAwesomeIcon icon={faPencil} />
													</Button>
												</Tooltip>
												<Tooltip title="Copy">
													<Button
														onClick={() => {
															window.localStorage.copyElementID = this.props.element.id;
															this.props.enqueueSnackbar("Element copied", {
																variant: "success",
															});
														}}
													>
														<FontAwesomeIcon icon={faCopy} />
													</Button>
												</Tooltip>
												<Tooltip title="Duplicate">
													<Button
														onClick={async () => {
															await API(`/elementCopy`, "POST", {
																elementID: this.props.element.id,
																destinationMessageID: this.props.element
																	.messageDBID,
															});
															this.props.loadElements();
														}}
													>
														<img
															src={duplicate}
															style={{ height: 16 }}
															alt=""
														/>
													</Button>
												</Tooltip>
												<Tooltip title="Delete">
													<Button
														onClick={() => {
															this.setState({ deleteConfirm: true });
														}}
														ref={(el) => {
															this.deleteButton = el;
														}}
													>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</Tooltip>
												<Tooltip title="Comments">
													<Button
														onClick={() => {
															this.setState({ commentsModalOpen: true });
														}}
													>
														<FontAwesomeIcon
															icon={hasComments ? faCommentDots : faComments}
															style={hasComments ? { color: "#f2a524" } : null}
														/>
													</Button>
												</Tooltip>
											</ButtonGroup>,
											<Menu
												key={2}
												anchorEl={this.deleteButton}
												keepMounted
												open={this.state.deleteConfirm}
												onClose={() => {
													this.setState({ deleteConfirm: false });
												}}
											>
												<MenuItem disabled>You Sure?</MenuItem>
												<MenuItem
													style={{ color: "red" }}
													onClick={this.delete}
												>
													Yes
												</MenuItem>
												<MenuItem
													onClick={() => {
														this.setState({ deleteConfirm: false });
													}}
												>
													No
												</MenuItem>
											</Menu>,
										]
									)}
									<Popover
										open={!!this.state.noteAnchor}
										anchorEl={this.state.noteAnchor}
										onClose={() => {
											this.setState({ noteAnchor: null });
											API("/db/element", "POST", {
												id: this.props.element.id,
												notes: this.props.element.notes,
											});
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}
									>
										<div style={{ padding: 10 }}>
											<p style={{ fontWeight: 700, fontFamily: "Raleway" }}>
												{this.props.element.name} Notes:
											</p>
											<TextareaAutosize
												rowsMin={3}
												placeholder="Notes"
												style={{ minWidth: 300 }}
												value={this.props.element.notes || ""}
												onChange={(evt) => {
													this.props.element.notes = evt.target.value;
												}}
											/>
										</div>
									</Popover>
								</div>
							</div>
						</div>
					)}
					<Dialog
						open={this.state.commentsModalOpen}
						onClose={() => {
							this.setState({
								commentsModalOpen: false,
							});
						}}
					>
						<DialogTitle style={{ minWidth: 450 }}>Comments</DialogTitle>
						<DialogContent>
							<Comments
								type="element"
								associatedID={this.props.element.id}
								history={this.props.history}
							/>
						</DialogContent>
					</Dialog>
				</div>
			);
		}
	}
);
