import React from "react";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";

const RedButton = React.forwardRef((props, ref) => {
	const theme = useTheme();
	let propsToPass = { ...props };
	delete propsToPass.style;

	return (
		<Button
			variant="contained"
			style={{
				background: props.disabled
					? "var(--darkGray)"
					: theme.palette.primary.main,
				color: "white",
				width: "100%",
				...props.style,
			}}
			ref={ref}
			{...propsToPass}
		>
			{props.children}
		</Button>
	);
});

export default RedButton;
