import React, {
	useEffect,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Slate,
	Editable,
	withReact,
	useSlate,
	ReactEditor,
	useSelected,
} from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Editor, Transforms, Node, Range } from "slate";
import { Button, Icon, Toolbar, Menu } from "../../../lib/SlateLib";
import isHotkey from "is-hotkey";
import { css } from "emotion";
import bit from "../../../img/bit.svg";
import API from "../../../API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faProjectDiagram,
	faClipboard,
	faHandshake,
	faImage,
} from "@fortawesome/pro-solid-svg-icons";
import MenuItem from "@material-ui/core/MenuItem";
import MUIMenu from "@material-ui/core/Menu";
import externalLMEIcon from "../../../img/externalLME.svg";
import newLME from "../../../img/newlme.svg";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MessageBrowser from "./MessageBrowser";
import { pageStore, plainTextSerialize } from "./Elements";
import isUrl from "is-url";
import { logError } from "../../../lib/ErrorLogger";
import { loadingScreen } from "../../../App";
import client from "../../../lib/feathers";

const HOTKEYS = {
	"mod+b": "bold",
	"mod+i": "italic",
	"mod+u": "underline",
	"mod+s": "save",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

let _bitCreating = false;

const withMentions = (editor) => {
	const { isInline, isVoid } = editor;

	editor.isInline = (element) => {
		return element.passthrough ||
			element.passthrough ||
			element.lme ||
			(element.elementID && element.messageID) ||
			element.bitID ||
			element.imageElementID
			? true
			: isInline(element);
	};

	editor.isVoid = (element) => {
		return element.passthrough ||
			element.passthrough ||
			element.lme ||
			(element.elementID && element.messageID) ||
			element.bitID ||
			element.imageElementID
			? true
			: isVoid(element);
	};

	return editor;
};

const withLinks = (editor) => {
	const { insertData, insertText, isInline } = editor;

	editor.isInline = (element) => {
		return element.type === "link" ? true : isInline(element);
	};

	editor.insertText = (text) => {
		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertText(text);
		}
	};

	editor.insertData = (data) => {
		const text = data.getData("text/plain");

		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertData(data);
		}
	};

	return editor;
};

const SlateComp = (props) => {
	const renderElement = useCallback(
		(props2) => (
			<Element
				{...props2}
				pageStore={props.pageStore}
				save={props.save}
				elementOrBitCaseID={
					props.type === "element" ? props.elementID : props.bitCase.id
				}
				type={props.type}
			/>
		),
		[props.pageStore, props.save, props.bitCase, props.elementID, props.type]
	);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
	const editor = useMemo(
		() => withLinks(withHistory(withMentions(withReact(createEditor())))),
		[]
	);

	const [length, setLength] = useState(null);

	useEffect(() => {
		if (props.element) {
			if (props.element.minLength !== props.element.maxLength) {
				setLength(`${props.element.minLength}-${props.element.maxLength}`);
			} else if (
				props.element.minLength === 0 &&
				props.element.maxLength === 0
			) {
				//ignore
			} else {
				setLength(props.element.minLength);
			}
		} else if (props.bitCase) {
			(async () => {
				const plainTextValue = plainTextSerialize(props.value);
				let min = plainTextValue.length;
				let max = plainTextValue.length;
				let containsBits = false;
				const bits = JSON.stringify(props.value).matchAll(/"bitID":(\d+)?/g);
				for (const bit of bits) {
					containsBits = true;
					const bitID = bit[1];
					const bitsDB = await client
						.service("bit")
						.find({ query: { id: parseInt(bitID) } });
					for (const bitDB of bitsDB) {
						min += bitDB.minLength;
						max += bitDB.maxLength;
					}
				}
				if (containsBits) {
					if (min !== max) {
						setLength(`${min}-${max}`);
					} else if (min === 0 && max === 0) {
						//ignore
					} else {
						setLength(min);
					}
				} else if (plainTextValue.length) {
					setLength(plainTextValue.length);
				}
			})();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div style={{ margin: 20 }}>
			<Slate
				editor={editor}
				value={parseSlateValue(props.value)}
				onChange={(value) => props.onChange(parseSlateValue(value))}
				data-gramm="false"
			>
				<Toolbar>
					<MarkButton format="bold" icon="format_bold" />
					<MarkButton format="italic" icon="format_italic" />
					<MarkButton format="underline" icon="format_underlined" />
					<BlockButton format="numbered-list" icon="format_list_numbered" />
					<BlockButton format="bulleted-list" icon="format_list_bulleted" />
					<LinkButton />
					{props.elements && props.elements.length > 1 ? (
						<LMEButton elements={props.elements} />
					) : null}
					<ExternalLMEButton elements={props.elements} />
					{props.attributes && props.attributes.length > 0 ? (
						<PassthroughButton attributes={props.attributes} />
					) : null}
					{props.pageStore.copyBitID && props.elementID ? (
						<PasteButton
							copyBitID={props.pageStore.copyBitID}
							elementID={props.elementID}
							copyBitText={props.pageStore.copyBitText}
						/>
					) : null}
					{!props.pageStore.copyBitID &&
					window.localStorage.copyBitID &&
					props.elementID ? (
						<PasteButton
							copyBitID={window.localStorage.copyBitID}
							elementID={props.elementID}
							copyBitText={window.localStorage.copyBitText}
							bitCaseID={props.bitCase?.id}
						/>
					) : null}
					<ImageElementButton elements={pageStore.imageElements} />
					{props.toolbar ? (
						<div style={{ float: "right" }}>{props.toolbar}</div>
					) : null}
					{length ? (
						<p style={{ color: "gray", textAlign: "right" }}>{length}</p>
					) : null}
				</Toolbar>
				<HoveringToolbar
					elementOrBitCaseID={
						props.type === "element" ? props.elementID : props.bitCase.id
					}
					type={props.type}
					save={props.save}
					bitCase={props.bitCase}
					loadElements={props.loadElements}
					messageID={props.messageID}
				/>
				<Editable
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					placeholder="Enter your brilliant content here..."
					spellCheck
					autoFocus={props.type === "element"}
					style={{ lineHeight: 1.5 }}
					onKeyDown={(event) => {
						const { selection } = editor;
						if (selection) {
							for (const hotkey in HOTKEYS) {
								if (isHotkey(hotkey, event)) {
									event.preventDefault();
									if (hotkey === "mod+s") {
									} else {
										const mark = HOTKEYS[hotkey];
										toggleMark(editor, mark);
									}
								}
							}
						}
					}}
				/>
			</Slate>
		</div>
	);
};

/**
 * @func parseSlateValue - Recursively strip smart quotes & smart apostrophes
 * from Slate content
 * @param list
 */
const parseSlateValue = (list) => list.map(fixSlateNode);

const fixSlateNode = (obj) => {
	const fixedObj = fixText(obj);
	const { children } = fixedObj;
	// NOTE: We have to return a new object instead of mutating the existing
	// property because Slate uses frozen Objects instead of mutable objects
	return !children
		? fixedObj
		: {
				...fixedObj,
				children: children.map((child) => fixSlateNode(child)),
		  };
};

const fixText = (obj) =>
	!obj.text
		? obj
		: {
				...obj,
				text: replaceSmartApostrophes(replaceSmartQuotes(obj.text)),
		  };

const replaceSmartApostrophes = (str) =>
	str.replaceAll(`‘`, `'`).replaceAll(`’`, `'`);
const replaceSmartQuotes = (str) =>
	str.replaceAll(`“`, `"`).replaceAll(`”`, `"`);

const HoveringToolbar = ({
	elementOrBitCaseID,
	type,
	save,
	bitCase,
	loadElements,
	messageID,
}) => {
	const ref = useRef();
	const editor = useSlate();

	useEffect(() => {
		const el = ref.current;
		const { selection } = editor;

		if (!el) {
			return;
		}

		let selectedNode;

		try {
			if (selection) {
				selectedNode = Node.get(editor, selection.focus.path);
			}
		} catch (e) {
			return;
		}

		if (
			!selection ||
			(selectedNode && selectedNode.bit) ||
			!ReactEditor.isFocused(editor) ||
			Range.isCollapsed(selection) ||
			Editor.string(editor, selection) === ""
		) {
			el.removeAttribute("style");
			return;
		}

		const domSelection = window.getSelection();
		const domRange = domSelection.getRangeAt(0);
		const rect = domRange.getBoundingClientRect();
		el.style.opacity = 1;

		const paperEl = document.querySelector(
			"body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div"
		);

		el.style.top = `${rect.top + paperEl.scrollTop - el.offsetHeight}px`;
		el.style.left = `${
			rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
		}px`;
	});

	return (
		<Menu
			ref={ref}
			className={css`
				padding: 8px 7px 6px;
				position: absolute;
				z-index: 1;
				top: -10000px;
				left: -10000px;
				margin-top: -6px;
				opacity: 0;
				background-color: #222;
				border-radius: 4px;
				transition: opacity 0.75s;
			`}
		>
			<FormatButton format="bold" icon="format_bold" />
			<FormatButton format="italic" icon="format_italic" />
			<FormatButton format="underline" icon="format_underlined" />
			{type === "element" && (
				<BitButton
					elementOrBitCaseID={elementOrBitCaseID}
					type={type}
					save={save}
					bitCase={bitCase}
				/>
			)}
			<NewLMEButton
				loadElements={loadElements}
				messageID={messageID}
				save={save}
			/>
		</Menu>
	);
};

const BitButton = ({ format, elementOrBitCaseID, type, save, bitCase }) => {
	const editor = useSlate();
	return (
		<Button
			reversed
			active={isFormatActive(editor, format)}
			onClick={async (event) => {
				event.preventDefault();
				if (!_bitCreating) {
					_bitCreating = true;
					// setBitCreating(true);
					await addBit(editor, elementOrBitCaseID, type, save, bitCase);
					// setBitCreating(false);
					_bitCreating = false;
				}
			}}
		>
			<img src={bit} style={{ maxHeight: 20 }} alt="icon" />
		</Button>
	);
};

const NewLMEButton = ({ messageID, loadElements, save }) => {
	const editor = useSlate();

	return (
		<Button
			onClick={async (event) => {
				event.preventDefault();
				const name = window.getSelection().toString() || "LME";

				try {
					let element;
					const elements = await client
						.service("element")
						.find({ query: { messageDBID: messageID, name } });

					if (elements.length) {
						element = elements[0];
					} else {
						element = await client.service("element").create({
							messageDBID: messageID,
							name,
						});
						await loadElements();
					}

					Transforms.insertNodes(editor, {
						label: name,
						lme: element.id,
						children: [{ text: "" }],
					});

					setTimeout(() => {
						save(false, element.id);
					}, 250);
				} catch (e) {
					console.error(e);
					alert("Error creating new LME");
				}
			}}
		>
			<img src={newLME} style={{ maxHeight: 20 }} alt="icon" />
		</Button>
	);
};

const addBit = async (editor, elementOrBitCaseID, type, save, bitCase) => {
	loadingScreen.loading = true;
	await save();
	try {
		if (type === "element") {
			const { data } = await API(`/db/bit`, "POST", {
				elementID: elementOrBitCaseID,
			});
			if (!data.id) {
				throw new Error(
					`Error creating bit on elementID: ${elementOrBitCaseID}`
				);
			}
			Transforms.insertNodes(editor, {
				label: window.getSelection().toString() || "bit",
				bitID: data.id,
				children: [{ text: "" }],
			});
			pageStore.openBitID = data.id;

			setTimeout(() => {
				const bitElement = document.getElementById("bitCases");
				bitElement.scrollIntoView({
					behavior: "smooth",
				});
			}, 250);
		} else {
			const { data } = await API(`/db/bit`, "POST", {
				bitCaseID: bitCase.dbID || bitCase.id,
			});

			if (!data.id) {
				throw new Error(
					`Error creating bit on bitCaseID: ${bitCase.dbID || bitCase.id}`
				);
			}

			Transforms.insertNodes(editor, {
				label: window.getSelection().toString() || "bit",
				bitID: data.id,
				children: [{ text: "" }],
			});
		}
		await save();
		loadingScreen.loading = false;
	} catch (e) {
		console.error(e);
		logError(e, {
			elementID: elementOrBitCaseID,
			bitCaseID: bitCase.dbID || bitCase.id,
		});
		alert("Error creating bit. Refresh TCS to play it safe.");
	}
};

const FormatButton = ({ format, icon, img, elementID, type }) => {
	const editor = useSlate();
	return (
		<Button
			reversed
			active={isFormatActive(editor, format)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleMark(editor, format, elementID, type);
			}}
		>
			{img ? (
				<img src={img} style={{ maxHeight: 20 }} alt="icon" />
			) : (
				<Icon>{icon}</Icon>
			)}
		</Button>
	);
};

const isFormatActive = (editor, format) => {
	try {
		const [match] = Editor.nodes(editor, {
			match: (n) => n[format] === true,
			mode: "all",
		});
		return !!match;
	} catch (e) {
		return false;
	}
};

const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(editor, format);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n) => LIST_TYPES.includes(n.type),
		split: true,
	});

	Transforms.setNodes(editor, {
		type: isActive ? "paragraph" : isList ? "list-item" : format,
	});

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const toggleMark = async (editor, format, elementID) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

const isBlockActive = (editor, format) => {
	try {
		const [match] = Editor.nodes(editor, {
			match: (n) => n.type === format,
		});

		return !!match;
	} catch (e) {
		return false;
	}
};

const isMarkActive = (editor, format) => {
	try {
		const marks = Editor.marks(editor);
		return marks ? marks[format] === true : false;
	} catch (e) {
		return false;
	}
};

const Element = ({
	attributes,
	children,
	element,
	pageStore,
	save,
	elementOrBitCaseID,
	type,
}) => {
	const selected = useSelected();
	if (element.bitID) {
		return (
			<span
				className="bit"
				{...attributes}
				onClick={(evt) => {
					evt.preventDefault();
					if (evt.detail === 1) {
						//don't fire second time if double click
						bitClicked(element, pageStore, save, elementOrBitCaseID, type);
					}
				}}
				contentEditable={false}
				style={{
					boxShadow: selected ? "0 0 0 2px var(--red)" : "none",
					userSelect: "none",
				}}
			>
				{element.label}
				{children}
			</span>
		);
	}

	if (element.imageElementID) {
		return (
			<span
				className="bit"
				{...attributes}
				onClick={(evt) => {
					evt.preventDefault();
					if (evt.detail === 1) {
						//don't fire second time if double click
						// bitClicked(element, pageStore, save, elementOrBitCaseID, type);
					}
				}}
				contentEditable={false}
				style={{
					boxShadow: selected ? "0 0 0 2px var(--red)" : "none",
					userSelect: "none",
					color: "#16B1F0",
				}}
			>
				{element.label}
				{children}
			</span>
		);
	}

	if (element.passthrough) {
		return (
			<span
				className="passthrough"
				{...attributes}
				contentEditable={false}
				style={{
					boxShadow: selected ? "0 0 0 2px var(--red)" : "none",
					userSelect: "none",
				}}
			>
				{element.label}
				{children}
			</span>
		);
	}

	if (element.lme || (element.elementID && element.messageID)) {
		return (
			<span
				className="lme"
				{...attributes}
				onClick={(evt) => {
					// lmeClicked(evt, leaf, pageStore, save);
				}}
				contentEditable={false}
				style={{
					boxShadow: selected ? "0 0 0 2px var(--red)" : "none",
					userSelect: "none",
				}}
			>
				{element.label}
				{children}
			</span>
		);
	}

	switch (element.type) {
		case "bulleted-list":
			return <ul {...attributes}>{children}</ul>;
		case "list-item":
			return <li {...attributes}>{children}</li>;
		case "numbered-list":
			return <ol {...attributes}>{children}</ol>;
		case "link":
			return (
				<a
					{...attributes}
					href={element.url}
					style={{ color: "blue", textDecoration: "underline" }}
				>
					{children}
				</a>
			);
		default:
			return <p {...attributes}>{children}</p>;
	}
};

const bitClicked = async (leaf, pageStore, save, elementOrBitCaseID, type) => {
	if (save) {
		await save();
	}

	if (pageStore && type === "element") {
		pageStore.openBitID = leaf.bitID;
		pageStore.clickedOnBitCase = null;
	} else if (type === "bitCase") {
		pageStore.clickedOnBitCase = leaf.label;
	}
};

const Leaf = ({ attributes, children, leaf, pageStore, save }) => {
	if (leaf.bold) {
		children = <span style={{ fontWeight: 700 }}>{children}</span>;
	}

	if (leaf.italic) {
		children = <span style={{ fontStyle: "italic" }}>{children}</span>;
	}

	if (leaf.underline) {
		children = <span style={{ textDecoration: "underline" }}>{children}</span>;
	}

	return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
	const editor = useSlate();
	return (
		<Button
			active={isBlockActive(editor, format)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleBlock(editor, format);
			}}
		>
			<Icon>{icon}</Icon>
		</Button>
	);
};

const MarkButton = ({ format, icon }) => {
	const editor = useSlate();
	return (
		<Button
			active={isMarkActive(editor, format)}
			onMouseDown={(event) => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			<Icon>{icon}</Icon>
		</Button>
	);
};

const LMEButton = ({ elements }) => {
	const editor = useSlate();
	const [lmeButton, setLMEButton] = useState();

	return elements && elements.length ? (
		<>
			<Button
				active={isMarkActive(editor, "lme")}
				onMouseDown={(event) => {
					event.preventDefault();
					setLMEButton(event.target);
				}}
			>
				<FontAwesomeIcon icon={faProjectDiagram} />
			</Button>
			<MUIMenu
				key={2}
				anchorEl={lmeButton}
				keepMounted
				open={!!lmeButton}
				onClose={() => {
					setLMEButton(null);
				}}
			>
				{elements
					.filter((element) => element.id !== pageStore.selectedElement.id)
					.map((element) => {
						return (
							<MenuItem
								key={element.id}
								onClick={() => {
									Transforms.insertNodes(editor, {
										label: element.name,
										lme: element.id,
										children: [{ text: "" }],
									});
									setLMEButton(null);
								}}
							>
								{element.name}
							</MenuItem>
						);
					})}
			</MUIMenu>
		</>
	) : null;
};

const ExternalLMEButton = ({ elements }) => {
	const editor = useSlate();
	const [lmeButton, setXLMEButton] = useState();
	const [currentSelection, setCurrentSelection] = useState();

	return elements && elements.length ? (
		<>
			<Button
				active={isMarkActive(editor, "xlme")}
				onMouseDown={(event) => {
					//store selection (cursor position) before opening the modal which
					//will cause the selection to be lost, and we'll need it once the
					//XLME is selected
					setCurrentSelection(editor.selection);

					setXLMEButton(event.target);
				}}
			>
				<img
					src={externalLMEIcon}
					style={{ height: 18, marginBottom: -3 }}
					alt="external linked message element icon"
				/>
			</Button>
			{lmeButton ? (
				<Dialog
					onClose={() => {
						setXLMEButton(false);
					}}
					open={true}
					fullWidth
				>
					<DialogContent>
						<MessageBrowser
							insertLME={(element, message) => {
								if (element.id === pageStore.selectedElement.id) {
									alert(
										"You can't add insert a LME that links to its own element."
									);
								} else {
									setTimeout(() => {
										//Why the fuck is this code in a set timeout?
										//Great question. If you figure it out, let @max know... 😒
										Transforms.select(editor, currentSelection);

										Transforms.insertNodes(
											editor,
											{
												label: `[${message.name}]:[${element.name}]`,
												elementID: element.id,
												messageID: message.id,
												children: [{ text: "" }],
											},
											{ at: currentSelection.anchor }
										);
									}, 1);
								}

								setXLMEButton(false);
							}}
						/>
					</DialogContent>
				</Dialog>
			) : null}
		</>
	) : null;
};

const PassthroughButton = ({ attributes }) => {
	const editor = useSlate();
	const [lmeButton, setLMEButton] = useState();

	return attributes && attributes.length ? (
		<>
			<Button
				active={isMarkActive(editor, "passthrough")}
				onMouseDown={(event) => {
					event.preventDefault();
					setLMEButton(event.target);
				}}
			>
				<FontAwesomeIcon icon={faHandshake} />
			</Button>
			<MUIMenu
				key={2}
				anchorEl={lmeButton}
				keepMounted
				open={!!lmeButton}
				onClose={() => {
					setLMEButton(null);
				}}
			>
				{attributes.map((element) => {
					return (
						<MenuItem
							key={element.id}
							onClick={() => {
								Transforms.insertNodes(editor, {
									label: element.name,
									passthrough: element.id,
									children: [{ text: "" }],
								});
								Transforms.move(editor);
								setLMEButton(null);
							}}
						>
							{element.name}
						</MenuItem>
					);
				})}
			</MUIMenu>
		</>
	) : null;
};

const PasteButton = ({ copyBitID, elementID, copyBitText, bitCaseID }) => {
	const editor = useSlate();

	return (
		<Button
			active={false}
			onMouseDown={async (event) => {
				if (copyBitID && elementID) {
					const { newBitID } = await API(`/duplicateBit`, "POST", {
						sourceBitID: copyBitID,
						destinationElementID: elementID,
						bitCaseID,
					});
					Transforms.insertNodes(editor, {
						label: copyBitText,
						bitID: newBitID,
						children: [{ text: "" }],
					});
				} else {
					alert("Error pasting bit. Please contact support");
				}
			}}
		>
			<FontAwesomeIcon icon={faClipboard} />
		</Button>
	);
};

const ImageElementButton = ({ elements }) => {
	const editor = useSlate();
	const [imageElementButton, setImageElementButton] = useState();

	return elements && elements.length ? (
		<>
			<Button
				active={isMarkActive(editor, "lme")}
				onMouseDown={(event) => {
					event.preventDefault();
					setImageElementButton(event.target);
				}}
			>
				<FontAwesomeIcon icon={faImage} />
			</Button>
			<MUIMenu
				key={2}
				anchorEl={imageElementButton}
				keepMounted
				open={!!imageElementButton}
				onClose={() => {
					setImageElementButton(null);
				}}
			>
				{elements.map((element) => {
					return (
						<MenuItem
							key={element.id}
							onClick={() => {
								Transforms.insertNodes(editor, {
									label: `IMG: [${element.name}]`,
									imageElementID: element.id,
									children: [{ text: "" }],
								});
								setImageElementButton(null);
							}}
						>
							{element.name}
						</MenuItem>
					);
				})}
			</MUIMenu>
		</>
	) : null;
};

const isLinkActive = (editor) => {
	const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
	return !!link;
};

const insertLink = (editor, url) => {
	if (editor.selection) {
		wrapLink(editor, url);
	}
};

const wrapLink = (editor, url) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor);
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link = {
		type: "link",
		url,
		children: isCollapsed ? [{ text: url }] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: "end" });
	}
};

const unwrapLink = (editor) => {
	Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

const LinkButton = () => {
	const editor = useSlate();

	return (
		<Button
			active={isLinkActive(editor)}
			onMouseDown={(event) => {
				event.preventDefault();
				const url = window.prompt("Enter the URL of the link:");
				if (!url) return;
				insertLink(editor, url);
			}}
		>
			<Icon>link</Icon>
		</Button>
	);
};

export default SlateComp;
