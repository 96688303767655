import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { ReactSortable } from "react-sortablejs";
import API from "../../../API";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import {
	CircularProgress,
	DialogActions,
	DialogTitle,
} from "@material-ui/core";
import RedButton from "../../../lib/RedButton";
import { faSort } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "react-select";
import client from "../../../lib/feathers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Webix from "../../../lib/Webix";
import { generateFilters } from "../../../lib/HelperFunctions";
import Grid from "@material-ui/core/Grid";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

export default class QandALabel extends Component {
	state = {
		options: null,
		elements: [],
		ruleDialogOpen: false,
		rulesData: null,
		openAttributeRules: null,
		oneOptionShow: false,
	};

	async componentDidMount() {
		this.load();
	}

	async componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.messageID !== this.props.messageID) {
			this.load();
		} else if (
			prevProps.messageListenerAttributeID !==
			this.props.messageListenerAttributeID
		) {
			await API(`/db/customQuery/messageQandALabel`, "POST", {
				query: "delete",
				options: { where: { messageID: this.props.messageID } },
			});
			this.load();
		}
	}

	load = async () => {
		this.setState({ options: null });
		const { data } = await API(
			`/message/${this.props.messageID}/qAndALabels`,
			"GET",
			{}
		);

		const oneOptionShow = !!data.find((option) => !option.hide);

		const elements = await client
			.service("element")
			.find({ query: { messageDBID: this.props.messageID } });

		this.setState({ options: data, elements, oneOptionShow });
	};

	save = async (skipLoad = false) => {
		await API(`/message/${this.props.messageID}/qAndALabels`, "POST", {
			options: this.state.options,
		});

		if (!skipLoad) {
			this.load();

			this.props.enqueueSnackbar("Q&A Options Set", {
				variant: "success",
			});
		}
	};

	closeDialog = () => {
		this.setState({
			ruleDialogOpen: false,
			rulesData: null,
			openAttributeRules: null,
		});
	};

	openRules = async (attribute) => {
		await this.save(true);

		const { data } = await API(
			`/messageRulesData/${this.props.messageID}`,
			"GET"
		);

		if (attribute.rules) {
			this.setState({ rules: JSON.parse(attribute.rules) });
		} else {
			this.setState({
				rules: {
					glue: "and",
					rules: [{}],
				},
			});
		}
		this.setState({
			openAttributeRules: attribute,
			rulesData: data,
			ruleDialogOpen: true,
		});
	};

	render() {
		return (
			<Fragment>
				<Snackbar
					open={this.state.open}
					autoHideDuration={5000}
					onClose={() => {
						this.setState({
							open: false,
						});
					}}
					anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
				>
					<Alert
						elevation={6}
						severity={this.state.severity?.toLowerCase()}
						style={{ marginBottom: 100 }}
						onClose={() => {
							this.setState({
								open: false,
							});
						}}
					>
						<AlertTitle>{this.state.severity}</AlertTitle>
						{this.state.message}
					</Alert>
				</Snackbar>
				<Paper style={{ padding: 15, marginTop: 10 }}>
					<h3>
						Q&A Options{" "}
						<Tooltip
							title={
								<span>
									Remember to hit the save button when done working in this
									panel. It does <span style={{ fontWeight: 900 }}>NOT</span>{" "}
									auto save like other cards on this page.
								</span>
							}
						>
							<HelpOutlineIcon style={{ fontSize: 18 }} />
						</Tooltip>
					</h3>
					{!this.state.options ? (
						<div style={{ justifyContent: "center", display: "flex" }}>
							<CircularProgress size={28} />
						</div>
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>
												Label
												<Tooltip
													title={
														<span>
															The label used for the options displayed to the
															end user. If you want to use an element's content
															instead of a static label, switch on the "element"
															column. Leave empty to use the option name as the
															label.
														</span>
													}
												>
													<HelpOutlineIcon style={{ fontSize: 14 }} />
												</Tooltip>
											</TableCell>
											<TableCell style={{ textAlign: "center" }}>
												Element
												<Tooltip
													title={
														<span>
															Toggle on if you'd like to use an element's
															content for the label.
															<br />
															Toggle off if you'd like to use a static label or
															the option name.
														</span>
													}
												>
													<HelpOutlineIcon style={{ fontSize: 14 }} />
												</Tooltip>
											</TableCell>
											<TableCell style={{ textAlign: "center" }}>
												Rules
												<Tooltip
													title={
														<span>
															This will force the "show" column on and determine
															if this option is shown based off the rules
															specified.
															<br />
															If rules are empty, the "open rules" button will
															be red.
														</span>
													}
												>
													<HelpOutlineIcon style={{ fontSize: 14 }} />
												</Tooltip>
											</TableCell>
											<TableCell style={{ textAlign: "center" }}>
												Show
												<Tooltip
													title={
														<span>
															As long as "Rules" mode isn't on, this will
															unconditionally show or hide the option.
														</span>
													}
												>
													<HelpOutlineIcon style={{ fontSize: 14 }} />
												</Tooltip>
											</TableCell>
										</TableRow>
									</TableHead>
									<ReactSortable
										list={this.state.options}
										setList={(newState) => this.setState({ options: newState })}
										tag={TableBody}
									>
										{this.state.options.map((option, index) => {
											let value;
											if (option.elementIDForLabel) {
												const matchingElement = this.state.elements.find(
													(element) => {
														return element.id === option.elementIDForLabel;
													}
												);
												value = {
													label: matchingElement.name,
													value: matchingElement.id,
												};
											}
											return (
												<TableRow key={option.id}>
													<TableCell>
														{option.useElementAsLabel ? (
															<>
																<label style={{ color: "rgba(0, 0, 0, 0.54)" }}>
																	{option.name}
																</label>
																<Select
																	options={this.state.elements.map(
																		(element) => {
																			return {
																				label: element.name,
																				value: element.id,
																			};
																		}
																	)}
																	value={value}
																	onChange={(value) => {
																		const options = [...this.state.options];
																		options[index].elementIDForLabel =
																			value.value;
																		this.setState({ options });
																	}}
																/>
															</>
														) : (
															<TextField
																label={option.name}
																value={option.label || ""}
																disabled={option.hide}
																onChange={(evt) => {
																	const options = [...this.state.options];
																	options[index].label = evt.target.value;
																	this.setState({ options });
																}}
															/>
														)}
													</TableCell>
													<TableCell style={{ textAlign: "center" }}>
														<Switch
															checked={option.useElementAsLabel}
															onChange={(event) => {
																const options = [...this.state.options];
																options[index].useElementAsLabel =
																	event.target.checked;
																if (event.target.checked) {
																	options[index].label = null;
																} else {
																	options[index].elementIDForLabel = null;
																}
																this.setState({ options });
															}}
														/>
													</TableCell>
													<TableCell style={{ textAlign: "center" }}>
														<Switch
															checked={option.useRules}
															onChange={(event) => {
																const options = [...this.state.options];
																options[index].useRules = event.target.checked;
																if (event.target.checked) {
																	options[index].hide = false;
																	this.openRules(option);
																} else {
																	options[index].rules = null;
																}
																this.setState({ options });
															}}
														/>
														{option.useRules && (
															<>
																<br />
																<Button
																	onClick={() => {
																		this.openRules(option);
																	}}
																	style={{
																		color:
																			option.rules && option.rules !== "null"
																				? "inherit"
																				: "red",
																	}}
																>
																	Open Rules
																</Button>
															</>
														)}
													</TableCell>
													<TableCell style={{ textAlign: "center" }}>
														<Switch
															checked={!option.hide}
															onChange={(evt) => {
																const options = [...this.state.options];
																options[index].hide = !evt.target.checked;
																this.setState({
																	options,
																	oneOptionShow: evt.target.checked
																		? true
																		: this.state.oneOptionShow,
																});
															}}
															disabled={option.useRules}
														/>
														<FontAwesomeIcon
															icon={faSort}
															style={{ cursor: "ns-resize" }}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</ReactSortable>
								</Table>
							</TableContainer>
							<Grid container spacing={2}>
								<Grid item xs={10}>
									<RedButton onClick={this.save}>Save</RedButton>
								</Grid>
								<Grid item xs={2}>
									<Button
										variant="contained"
										onClick={() => {
											this.setState(
												{
													oneOptionShow: !this.state.oneOptionShow,
													options: this.state.options.map((option) => {
														option.hide = this.state.oneOptionShow;
														return option;
													}),
												},
												() => {
													this.save(true);
												}
											);
										}}
									>
										{this.state.oneOptionShow ? "Hide" : "Show"} All
									</Button>
								</Grid>
							</Grid>
							<Dialog
								onClose={this.closeDialog}
								open={this.state.ruleDialogOpen}
								fullWidth
								maxWidth="lg"
							>
								<DialogTitle>Rules</DialogTitle>
								<DialogContent>
									<Webix
										data={this.state.rules}
										ui={{
											id: `querybuilder`,
											view: "querybuilder",
											maxLevel: 3,
											fields: this.state.ruleDialogOpen
												? this.state.rulesData.map((row) => {
														const attribute = row.attribute;
														return {
															id: attribute.id,
															value: attribute.name,
															type:
																attribute.type === "direct" ||
																attribute.type === "calculated"
																	? attribute.id
																	: "text",
														};
												  })
												: [],
											filters: this.state.ruleDialogOpen
												? generateFilters(this.state.rulesData)
												: [],
										}}
									/>
								</DialogContent>
								<DialogActions>
									<Grid container>
										<Grid item xs={2} />
										<Grid item xs={3}>
											<Button
												variant="contained"
												color="secondary"
												style={{ width: "100%" }}
												onClick={this.closeDialog}
											>
												Close
											</Button>
										</Grid>
										<Grid item xs={2} />
										<Grid item xs={3}>
											<RedButton
												onClick={async () => {
													const querybuilder = window.$$(`querybuilder`);
													let updatedRules = JSON.stringify(
														querybuilder ? querybuilder.getValue() : null
													);

													const optionIndex = this.state.options.findIndex(
														(option) => {
															return (
																option.id === this.state.openAttributeRules.id
															);
														}
													);
													const updatedOptions = [...this.state.options];
													updatedOptions[optionIndex].rules = updatedRules;
													this.setState({ options: updatedOptions });

													await client
														.service("messageQAndALabel")
														.patch(
															this.state.openAttributeRules.labelOptionID,
															{
																rules: updatedRules,
															}
														);
													this.closeDialog();
												}}
											>
												Save & Close
											</RedButton>
										</Grid>
									</Grid>
								</DialogActions>
							</Dialog>
						</>
					)}
				</Paper>
			</Fragment>
		);
	}
}
