import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import { logError } from "../../../lib/ErrorLogger";
import { sortBy } from "../AttributeBuilder/store";

export const MoveToContextMenu = ({ anchorEl, open, setOpen, onClose }) => {
	if (!anchorEl) {
		return <> </>;
	}

	return (
		<Menu
			anchorEl={anchorEl}
			open={Boolean(anchorEl) && !open}
			onClose={onClose}
		>
			<MenuItem onClick={() => setOpen(true)}>Move To</MenuItem>
		</Menu>
	);
};

export const MoveToDialog = ({
	title,
	loadFolders,
	onSave,
	onClose,
	excludeIDs = [],
}) => {
	const [treeData, setTreeData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState(null);
	const [defaultExpandedKeys, setDefaultExpandedKeys] = useState([]);

	const onSubmit = () => {
		if (!selected) {
			return Promise.resolve(null);
		}
		setLoading(true);
		return onSave(selected[0]);
	};

	const onSelect = (selectedKeys, { node }) => {
		if (excludeIDs.includes(node.key)) {
			return null;
		}
		setSelected(node ? [node.key] : []);
	};

	useEffect(() => {
		setLoading(true);
		loadFolders()
			.then((folderList) => {
				const sortedRoots = sortBy("name")(
					folderList.filter(({ parentFolderID }) => parentFolderID === null)
				);
				setDefaultExpandedKeys(sortedRoots.map(({ id }) => id));
				setTreeData(
					sortedRoots.map((folder) =>
						Folder(folder, { excludeIDs, folders: folderList })
					)
				);
				setLoading(false);
				return folderList;
			})
			.catch((error) => {
				logError(error, {
					component: "MoveToDialog",
					method: "loadFolders",
				});
				setLoading(false);
				return Promise.reject(error);
			});
		// eslint-disable-next-line
	}, []);

	return (
		<Dialog onClose={onClose} open={true} maxWidth="lg" fullWidth>
			<DialogTitle id="form-dialog-title" style={{ minWidth: 450 }}>
				{title}
			</DialogTitle>
			<DialogContent dividers>
				<style dangerouslySetInnerHTML={{ __html: STYLE }} />
				{loading || !treeData.length ? (
					<CircularProgress />
				) : (
					<Tree
						treeData={treeData}
						onSelect={onSelect}
						selectedKeys={selected}
						allowDrop={false}
						defaultExpandedKeys={defaultExpandedKeys}
						showLine
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button
					color="primary"
					type="submit"
					disabled={!selected}
					onClick={onSubmit}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const STYLE = `
    .rc-tree-child-tree {
      display: block;
    }
    
    .node-motion {
      transition: all .3s;
      overflow-y: hidden;
    }
`;

const Folder = (folder, { excludeIDs, folders }) => {
	const { id, name } = folder;
	const children = folders
		.filter((f) => id === f.parentFolderID)
		.map((f) => Folder(f, { excludeIDs, folders }));
	return {
		...folder,
		children: sortBy("name")(children),
		disabled: excludeIDs.includes(id),
		isLeaf: false,
		key: id,
		title: name,
	};
};
