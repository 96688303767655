import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import RedButton from "../../lib/RedButton";
import React, { useState } from "react";
import { ElementSearchResults } from "./Search";
import API from "../../API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";

const SearchEmpty = () => {
	const [messageName, setMessageName] = useState("");
	const [elementName, setElementName] = useState("");
	const [elements, setElements] = useState();

	const search = async (evt) => {
		evt.preventDefault();

		const { elements } = await API("/searchEmpty", "POST", {
			messageName,
			elementName,
		});
		setElements(elements);
	};

	return (
		<Grid container={true} spacing={2}>
			<Grid item xs={6}>
				<Paper style={{ padding: 10, marginTop: 10 }}>
					<h3>
						Search Empty{"  "}
						<Tooltip
							title={`Finds empty elements based off search queries. Uses partial matches
						in message & element name, so you could do a search for a message
						name "Dashboard" and get back any elements that were empty on any
						message that contained "Dashboard" in its name.`}
						>
							<span>
								<FontAwesomeIcon
									icon={faQuestionCircle}
									style={{ fontSize: 14 }}
								/>
							</span>
						</Tooltip>
					</h3>
					<form onSubmit={search}>
						<TextField
							label={"Message Name"}
							variant="outlined"
							value={messageName}
							onChange={(evt) => {
								setMessageName(evt.target.value);
							}}
						/>
						<TextField
							label={"Element Name"}
							variant="outlined"
							value={elementName}
							onChange={(evt) => {
								setElementName(evt.target.value);
							}}
							style={{ marginTop: 15 }}
						/>
						{(messageName && messageName !== "") ||
						(elementName && elementName !== "") ? (
							<RedButton type="submit" style={{ marginTop: 15 }}>
								Search
							</RedButton>
						) : null}
					</form>
				</Paper>
			</Grid>
			<Grid item xs={6}>
				{elements && elements.length ? (
					<Paper style={{ padding: 10, marginTop: 10 }}>
						<h3>Elements</h3>
						<ElementSearchResults elements={elements} hideContent={true} />
					</Paper>
				) : null}
			</Grid>
		</Grid>
	);
};

export default SearchEmpty;
