import { observer } from "mobx-react";
import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { pageStore } from "../Canvas";

const ExtractorDialog = observer(
	class extends Component {
		close = () => {
			pageStore.ExtractorDialogOpen = false;
		};

		componentDidMount() {
			setTimeout(() => {
				pageStore.ExtractorDialogOpen = false;
			}, 2500);
		}

		render() {
			return (
				<Dialog onClose={this.props.close} open={true} fullWidth>
					<DialogContent>
						{/*<h3>{pageStore.mode} Extractor</h3>*/}
						<img
							src="https://media.giphy.com/media/Jf8tQksLHhpXa/source.gif"
							alt="computer says no"
							style={{ width: "100%" }}
						/>
						<Grid container>
							<Grid item xs={10}></Grid>
							<Grid item xs={2}>
								<Button onClick={this.close}>Cancel</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			);
		}
	}
);

export default ExtractorDialog;
