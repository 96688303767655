import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import SlateComp from "./Slate";
import { pageStore } from "./Elements";
import { CircularProgress } from "@material-ui/core";
import API from "../../../API";
import shortid from "shortid";
import { action, runInAction, toJS, observable } from "mobx";
import { observer } from "mobx-react";
import { findNode } from "../../../lib/HelperFunctions";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import VisibilitySensor from "react-visibility-sensor";
import duplicate from "../../../img/duplicate.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCommentDots,
	faComments,
	faPlus,
	faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Comments from "./Comments";
import Dialog from "@material-ui/core/Dialog";
import client from "../../../lib/feathers";
import { RuleContainer } from "./RuleContainer";

export default observer(
	class BitCases extends Component {
		state = { loading: true };

		async componentDidMount() {
			this.load();
		}

		componentWillUnmount() {
			client
				.service("channelManager")
				.remove({ name: `bitCase/${pageStore.openBitID}` });
			client
				.service("bitCase")
				.removeListener("created")
				.removeListener("removed")
				.removeListener("patched");
		}

		load = async () => {
			this.setState({ loading: true });
			pageStore.bitCases = [];

			client
				.service("channelManager")
				.create({ name: `bitCase/${pageStore.openBitID}` });

			const bitCasesDB = client.service("bitCase");
			let bitCases = await bitCasesDB.find({
				query: { bitID: pageStore.openBitID, $sort: { id: 1 } },
			});

			if (bitCases.length === 0) {
				bitCases = [
					await client.service("bitCase").create({
						bitID: pageStore.openBitID,
						content: JSON.stringify([
							{
								type: "paragraph",
								children: [{ text: "" }],
							},
						]),
					}),
				];

				await client
					.service("bitCaseRule")
					.create({ bitCaseID: bitCases[0].id, order: 0 });
			}

			bitCasesDB
				.on("created", (newRow) => {
					pageStore.bitCases.push(newRow);
				})
				.on("removed", (deleted) => {
					pageStore.bitCases = pageStore.bitCases.filter(
						(bitCase) => bitCase.id !== deleted.id
					);
				})
				.on("patched", async (patched) => {
					const toPatch = pageStore.bitCases.findIndex(
						(item) => item.id === patched.id
					);
					if (toPatch !== -1) {
						pageStore.bitCases[toPatch] = {
							...patched,
							//content is saved using the old REST endpoints instead of the socket
							//so don't overwrite whatever content that is in the UI that might
							//not have been saved yet -MZM 3/23/21
							content: pageStore.bitCases[toPatch].content,
						};
					}
				});

			pageStore.bitCases = bitCases.map((bitCase) => {
				return {
					...bitCase,
					content: JSON.parse(bitCase.content),
				};
			});

			let attributesData = await API(
				`/messageRulesData/${this.props.messageDBID}`,
				"GET"
			);
			this.setState({ loading: false, attributesData: attributesData.data });

			const { commentNotifications } = await API(
				`/commentNotifications/bitCase/${this.props.bitID}`,
				"GET",
				{}
			);

			pageStore.commentNotifications = commentNotifications;
		};

		componentDidUpdate(prevProps, prevState, snapshot) {
			if (prevProps.bitID !== this.props.bitID) {
				this.load();
			}
		}

		render() {
			return (
				<div id="bitCases">
					<Paper
						style={{
							marginTop: 20,
							padding: 10,
							minHeight: 150,
						}}
					>
						<Grid container>
							<Grid item xs={6}>
								<h3>Bit Cases</h3>
							</Grid>
							<Grid item xs={6} style={{ textAlign: "right" }}>
								<Button
									onClick={() => {
										const content = toJS(this.props.selectedElement.content);
										pageStore.copyBitID = parseInt(this.props.bitID);

										let matchingNode = { text: "pastedBit" };
										for (const paragraph of content) {
											matchingNode = findNode(pageStore.copyBitID, paragraph);
											break;
										}
										pageStore.copyBitText = matchingNode.label;

										window.localStorage.copyBitID = pageStore.copyBitID;
										window.localStorage.copyBitText = matchingNode.label;
									}}
								>
									Copy Bit
								</Button>
							</Grid>
						</Grid>
						{this.state.loading ? (
							<div style={{ justifyContent: "center", display: "flex" }}>
								<CircularProgress size={28} />
							</div>
						) : (
							<div>
								{pageStore.bitCases
									.filter((bitCase) => bitCase.bitID === this.props.bitID)
									.map((bitCase, index) => (
										<BitCase
											attributes={this.props.attributes}
											bitCase={bitCase}
											bitID={this.props.bitID}
											index={index}
											attributesData={this.state.attributesData}
											key={bitCase.id}
											setActiveElement={this.props.setActiveElement}
											history={this.props.history}
											commentBitCaseID={this.props.commentBitCaseID}
											save={this.props.save}
											selectedElement={this.props.selectedElement}
											messageDBID={this.props.messageDBID}
											loadElements={this.props.loadElements}
										/>
									))}
							</div>
						)}
					</Paper>
				</div>
			);
		}
	}
);

const BitCase = observer(
	class BitCase extends Component {
		constructor(props) {
			super(props);

			this.state = {
				deleteConfirm: false,
				isVisible: false,
				commentsModalOpen: props.commentBitCaseID === props.bitCase.id,
			};
			this.caseCheckboxes = observable.map({});
		}

		delete = async () => {
			if (!shortid.isValid(this.props.bitCase.id)) {
				await API(`/db/bitCase/${this.props.bitCase.id}`, "DELETE");
			}

			pageStore.bitCases.splice(this.props.index, 1);
		};

		getToolbar = () => {
			const hasComments = !!pageStore.commentNotifications?.find(
				(notification) => {
					return notification.comment.associatedID === this.props.bitCase.id;
				}
			);

			return (
				<>
					<ButtonGroup
						color="secondary"
						aria-label="text primary button group"
						size="small"
					>
						<Tooltip title="Duplicate">
							<Button
								onClick={async () => {
									await this.props.save();

									const { newBitCase } = await API(
										`/duplicateBitCase`,
										"POST",
										{
											bitCaseID: this.props.bitCase.id,
										}
									);
									pageStore.bitCases.push({
										...newBitCase,
										content: JSON.parse(newBitCase.content),
									});
								}}
							>
								<img src={duplicate} style={{ height: 16 }} alt="" />
							</Button>
						</Tooltip>
						{pageStore.bitCases.length > 1 ? (
							<Tooltip title="Remove">
								<Button
									onClick={() => {
										this.setState({ deleteConfirm: true });
									}}
									ref={(el) => {
										this.deleteButton = el;
									}}
								>
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							</Tooltip>
						) : null}
						<Tooltip title="Comments">
							<Button
								onClick={() => {
									this.setState({ commentsModalOpen: true });
								}}
							>
								<FontAwesomeIcon
									icon={hasComments ? faCommentDots : faComments}
									style={hasComments ? { color: "#f2a524" } : null}
								/>
							</Button>
						</Tooltip>
						<Tooltip title="Add Case">
							<Button
								onClick={async () => {
									await addNewBitCase(this.props.bitID);
								}}
							>
								<FontAwesomeIcon icon={faPlus} />
							</Button>
						</Tooltip>
					</ButtonGroup>
					<Menu
						anchorEl={this.deleteButton}
						keepMounted
						open={this.state.deleteConfirm}
						onClose={() => {
							this.setState({ deleteConfirm: false });
						}}
					>
						<MenuItem disabled>You Sure?</MenuItem>
						<MenuItem style={{ color: "red" }} onClick={this.delete}>
							Yes
						</MenuItem>
						<MenuItem
							onClick={() => {
								this.setState({ deleteConfirm: false });
							}}
						>
							No
						</MenuItem>
					</Menu>
				</>
			);
		};

		render() {
			return (
				<VisibilitySensor
					partialVisibility={true}
					onChange={(isVisible) => {
						if (!this.state.isVisible) {
							this.setState({ isVisible });
						}
					}}
				>
					<>
						<Paper
							elevation={10}
							style={{
								marginTop: 15,
								marginBottom: 15,
								border: "1px solid black",
								padding: 10,
							}}
							onFocus={(evt) => {
								this.props.setActiveElement(this.props.bitCase.id);
							}}
						>
							<span style={{ fontWeight: 700, fontFamily: "Raleway" }}>
								Case #{this.props.index + 1}
							</span>
							<FormControlLabel
								style={{ marginLeft: 15 }}
								control={
									<Checkbox
										checked={pageStore.bitCases[this.props.index].else}
										onChange={(evt) => {
											if (evt.target.checked) {
												pageStore.bitCases = pageStore.bitCases.map(
													(bitCase) => {
														bitCase.else = false;
														return bitCase;
													}
												);
												pageStore.bitCases[this.props.index].else = true;
											} else {
												pageStore.bitCases[this.props.index].else = false;
												pageStore.bitCases[this.props.index].rules = {
													glue: "and",
													rules: [{}],
												};
											}
										}}
									/>
								}
								label="Else"
							/>
							<SlateComp
								type="bitCase"
								value={toJS(this.props.bitCase.content)}
								onChange={(val) => {
									pageStore.bitCases[this.props.index].content = val;
								}}
								elements={pageStore.elements}
								toolbar={this.getToolbar()}
								attributes={this.props.attributes}
								bitCase={pageStore.bitCases[this.props.index]}
								pageStore={pageStore}
								save={this.props.save}
								elementID={this.props.selectedElement.id}
								messageID={pageStore.selectedElement.messageDBID}
								loadElements={this.props.loadElements}
							/>
							{this.props.bitCase.else || !this.state.isVisible ? null : (
								<RuleContainer
									bitCase={this.props.bitCase}
									attributes={this.props.attributes}
									messageID={pageStore.selectedElement.messageDBID}
								/>
							)}
						</Paper>
						<Dialog
							open={this.state.commentsModalOpen}
							onClose={() => {
								this.setState({
									commentsModalOpen: false,
								});
							}}
						>
							<DialogTitle style={{ minWidth: 450 }}>Comments</DialogTitle>
							<DialogContent>
								<Comments
									type="bitCase"
									associatedID={this.props.bitCase.id}
									history={this.props.history}
								/>
							</DialogContent>
						</Dialog>
					</>
				</VisibilitySensor>
			);
		}
	}
);

export const addNewBitCase = action(async (bitID) => {
	const { toCreate } = generateCasesToSave([{ ...blankBit(bitID) }]);
	const data = await API(`/bitCase`, "POST", {
		toCreateBits: toCreate,
		toUpdateBits: [],
	});
	await Promise.all(
		data.created.map((bitCase) =>
			client.service("bitCaseRule").create({
				bitCaseID: bitCase.id,
				order: 0,
			})
		)
	);
	runInAction(() => {
		data.created.forEach((bitCase) => {
			pageStore.bitCases.push({
				...bitCase,
				content: JSON.parse(bitCase.content),
			});
		});
	});

	return data;
});

const blankBit = (bitID) => ({
	bitID,
	id: shortid.generate(),
	content: [
		{
			type: "paragraph",
			children: [{ text: "" }],
		},
	],
	rules: { glue: "and", rules: [{}] },
});

const generateCasesToSave = (bitCases) => {
	let toCreate = [];
	let toUpdate = [];
	for (let bitCase of bitCases) {
		bitCase.content = JSON.stringify(bitCase.content);
		delete bitCase.rules; //these are legacy rules from webix, so we don't save over them

		if (bitCase.dbID) {
			bitCase.id = bitCase.dbID;
			toUpdate.push(bitCase);
		} else if (shortid.isValid(bitCase.id)) {
			bitCase.shortID = bitCase.id;
			delete bitCase.id;
			toCreate.push(bitCase);
		} else {
			toUpdate.push(bitCase);
		}
	}
	return { toUpdate, toCreate };
};
