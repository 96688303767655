import React, { Component, useEffect, useState } from "react";
import "./Canvas.scss";
import { observer } from "mobx-react";
import { observable, observe } from "mobx";
import Fab from "@material-ui/core/Fab";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faShapes } from "@fortawesome/pro-solid-svg-icons";
import API from "../../../API";
import SaveButton from "../../../lib/SaveButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import shortid from "shortid";
import NewExtractorDialog from "./canvasModals/Extractor";
import NewMessageDialog from "./canvasModals/Message";
import NewBrancherDialog from "./canvasModals/Brancher";
import NewContainerDialog from "./canvasModals/Container";
import NewPortalDialog from "./canvasModals/Portal";
import snackbarStore from "../../../lib/SnackbarStore";
import { placeCaretAtEnd } from "../../../lib/HelperFunctions";
import { withRouter } from "react-router";
import Mousetrap from "mousetrap";
import { appStore } from "../../../App";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { isPortal, isBrancher } from "../../../lib/HelperFunctions";
import client from "../../../lib/feathers";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import downloadjs from "downloadjs";
import hash from "object-hash";
import { Dialog, DialogContent, ListItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import queueImport from "queue";
// const queue = queueImport({ autostart: true });
import { format } from "timeago.js";

export const pageStore = new observable({
	MessageDialogOpen: false,
	BrancherDialogOpen: false,
	ContainerDialogOpen: false,
	ExtractorDialogOpen: false,
	PortalDialogOpen: false,
	selectedCell: null,
	mode: "New",
	lockedByUserID: null,
	instanceID: shortid.generate(),
	lastClickCoordinatesX: 0,
	lastClickCoordinatesY: 0,
});

// Extend the EditorUI init method
var editorUiInit = window.EditorUi.prototype.init;
window.EditorUi.prototype.init = function () {
	editorUiInit.apply(this, arguments);
	this.actions.get("export").setEnabled(false);
	this.actions.get("open").setEnabled(false);
	this.actions.get("import").setEnabled(false);
	this.actions.get("save").setEnabled(false);
	this.actions.get("saveAs").setEnabled(false);
	this.actions.get("export").setEnabled(false);
	this.actions.get("rotation").setEnabled(false);
};
const enc = new window.mxCodec();

const Canvas = withRouter(
	observer(
		class Canvas extends Component {
			state = {
				currentCanvasID: null,
				editMode: false,
				layoutMenu: null,
				lastClickedPortal: null,
				restoreModalOpen: false,
				lastSave: null,
			};
			canvasHistory = [];
			messagesToDeleteFromDB = [];
			loadingQueue = [];

			async componentWillUnmount() {
				if (this.state.editMode) {
					await this.save(true);
				}
				window.loadCanvas = null;

				if (this.disposer) {
					this.disposer();
				}

				Mousetrap.unbind("mod+y");
				Mousetrap.unbind("mod+c");
				Mousetrap.unbind("mod+x");
				Mousetrap.unbind(["mod+v", "mod+shift+v"]);
				Mousetrap.unbind(["mod+d", "mod+shift+d"]);
				this.loadingQueue = [];
				this._ismounted = false;
			}

			componentDidUpdate(prevProps, prevState, snapshot) {
				const canEdit = appStore.selectedProjectAccessMap.update;
				let editModeToggled = false;
				if (!this.state.editMode && canEdit) {
					editModeToggled = true;
					this.toggleEditMode();
					this.setState({ editMode: canEdit });
				}
				const canvasIdChanged =
					this.props.match.params.canvasID !== prevProps.match.params.canvasID;
				const tabWasntActiveButItIsNow =
					this.props.isActive && !prevProps.isActive;
				const tabWasActiveNowItIsnt =
					prevProps.isActive && !this.props.isActive;
				if (canEdit && tabWasntActiveButItIsNow && !canvasIdChanged) {
					// If they're still on the same canvas, but they just switched back
					// to this tab, grab the scroll position before loading so we
					// can scroll back to the original position after reloading the canvas
					const scrollPositions = {
						left: window.graph.container.scrollLeft,
						top: window.graph.container.scrollTop,
					};
					this.loadingQueue.push(
						() =>
							new Promise((resolve) => {
								requestAnimationFrame(() => {
									window.graph.container.scrollTo({
										...scrollPositions,
										behavior: "smooth",
									});
									return resolve(true);
								});
							})
					);
				}
				if (canvasIdChanged || (canEdit && tabWasntActiveButItIsNow)) {
					this.loadCanvas();
				} else if (
					canEdit &&
					!editModeToggled &&
					this.state.editMode &&
					tabWasActiveNowItIsnt
				) {
					this.save();
				}
			}

			isVisible() {
				return [
					this.props.match.isExact,
					!pageStore.BrancherDialogOpen,
					!pageStore.PortalDialogOpen,
					!pageStore.ExtractorDialogOpen,
					!pageStore.MessageDialogOpen,
					!pageStore.ContainerDialogOpen,
				].every(Boolean);
			}

			findOutgoingLines = (cellToGetOutgoingLines) => {
				return (
					cellToGetOutgoingLines.edges?.filter((edge) => {
						return (
							edge.source &&
							edge.target &&
							edge.source.id === cellToGetOutgoingLines.id &&
							edge.target.id !== cellToGetOutgoingLines.id
						);
					}) || []
				);
			};

			findIncomingLines = (cell) => {
				return (
					cell.edges?.filter((edge) => {
						return (
							edge.source &&
							edge.target &&
							edge.source.id !== cell.id &&
							edge.target.id === cell.id
						);
					}) || []
				);
			};

			async componentDidMount() {
				this._ismounted = true;
				this.disposer = observe(appStore, "selectedProjectName", async () => {
					if (
						this.state?.currentCanvasID &&
						this.state.currentCanvasID !== appStore.selectedProject
					) {
						this.props.history.push("/canvas");
						this.canvasHistory = [];
					} else if (appStore.selectedProjectName && this._ismounted) {
						this.loadCanvas();
					}
				});

				const updateLastSave = async () => {
					if (this.state.currentCanvasID) {
						const canvas = await client.service("canvas").find({
							query: {
								id: this.state.currentCanvasID,
								attributes: ["updatedAt"],
							},
						});
						if (canvas.length) {
							this.setState({ lastSave: format(canvas[0].updatedAt) });
						}
					}
				};

				setInterval(updateLastSave, 30000);
				setTimeout(updateLastSave, 5000);

				window.loadCanvas = this.loadCanvas;
				(() => {
					// Adds required resources (disables loading of fallback properties, this can only
					// be used if we know that all keys are defined in the language specific file)
					window.mxResources.loadDefaultBundle = true;
					var bundle =
						window.mxResources.getDefaultBundle(
							window.RESOURCE_BASE,
							window.mxLanguage
						) ||
						window.mxResources.getSpecialBundle(
							window.RESOURCE_BASE,
							window.mxLanguage
						);

					// Fixes possible asynchronous requests
					window.mxUtils.getAll(
						[
							bundle,
							"/mxgraph/grapheditor/" + window.STYLE_PATH + "/default.xml",
						],
						async (xhr) => {
							// Adds bundle text to resources
							window.mxResources.parse(xhr[0].getText());

							// Configures the default graph theme
							var themes = {};
							themes[
								window.Graph.prototype.defaultThemeName
							] = xhr[1].getDocumentElement();

							window.mxVertexHandler.prototype.rotationEnabled = false;
							window.mxEdgeHandler.prototype.cloneEnabled = false;
							window.mxGraphHandler.prototype.cloneEnabled = false;

							const editor = new window.Editor(
								window.urlParams["chrome"] === "0",
								themes
							);

							window.editor = editor;
							window.graph = editor.graph;

							//disable click on the icons that appear on hover to prevent cloning
							window.HoverIcons.prototype.click = () => {};

							// Main
							window.editorui = new window.EditorUi(
								editor,
								document.getElementById("geEditor")
							);

							window.graph.addListener(
								window.mxEvent.CELLS_ADDED,
								(sender, evt, ...args) => {
									if (!evt.properties?.cells[0].id) {
										window.editor.setGraphXml(this.initialXML);
										return;
									} else if (
										!evt.properties?.cells[0].id.includes("highlight")
									) {
										const allCells = Object.values(
											window.graph.getModel().cells
										);

										if (allCells.length > 499 && allCells.length % 100 === 0) {
											alert(
												`Warning! There are now ${allCells.length} cells on this canvas. You can proceed, but it's your funeral if you run into performance problems. Don't say you haven't been warned...`
											);
										}

										let copyMode = false;
										for (const newCell of evt.properties.cells) {
											if (!newCell.style.includes("edgeStyle")) {
												copyMode = newCell.value !== "";
											}
										}
										if (copyMode) {
											return;
										}
										pageStore.mode = "New";
										pageStore.selectedCell = evt.properties.cells[0];
										const cellStyle = evt.properties.cells[0].style;

										if (cellStyle.includes("shape=messageRectangle;")) {
											pageStore.MessageDialogOpen = true;
										} else if (cellStyle.includes("shape=isoRectangle;")) {
											pageStore.BrancherDialogOpen = true;
										} else if (
											cellStyle.includes(
												"shape=mxgraph.flowchart.manual_operation"
											)
										) {
											pageStore.ContainerDialogOpen = true;
										} else if (cellStyle.includes("shape=trapezoid;")) {
											window.graph.removeCells(evt.properties.cells);
											pageStore.ExtractorDialogOpen = true;
										} else if (cellStyle.includes("endArrow=classic")) {
											window.graph.removeCells(evt.properties.cells);
											pageStore.ExtractorDialogOpen = true;
										} else if (cellStyle.includes("shape=circle;")) {
											pageStore.PortalDialogOpen = true;
										} else {
											// console.log(evt.properties.cells[0].style);
										}
									}
								}
							);

							window.graph.addListener(
								window.mxEvent.END_UPDATE,
								(sender, evt) => {
									if (!this.state.editMode) {
										window.editor.setGraphXml(this.initialXML);
									}
								}
							);
							window.graph.addListener(
								window.mxEvent.MOVE_CELLS,
								(sender, evt) => {
									if (!this.state.editMode) {
										window.editor.setGraphXml(this.initialXML);
									}
								}
							);

							window.graph.addListener(
								window.mxEvent.RESIZE_CELLS,
								(sender, evt) => {
									if (!this.state.editMode) {
										window.editor.setGraphXml(this.initialXML);
									}
								}
							);

							if (!this.state.editMode) {
								window.graph.cellEditor.startEditing = () => {
									snackbarStore.setMessage(
										"Warning",
										"You do not have edit access for this project!"
									);
								};
							}

							window.graph.dblClick = async (evt, cell) => {
								const selectedCell = cell;
								pageStore.selectedCell = selectedCell;

								if (selectedCell === undefined) {
								} else if (
									selectedCell.style.includes("shape=messageRectangle;")
								) {
									if (this.state.editMode) {
										await this.save();
									}
									window.localStorage.setItem(
										"lastClickedMessageName",
										cell.value
									);
									this.props.history.push(
										`/canvas/${this.state.currentCanvasID}/${selectedCell.id}`
									);
								} else if (this.state.editMode) {
									pageStore.mode = "Edit";

									window.graph.cellEditor.startEditing = function () {
										window.mxCellEditor.prototype.startEditing.apply(
											this,
											arguments
										);
									};

									if (selectedCell.style.includes("shape=isoRectangle;")) {
										pageStore.BrancherDialogOpen = true;
									} else if (
										selectedCell.style.includes(
											"shape=mxgraph.flowchart.manual_operation"
										)
									) {
										pageStore.ContainerDialogOpen = true;
									} else if (selectedCell.style.includes("shape=trapezoid;")) {
										window.graph.removeCells(evt.properties.cells);
										pageStore.ExtractorDialogOpen = true;
									} else if (selectedCell.style.includes("shape=circle;")) {
										pageStore.PortalDialogOpen = true;
									} else if (selectedCell.style.includes("strokeColor=none;")) {
										window.graph.cellEditor.startEditing(selectedCell);

										placeCaretAtEnd(window.graph.cellEditor.textarea);

										window.graph.cellEditor.canvasSave = async () => {
											await this.save();
										};

										window.graph.cellEditor.stopEditing = function () {
											window.mxCellEditor.prototype.stopEditing.apply(
												this,
												arguments
											);
											window.graph.cellEditor.startEditing = () => {};
											this.canvasSave();
										};
									}
								}
							};

							// window.graph.model.addListener(
							// 	window.mxEvent.CHANGE,
							// 	async (sender, evt) => {
							// 		if (!this.socketUpdate) {
							// 			const changes = evt.getProperty("edit").changes;
							// 			let nodes = [];
							// 			const codec = new window.mxCodec();
							//
							// 			for (let i = 0; i < changes.length; i++) {
							// 				const encoded = codec.encode(changes[i]);
							// 				nodes.push(window.mxUtils.getXml(encoded));
							// 			}

							// if (nodes[0] && !nodes[0].includes("<mxRootChange>")) {
							// 	if (nodes.length > 25) {
							// 		const enc = new window.mxCodec();
							// 		const currentNode = enc.encode(window.graph.getModel());
							//
							// 		const currentCanvasXML = window.mxUtils.getXml(
							// 			currentNode,
							// 			this.linefeed
							// 		);
							//
							// 		await client.service("graphUpdate").create({
							// 			currentCanvasID: this.state.currentCanvasID,
							// 			currentCanvasXML,
							// 		});
							// 	} else {
							// 		await client.service("graphUpdate").create({
							// 			changes: nodes,
							// 			currentCanvasID: this.state.currentCanvasID,
							// 		});
							// 	}
							//
							// 	this.socketUpdate = false;
							// }
							// 		}
							// 	}
							// );

							// client.service("graphUpdate").on("created", (data) => {
							// 	queue.push((updatesDone) => {
							// 		if (!this.props.isActive) {
							// 			return;
							// 		}
							// 		let changes = [];
							// 		const model = window.graph.getModel();
							//
							// 		var codec = new window.mxCodec();
							// 		codec.lookup = function (id) {
							// 			return window.graph.model.getCell(id);
							// 		};
							//
							// 		window.graph.getModel().beginUpdate();
							// 		this.socketUpdate = true;
							// 		if (data.currentCanvasXML) {
							// 			const scrollPositions = {
							// 				left: window.graph.container.scrollLeft,
							// 				top: window.graph.container.scrollTop,
							// 			};
							//
							// 			const doc = window.mxUtils.parseXml(data.currentCanvasXML);
							// 			const node = doc.documentElement;
							// 			window.editor.setGraphXml(node);
							// 			window.graph.container.scrollTo({
							// 				...scrollPositions,
							// 			});
							// 		} else {
							// 			for (const node of data.changes) {
							// 				const xml = window.mxUtils.parseXml(node);
							//
							// 				var change = codec.decode(xml.documentElement);
							// 				change.model = model;
							// 				change.execute();
							// 				changes.push(change);
							//
							// 				let edit = new window.mxUndoableEdit(model, false);
							// 				edit.changes = changes;
							//
							// 				model.fireEvent(
							// 					new window.mxEventObject(
							// 						window.mxEvent.CHANGE,
							// 						"edit",
							// 						edit,
							// 						"changes",
							// 						changes,
							// 						"socketUpdate"
							// 					)
							// 				);
							// 			}
							// 		}
							//
							// 		window.graph.getModel().endUpdate();
							// 		const enc = new window.mxCodec();
							// 		const currentNode = enc.encode(window.graph.getModel());
							//
							// 		const currentCanvasXML = window.mxUtils.getXml(
							// 			currentNode,
							// 			this.linefeed
							// 		);
							//
							// 		this.xmlToCompareAtSave = currentCanvasXML;
							// 		this.socketUpdate = false;
							// 		updatesDone();
							// 	});
							// });

							window.graph.addListener(
								window.mxEvent.REMOVE_CELLS,
								async (sender, evt) => {
									if (!evt.properties?.cells[0]?.id.includes("highlight")) {
										if (evt.properties.cells) {
											for (const cell of evt.properties.cells) {
												if (cell.value === "Top Level of Project") {
													window.graph.addCell(cell);
												}
											}
										} else {
											// window.graph.addCells(cell);
										}

										if (!this.state.editMode) {
											window.editor.setGraphXml(this.initialXML);
										} else {
											for (const cell of evt.properties.cells) {
												if (cell.style.includes("shape=")) {
													if (cell.style.includes("shape=messageRectangle")) {
														this.messagesToDeleteFromDB.push(cell.value);
													} else if (
														cell.style.includes(
															"shape=mxgraph.flowchart.manual_operation"
														)
													) {
														//don't allow for deletion of canvases unless they are empty
														const data = await API(`/canvas`, "DELETE", {
															name: cell.value,
															currentCanvas: this.state.currentCanvasID,
															cellID: cell.id,
														});
														if (data.alert) {
															alert(data.alert);
															window.graph.addCell(cell);
														}
													}

													const allCells = Object.values(
														window.graph.getModel().cells
													);

													let toRemove = allCells.filter((cellToFilter) => {
														return (
															cellToFilter.style &&
															cellToFilter.style.includes(
																"edgeStyle=orthogonalEdgeStyle"
															) &&
															(!cellToFilter.source || !cellToFilter.target)
														);
													});

													window.graph.removeCells(toRemove);
												}
											}
										}
									}
								}
							);

							window.mxPopupMenu.prototype.enabled = false;

							const rightClickOrTapAndHold = (sender, evt) => {
								if (!evt.properties.cell) {
									//do nothing, there's no cell selected
								} else if (
									evt.properties.cell.style.includes(
										"shape=mxgraph.flowchart.manual_operation"
									) &&
									evt.properties.cell.value !== "Top Level of Project"
								) {
									this.containerRightClicked(evt.properties.cell.value);
								} else if (
									evt.properties.cell.style.includes("shape=trapezoid")
								) {
									if (this.canvasHistory.length > 1) {
										this.back();
									} else {
										alert(
											"This is the top level of the project. Please interact with other shapes on this canvas"
										);
									}
								} else if (evt.properties.cell.style.includes("shape=circle")) {
									this.portalRightClicked(evt.properties.cell);
								}
							};

							window.graph.addListener(
								window.mxEvent.CLICK,
								async (sender, evt) => {
									pageStore.lastClickCoordinatesX = evt.properties.event.x;
									pageStore.lastClickCoordinatesY = evt.properties.event.y;
									if (
										evt.properties.event.which === 3 ||
										evt.properties.event.ctrlKey
									) {
										window.mxEvent.consume(evt.properties.event);
										rightClickOrTapAndHold(sender, evt);
									}
								}
							);

							window.graph.addListener(
								window.mxEvent.TAP_AND_HOLD,
								async (sender, evt) => {
									rightClickOrTapAndHold(sender, evt);
								}
							);

							window.graph.addListener(
								window.mxEvent.SAVE,
								async (sender, evt) => {
									window.mxEvent.consume(evt.properties.event);
								}
							);

							window.graph.connectionHandler.addListener(
								window.mxEvent.CONNECT,
								async (sender, evt) => {
									const edge = evt.getProperty("cell");
									const source = window.graph
										.getModel()
										.getTerminal(edge, true);
									const target = window.graph
										.getModel()
										.getTerminal(edge, false);

									const sourceOutgoingLines =
										!target || !source ? null : this.findOutgoingLines(source);

									const targetOutgoingLines =
										!target || !source ? null : this.findOutgoingLines(target);

									if (!source || !target) {
										snackbarStore.setMessage(
											"Warning",
											"Lines must connect to a shape on both ends."
										);
										window.graph.removeCells([edge]);
									} else if (!this.state.editMode) {
										window.graph.removeCells([edge]);
									} else if (
										!isBrancher(source) &&
										sourceOutgoingLines &&
										sourceOutgoingLines.length > 1
									) {
										snackbarStore.setMessage(
											"Warning",
											"Only branchers can have multiple outgoing lines."
										);
										window.graph.removeCells([edge]);
									} else if (isPortal(source) && source.edges.length > 1) {
										//if drawing a line out of portal and an outgoing line exists, don't allow it
										//portals can have one and only one line coming out of them
										window.graph.removeCells([edge]);
										snackbarStore.setMessage(
											"Warning",
											"Portals can't have outgoing lines if they have incoming lines."
										);
									} else if (
										isPortal(target) &&
										targetOutgoingLines.length > 0
									) {
										//if target is portal and it has lines connected to it and
										// the line it connects to is going out of the target, don't allow it
										//you can not connect lines to portals that have lines going out of it
										snackbarStore.setMessage(
											"Warning",
											"Portals can not have both incoming & outgoing lines."
										);
										window.graph.removeCells([edge]);
									}

									if (isPortal(source)) {
										await this.outlinePortal(source);
									}
									if (isPortal(target)) {
										await this.outlinePortal(target);
									}
								}
							);

							if (appStore.selectedProjectName) {
								await this.loadCanvas();
							}
						},
						function () {
							document.body.innerHTML =
								'<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
						}
					);
				})();

				const copy = (cut = false) => {
					const selectedCells = window.graph.getSelectionCells();
					window.mxClipboard.copy(window.graph);

					if (selectedCells) {
						window.localStorage.copiedItems = JSON.stringify(
							selectedCells.map((selectedCell) => {
								return {
									sourceCanvasID: this.state.currentCanvasID,
									sourceShapeID: selectedCell.id,
									name: selectedCell.value,
									cut,
								};
							})
						);

						const enc = new window.mxCodec();
						try {
							if (window.mxClipboard.getCells()) {
								window.localStorage.copiedCells = window.mxUtils.getXml(
									enc.encode(window.mxClipboard.getCells())
								);
							}
						} catch (e) {
							console.error(e);
							alert("Error copying cells. May cause save issues.");
						}

						if (cut) {
							snackbarStore.setMessage(
								"Info",
								"Shape(s) have been cut to clipboard. They will move from source canvas upon pasting."
							);
						}
					}
				};

				const paste = async (e) => {
					try {
						const duplicateMessage = (
							originalMessageID,
							newShapeID,
							deep = false
						) => {
							return API("/messageDuplicate", "POST", {
								deep,
								messageID: originalMessageID,
								canvasID: this.state.currentCanvasID,
								projectID: appStore.selectedProject,
								shapeID: newShapeID,
							});
						};

						const duplicateBrancher = (
							{ sourceCanvasID, sourceShapeID },
							newShapeID,
							brancherCell,
							newCells
						) => {
							let matchedMessageIDs = {};
							if (brancherCell.edges) {
								for (const oldMessage of brancherCell.edges) {
									const newMessage = newCells.find(
										(cell) => cell.originalID === oldMessage.target.originalID
									);
									matchedMessageIDs[oldMessage.target.originalID] = Number(
										newMessage.id
									);
								}
							} else {
								const newMessage = newCells.find(
									(cell) => cell.originalID === brancherCell.originalID
								);
								matchedMessageIDs[brancherCell.originalID] = Number(
									newMessage.id
								);
							}
							return API("/brancherDuplicate", "POST", {
								matchedMessageIDs,
								sourceCanvasID,
								sourceShapeID,
								newShapeID,
								newCanvasID: this.state.currentCanvasID,
								edges: !!brancherCell.edges,
							});
						};

						const move = async (cellsToMove) => {
							await API(`/moveShape`, "POST", {
								cellsToMove,
								sourceCanvasID: cellsToMove[0].pasteData.sourceCanvasID,
								destinationCanvasID: this.state.currentCanvasID,
							});
						};

						const pasteMessage = async (pasteData, messageCell) => {
							const { name, messageID } = await API(
								"/messageDuplicateName",
								"POST",
								{
									name: pasteData.name,
									shapeID: pasteData.sourceShapeID,
									canvasID: pasteData.sourceCanvasID,
									projectID: appStore.selectedProject,
								}
							);
							if (name) {
								window.graph.model.setValue(messageCell, name);
							}
							return {
								originalMessageID: messageID,
							};
						};

						if (
							window.localStorage.copiedItems &&
							this.state.editMode &&
							document.activeElement === window.graph.container
						) {
							let clipboardCells = window.mxUtils.parseXml(
								window.localStorage.copiedCells
							);
							const codec = new window.mxCodec(clipboardCells);
							clipboardCells = codec.decode(clipboardCells.documentElement);

							const pastedCellsData = JSON.parse(
								window.localStorage.copiedItems
							);

							const copiedCells = window.graph.getImportableCells(
								clipboardCells
							);

							// Sort copied cells so edges are last and branchers are next to last
							let sortedCopiedCells = copiedCells.sort((a, b) => {
								if (
									a.style.includes("edgeStyle") &&
									b.style.includes("shape=isoRectangle")
								) {
									return 1;
								} else if (a.style.includes("edgeStyle")) {
									return 0;
								}
								return -1;
							});

							// Determines where to paste on the graph
							const originalCoords = {
								x: copiedCells[0].geometry.x + 5,
								y: copiedCells[0].geometry.y + 50,
							};
							const pasteX =
								window.graph.container.scrollLeft / window.graph.view.scale -
								window.graph.view.translate.x +
								pageStore.lastClickCoordinatesX;
							const pasteY =
								window.graph.container.scrollTop / window.graph.view.scale -
								window.graph.view.translate.y +
								pageStore.lastClickCoordinatesY;
							const deltaX = pasteX - originalCoords.x;
							const deltaY = pasteY - originalCoords.y;

							window.graph.model.beginUpdate();

							sortedCopiedCells = window.graph.importCells(
								copiedCells,
								deltaX,
								deltaY,
								window.graph.getDefaultParent()
							);

							let cellsToMove = [];

							for (const newCell of sortedCopiedCells) {
								const newCellType = determineShapeType(newCell);
								const pasteData = pastedCellsData.find((cell) => {
									return Number(cell.sourceShapeID) === newCell.originalID;
								});
								const newShapeID = Number(newCell.id);

								let originalMessageID = null;

								try {
									// If !cut, see if message name already exists and change if needed
									if (newCellType === "message" && !pasteData.cut) {
										const newMessage = await pasteMessage(pasteData, newCell);
										originalMessageID = newMessage.originalMessageID;
									}

									if (pasteData?.cut && newCellType !== "edge") {
										cellsToMove.push({ pasteData, newShapeID, newCellType });
									} else if (newCellType === "message") {
										await duplicateMessage(
											originalMessageID,
											newShapeID,
											e.shiftKey
										);
									}

									if (newCellType === "brancher" && e.shiftKey) {
										await duplicateBrancher(
											pasteData,
											newShapeID,
											newCell,
											sortedCopiedCells
										);
									}
								} catch (error) {
									console.error(`Paste error #1:`);
									console.error(error);
								}
							}

							if (pastedCellsData[0].cut) {
								await move(cellsToMove);
							}

							window.graph.setSelectionCells(sortedCopiedCells);
							return sortedCopiedCells;
						}
					} catch (e) {
						console.error(`Paste error #2:`);
						console.error(e);
					} finally {
						if (this.messagesToDeleteFromDB) {
							await this.save();
						}
						//end update or mxGraph gets stupid *real* quick
						window.graph.model.endUpdate();
					}
				};

				Mousetrap.bind("mod+c", () => {
					if (this.isVisible()) {
						copy();
					}
				});
				Mousetrap.bind("mod+x", () => {
					if (this.isVisible()) {
						copy(true);
					}
				});
				Mousetrap.bind(["mod+v", "mod+shift+v"], (...args) =>
					this.isVisible() ? paste(...args) : null
				);
				Mousetrap.bind(["mod+d", "mod+shift+d"], (e, combo) => {
					if (this.isVisible()) {
						// Prevent default + propagation so users don't accidentally
						// bookmark this page =D
						e.preventDefault();
						e.stopPropagation();
						copy();
						window.requestAnimationFrame(async () => await paste(e));
					}
				});

				Mousetrap.bind("mod+y", async (e) => {
					if (this.isVisible()) {
						e.preventDefault();
						const currentNode = enc.encode(window.graph.getModel());
						try {
							const currentCanvasXML = window.mxUtils.getXml(
								currentNode,
								this.linefeed
							);
							const { data } = await API(`/db/customQuery/canvas`, "POST", {
								query: "findOne",
								options: {
									where: { id: this.state.currentCanvasID },
									attributes: ["name"],
								},
							});
							downloadjs(
								currentCanvasXML,
								`${data.name}.xml`,
								"application/xml"
							);
						} catch (e) {
							console.error(e);
							alert("Error downloading XML. Contact support.");
						}
					}
				});
			}

			containerRightClicked = async (name) => {
				const { data } = await API(`/db/customQuery/canvas`, "POST", {
					query: "findOne",
					options: {
						where: { name: name, projectID: appStore.selectedProject },
						raw: true,
						attributes: ["id"],
					},
				});

				this.loadingQueue.push(
					() =>
						new Promise((resolve) => {
							requestAnimationFrame(() => {
								// eslint-disable-next-line
								const [cell, ...tail] = findCellsByName(name);
								panToCell(cell);
								return resolve(true);
							});
						})
				);

				if (!data) {
					const content = `<mxGraphModel><root><mxCell id="0"/><mxCell id="1" parent="0"/><mxCell id="2" value="${name}" style="shape=trapezoid;perimeter=trapezoidPerimeter;whiteSpace=wrap;html=1;fillColor=#ffffff;" vertex="1" parent="1"><mxGeometry x="375" y="200" width="100" height="50" as="geometry"/></mxCell></root></mxGraphModel>`;
					this.xmlToCompareAtSave = content;

					const result = await API("/db/canvas", "POST", {
						name: name,
						content,
						projectID: appStore.selectedProject,
					});
					this.props.history.push(`/canvas/${result.data.id}`);
				} else {
					if (this.state.editMode) {
						await this.save();
						this.setState({ editMode: false });
					}
					this.props.history.push(`/canvas/${data.id}`);
				}
			};

			portalRightClicked = async (portal) => {
				//MZM 11/14/20 I have no idea why I enforced a line must be made before
				//moving to another canvas. This got disabled when we made the move to
				//one type of portal.
				const portalOut =
					portal.edges &&
					portal.edges.length &&
					portal.id === portal.edges[0].source.id;
				//
				// const portalIn =
				// 	portal.edges.length && portal.id === portal.edges[0].target.id;
				//
				// if (!portalOut && !portalIn) {
				// 	snackbarStore.setMessage(
				// 		"Warning",
				// 		"A connection line must be made on the portal before navigating to another canvas"
				// 	);
				// } else {
				if (portalOut) {
					snackbarStore.setMessage(
						"Warning",
						"TCS can not currently find the source of destination portals"
					);
				} else {
					if (this.state.editMode) {
						await this.save();
					}

					const { matchingPortal } = await API(`/followPortal`, "POST", {
						name: portal.value,
						shapeID: portal.id,
						canvasID: this.state.currentCanvasID,
						projectID: appStore.selectedProject,
					});

					if (matchingPortal) {
						if (matchingPortal.canvasID !== this.state.currentCanvasID) {
							const oldCanvasCells = hash(
								Object.values(window.graph.getModel().cells)
							);

							this.props.history.push(`/canvas/${matchingPortal.canvasID}`);

							let runCounter = 0;
							const waitForCellsToChange = setInterval(() => {
								runCounter++;
								const currentCells = Object.values(
									window.graph.getModel().cells
								);
								const currentCellsHashed = hash(currentCells);

								if (currentCellsHashed !== oldCanvasCells) {
									clearInterval(waitForCellsToChange);
									const matchingPortalCell = currentCells.find((cell) => {
										return `${matchingPortal.shapeID}` === cell.id;
									});

									if (matchingPortalCell) {
										panToCell(matchingPortalCell, true);
									}
								} else if (runCounter > 200) {
									snackbarStore.setMessage(
										"Warning",
										"Could not find matching portal"
									);
								}
							}, 250);
						} else {
							const currentCells = Object.values(window.graph.getModel().cells);
							const matchingPortalCell = currentCells.find((cell) => {
								return `${matchingPortal.shapeID}` === cell.id;
							});

							if (matchingPortalCell) {
								panToCell(matchingPortalCell, true);
							} else {
								snackbarStore.setMessage("Warning", "No matching portal found");
							}
						}
					} else {
						snackbarStore.setMessage("Warning", "No matching portal found");
						window.graph.setCellStyles("strokeWidth", "4", [portal]);
						window.graph.setCellStyles("strokeColor", "#FF331C", [portal]);
					}
				}
				// }
			};

			save = async (autoSave = false) => {
				const canEdit = appStore.selectedProjectAccessMap.update;
				if (canEdit && (!this.props.loading || !autoSave)) {
					try {
						const enc = new window.mxCodec();
						const currentNode = enc.encode(window.graph.getModel());

						const currentCanvasXML = window.mxUtils.getXml(
							currentNode,
							this.linefeed
						);
						//if the current canvas is the same as the last time we saved, don't save!
						if (currentCanvasXML !== this.xmlToCompareAtSave) {
							this.xmlToCompareAtSave = currentCanvasXML;
							const doc = window.mxUtils.parseXml(currentCanvasXML);
							const newNode = doc.documentElement;
							this.initialXML = newNode;

							await API(`/db/canvas`, "POST", {
								content: currentCanvasXML,
								id: this.state.currentCanvasID,
							});

							await API(`/db/canvasSaveHistory`, "POST", {
								content: currentCanvasXML,
								canvasID: this.state.currentCanvasID,
							});

							if (this.messagesToDeleteFromDB) {
								await API(`/message/names`, "DELETE", {
									messages: this.messagesToDeleteFromDB,
								});
								this.messagesToDeleteFromDB = [];
							}
							await API(`/compileCanvases`, "POST", {
								canvasID: this.state.currentCanvasID,
							});
						} else {
							this.xmlToCompareAtSave = currentCanvasXML;
						}
					} catch (e) {
						console.error(e);
						alert(
							'Canvas save failed. Everybody panic! ...And contact Max before hitting "OK." That\'d be helpful too.'
						);

						try {
							const enc = new window.mxCodec();
							console.log(enc);
							console.log(window.graph.getModel());
							const currentNode = enc.encode(window.graph.getModel());
							console.log(currentNode);

							const currentCanvasXML = window.mxUtils.getXml(
								currentNode,
								this.linefeed
							);
							console.log(currentCanvasXML);
						} catch (e2) {
							console.error(e2);
						}
					}
				}
			};

			loadCanvas = async (canvasChange = false) => {
				this.props.setLoading(true);
				if (canvasChange) {
					this.canvasHistory = [this.props.match.params.canvasID];
				} else if (
					this.canvasHistory[this.canvasHistory.length - 1] !==
						this.props.match.params.canvasID &&
					this.props.match.params.canvasID !== undefined
				) {
					this.canvasHistory.push(this.props.match.params.canvasID);
				}

				let data;
				const originalXml = this.xmlToCompareAtSave;

				if (this.props.match.params.canvasID) {
					const result = await API(
						`/db/canvas/${this.props.match.params.canvasID}`,
						"GET"
					);
					data = result.data;
				} else {
					const result = await API(`/db/customQuery/canvas`, "POST", {
						query: "findOne",
						options: {
							where: {
								name: appStore.selectedProjectName,
							},
						},
					});

					this.props.setLoading(false);
					if (result) {
						this.props.history.push(`/canvas/${result.data.id}`);
						return;
					} else {
						alert("Something went wrong loading the canvas");
						return;
					}
				}

				if (data) {
					await client.service("canvasUpdater").remove(0);
					client.service("canvasUpdater").create({ currentCanvasID: data.id });
					this.setState({
						currentCanvasID: data.id,
					});
					const doc = window.mxUtils.parseXml(data.content);
					const node = doc.documentElement;
					this.initialXML = node;
					this.xmlToCompareAtSave = data.content;
					if (this.xmlToCompareAtSave !== originalXml && this.props.isActive) {
						window.editor.setGraphXml(node);
					}
					if (appStore) {
						appStore.loaded = data.id;
					}
				} else {
					snackbarStore.setMessage(
						"Warning",
						"Loading failed, contact support."
					);
				}

				const allCells = Object.values(window.graph.getModel().cells);

				if (this.props.match.params.messageID) {
					this.loadingQueue.push(
						() =>
							new Promise((resolve) =>
								requestAnimationFrame(() => {
									panToCell(
										Object.values(window.graph.getModel().cells).find(
											(cell) => cell.id === this.props.match.params.messageID
										)
									);
									return resolve(true);
								})
							)
					);
				}

				const isMessage = (obj) => {
					try {
						return obj.style && obj.style.includes("shape=messageRectangle;");
					} catch {
						return false;
					}
				};
				const allMessageCells = [];
				for (const cell of allCells) {
					if (isMessage(cell)) {
						allMessageCells.push(cell);
					}
				}
				const { messages } = await API(`/messageStatus`, "POST", {
					messageNames: allMessageCells.map((cell) => {
						return cell.value;
					}),
				});
				for (const message of messages) {
					const cell = allMessageCells.find((cell) => {
						return cell.value === message.name;
					});
					if (cell) {
						updateMessageColors(cell, message.status);
					}
				}

				try {
					const currentNode = enc.encode(window.graph.getModel());

					const currentCanvasXML = window.mxUtils.getXml(
						currentNode,
						this.linefeed
					);
					this.xmlToCompareAtSave = currentCanvasXML;
					requestAnimationFrame(async () => {
						this.props.setLoading(false);
						await this.handleLoadingQueue();
					});
				} catch (e) {
					console.error(e);
					alert("Error loading canvas. Contact support.");
					try {
						console.log(window.graph.getModel());
						const currentNode = enc.encode(window.graph.getModel());
						console.log(currentNode);
						const currentCanvasXML = window.mxUtils.getXml(
							currentNode,
							this.linefeed
						);
						console.log(currentCanvasXML);
						console.log(this.lineFeed);
					} catch (e2) {
						console.error(e2);
					}
				}
			};

			handleLoadingQueue = async () => {
				const fn = this.loadingQueue.pop();
				if (fn) {
					await fn();
				}
				return !this.loadingQueue.length
					? Promise.resolve(this)
					: this.handleLoadingQueue();
			};

			back = async () => {
				if (this.state.editMode) {
					await this.save();
				}
				this.canvasHistory.pop();

				this.props.history.push(
					`/canvas/${this.canvasHistory[this.canvasHistory.length - 1]}`
				);
			};

			toggleEditMode = async () => {
				const sidebar = document.getElementsByClassName("geFormatContainer")[0];
				const sidebarShowing = sidebar?.style.display === "block";
				const editMode = !this.state.editMode;
				if (!editMode) {
					//leaving edit mode
					await this.save();
					this.setState({ editMode });

					window.graph.cellEditor.startEditing = () => {
						if (!this.state.editMode) {
							snackbarStore.setMessage(
								"Warning",
								"You do not have edit access for this project!"
							);
						}
					};
					if (sidebarShowing) {
						sidebar.style.display = "none";
						//Using local storage here as it should be persistent after a user
						//closes the page and reopens it. Also solves navigating between
						//canvases even though the component stays mounted.
						window.localStorage.sidebarShowing = "true";
					} else {
						delete window.localStorage.sidebarShowing;
					}
				} else {
					if (window.localStorage.sidebarShowing === "true") {
						this.toggleSidebar();
					}
				}
			};

			arrange = (type) => {
				new window[type](window.graph).execute(window.graph.getDefaultParent());
				this.setState({ layoutMenu: null });
			};

			toggleSidebar() {
				const sidebar = document.getElementsByClassName("geFormatContainer")[0];

				const sidebarShowing = sidebar.style.display === "block";

				sidebar.style.display = sidebarShowing ? "none" : "block";
			}

			redOutlineCheck = async (evt) => {
				evt.preventDefault();

				snackbarStore.setMessage(
					"Info",
					"Starting to reoutline branchers & portals"
				);
				await this.save();
				const allCells = Object.values(window.graph.getModel().cells);
				const branchers = await client.service("brancher").find({
					query: {
						canvasID: this.state.currentCanvasID,
						include: ["BrancherRuleV2"],
					},
				});

				for (const cell of allCells) {
					if (isBrancher(cell)) {
						const hasRules = checkBrancherRules(branchers, cell);

						if (!hasRules) {
							window.graph.setCellStyles("strokeWidth", "4", [cell]);
							window.graph.setCellStyles("strokeColor", "#FF331C", [cell]);
						} else {
							window.graph.setCellStyles("strokeWidth", "1", [cell]);
							window.graph.setCellStyles("strokeColor", "#000", [cell]);
						}
					} else if (isPortal(cell)) {
						await this.outlinePortal(cell);
					}
				}
				snackbarStore.setMessage(
					"Success",
					"Branchers & portals have been reoutlined"
				);
			};

			async outlinePortal(cell) {
				let matchingPortal = false;
				if (this.findOutgoingLines(cell).length > 0) {
					const matchingShapes = await client
						.service("shape")
						.find({ query: { name: cell.value, hasIncomingLines: true } });
					if (matchingShapes && matchingShapes.length > 0) {
						matchingPortal = true;
					}
				} else if (this.findIncomingLines(cell).length > 0) {
					const matchingShapes = await client.service("shape").find({
						query: { name: cell.value, nextShapeID: { $ne: "" } },
					});
					if (matchingShapes && matchingShapes.length > 0) {
						matchingPortal = true;
					}
				}

				if (matchingPortal) {
					window.graph.setCellStyles("strokeWidth", "1", [cell]);
					window.graph.setCellStyles("strokeColor", "#000", [cell]);
				} else {
					window.graph.setCellStyles("strokeWidth", "4", [cell]);
					window.graph.setCellStyles("strokeColor", "#FF331C", [cell]);
				}
			}

			render() {
				return (
					<>
						<div id="geEditor" style={{ height: "100%" }} />
						{appStore.selectedProjectAccessMap.update ||
						process.env.REACT_APP_ENV === "local" ? (
							<div
								style={{
									position: "fixed",
									zIndex: 2,
									top: "55px",
									background: "#e6e6e6",
									textAlign: "center",
									width: 70,
								}}
							>
								<FormControlLabel
									control={
										<Switch
											checked={this.state.editMode}
											onChange={this.toggleEditMode}
											color="primary"
											disabled={this.props.match.path.includes("prototype")}
											size="small"
										/>
									}
									label="Edit"
									className="editToggle"
									style={{ display: "none" }}
								/>
								<FontAwesomeIcon
									icon={faShapes}
									onClick={(event) => {
										this.setState({ layoutMenu: event.currentTarget });
									}}
								/>
								<Menu
									id="simple-menu"
									anchorEl={this.state.layoutMenu}
									keepMounted
									open={Boolean(this.state.layoutMenu)}
									onClose={() => {
										this.setState({ layoutMenu: null });
									}}
								>
									<MenuItem
										onClick={() => {
											this.arrange("mxHierarchicalLayout");
										}}
									>
										Hierarchical
									</MenuItem>
									<MenuItem
										onClick={() => {
											this.arrange("mxCircleLayout");
										}}
									>
										Circle
									</MenuItem>
									<MenuItem
										onClick={() => {
											this.arrange("mxCompactTreeLayout");
										}}
									>
										Compact Tree
									</MenuItem>
									<MenuItem
										onClick={() => {
											this.arrange("mxFastOrganicLayout");
										}}
									>
										Fast Organic
									</MenuItem>
									<MenuItem
										onClick={() => {
											this.arrange("mxParallelEdgeLayout");
										}}
									>
										Parallel Edge
									</MenuItem>
								</Menu>
							</div>
						) : null}
						{pageStore.MessageDialogOpen ? (
							<NewMessageDialog
								currentCanvasID={this.state.currentCanvasID}
								selectedCell={pageStore.selectedCell}
								saveCanvas={this.save}
							/>
						) : null}
						{pageStore.BrancherDialogOpen ? (
							<NewBrancherDialog
								currentCanvasID={this.state.currentCanvasID}
								selectedCell={pageStore.selectedCell}
							/>
						) : null}
						{pageStore.ContainerDialogOpen ? (
							<NewContainerDialog
								currentCanvasID={this.state.currentCanvasID}
								save={this.save}
							/>
						) : null}
						{pageStore.ExtractorDialogOpen ? <NewExtractorDialog /> : null}
						{pageStore.PortalDialogOpen ? (
							<NewPortalDialog currentCanvasID={this.state.currentCanvasID} />
						) : null}

						{this.canvasHistory.length > 1 ? (
							<Fab
								color="primary"
								aria-label="add"
								style={{ position: "fixed", zIndex: 1, top: 60, left: 85 }}
								onClick={this.back}
							>
								<FontAwesomeIcon icon={faChevronLeft} />
							</Fab>
						) : null}
						{this.state.editMode ? (
							<>
								<div
									style={{
										position: "fixed",
										zIndex: 2,
										top: 33,
										right: 10,
										color: "white",
										cursor: "pointer",
									}}
									onClick={() => {
										try {
											console.log(appStore.selectedProjectAccessMap.update);
											console.log(this.props.loading);
											console.log(this.xmlToCompareAtSave);

											const enc = new window.mxCodec();
											console.log(enc);
											console.log(window.graph.getModel());
											const currentNode = enc.encode(window.graph.getModel());
											console.log(currentNode);

											const currentCanvasXML = window.mxUtils.getXml(
												currentNode,
												this.linefeed
											);
											console.log(currentCanvasXML);
										} catch (e2) {
											console.error(e2);
										}
										alert(
											"Please contact Max so he can collect debugging info."
										);
									}}
								>
									Last save: {this.state.lastSave || "?"}
								</div>
								<SaveButton
									style={{
										position: "fixed",
										zIndex: 1,
										top: 60,
										left:
											this.props.panePosition === "center"
												? "calc(50vw - 85px)"
												: "calc(100vw - 105px)",
									}}
									save={this.save}
									autoSave={30}
									autoSaveData={"canvas"}
									onContextMenu={(evt) => {
										evt.preventDefault();
										console.log(1);
										this.setState({ restoreModalOpen: true });
									}}
								/>
							</>
						) : null}
						<Fab
							color="primary"
							aria-label="Zoom In"
							style={{
								position: "absolute",
								zIndex: 1,
								bottom: 168,
								left: 85,
							}}
							onClick={() => (window.graph ? window.graph.zoomIn() : null)}
						>
							<ZoomInIcon fontSize="large" />
						</Fab>
						<Fab
							color="primary"
							aria-label="Zoom Out"
							style={{ position: "absolute", zIndex: 1, bottom: 108, left: 85 }}
							onClick={() => (window.graph ? window.graph.zoomOut() : null)}
						>
							<ZoomOutIcon fontSize="large" />
						</Fab>
						{this.state.editMode ? (
							<Fab
								color="primary"
								aria-label="Toggle sidebar"
								style={{
									position: "absolute",
									zIndex: 1,
									bottom: 108,
									left:
										this.props.panePosition === "center"
											? "calc(50vw - 85px)"
											: "calc(100vw - 105px)",
								}}
								onClick={this.toggleSidebar}
								onContextMenu={this.redOutlineCheck}
							>
								<TextFieldsIcon fontSize="large" />
							</Fab>
						) : null}
						<RestoreModal
							isOpen={this.state.restoreModalOpen}
							close={() => {
								this.setState({ restoreModalOpen: false });
							}}
							currentCanvasID={this.state.currentCanvasID}
						/>
					</>
				);
			}
		}
	)
);

const RestoreModal = ({ isOpen, close, currentCanvasID }) => {
	const [savePoints, setSavePoints] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		(async () => {
			if (isOpen) {
				const savePointsDB = await client.service("canvasSaveHistory").find({
					query: { canvasID: currentCanvasID, $sort: { id: -1 }, limit: 20 },
				});

				setSavePoints(savePointsDB);
			}
		})();
	}, [currentCanvasID, isOpen]);

	return (
		<Dialog
			onClose={() => {
				close();
			}}
			open={isOpen}
		>
			<DialogContent style={{ minWidth: 500 }}>
				<p>
					Click on the preview button to confirm you want to restore that
					version, close the tab, then click the restore button.
				</p>
				<List>
					{savePoints
						? savePoints.map((savePoint) => {
								return (
									<ListItem key={savePoint.id}>
										<Grid container>
											<Grid item xs={6} style={{ alignSelf: "center" }}>
												{moment(savePoint.createdAt).format(
													"MMMM Do YYYY, h:mm:ss a"
												)}
											</Grid>
											<Grid item xs={3} style={{ textAlign: "center" }}>
												<Button
													disabled={loading}
													onClick={() => {
														window.open(
															`${window.location.origin}/canvasViewer/${savePoint.id}?saveHistory=true`
														);
													}}
												>
													Preview
												</Button>
											</Grid>
											<Grid item xs={3} style={{ textAlign: "center" }}>
												<Button
													onClick={async () => {
														setLoading(true);
														await client
															.service("canvas")
															.patch(currentCanvasID, {
																content: savePoint.content,
															});
														setLoading(false);
														window.location.reload();
													}}
													disabled={loading}
												>
													Restore
												</Button>
											</Grid>
										</Grid>
									</ListItem>
								);
						  })
						: "Loading"}
				</List>
			</DialogContent>
		</Dialog>
	);
};

export const updateCell = (
	name,
	selectedCell = window.graph.getSelectionCell()
) => {
	window.graph.getModel().beginUpdate();
	window.graph.cellLabelChanged(selectedCell, name, false);
	window.graph.getModel().endUpdate();
};

export const updateMessageColors = (cell, messageStatus) => {
	window.graph.getModel().beginUpdate();
	if (messageStatus === "Backlog") {
		window.graph.setCellStyles("fillColor", "#16B1F0", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "To Do") {
		window.graph.setCellStyles("fillColor", "#EF5F2F", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "Doing") {
		window.graph.setCellStyles("fillColor", "#390A4E", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "In Process") {
		window.graph.setCellStyles("fillColor", "#F2A524", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "Roadblocked") {
		window.graph.setCellStyles("fillColor", "#D1383F", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "Done") {
		window.graph.setCellStyles("fillColor", "#7FAF41", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "In Testing") {
		window.graph.setCellStyles("fillColor", "#C6C6C6", [cell]);
		window.graph.setCellStyles("fontColor", "#000", [cell]);
	} else if (messageStatus === "Approved") {
		window.graph.setCellStyles("fillColor", "#2F3D47", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	} else if (messageStatus === "In Review") {
		window.graph.setCellStyles("fillColor", "#764899", [cell]);
		window.graph.setCellStyles("fontColor", "#fff", [cell]);
	}
	window.graph.getModel().endUpdate();
};

export const determineShapeType = (shape) => {
	if (shape.style.includes("shape=messageRectangle")) {
		return "message";
	} else if (shape.style.includes("shape=isoRectangle")) {
		return "brancher";
	} else if (shape.style.includes("shape=mxgraph.flowchart.manual_operation")) {
		return "container";
	} else if (shape.style.includes("shape=trapezoid")) {
		return "extractor";
	} else if (
		shape.style.includes("endArrow") ||
		shape.style.includes("edgeStyle")
	) {
		return "edge";
	} else if (shape.style.includes("shape=mxgraph.flowchart.merge_or_storage")) {
		return "send";
	} else if (
		shape.style.includes("shape=mxgraph.flowchart.extract_or_measurement")
	) {
		return "return";
	} else if (shape.style.includes("shape=circle")) {
		return "portal";
	} else if (shape.style.includes("shape=offPageConnector")) {
		return "crossCanvasPortal";
	} else if (shape.style.includes("text")) {
		return "text";
	} else if (shape.style.includes("shape=note")) {
		return "sticky";
	} else if (shape.style.includes("shape=mxgraph.flowchart.terminator")) {
		return "terminator";
	}
};

const panToCell = (cell) => window.graph.scrollCellToVisible(cell, true);

const findCellsByName = (name) =>
	Object.values(window.graph.getModel().cells).filter(
		(cell) => cell.value && cell.value === name
	);

export const checkBrancherRules = (branchers, cell) => {
	let hasRules = true;
	const filteredBranchers = branchers.filter(
		(brancher) => `${brancher.brancherCanvasID}` === cell.id
	);
	if (filteredBranchers.length === 0) {
		hasRules = false;
	}

	hasRulesLoop: for (const brancher of filteredBranchers) {
		if (hasRules && !brancher.else) {
			if (!brancher.brancherRuleV2s.length) {
				hasRules = false;
				break;
			}
			for (const rule of brancher.brancherRuleV2s) {
				if (
					!(
						rule.attributeID &&
						(rule.optionID ||
							rule.optionValue ||
							rule.operator === "Is Not Empty" ||
							rule.operator === "Is Empty") &&
						rule.operator
					)
				) {
					hasRules = false;
					break hasRulesLoop;
				}
			}
		}
	}
	return hasRules;
};

export default Canvas;
