import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import shortid from "shortid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import {
	faPlus,
	faTrash,
	faPencil,
	faBook,
	faTimes,
	faSave,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import API from "../../../API";
import snackbarStore from "../../../lib/SnackbarStore";
import { appStore } from "../../../App";
import { TreeStore as pageStore } from "./store";

export default observer(
	class Options extends Component {
		state = { currentPage: 1, editGlossary: false };

		render() {
			const options = this.props.options || pageStore.detail.options;
			return (
				<>
					<h3>
						{this.props.technicalTab
							? "Options & Glossaries"
							: "Attribute Options"}
					</h3>

					{options ? (
						<>
							{options.map((option, index) => {
								if (
									index >= (this.state.currentPage - 1) * 20 &&
									index < this.state.currentPage * 20
								) {
									return (
										<OptionList
											option={option}
											key={option.id}
											index={index}
											technicalTab={this.props.technicalTab}
										/>
									);
								} else {
									return null;
								}
							})}
							<div style={{ textAlign: "center", paddingTop: 15 }}>
								<Pagination
									count={Math.ceil(options.length / 20)}
									page={this.state.currentPage}
									onChange={(evt, page) => {
										this.setState({ currentPage: page });
									}}
								/>
							</div>
						</>
					) : (
						<div style={{ textAlign: "center", padding: 15 }}>
							<CircularProgress />
						</div>
					)}
					{!this.props.technicalTab && (
						<Fab
							color="primary"
							aria-label="add"
							style={{ position: "fixed", zIndex: 1, bottom: 115, right: 20 }}
							onClick={() => {
								options.push({
									edit: true,
									id: shortid.generate(),
									defaultOption: false,
									attributeID: this.props.id,
									name: "",
								});
								this.setState({
									currentPage: Math.ceil(options.length / 20),
								});
							}}
						>
							<FontAwesomeIcon icon={faPlus} />
						</Fab>
					)}
				</>
			);
		}
	}
);

const OptionList = withRouter(
	observer(
		class OptionList extends Component {
			state = {
				loading: false,
				deleteConfirm: false,
				glossaryModalOpen: false,
			};

			save = async (evt) => {
				evt.preventDefault();

				let toSend = JSON.parse(JSON.stringify(this.props.option));

				if (shortid.isValid(toSend.id)) {
					delete toSend.id;
				}
				try {
					if (toSend.defaultOption) {
						await API(`/clearDefaults`, "POST", {
							attributeID: this.props.option.attributeID,
						});
					}
					try {
						const { data } = await API(`/db/option`, "POST", toSend);

						pageStore.detail.options[this.props.index].id = data.id;
						pageStore.detail.options[this.props.index].edit = false;
					} catch (e) {
						console.log(e);
					}
				} catch (e) {
					snackbarStore.setMessage("Error", "Something went wrong");
					console.error(e);
				}
			};

			delete = async () => {
				this.setState({ deleteConfirm: false });

				const data = await API(`/option/safeDelete`, "DELETE", {
					id: this.props.option.id,
				});

				if (data.safeToDelete) {
					await API(`/db/option/${this.props.option.id}`, "DELETE");
					await API(`/db/customQuery/messageQandALabel`, "POST", {
						query: "delete",
						options: { where: { optionID: this.props.option.id } },
					});
					pageStore.detail.options.splice(this.props.index, 1);
				} else {
					if (
						window.confirm(
							`${data.whereIsUsed}\nWould you like to go there now?`
						)
					) {
						this.props.history.push(data.redirectURL);
					}
				}
			};

			saveSynonyms = async () => {
				const synonyms = this.state.synonymsText.split(/\r?\n/);
				const deduped = [...new Set(synonyms)].map((synonym) => {
					return synonym.trim().substring(0, 254);
				});
				this.setState({ synonymsText: deduped.sort().join("\n") });

				await API(`/synonym/option`, "POST", {
					synonyms: deduped,
					optionID: this.props.option.id,
				});
			};

			dedupe = () => {
				const synonyms = this.state.synonymsText.split(/\r?\n/);
				const deduped = [...new Set(synonyms)];
				this.setState({ synonymsText: deduped.join("\n") });
			};

			sort = () => {
				const synonyms = this.state.synonymsText.split(/\r?\n/);
				this.setState({ synonymsText: synonyms.sort().join("\n") });
			};

			saveGlossarySynonyms = async () => {
				if (this.state.glossarySynonymsText) {
					const synonyms = this.state.glossarySynonymsText.split(/\r?\n/);
					const deduped = [...new Set(synonyms)].map((synonym) => {
						return synonym.trim().substring(0, 254);
					});
					this.setState({ glossarySynonymsText: deduped.sort().join("\n") });

					const glossaryObj = this.state.glossaryEntries.find((glossary) => {
						return glossary.name === this.state.linkedGlossary;
					});

					await API(`/synonym/glossary`, "POST", {
						synonyms: deduped,
						glossaryID: glossaryObj.id,
						editGlossary: false,
						glossarySynonymsText: "",
					});
				}
			};

			dedupeGlossary = () => {
				const synonyms = this.state.glossarySynonymsText.split(/\r?\n/);
				const deduped = [...new Set(synonyms)];
				this.setState({ glossarySynonymsText: deduped.join("\n") });
			};

			sortGlossary = () => {
				const synonyms = this.state.glossarySynonymsText.split(/\r?\n/);
				this.setState({ glossarySynonymsText: synonyms.sort().join("\n") });
			};

			autoLink = async () => {
				const { data } = await API(`/glossary/search`, "POST", {
					projectID: appStore.selectedProject,
					searchTerm: this.props.option.name,
				});

				const exactMatch = data.find((match) => {
					return (
						match.name.toLowerCase() === this.props.option.name.toLowerCase()
					);
				});

				if (exactMatch) {
					this.setState({ linkedGlossary: exactMatch.name });

					API(`/db/option`, "POST", {
						id: this.props.option.id,
						glossaryID: exactMatch.id,
					});
				} else if (data.length === 1) {
					this.setState({ linkedGlossary: data[0].name });

					API(`/db/option`, "POST", {
						id: this.props.option.id,
						glossaryID: data[0].id,
					});
				} else {
					snackbarStore.setMessage("Warning", "Auto linking failed");
				}
			};

			render() {
				return (
					<div className="OptionInList">
						{shortid.isValid(this.props.option.id) || this.props.option.edit ? (
							<form style={{ display: "flex", alignItems: "center" }}>
								<div style={{ flexGrow: 100 }}>
									<Grid container>
										<Grid
											item
											xs={1}
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Checkbox
												checked={this.props.option.defaultOption}
												style={{
													padding: 0,
												}}
												onChange={(evt) => {
													for (const option of pageStore.detail.options) {
														option.defaultOption = false;
													}
													pageStore.detail.options[
														this.props.index
													].defaultOption = evt.target.checked;
												}}
												inputProps={{ "aria-label": "primary checkbox" }}
											/>
										</Grid>
										<Grid item xs={11}>
											<TextField
												label="Name"
												variant="outlined"
												value={this.props.option.name || ""}
												onChange={(evt) => {
													pageStore.detail.options[this.props.index].name =
														evt.target.value;
												}}
												size="small"
												disabled={this.state.loading}
												autoFocus
												autoComplete="off"
											/>
										</Grid>
									</Grid>
								</div>
								<div style={{ flex: 1, minWidth: 175 }}>
									{this.state.loading ? (
										<div style={{ justifyContent: "center", display: "flex" }}>
											<CircularProgress size={28} />
										</div>
									) : (
										<ButtonGroup
											color="secondary"
											aria-label="text primary button group"
											variant="text"
											style={{ width: "100%" }}
										>
											<Button
												onClick={this.save}
												disabled={
													!this.props.option.name ||
													this.props.option.name === ""
												}
												style={{ width: "100%" }}
												type="submit"
											>
												<FontAwesomeIcon icon={faSave} />
											</Button>
											<Button
												onClick={() => {
													if (this.props.option.edit) {
														pageStore.detail.options[
															this.props.index
														].edit = false;
													} else {
														pageStore.detail.options.splice(
															this.props.index,
															1
														);
													}
												}}
												style={{ width: "100%" }}
											>
												<FontAwesomeIcon icon={faTimes} />
											</Button>
										</ButtonGroup>
									)}
								</div>
							</form>
						) : (
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
									}}
								>
									<div style={{ flexGrow: 100 }}>
										<Button
											style={{
												width: "100%",
												justifyContent: "left",
												textTransform: "none",
												fontWeight: 400,
												background: "inherit",
											}}
											onClick={() => {
												if (this.props.technicalTab) {
													this.setState({ glossaryModalOpen: true });
												}
											}}
										>
											<Checkbox
												checked={this.props.option.defaultOption}
												style={{
													visibility: this.props.option.defaultOption
														? "visible"
														: "hidden",
													padding: 0,
												}}
												onChange={() => {}}
												inputProps={{ "aria-label": "primary checkbox" }}
											/>
											{this.props.option.name}
										</Button>
									</div>
									<div
										style={{
											flex: 1,
											minWidth: this.props.technicalTab ? 50 : 175,
										}}
									>
										{this.state.loading ? (
											<div
												style={{ justifyContent: "center", display: "flex" }}
											>
												<CircularProgress size={28} />
											</div>
										) : this.props.technicalTab ? (
											[
												<Button
													onClick={() => {
														this.setState({ glossaryModalOpen: true });
													}}
													style={{ width: "100%" }}
												>
													<FontAwesomeIcon
														icon={faBook}
														style={{
															color: this.props.option.glossaryID
																? "#d1373f"
																: "inherit",
														}}
													/>
												</Button>,
											]
										) : (
											[
												<ButtonGroup
													key={1}
													color="secondary"
													aria-label="text primary button group"
													style={{ width: "100%" }}
													variant="text"
												>
													<Button
														onClick={() => {
															pageStore.detail.options[
																this.props.index
															].edit = true;
														}}
														style={{ width: "100%" }}
													>
														<FontAwesomeIcon icon={faPencil} />
													</Button>
													<Button
														onClick={() => {
															this.setState({ glossaryModalOpen: true });
														}}
														style={{ width: "100%" }}
													>
														<FontAwesomeIcon
															icon={faBook}
															style={{
																color: this.props.option.glossaryID
																	? "#d1373f"
																	: "inherit",
															}}
														/>
													</Button>
													<Button
														onClick={() => {
															this.setState({ deleteConfirm: true });
														}}
														style={{ width: "100%" }}
														ref={(el) => {
															this.deleteButton = el;
														}}
													>
														<FontAwesomeIcon icon={faTrash} />
													</Button>
												</ButtonGroup>,
												<Menu
													key={2}
													anchorEl={this.deleteButton}
													keepMounted
													open={this.state.deleteConfirm}
													onClose={() => {
														this.setState({ deleteConfirm: false });
													}}
												>
													<MenuItem disabled>You Sure?</MenuItem>
													<MenuItem
														style={{ color: "red" }}
														onClick={this.delete}
													>
														Yes
													</MenuItem>
													<MenuItem
														onClick={() => {
															this.setState({ deleteConfirm: false });
														}}
													>
														No
													</MenuItem>
												</Menu>,
											]
										)}
									</div>
								</div>
							</div>
						)}
						<Dialog
							open={this.state.glossaryModalOpen}
							onClose={() => {
								this.setState({
									glossaryModalOpen: false,
									glossaryEntries: null,
								});
							}}
							onEntering={async () => {
								const { glossaryEntries } = await API(
									`/glossaryEntries`,
									"POST",
									{
										projectID: appStore.selectedProject,
									}
								);

								if (this.props.option.glossaryID) {
									const glossary = glossaryEntries.find((glossary) => {
										return glossary.id === this.props.option.glossaryID;
									});

									if (glossary) {
										this.setState({ linkedGlossary: glossary.name });
									}
								}

								const synonyms = await API(
									`/db/customQuery/optionSynonym`,
									"POST",
									{
										query: "find",
										options: { where: { optionID: this.props.option.id } },
									}
								);

								this.setState({
									glossaryEntries,
									synonymsText: synonyms.data
										.map((row) => {
											return row.synonym;
										})
										.join("\n"),
								});
							}}
						>
							<DialogContent style={{ minWidth: 500 }}>
								{!this.state.glossaryEntries ? (
									<div
										style={{
											justifyContent: "center",
											alignItems: "center",
											display: "flex",
											height: "100%",
										}}
									>
										<CircularProgress />
									</div>
								) : (
									<>
										<h3>Linked Glossary Entry:</h3>
										<Grid container>
											<Grid item xs={9}>
												<Autocomplete
													options={this.state.glossaryEntries.map((option) => {
														return option.name;
													})}
													style={{ marginTop: 10, marginBottom: 10 }}
													value={this.state.linkedGlossary || null}
													onChange={(evt, val) => {
														this.setState({
															linkedGlossary: val,
															editGlossary: false,
														});

														const glossaryObj = this.state.glossaryEntries.find(
															(glossary) => {
																return glossary.name === val;
															}
														);
														this.props.option.glossaryID = glossaryObj
															? glossaryObj.id
															: null;
														API(`/db/option`, "POST", {
															id: this.props.option.id,
															glossaryID: glossaryObj ? glossaryObj.id : null,
														});
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Linked Glossary"
															variant="outlined"
														/>
													)}
												/>
											</Grid>
											<Grid
												item
												xs={3}
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												{this.state.linkedGlossary ? (
													<Button
														variant="contained"
														onClick={async () => {
															this.setState({
																editGlossary: false,
																glossarySynonymsText: "",
															});

															const glossaryObj = this.state.glossaryEntries.find(
																(glossary) => {
																	return (
																		glossary.name === this.state.linkedGlossary
																	);
																}
															);

															const synonyms = await API(
																`/db/customQuery/glossaryEntrySynonym`,
																"POST",
																{
																	query: "find",
																	options: {
																		where: { glossaryID: glossaryObj.id },
																	},
																}
															);

															this.setState({
																glossarySynonymsText: synonyms.data
																	.map((row) => {
																		return row.synonym;
																	})
																	.join("\n"),
																editGlossary: true,
															});
														}}
													>
														Edit
													</Button>
												) : (
													<Button variant="contained" onClick={this.autoLink}>
														Auto Link
													</Button>
												)}
											</Grid>
										</Grid>

										{this.state.linkedGlossary ? null : (
											<>
												<Divider style={{ marginTop: 15, marginBottom: 15 }} />
												<Grid container>
													<Grid item xs={6}>
														<h3>Synonyms:</h3>
													</Grid>
													<Grid item xs={6} style={{ textAlign: "right" }}>
														<ButtonGroup
															color="secondary"
															variant="text"
															style={{
																width: "100%",
																justifyContent: "flex-end",
															}}
														>
															<Button onClick={this.dedupe}>Dedupe</Button>
															<Button onClick={this.sort}>Sort</Button>
															<Button onClick={this.saveSynonyms}>Save</Button>
														</ButtonGroup>
													</Grid>
													<TextField
														id="outlined-multiline-static"
														label="Synonyms"
														multiline
														rows={10}
														variant="outlined"
														value={this.state.synonymsText}
														onChange={(evt) => {
															this.setState({ synonymsText: evt.target.value });
														}}
													/>
												</Grid>
											</>
										)}
										{this.state.editGlossary ? (
											<>
												<Divider style={{ marginTop: 15, marginBottom: 15 }} />
												<Grid container>
													<Grid item xs={6}>
														<h3>Glossary Synonyms:</h3>
													</Grid>
													<Grid item xs={6} style={{ textAlign: "right" }}>
														<ButtonGroup
															color="secondary"
															variant="text"
															style={{
																width: "100%",
																justifyContent: "flex-end",
															}}
														>
															<Button onClick={this.dedupeGlossary}>
																Dedupe
															</Button>
															<Button onClick={this.sortGlossary}>Sort</Button>
															<Button onClick={this.saveGlossarySynonyms}>
																Save
															</Button>
														</ButtonGroup>
													</Grid>
													<TextField
														id="outlined-multiline-static"
														label="Synonyms"
														multiline
														rows={10}
														variant="outlined"
														value={this.state.glossarySynonymsText}
														onChange={(evt) => {
															this.setState({
																glossarySynonymsText: evt.target.value,
															});
														}}
													/>
												</Grid>
											</>
										) : null}
									</>
								)}
								<DialogActions>
									<Button
										onClick={() => {
											this.setState({
												glossaryModalOpen: false,
												glossaryEntries: null,
											});
										}}
									>
										Close
									</Button>
									<Button
										onClick={async () => {
											if (this.state.linkedGlossary) {
												await this.saveGlossarySynonyms();
											} else {
												await this.saveSynonyms();
											}
											this.setState({
												glossaryModalOpen: false,
												glossaryEntries: null,
											});
										}}
									>
										Save
									</Button>
								</DialogActions>
							</DialogContent>
						</Dialog>
					</div>
				);
			}
		}
	)
);
