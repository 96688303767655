import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import API from "../../../API";
import RedButton from "../../../lib/RedButton";
import CalculatedEditor from "./CalculatedEditor";
import Options from "./OptionList";
import { TreeStore, updateAttribute } from "./store";
import client from "../../../lib/feathers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "react-select";
import { appStore } from "../../../App";

const useStyles = makeStyles(() => ({
	paper: {
		padding: 15,
		marginBottom: 15,
	},
}));

export const EditAttribute = observer(() => {
	const {
		loading,
		id,
		showDefaultValue,
		showOptions,
		showCalculated,
		showUsedOn,
	} = TreeStore.detail;
	const [attributeDefaultValue, setAttributeDefaultValue] = useState();
	const classes = useStyles();

	const save = async ({ name }) => {
		let payload = { id, name };
		if (showDefaultValue) {
			payload.defaultValue = attributeDefaultValue;
		}
		return await updateAttribute(TreeStore, payload);
	};

	useEffect(() => {
		if (showDefaultValue) {
			API(`/db/attribute/${id}`, "GET").then(({ data }) => {
				setAttributeDefaultValue(data.defaultValue || "");
				return data;
			});
		}
	}, [id, showDefaultValue]);

	return (
		<>
			{!loading ? (
				<>
					{showDefaultValue ? (
						<Paper className={classes.paper}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<TextField
										autoFocus
										label="Default Value"
										fullWidth
										value={attributeDefaultValue || ""}
										onChange={(evt) => {
											setAttributeDefaultValue(evt.target.value);
										}}
									/>
								</Grid>

								<Grid item xs={12}>
									<RedButton
										style={{ marginTop: 10, marginBottom: 15 }}
										onClick={save}
									>
										Save
									</RedButton>
								</Grid>
							</Grid>
						</Paper>
					) : null}
					{showOptions ? (
						<Paper style={{ padding: 10 }}>
							<Options
								id={id}
								optionsLoading={TreeStore.detail.loading}
								technicalTab={false}
							/>
						</Paper>
					) : null}
					<AttributeProperties
						attributeID={id}
						showCalculated={showCalculated}
					/>
					{showCalculated ? (
						<CalculatedEditor id={id} type={"attribute"} />
					) : null}
					{showUsedOn ? <UsedOn id={id} /> : null}
				</>
			) : (
				<Paper className={classes.paper}>
					<div style={{ justifyContent: "center", display: "flex" }}>
						<CircularProgress size={28} />
					</div>
				</Paper>
			)}
		</>
	);
});

const AttributeProperties = ({ attributeID, showCalculated }) => {
	const [expanded, setExpanded] = useState(false);
	const [isSessionAttribute, setIsSessionAttribute] = useState(false);
	const [linkedRetriever, setLinkedRetriever] = useState(null);
	const [linkedRetrievers, setLinkedRetrievers] = useState();

	const handleChange = (event, isExpanded) => {
		setExpanded(isExpanded);
	};

	useEffect(() => {
		if (expanded) {
			(async () => {
				const attributesDB = await client
					.service("attribute")
					.find({ query: { id: attributeID } });

				let linkedRetrieversDB = [];
				if (showCalculated) {
					linkedRetrieversDB = await client.service("retriever").find({});
					setLinkedRetrievers(
						linkedRetrieversDB.map((ret) => ({
							value: ret.id,
							label: ret.name,
						}))
					);
				}

				if (attributesDB.length) {
					setIsSessionAttribute(attributesDB[0].sessionAttribute);
					if (attributesDB[0].linkedRetriever && linkedRetrieversDB.length) {
						const matchingRetriever = linkedRetrieversDB.find(
							(ret) => ret.id === attributesDB[0].linkedRetriever
						);
						if (matchingRetriever) {
							setLinkedRetriever({
								value: matchingRetriever.id,
								label: matchingRetriever.name,
							});
						}
					}
				}
			})();
		}
	}, [expanded, attributeID, showCalculated]);

	return (
		<Accordion expanded={expanded} onChange={handleChange}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<h3>Attribute Properties</h3>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={isSessionAttribute}
									onChange={() => {
										client.service("attribute").patch(attributeID, {
											sessionAttribute: !isSessionAttribute,
										});

										setIsSessionAttribute(!isSessionAttribute);
									}}
								/>
							}
							label="Session Attribute"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						{linkedRetrievers && (
							<div>
								<label>Linked Retriever:</label>
								<br />
								<Select
									options={linkedRetrievers}
									value={linkedRetriever}
									onChange={(selected) => {
										setLinkedRetriever(selected);
										client
											.service("attribute")
											.patch(attributeID, { linkedRetriever: selected.value });
									}}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export const UsedOn = ({ id }) => {
	const history = useHistory();
	const [expanded, setExpanded] = useState(false);
	const [messages, setMessages] = useState([]);

	const handleChange = (event, isExpanded) => {
		setExpanded(isExpanded);
	};

	useEffect(() => {
		if (expanded) {
			API(`/db/customQuery/messageAttribute`, "POST", {
				query: "find",
				options: {
					limit: 25,
					where: { attributeID: id },
					include: [
						{
							model: "Message",
							where: { projectID: appStore.selectedProject },
							attributes: ["name", "id", "shapeID", "canvasID", "projectID"],
							required: true,
						},
					],
				},
			}).then(({ data }) => {
				let messageIDs = [];
				setMessages(
					data
						.filter((messageAttribute) => {
							//dedupe
							if (messageIDs.includes(messageAttribute.message.id)) {
								return false;
							} else {
								messageIDs.push(messageAttribute.message.id);
								return true;
							}
						})
						.map((messageAttribute) => messageAttribute.message)
				);
				return data;
			});
		}
	}, [expanded, id]);

	return (
		<Accordion expanded={expanded} onChange={handleChange}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<h3>Messages</h3>
			</AccordionSummary>
			<AccordionDetails>
				<List style={{ width: "100%" }}>
					{messages.length ? (
						messages.map((message) => {
							return (
								<ListItem
									key={message.id}
									onClick={async () => {
										history.push(
											`/canvas/${message.canvasID}/${message.shapeID}`
										);
									}}
									button
									style={{
										width: "100%",
										justifyContent: "left",
										textTransform: "none",
									}}
								>
									{message.name}
								</ListItem>
							);
						})
					) : (
						<p>This attribute is not in use</p>
					)}
				</List>
			</AccordionDetails>
		</Accordion>
	);
};
