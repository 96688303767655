/**
 * @module attributeImporter - Transposes (CSV & Excel docs) => Attributes
 * - This is bound a Project because it allows users to quickly upload a spreadsheet of Attributes,
 *     & have them automagically available in the active project
 *
 * - This expects the Doc to have the following fields:
 *      File/Folder, Parent Folder, Folder Name,  File Type, Attribute, Option, Default Option, Raw/List Link
 *
 * @see {Google Drive/tuzag team/tuzagTCS/Mockups/Sample Attributes Upload template.xlsx} for the import template
 */
import Papa from "papaparse";
import API from "../../../API";
import client from "../../../lib/feathers";
import { appStore } from "../../../App";

export const attributeImporter = async (projectID, file, onProgressUpdate) => {
	const fileRows = await papaDecorator(file);
	const { parsedRows, optionCount } = parseFile(fileRows);
	return await uploadFile(projectID, parsedRows, onProgressUpdate, optionCount);
};

export const papaDecorator = (file, header = true) =>
	new Promise((resolve, reject) => {
		Papa.parse(file, {
			header,
			complete: (results) =>
				results.errors && results.errors.length
					? reject(results.errors)
					: resolve(results.data),
		});
	});

const parseFile = (rows) => {
	let results = [];
	let optionCount = 0;
	for (const row of rows) {
		const rowType = lowerCase(row["Folder/File"]);
		// Exit early if we reached the end of the file
		if (rowType === "end of file") {
			break;
		}
		const parentFolder = row["Parent Folder"];
		const folderName = row["Folder Name"];
		const attribute = row["Attribute"];
		const optionName = row["Option"];
		const defaultOption = Boolean(lowerCase(row["Default Option"]));
		const sessionAttribute = Boolean(lowerCase(row["Session Attribute"]));

		if ("folder" === rowType) {
			results.push({
				type: rowType,
				name: folderName,
				parentFolder: parentFolder === "root" ? null : parentFolder,
			});
		} else if ("file" === rowType) {
			optionCount++;
			results.push({
				type: rowType,
				name: attribute,
				fileType: lowerCase(row["File Type"]).split(" ")[0],
				parentFolder: parentFolder === "root" ? null : parentFolder,
				code: row.Code && row.Code !== "" ? row.Code : "",
				options: [
					{
						name: optionName,
						default: defaultOption,
					},
				],
				glossary: row["Linked Glossary Entry"],
				sessionAttribute,
			});
		} else if (optionName && optionName.length) {
			optionCount++;
			// Otherwise, it's an option row.  Grab the last row & push
			// this value into its list of options
			results[results.length - 1].options.push({
				name: optionName,
				default: defaultOption,
			});
		}
	}
	return { parsedRows: results, optionCount };
};

//-- API Methods
const uploadFile = async (
	projectID,
	parsedRows,
	onProgressUpdate,
	optionCount
) => {
	let currentOption = 1;
	const getParent = getParentFolder();

	try {
		for (const row of parsedRows) {
			const parentFolderID = !row.parentFolder
				? null
				: await getParent.next(row.parentFolder);
			// FIXME: CR 2020-Sep-18 - The customQuery POSTS, & back-to-back POST -> GET requests
			//    below are a WORKAROUND for an issue in the API itself.  Due to time constraints, I was unable
			//    to resolve the API issue, but this will let users move forward with uploading Attributes via CSV file
			//    Please revert the logic below so we just do one PUT request once the API plays nice with
			//    upserts + unique constraints

			//FIXME MZM 2021-Mar-1 UPDATE - I've rewritten the importer in the backend for the glossary endpoint
			//  `/glossaryImport` but have not yet ported it over here. Upserts in the database are still fucked
			//  up but at least the back to back calls have all been moved to the backend. What I have not yet
			//  solved for with that new update is progress reporting.
			if ("folder" === row.type) {
				const folder = await API(`/db/customQuery/attributeFolder`, "POST", {
					query: "findOne",
					options: {
						where: {
							projectID,
							name: row.name,
						},
					},
				});

				if (folder.data?.id) {
					await API(`/db/attributeFolder`, "POST", {
						projectID,
						parentFolderID,
						id: folder.data.id,
						name: row.name,
					});
					const data = await API(
						`/db/attributeFolder/${folder.data.id}`,
						"GET"
					);
					getParent.push(data.name, data.id);
				} else {
					const data = await API(`/db/attributeFolder`, "POST", {
						projectID,
						parentFolderID,
						name: row.name,
					});
					getParent.push(data.name, data.id);
				}
			} else if ("file" === row.type) {
				let attributeData = null;
				const attribute = await API(`/db/customQuery/attribute`, "POST", {
					query: "findOne",
					options: {
						where: {
							projectID,
							name: row.name,
						},
					},
				});
				if (attribute?.data?.id) {
					await API(`/db/attribute`, "POST", {
						parentFolderID,
						projectID,
						id: attribute.data.id,
						name: row.name,
						type: row.fileType,
						code: row.code,
						sessionAttribute: row.sessionAttribute,
						defaultValue:
							row.fileType === "retrieved" && row.options[0].default
								? row.options[0].name
								: null,
					});
					const { data } = await API(
						`/db/attribute/${attribute.data.id}`,
						"GET"
					);
					attributeData = data;
					getParent.push(data.name, data.id);
				} else {
					const { data } = await API("/db/attribute", "POST", {
						parentFolderID,
						projectID,
						name: row.name,
						type: row.fileType,
						sessionAttribute: row.sessionAttribute,
						code: row.code,
						defaultValue:
							row.fileType === "retrieved" && row.options[0].default
								? row.options[0].name
								: null,
					});
					attributeData = data;
					getParent.push(data.name, data.id);
				}

				// Save the options for this row
				for (const option of row.options) {
					currentOption++;
					if (option && option.name.length) {
						if (row.fileType === "retrieved") {
							//ignore, we store the default value in the attribute table
						} else {
							let optionData = {
								name: option.name,
								attributeID: attributeData.id,
								defaultOption: option.default,
							};

							if (row.glossary) {
								const glossary = await client
									.service("glossaryEntry")
									.find({ query: { name: row.glossary } });

								if (glossary.length && glossary[0].name) {
									optionData.glossaryID = glossary[0].id;
								}
							}

							await API("/db/option", "PUT", {
								data: optionData,
							});
						}
					}
					onProgressUpdate((currentOption / optionCount) * 100);
				}
			}
		}
		await getParent.complete(null);
		return Promise.resolve(true);
	} catch (e) {
		await getParent.complete(null);
		return Promise.reject(e);
	}
};

/**
 * @func getParentFolder - Iterator for keeping a memoized lookup of attribute names -> IDs
 * Clear out the 'cache' by calling 'complete()' ;)
 */
const getParentFolder = () => {
	let folderIdCache = {};
	return {
		next: async (name) => {
			const cachedValue = folderIdCache[name];
			return cachedValue
				? await Promise.resolve(cachedValue)
				: API(`/db/customQuery/attributeFolder`, "POST", {
						query: "findOne",
						options: { where: { name, projectID: appStore.selectedProject } },
				  }).then(({ data }) => {
						if (data && data.id) {
							folderIdCache[name] = data.id;
							return data.id;
						}
						return null;
				  });
		},
		push: (key, value) => {
			folderIdCache[key] = value;
			return value;
		},
		complete: (value) => {
			folderIdCache = {};
			return Promise.resolve(value);
		},

		error: (err) => Promise.reject(err),
	};
};

//-- Utils
const lowerCase = (s) => (s ? String(s).toLowerCase().trim() : "");
