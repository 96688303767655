import React, { Component } from "react";
import qrcode from "qrcode";
import { authenticator } from "@otplib/preset-default";

export default class QRCode extends Component {
	constructor() {
		super();
		this.secret = authenticator.generateSecret();
	}
	state = { img: null };
	render() {
		const user = "max@tuz.ag";
		const service = "tuzagTCS";
		console.log(this.secret);
		const otpauth = authenticator.keyuri(user, service, this.secret);
		qrcode.toDataURL(otpauth, (err, imgUrl) => {
			if (err) {
				console.log("Error with QR");
				return;
			}
			this.setState({ img: imgUrl });
		});

		return (
			<div>
				<p>Test</p>
				{this.state.img ? <img src={this.state.img} alt="QR Code" /> : null}
			</div>
		);
	}
}
