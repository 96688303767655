import debounce from "lodash.debounce";
import { action, observable, runInAction } from "mobx";
import { appStore } from "../../../App";
import API from "../../../API";

const initialState = {
	tree: {
		init: false,
		loading: false,
		// string[] - 'key' values used for RC-Tree
		selected: [],
		loadedKeys: {},
		// data: [],
		folders: [],
		attributes: [],
		treeData: [],
	},
	// Use to handle state when editing a single Attribute/Folder
	detail: {
		open: false,
		loading: false,
		id: null,
		isAttribute: false,
		data: {},
		options: [],
		showCalculated: false,
		showDefaultValue: false,
		showOptions: false,
		showUsedOn: false,
	},
	search: {
		loading: false,
		data: [],
		query: "",
		selectedId: null,
	},
	modal: {
		// Creation
		attributeOpen: false,
		folderOpen: false,
		uploadOpen: false,
		// Editing
		editFolderOpen: false,
		editAttributeOpen: false,
	},
};

export const TreeStore = observable(initialState);

//-- Actions
export const reset = action((state) => {
	Object.entries(initialState).forEach(([key, value]) => {
		state[key] = value;
	});
});

export const resetDetail = action((state) => {
	Object.entries(initialState.detail).forEach(([key, value]) => {
		state.detail[key] = value;
	});
});

/**
 * @func updateTree - Make the `treeData` key reflect the Attributes & Folders
 * @param {Object} state
 * @returns {Object}
 */
export const updateTree = (state) => {
	const { folders, attributes } = state.tree;
	const roots = folders.filter((folder) => null === folder.parentFolderID);
	const treeData = sortBy("name")(roots).map((folder) =>
		Folder(folder, { attributes, folders })
	);
	state.tree.treeData = treeData;
	return treeData;
};

/**
 * @typedef {Object} AttributeResponse
 * @property {Number|null} parentFolderID
 * @property {Object[]} attributes
 * @property {Object[]} folders
 */

/**
 * @func loadData - Load tree data for the given node(s)
 * @param {TreeStore} state
 * @param {Number|null} parentFolderID
 * @returns {Promise<AttributeResponse>}
 */
export const loadData = action(async (state, parentFolderID) => {
	state.tree.init = true;
	state.tree.loading = true;
	const params = parentFolderID ? `?parentFolderID=${parentFolderID}` : "";
	const response = await API(`/attributesTreeV2${params}`, "GET");
	state.tree.folders = uniqById(state.tree.folders.concat(response.folders));
	state.tree.attributes = uniqById(
		state.tree.attributes.concat(response.attributes)
	);
	state.tree.loading = false;
	if (parentFolderID) {
		// Key === folderID, value === RC Tree formatted parent folder ID
		state.tree.loadedKeys[parentFolderID] = `folder-${parentFolderID}`;
	}
	return response;
});

export const loadTreeData = action(async (parentFolderID) => {
	const response = await loadData(TreeStore, parentFolderID);
	runInAction(() => updateTree(TreeStore));
	return response;
});

//-- Fire a POST request & update attributes in state
export const createAttribute = action(async (state, data) => {
	const selectedKey = state.tree.selected[0];
	const selectedId = keyToId(selectedKey);
	const folderIsSelected = selectedKey.includes("folder");
	const parentFolderID = folderIsSelected
		? selectedId
		: state.tree.attributes.find((attribute) => selectedId === attribute.id)
				?.parentFolderID;
	const response = await API(`/db/attribute`, "POST", {
		...data,
		parentFolderID,
	});
	const attribute = response.data;
	state.tree.attributes = state.tree.attributes.concat(attribute);
	return attribute;
});

//-- Fire a POST request & update folders in state
export const createFolder = action(async (state, data) => {
	const selectedKey = state.tree.selected[0];
	const selectedId = keyToId(selectedKey);
	const folderIsSelected = selectedKey.includes("folder");
	const parentFolderID = folderIsSelected
		? selectedId
		: state.tree.attributes.find((attribute) => selectedId === attribute.id)
				?.parentFolderID;
	const response = await API(`/db/attributeFolder`, "POST", {
		...data,
		parentFolderID,
	});
	const folder = response.data;
	state.tree.folders = state.tree.folders.concat(folder);
	return folder;
});

//-- Make the DELETE request, remove the attribute from state
export const deleteAttribute = action(async (state, id) => {
	const attribute = state.tree.attributes.find((a) => id === a.id);
	const { parentFolderID } = attribute;
	// Check if it's safe to delete this Attribute
	const { safeToDelete, whereIsUsed } = await API(
		`/attribute/safeDelete`,
		"DELETE",
		{
			attributeID: id,
		}
	);
	if (safeToDelete) {
		await API(`/db/attribute/${id}`, "DELETE");
		state.tree.attributes = state.tree.attributes.filter((a) => id !== a.id);
	} else {
		alert(
			`${whereIsUsed} Please remove or change the attribute then try deleting again`
		);
	}
	return { id, parentFolderID };
});

//-- Make the DELETE request, remove the folder & child attributes from state
export const deleteFolder = action(async (state, id) => {
	const folder = state.tree.folders.find((f) => id === f.id);
	const { parentFolderID } = folder;
	// Check if it's safe to delete this Folder
	const { safeToDelete, whereIsUsed } = await API(
		`/attributeFolder/safeDelete`,
		"DELETE",
		{
			id,
		}
	);
	if (safeToDelete) {
		await API(`/db/attributeFolder/${id}`, "DELETE");
		// Exclude the deleted folder + child folders
		state.tree.folders = state.tree.folders.filter(
			(f) => id !== f.id && id !== f.parentFolderID
		);
		state.tree.attributes = state.tree.attributes.filter(
			(attribute) => id !== attribute.parentFolderID
		);
	} else {
		alert(
			`${whereIsUsed} Please remove or change the attribute then try deleting again`
		);
	}

	return { id, parentFolderID };
});

//-- Move Attributes in the tree
export const moveAttribute = action(async (state, id, parentFolderID) => {
	const staleAttribute = state.tree.attributes.find(
		(attribute) => id === attribute.id
	);
	// Make sure the parent changed before we fire
	const folderChanged =
		staleAttribute && staleAttribute.parentFolderID !== parentFolderID;
	if (!folderChanged) {
		return Promise.resolve(staleAttribute);
	}
	await API(`/db/attribute`, "POST", {
		id,
		parentFolderID,
	});

	// Reload both the previous folder & the new folder
	return Promise.all([
		loadTreeData(parentFolderID),
		loadTreeData(staleAttribute.parentFolderID),
	]);
});

//--  Move Attribute Folders in the tree
export const moveFolder = action(async (state, id, parentFolderID) => {
	const staleFolder = state.tree.folders.find(
		(attribute) => id === attribute.id
	);
	// Make sure the parent changed before we fire
	const folderChanged =
		staleFolder && staleFolder.parentFolderID !== parentFolderID;
	if (!folderChanged) {
		return Promise.resolve(staleFolder);
	}
	await API(`/db/attributeFolder`, "POST", {
		id,
		parentFolderID,
	});
	// Reload both the previous folder & the new folder
	return Promise.all([
		loadTreeData(parentFolderID),
		loadTreeData(staleFolder.parentFolderID),
	]);
});

export const editFolder = action(async (state, id) => {
	state.detail = {
		...state.detail,
		...initialState.detail,
		id,
		isAttribute: false,
		loading: true,
		open: true,
		data: {},
		options: [],
		showCalculated: false,
		showDefaultValue: false,
		showOptions: false,
		showUsedOn: false,
	};
	const { data } = await API(`/db/attributeFolder/${id}`, "GET");
	state.detail = {
		...state.detail,
		data: { ...data },
		loading: false,
	};
	return data;
});

export const editAttribute = action(async (state, id) => {
	state.detail = {
		...state.detail,
		id,
		isAttribute: true,
		loading: true,
		open: true,
		data: {},
	};
	const attributeResponse = await API(`/db/attribute/${id}`, "GET");
	const attribute = attributeResponse.data;
	let detail = {
		loading: false,
		data: { ...attribute },
		isAttribute: true,
		options: [],
		showCalculated: false,
		showDefaultValue: false,
		showOptions: false,
		showUsedOn: true,
	};
	const optionLessTypes = ["retrieved", "raw"];
	if (!optionLessTypes.includes(attribute.type)) {
		const optionResponse = await API(`/db/customQuery/option`, "POST", {
			query: "find",
			options: {
				where: {
					attributeID: id,
				},
				order: [["name", "ASC"]],
			},
		});
		detail.options = optionResponse.data;
		detail.showOptions = true;

		detail.showCalculated = true;
	} else if ("retrieved" === attribute.type) {
		detail.showCalculated = true;
		detail.showDefaultValue = true;
	}
	state.detail = { ...state.detail, ...detail };
	return attribute;
});

// PATCH an Attribute
export const updateAttribute = action(async (state, { id, ...fields }) => {
	state.detail.loading = true;
	const data = await API(`/attribute/${id}`, "PATCH", {
		data: {
			id,
			...fields,
		},
	});
	state.tree.attributes = state.tree.attributes.map((item) =>
		id !== item.id ? item : { ...item, ...data }
	);
	state.detail.loading = false;
	state.detail.data = { ...state.detail.data, ...data };
	updateTree(state);
	return data;
});

// PUT a Folder
export const updateFolder = action(async (state, { id, ...fields }) => {
	state.detail.loading = true;
	const { data } = await API("/db/attributeFolder", "PUT", {
		data: {
			id,
			...fields,
		},
	});
	state.tree.folders = state.tree.folders.map((item) =>
		id !== item.id ? item : { ...item, ...data }
	);
	state.detail.loading = false;
	state.detail.data = { ...state.detail.data, ...data };
	updateTree(state);
	return data;
});

//-- Search
const _searchAttributes = action(async (state, query) => {
	state.search.loading = true;
	try {
		const { data } = await API("/attribute/search", "POST", {
			searchTerm: query,
			projectID: appStore.selectedProject,
			notIn: [],
		});
		// Don't update the state if the search query changed while
		// we were loading the results
		if (state.search.query === query) {
			state.search.data = data;
			state.search.loading = false;
		}
		return data;
	} catch (error) {
		state.search.loading = false;
		throw error;
	}
});

export const searchAttributes = debounce(_searchAttributes, 500);

// Helper functions
export const keyToId = (key) =>
	Number(key.replace("attribute-", "").replace("folder-", ""));

export const Key = {
	toId: keyToId,
	isAttribute: (key) => key.includes("attribute-"),
	isFolder: (key) => key.includes("folder-"),
};

const Folder = (folder, { attributes, folders }) => {
	const { id, name } = folder;
	const sortChildren = sortBy("name");
	const childFolders = folders
		.filter((f) => id === f.parentFolderID)
		.map((f) => Folder(f, { attributes, folders }));
	const childAttributes = attributes
		.filter((attribute) => id === attribute.parentFolderID)
		.map(Attribute);
	return {
		...folder,
		key: `folder-${id}`,
		title: name,
		children: [...sortChildren(childFolders), ...sortChildren(childAttributes)],
	};
};

const Attribute = (attribute) => ({
	...attribute,
	key: `attribute-${attribute.id}`,
	title: attribute.name,
	isLeaf: true,
});

export const uniqBy = (key) => (list) => {
	const keyMap = list.reduce((map, item) => {
		map[item[key]] = item;
		return map;
	}, {});
	return Object.values(keyMap);
};

export const uniqById = uniqBy("id");

export const sortBy = (key) => (list) =>
	list.sort((current, next) => {
		const left = current[key]?.toLowerCase();
		const right = next[key]?.toLowerCase();
		return left < right ? -1 : left > right ? 1 : 0;
	});
